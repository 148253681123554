import { ActivityType, ChangeSetStatus, ChangeSetType, DocumentType, EventType } from '~/db_types';
import { AssemblyTemplateType, DigitalLibraryRecordType } from '~/db_types/swagger_types';
import { EntityType } from '~/nasa_ui/types';
const FileContentTypeIcons = {
    // Media
    image: 'fad fa-image',
    audio: 'fad fa-file-audio',
    video: 'fad fa-file-video',
    // Documents
    'application/pdf': 'fad fa-file-pdf',
    'application/msword': 'fad fa-file-word',
    'application/vnd.ms-word': 'fad fa-file-word',
    'application/vnd.oasis.opendocument.text': 'fad fa-file-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml': 'fad fa-file-word',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'fad fa-file-word',
    'application/vnd.ms-excel': 'fad fa-file-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml': 'fad fa-file-excel',
    'application/vnd.oasis.opendocument.spreadsheet': 'fad fa-file-excel',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'fad fa-file-excel',
    'application/vnd.ms-powerpoint': 'fad fa-file-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml': 'fad fa-file-powerpoint',
    'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'fad fa-file-powerpoint',
    'application/vnd.oasis.opendocument.presentation': 'fad fa-file-powerpoint',
    'text/plain': 'fad fa-file-alt',
    'text/html': 'fad fa-file-code',
    'application/json': 'fad fa-file-code',
    // Archives
    'application/gzip': 'fad fa-file-archive',
    'application/zip': 'fad fa-file-archive',
    'application/octet-stream': 'fad fa-file',
    default: 'fad fa-file'
};
export const getIconByContentType = (contentType) => {
    if (!contentType) {
        return CosmicIcons.help;
    }
    if (contentType.startsWith('image') || contentType.startsWith('video')) {
        const type = contentType.split('/')[0];
        if (!type) {
            return CosmicIcons.default;
        }
        return CosmicIcons[type];
    }
    return CosmicIcons[contentType] ? CosmicIcons[contentType] : CosmicIcons.default;
};
/**
 * To reduce dependancies we list the VALUE of the subtypes here
 *
 * ActivityType.AUTOCYCLE:AUTOCYCLE
 */
export const CosmicIcons = {
    ...FileContentTypeIcons,
    // For "authority information" usually consists of a name/number
    AUTHORITY: 'fad fa-gavel',
    // #region Activity Types
    [ActivityType.AUTOCYCLE]: 'fad fa-atom-alt',
    [ActivityType.CALIBRATION]: 'fad fa-bullseye-arrow',
    [ActivityType.CHARGE]: 'fad fa-atom-alt',
    [ActivityType.CHECK_OUT]: 'fad fa-atom-alt',
    [ActivityType.DISCHARGE]: 'fad fa-atom-alt',
    [ActivityType.DUMP]: 'fad fa-atom-alt',
    [ActivityType.EMU_H20_DUMP_AND_FILL]: 'fad fa-atom-alt',
    [ActivityType.EMU_H20_RECHARGE]: 'fad fa-atom-alt',
    [ActivityType.EVA_ACTIVITY]: 'fad fa-atom-alt',
    [ActivityType.FILL]: 'fad fa-atom-alt',
    [ActivityType.GREASE_AND_CIRCULATE]: 'fad fa-atom-alt',
    [ActivityType.IODINATE_LOOP_SCRUB]: 'fad fa-atom-alt',
    [ActivityType.ITEM_146_CYCLE]: 'fad fa-atom-alt',
    [ActivityType.INSPECTION]: 'fa-regular fa-eyes',
    [ActivityType.O2_POPPET_VERIFICATION]: 'fad fa-atom-alt',
    [ActivityType.OTHER]: 'fad fa-atom-alt',
    [ActivityType.REGENERATION]: 'fad fa-atom-alt',
    [ActivityType.TAK_DATA_COLLECTION]: 'fad fa-atom-alt',
    [ActivityType.TEST]: 'fad fa-atom-alt',
    [ActivityType.TETHER_INSPECTION]: 'fad fa-atom-alt',
    [ActivityType.VENT]: 'fad fa-atom-alt',
    [ActivityType.WLVTA_REPLACEMENT]: 'fad fa-atom-alt',
    // #endregion
    // Change Sets
    [ChangeSetType.ITEM_INSTANCE_ON_ORBIT_FAILURE]: 'fad fa-planet-moon',
    // Change set status (open and rejected exist in this list)
    [ChangeSetStatus.APPROVED]: 'fad fa-check-circle',
    // Digital Record types
    [DigitalLibraryRecordType.ARCHITECTURE]: 'fad fa-archway',
    [DigitalLibraryRecordType.CERTIFICATION]: 'fad fa-file-certificate',
    [DigitalLibraryRecordType.TECHNICAL_DOCUMENTATION]: 'fad fa-university',
    [DigitalLibraryRecordType.VERIFICATION]: 'fad fa-badge-check',
    // #region Document Types
    [DocumentType.C_DISCREPANCY_REPORT]: 'fal fa-fragile',
    [DocumentType.C_EASY_TRANSFER]: 'fad fa-shipping-fast',
    [DocumentType.C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM]: 'fad fa-bullhorn',
    [DocumentType.GCAR]: 'fa-duotone fa-solid fa-file-certificate',
    [DocumentType.C_RCA]: 'fad fa-comment-exclamation',
    [DocumentType.C_TPS]: 'fal fa-tasks',
    [DocumentType.C_WORKFLOW]: 'fad fa-chart-network',
    [DocumentType.C_DIGITAL_RECORD_REVIEW_ITEM]: 'fal fa-highlighter',
    [DocumentType.ACCEPTANCE_DATA_PACK]: 'fad fa-hand-holding-box',
    [DocumentType.DRAWING]: 'fas fa-drafting-compass',
    [DocumentType.DISCREPANCY_REPORT]: 'fal fa-fragile',
    [DocumentType.DELIVERY_ORDER]: 'fa-regular fa-file-invoice-dollar',
    [DocumentType.ENGINEERING_CHANGE]: 'fad fa-random',
    [DocumentType.EASY_TRANSFER]: 'fal fa-shipping-fast',
    [DocumentType.FAILURE_INVESTIGATION_ANOMALY_REPORT]: 'fad fa-fire',
    [DocumentType.MATERIAL_USAGE_AGREEMENT]: 'fas fa-handshake',
    [DocumentType.PROCEDURE]: 'fad fa-shoe-prints',
    [DocumentType.OVER_DUE_TAG]: 'fad fa-tag',
    [DocumentType.TPS]: 'fal fa-tasks',
    [DocumentType.WAIVER]: 'fa-regular fa-wave-square',
    [DocumentType.YELLOW_TAG_NOTICE]: 'fad fa-tag',
    // #endregion
    // #region EntityTypes
    [EntityType.ACTIVITY]: 'fad fa-stopwatch',
    [EntityType.APPROVAL_ROUTING_TEMPLATE]: 'fad fa-route',
    [EntityType.ASSEMBLY_TEMPLATE]: 'far fa-sitemap',
    [EntityType.ASSEMBLY_TRANSACTION]: 'fal fa-receipt',
    [EntityType.ASSEMBLY]: 'fad fa-sitemap',
    [EntityType.CHANGE_SET]: 'fad fa-code-merge',
    [EntityType.COMMENT]: 'fad fa-comment-alt-lines',
    [EntityType.CONTACT]: 'fad fa-address-book',
    [EntityType.CONTRACT]: 'fad fa-file-contract',
    [EntityType.CREW]: 'fad fa-user-astronaut',
    [EntityType.DELIVERY_MATRIX_ITEM]: 'fad fa-parachute-box',
    [EntityType.DELIVERABLE]: 'fad fa-parachute-box',
    [EntityType.DIGITAL_RECORD]: 'fad fa-record-vinyl',
    [EntityType.DOCUMENT]: 'fad fa-file-alt',
    [EntityType.EVENT]: 'fad fa-calendar-alt',
    [EntityType.HARDWARE_LIST_TEMPLATE]: 'far fa-clipboard-list',
    [EntityType.HARDWARE_LIST]: 'fad fa-clipboard-list-check',
    [EntityType.INVENTORY]: 'fa-duotone fa-solid fa-grid',
    [EntityType.ITEM_DRAWING]: 'fa-duotone fa-solid fa-square',
    [EntityType.ITEM_INSTANCE]: 'fa-solid fa-grid',
    [EntityType.ITEM_MASTER]: 'fa-duotone fa-grid-2',
    [EntityType.JF288]: 'fad fa-user-hard-hat',
    [EntityType.LOCATION]: 'fad fa-archive',
    [EntityType.MEASUREMENT]: 'fas fa-ruler-combined',
    [EntityType.ORGANIZATION]: 'fad fa-building',
    [EntityType.PHYSICAL_INVENTORY_AUDIT]: 'fad fa-tally',
    [EntityType.PRODUCT_BREAKDOWN_STRUCTURE]: 'fad fa-sitemap',
    [EntityType.RESPONSIBLE_ORGANIZATION]: 'fad fa-landmark',
    [EntityType.RISK]: 'fad fa-umbrella',
    [EntityType.TRANSACTION]: 'fal fa-receipt',
    [EntityType.USER_GROUP]: 'fad fa-users',
    [EntityType.USER]: 'fad fa-user',
    [EntityType.VEHICLE]: 'fad fa-rocket',
    // #endregion
    alias: 'fad fa-user-secret',
    movement: 'fad fa-shipping-fast',
    // Event types
    [EventType.ALLOCATION_WINDOW]: 'fa-duotone fa-window-frame-open',
    [EventType.DIGITAL_RECORD_REVIEW]: 'fad fa-album-collection',
    [EventType.LAUNCH]: 'fa-duotone fa-plane-departure',
    [EventType.RETURN]: 'fa-duotone fa-plane-arrival',
    milestones: 'fal fa-medal',
    actions: 'fad fa-star-exclamation',
    ASSEMBLY_TEMPLATE: 'far fa-sitemap',
    EVENT_ASSEMBLY: 'events--text far fa-sitemap',
    CRITICAL_INVENTORY_TRACKING: 'fad fa-dice-d20',
    CHICKLET: 'fad fa-kiwi-bird',
    // User Types
    ADMIN: 'fad fa-user-crown',
    USER: 'fad fa-user',
    SYSTEM: 'fad fa-solar-system',
    // CosmicDocumentStatus
    DRAFT: 'fad fa-construction',
    PENDING: 'fad fa-spinner',
    REJECTED: 'fad fa-times-circle',
    OPEN: 'fad fa-door-open',
    WORK_ORDER_STEP_REJECTED: 'fad fa-times-circle',
    WORK_ORDER_STEPS_COMPLETE: 'fad fa-clipboard-check',
    CLOSED: 'fad fa-door-closed',
    // API2 steps
    work_order_step: 'fad fa-clipboard',
    administrate: 'fad fa-clipboard-list',
    advanced: 'fad fa-robot',
    angle_left: 'fas fa-angle-left',
    angle_right: 'fas fa-angle-right',
    application_engineering: 'fad fa-object-group',
    application_operations: 'fad fa-file-contract',
    application_personnel: 'fad fa-users',
    application_planning: 'fad fa-calendar-day',
    application_property: 'fad fa-truck',
    application_reporting: 'fad fa-chart-line',
    arrow_down: 'fad fa-long-arrow-alt-down',
    arrow_left: 'fad fa-long-arrow-alt-left',
    arrow_right: 'fad fa-long-arrow-alt-right',
    arrow_up: 'fad fa-long-arrow-alt-up',
    archived: 'fad fa-ghost',
    attachment: 'fad fa-folder-open',
    atom: 'fad fa-atom-alt',
    bolt: 'fad fa-bolt',
    browser: 'fad fa-browser',
    calendar_day: 'fad fa-calendar-day',
    calendar: 'fad fa-calendar',
    checklist: 'fad fa-clipboard-list-check',
    chevron_down: 'fa fa-chevron-down',
    chevron_up: 'fa fa-chevron-up',
    circle_check: 'fad fa-check-circle',
    circle_plus: 'fad fa-plus-circle',
    circle: 'fad fa-circle',
    clear: 'fad fa-times-circle',
    clone: 'fad fa-clone',
    close: 'fad fa-times-circle',
    cog: 'fad fa-cog',
    comparison: 'fad fa-columns',
    complete: 'fad fa-check',
    components_isTool: 'fad fa-wrench',
    cost: 'fad fa-dollar-sign',
    create_fat: 'fas fa-plus',
    create: 'fal fa-plus',
    crews_female: 'fad fa-female',
    crews_male: 'fad fa-male',
    demo: 'fad fa-cog fa-spin',
    download: 'fad fa-cloud-download',
    edit: 'fa-duotone fa-solid fa-pen-to-square',
    ellipsis: 'fas fa-ellipsis-v',
    event: 'fad fa-calendar-day',
    exclamation: 'fad fa-exclamation',
    expand: 'fas fa-angle-up',
    external_link: 'fad fa-external-link',
    eye: 'far fa-eye',
    eye_slash: 'fas fa-eye-slash',
    filter: 'fas fa-sm fa-filter',
    group: 'fal fa-object-group',
    help: 'fad fa-question-circle',
    history: 'fad fa-history',
    home: 'fad fa-home',
    import: 'fad fa-file-import',
    info: 'fal fa-info',
    info_circle: 'fad fa-info-circle',
    info_strong: 'fas fa-info',
    install: 'fas fa-download',
    lil_edit: 'fa-light fa-pencil caption',
    limited_life: 'fad fa-sensor-alert',
    link: 'fad fa-link',
    loading: 'fad fa-spinner fa-spin',
    maars: 'fad fa-table',
    mail: 'fad fa-mail-bulk',
    metadata: 'fa-duotone fa-pen-field',
    mini: 'fad fa-rectangle-wide',
    minimize: 'fas fa-window-minimize',
    need: 'fa-regular fa-hand-holding-box',
    notes: 'fa-regular fa-notes',
    objectGroup: 'fal fa-object-group',
    paperwork: 'fad fa-scroll',
    part_reference: 'fa-regular fa-grid',
    part_tool: 'fad fa-wrench',
    pdf: 'fad fa-file-pdf',
    pipeline_approved: 'fad fa-check-circle',
    pipeline_canceled: 'fal fa-ban',
    pipeline_complete: 'fad fa-clipboard-check',
    pipeline_connector: 'fas fa-arrow-right',
    pipeline_null: 'fad fa-minus-circle',
    pipeline_open: 'fas fa-circle-notch',
    pipeline_rejected: 'fad fa-times-circle',
    play: 'fas fa-play',
    print_slash: 'fad fa-print-slash',
    print: 'fa fa-print',
    refresh: 'fad fa-sync',
    relationships: 'fad fa-spider-web',
    reload: 'fad fa-sync',
    reopen: 'fad fa-redo-alt',
    repeat: 'fad fa-repeat',
    report_bar: 'fad fa-chart-bar',
    report: 'fad fa-user-chart',
    root: 'far fa-crown',
    save: 'fad fa-save',
    scanner: 'far fa-scanner',
    search: 'fad fa-search',
    select_all: 'fad fa-check-square',
    select_all_off: 'fal fa-square',
    sort: 'px-1 fad fa-long-arrow-up',
    square_check: 'fad fa-check-square',
    star: 'fas fa-star',
    status_close: 'fad fa-door-closed',
    status_open: 'fad fa-door-open',
    stop: 'fas fa-stop',
    strong_close: 'fad fa-times-circle',
    table: 'fad fa-table',
    transaction: 'fal fa-receipt',
    trash: 'fad fa-trash-alt',
    unarchive: 'fas fa-heartbeat',
    uninstall: 'fas fa-upload',
    user_hat: 'fad fa-user-hard-hat',
    warning: 'fad fa-exclamation-triangle',
    // true and false
    true: 'fad fa-check-circle',
    false: 'fad fa-times-circle',
    // COSMIC concepts
    ACTUALIZATION: 'fad fa-hat-wizard',
    MANAGE_PAGE: 'fad fa-wrench',
    // #region MegaAllocationTypes
    ['GROUND']: 'fa-regular fa-person-arrow-down-to-line',
    ['ON_ORBIT']: 'fa-regular fa-planet-moon',
    ['TRADE_STUDY']: 'fa-regular fa-face-monocle',
    // #endregion
    // #region cTPS
    tps_warning: 'fas fa-skull-crossbones',
    tps_caution: 'fas fa-triangle-exclamation',
    // (tps notes use info_strong)
    // #endregion
    // #region AssemblyTemplate types
    [AssemblyTemplateType.MEGA]: 'fa-duotone fa-user-astronaut'
    // #endregion
};
