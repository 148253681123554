/** Methods to use when ordering `Activity`. */
export var ActivitiesOrderBy;
(function (ActivitiesOrderBy) {
    ActivitiesOrderBy["NATURAL"] = "NATURAL";
    ActivitiesOrderBy["ID_ASC"] = "ID_ASC";
    ActivitiesOrderBy["ID_DESC"] = "ID_DESC";
    ActivitiesOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    ActivitiesOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    ActivitiesOrderBy["ORGANIZATION_CODE_ASC"] = "ORGANIZATION_CODE_ASC";
    ActivitiesOrderBy["ORGANIZATION_CODE_DESC"] = "ORGANIZATION_CODE_DESC";
    ActivitiesOrderBy["NAME_ASC"] = "NAME_ASC";
    ActivitiesOrderBy["NAME_DESC"] = "NAME_DESC";
    ActivitiesOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    ActivitiesOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    ActivitiesOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    ActivitiesOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    ActivitiesOrderBy["START_DATE_TIME_ASC"] = "START_DATE_TIME_ASC";
    ActivitiesOrderBy["START_DATE_TIME_DESC"] = "START_DATE_TIME_DESC";
    ActivitiesOrderBy["END_DATE_TIME_ASC"] = "END_DATE_TIME_ASC";
    ActivitiesOrderBy["END_DATE_TIME_DESC"] = "END_DATE_TIME_DESC";
    ActivitiesOrderBy["OPERATION_TIME_ASC"] = "OPERATION_TIME_ASC";
    ActivitiesOrderBy["OPERATION_TIME_DESC"] = "OPERATION_TIME_DESC";
    ActivitiesOrderBy["STATIC_TIME_ASC"] = "STATIC_TIME_ASC";
    ActivitiesOrderBy["STATIC_TIME_DESC"] = "STATIC_TIME_DESC";
    ActivitiesOrderBy["CYCLES_ASC"] = "CYCLES_ASC";
    ActivitiesOrderBy["CYCLES_DESC"] = "CYCLES_DESC";
    ActivitiesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ActivitiesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ActivitiesOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    ActivitiesOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    ActivitiesOrderBy["AUTHORITY_NUMBER_ASC"] = "AUTHORITY_NUMBER_ASC";
    ActivitiesOrderBy["AUTHORITY_NUMBER_DESC"] = "AUTHORITY_NUMBER_DESC";
    ActivitiesOrderBy["IS_MANNED_ASC"] = "IS_MANNED_ASC";
    ActivitiesOrderBy["IS_MANNED_DESC"] = "IS_MANNED_DESC";
    ActivitiesOrderBy["PRESSURIZED_TIME_ASC"] = "PRESSURIZED_TIME_ASC";
    ActivitiesOrderBy["PRESSURIZED_TIME_DESC"] = "PRESSURIZED_TIME_DESC";
    ActivitiesOrderBy["PRESSURIZATION_RANGE_ASC"] = "PRESSURIZATION_RANGE_ASC";
    ActivitiesOrderBy["PRESSURIZATION_RANGE_DESC"] = "PRESSURIZATION_RANGE_DESC";
    ActivitiesOrderBy["AUTHORITY_CODE_ASC"] = "AUTHORITY_CODE_ASC";
    ActivitiesOrderBy["AUTHORITY_CODE_DESC"] = "AUTHORITY_CODE_DESC";
    ActivitiesOrderBy["CHANGE_SET_ID_ASC"] = "CHANGE_SET_ID_ASC";
    ActivitiesOrderBy["CHANGE_SET_ID_DESC"] = "CHANGE_SET_ID_DESC";
    ActivitiesOrderBy["CREW_TIME_ASC"] = "CREW_TIME_ASC";
    ActivitiesOrderBy["CREW_TIME_DESC"] = "CREW_TIME_DESC";
    ActivitiesOrderBy["IS_PREVENTATIVE_MAINTENANCE_ASC"] = "IS_PREVENTATIVE_MAINTENANCE_ASC";
    ActivitiesOrderBy["IS_PREVENTATIVE_MAINTENANCE_DESC"] = "IS_PREVENTATIVE_MAINTENANCE_DESC";
    ActivitiesOrderBy["CALIBRATION_TYPE_ASC"] = "CALIBRATION_TYPE_ASC";
    ActivitiesOrderBy["CALIBRATION_TYPE_DESC"] = "CALIBRATION_TYPE_DESC";
    ActivitiesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ActivitiesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ActivitiesOrderBy["ACTIVITY_DATE_ASC"] = "ACTIVITY_DATE_ASC";
    ActivitiesOrderBy["ACTIVITY_DATE_DESC"] = "ACTIVITY_DATE_DESC";
    ActivitiesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ActivitiesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ActivitiesOrderBy || (ActivitiesOrderBy = {}));
export var ActivityType;
(function (ActivityType) {
    ActivityType["AUTOCYCLE"] = "AUTOCYCLE";
    ActivityType["BATTERY_MAINTENANCE"] = "BATTERY_MAINTENANCE";
    ActivityType["CALIBRATION"] = "CALIBRATION";
    ActivityType["CHARGE"] = "CHARGE";
    ActivityType["CHECK_OUT"] = "CHECK_OUT";
    ActivityType["CYCLE"] = "CYCLE";
    ActivityType["DISCHARGE"] = "DISCHARGE";
    ActivityType["DRY_OUT"] = "DRY_OUT";
    ActivityType["DUMP"] = "DUMP";
    ActivityType["EMU_H20_DUMP_AND_FILL"] = "EMU_H20_DUMP_AND_FILL";
    ActivityType["EMU_H20_RECHARGE"] = "EMU_H20_RECHARGE";
    ActivityType["EVA_ACTIVITY"] = "EVA_ACTIVITY";
    ActivityType["FILL"] = "FILL";
    ActivityType["FLIGHT"] = "FLIGHT";
    ActivityType["GREASE_AND_CIRCULATE"] = "GREASE_AND_CIRCULATE";
    ActivityType["GSE_CALIBRATION"] = "GSE_CALIBRATION";
    ActivityType["RECEIVING_INSPECTION"] = "RECEIVING_INSPECTION";
    ActivityType["INTERVAL"] = "INTERVAL";
    ActivityType["IODINATE_LOOP_SCRUB"] = "IODINATE_LOOP_SCRUB";
    ActivityType["ITEM_146_CYCLE"] = "ITEM_146_CYCLE";
    ActivityType["MAINTENANCE"] = "MAINTENANCE";
    ActivityType["O2_POPPET_VERIFICATION"] = "O2_POPPET_VERIFICATION";
    ActivityType["OPERATION"] = "OPERATION";
    ActivityType["OTHER"] = "OTHER";
    ActivityType["PRESSURIZATION"] = "PRESSURIZATION";
    ActivityType["REGENERATION"] = "REGENERATION";
    ActivityType["RESIZE_OR_DRY_RUN"] = "RESIZE_OR_DRY_RUN";
    ActivityType["SERVICE"] = "SERVICE";
    ActivityType["TAK_DATA_COLLECTION"] = "TAK_DATA_COLLECTION";
    ActivityType["TEN_POINT_TWO_OPS"] = "TEN_POINT_TWO_OPS";
    ActivityType["TEST"] = "TEST";
    ActivityType["TETHER_INSPECTION"] = "TETHER_INSPECTION";
    ActivityType["WETTING_OR_IODINATING"] = "WETTING_OR_IODINATING";
    ActivityType["WLVTA_REPLACEMENT"] = "WLVTA_REPLACEMENT";
    ActivityType["VENT"] = "VENT";
    ActivityType["GMIP"] = "GMIP";
    ActivityType["MIP"] = "MIP";
    ActivityType["INSPECTION"] = "INSPECTION";
})(ActivityType || (ActivityType = {}));
/** Methods to use when ordering `ApprovalRoutingGroup`. */
export var ApprovalRoutingGroupsOrderBy;
(function (ApprovalRoutingGroupsOrderBy) {
    ApprovalRoutingGroupsOrderBy["NATURAL"] = "NATURAL";
    ApprovalRoutingGroupsOrderBy["APPROVAL_ROUTING_TEMPLATE_ID_ASC"] = "APPROVAL_ROUTING_TEMPLATE_ID_ASC";
    ApprovalRoutingGroupsOrderBy["APPROVAL_ROUTING_TEMPLATE_ID_DESC"] = "APPROVAL_ROUTING_TEMPLATE_ID_DESC";
    ApprovalRoutingGroupsOrderBy["GROUP_CODE_ASC"] = "GROUP_CODE_ASC";
    ApprovalRoutingGroupsOrderBy["GROUP_CODE_DESC"] = "GROUP_CODE_DESC";
    ApprovalRoutingGroupsOrderBy["SEQUENCE_ASC"] = "SEQUENCE_ASC";
    ApprovalRoutingGroupsOrderBy["SEQUENCE_DESC"] = "SEQUENCE_DESC";
    ApprovalRoutingGroupsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ApprovalRoutingGroupsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ApprovalRoutingGroupsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ApprovalRoutingGroupsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ApprovalRoutingGroupsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ApprovalRoutingGroupsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ApprovalRoutingGroupsOrderBy || (ApprovalRoutingGroupsOrderBy = {}));
/** Methods to use when ordering `ApprovalRoutingTemplate`. */
export var ApprovalRoutingTemplatesOrderBy;
(function (ApprovalRoutingTemplatesOrderBy) {
    ApprovalRoutingTemplatesOrderBy["NATURAL"] = "NATURAL";
    ApprovalRoutingTemplatesOrderBy["ID_ASC"] = "ID_ASC";
    ApprovalRoutingTemplatesOrderBy["ID_DESC"] = "ID_DESC";
    ApprovalRoutingTemplatesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ApprovalRoutingTemplatesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ApprovalRoutingTemplatesOrderBy["NAME_ASC"] = "NAME_ASC";
    ApprovalRoutingTemplatesOrderBy["NAME_DESC"] = "NAME_DESC";
    ApprovalRoutingTemplatesOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    ApprovalRoutingTemplatesOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    ApprovalRoutingTemplatesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ApprovalRoutingTemplatesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ApprovalRoutingTemplatesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ApprovalRoutingTemplatesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ApprovalRoutingTemplatesOrderBy || (ApprovalRoutingTemplatesOrderBy = {}));
/** Methods to use when ordering `AssemblyTemplateNode`. */
export var AssemblyTemplateNodesOrderBy;
(function (AssemblyTemplateNodesOrderBy) {
    AssemblyTemplateNodesOrderBy["NATURAL"] = "NATURAL";
    AssemblyTemplateNodesOrderBy["NODE_DEPTH_ASC"] = "NODE_DEPTH_ASC";
    AssemblyTemplateNodesOrderBy["NODE_DEPTH_DESC"] = "NODE_DEPTH_DESC";
    AssemblyTemplateNodesOrderBy["SEQUENCE_ASC"] = "SEQUENCE_ASC";
    AssemblyTemplateNodesOrderBy["SEQUENCE_DESC"] = "SEQUENCE_DESC";
    AssemblyTemplateNodesOrderBy["SORT_KEY_ASC"] = "SORT_KEY_ASC";
    AssemblyTemplateNodesOrderBy["SORT_KEY_DESC"] = "SORT_KEY_DESC";
    AssemblyTemplateNodesOrderBy["ASSEMBLY_TEMPLATE_ID_ASC"] = "ASSEMBLY_TEMPLATE_ID_ASC";
    AssemblyTemplateNodesOrderBy["ASSEMBLY_TEMPLATE_ID_DESC"] = "ASSEMBLY_TEMPLATE_ID_DESC";
    AssemblyTemplateNodesOrderBy["NAME_ASC"] = "NAME_ASC";
    AssemblyTemplateNodesOrderBy["NAME_DESC"] = "NAME_DESC";
    AssemblyTemplateNodesOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    AssemblyTemplateNodesOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    AssemblyTemplateNodesOrderBy["ROOT_ASSEMBLY_TEMPLATE_ID_ASC"] = "ROOT_ASSEMBLY_TEMPLATE_ID_ASC";
    AssemblyTemplateNodesOrderBy["ROOT_ASSEMBLY_TEMPLATE_ID_DESC"] = "ROOT_ASSEMBLY_TEMPLATE_ID_DESC";
})(AssemblyTemplateNodesOrderBy || (AssemblyTemplateNodesOrderBy = {}));
/** Methods to use when ordering `AssemblyTemplatePart`. */
export var AssemblyTemplatePartsOrderBy;
(function (AssemblyTemplatePartsOrderBy) {
    AssemblyTemplatePartsOrderBy["NATURAL"] = "NATURAL";
    AssemblyTemplatePartsOrderBy["ID_ASC"] = "ID_ASC";
    AssemblyTemplatePartsOrderBy["ID_DESC"] = "ID_DESC";
    AssemblyTemplatePartsOrderBy["ASSEMBLY_TEMPLATE_ID_ASC"] = "ASSEMBLY_TEMPLATE_ID_ASC";
    AssemblyTemplatePartsOrderBy["ASSEMBLY_TEMPLATE_ID_DESC"] = "ASSEMBLY_TEMPLATE_ID_DESC";
    AssemblyTemplatePartsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    AssemblyTemplatePartsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    AssemblyTemplatePartsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    AssemblyTemplatePartsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    AssemblyTemplatePartsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    AssemblyTemplatePartsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    AssemblyTemplatePartsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    AssemblyTemplatePartsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    AssemblyTemplatePartsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    AssemblyTemplatePartsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    AssemblyTemplatePartsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    AssemblyTemplatePartsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    AssemblyTemplatePartsOrderBy["SERIAL_NUMBER_ASC"] = "SERIAL_NUMBER_ASC";
    AssemblyTemplatePartsOrderBy["SERIAL_NUMBER_DESC"] = "SERIAL_NUMBER_DESC";
})(AssemblyTemplatePartsOrderBy || (AssemblyTemplatePartsOrderBy = {}));
/** Methods to use when ordering `AssemblyTemplate`. */
export var AssemblyTemplatesOrderBy;
(function (AssemblyTemplatesOrderBy) {
    AssemblyTemplatesOrderBy["NATURAL"] = "NATURAL";
    AssemblyTemplatesOrderBy["ID_ASC"] = "ID_ASC";
    AssemblyTemplatesOrderBy["ID_DESC"] = "ID_DESC";
    AssemblyTemplatesOrderBy["NAME_ASC"] = "NAME_ASC";
    AssemblyTemplatesOrderBy["NAME_DESC"] = "NAME_DESC";
    AssemblyTemplatesOrderBy["SEQUENCE_ASC"] = "SEQUENCE_ASC";
    AssemblyTemplatesOrderBy["SEQUENCE_DESC"] = "SEQUENCE_DESC";
    AssemblyTemplatesOrderBy["MIN_QUANTITY_ASC"] = "MIN_QUANTITY_ASC";
    AssemblyTemplatesOrderBy["MIN_QUANTITY_DESC"] = "MIN_QUANTITY_DESC";
    AssemblyTemplatesOrderBy["MAX_QUANTITY_ASC"] = "MAX_QUANTITY_ASC";
    AssemblyTemplatesOrderBy["MAX_QUANTITY_DESC"] = "MAX_QUANTITY_DESC";
    AssemblyTemplatesOrderBy["PARENT_ID_ASC"] = "PARENT_ID_ASC";
    AssemblyTemplatesOrderBy["PARENT_ID_DESC"] = "PARENT_ID_DESC";
    AssemblyTemplatesOrderBy["PBS_ITEM_ID_ASC"] = "PBS_ITEM_ID_ASC";
    AssemblyTemplatesOrderBy["PBS_ITEM_ID_DESC"] = "PBS_ITEM_ID_DESC";
    AssemblyTemplatesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    AssemblyTemplatesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    AssemblyTemplatesOrderBy["INSTALLED_ON_ASC"] = "INSTALLED_ON_ASC";
    AssemblyTemplatesOrderBy["INSTALLED_ON_DESC"] = "INSTALLED_ON_DESC";
    AssemblyTemplatesOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    AssemblyTemplatesOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    AssemblyTemplatesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    AssemblyTemplatesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    AssemblyTemplatesOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    AssemblyTemplatesOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    AssemblyTemplatesOrderBy["DIGITAL_LIBRARY_RECORD_ID_ASC"] = "DIGITAL_LIBRARY_RECORD_ID_ASC";
    AssemblyTemplatesOrderBy["DIGITAL_LIBRARY_RECORD_ID_DESC"] = "DIGITAL_LIBRARY_RECORD_ID_DESC";
    AssemblyTemplatesOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    AssemblyTemplatesOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    AssemblyTemplatesOrderBy["AUTO_HARDWARE_LIST_ID_ASC"] = "AUTO_HARDWARE_LIST_ID_ASC";
    AssemblyTemplatesOrderBy["AUTO_HARDWARE_LIST_ID_DESC"] = "AUTO_HARDWARE_LIST_ID_DESC";
    AssemblyTemplatesOrderBy["WAIVER_DATE_ASC"] = "WAIVER_DATE_ASC";
    AssemblyTemplatesOrderBy["WAIVER_DATE_DESC"] = "WAIVER_DATE_DESC";
    AssemblyTemplatesOrderBy["WAIVER_NUMBER_ASC"] = "WAIVER_NUMBER_ASC";
    AssemblyTemplatesOrderBy["WAIVER_NUMBER_DESC"] = "WAIVER_NUMBER_DESC";
    AssemblyTemplatesOrderBy["NEED_DATE_ASC"] = "NEED_DATE_ASC";
    AssemblyTemplatesOrderBy["NEED_DATE_DESC"] = "NEED_DATE_DESC";
    AssemblyTemplatesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    AssemblyTemplatesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(AssemblyTemplatesOrderBy || (AssemblyTemplatesOrderBy = {}));
/** Methods to use when ordering `AssemblyTemplatesToEvent`. */
export var AssemblyTemplatesToEventsOrderBy;
(function (AssemblyTemplatesToEventsOrderBy) {
    AssemblyTemplatesToEventsOrderBy["NATURAL"] = "NATURAL";
    AssemblyTemplatesToEventsOrderBy["ASSEMBLY_TEMPLATE_ID_ASC"] = "ASSEMBLY_TEMPLATE_ID_ASC";
    AssemblyTemplatesToEventsOrderBy["ASSEMBLY_TEMPLATE_ID_DESC"] = "ASSEMBLY_TEMPLATE_ID_DESC";
    AssemblyTemplatesToEventsOrderBy["COSMIC_EVENT_ID_ASC"] = "COSMIC_EVENT_ID_ASC";
    AssemblyTemplatesToEventsOrderBy["COSMIC_EVENT_ID_DESC"] = "COSMIC_EVENT_ID_DESC";
    AssemblyTemplatesToEventsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    AssemblyTemplatesToEventsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    AssemblyTemplatesToEventsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    AssemblyTemplatesToEventsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    AssemblyTemplatesToEventsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    AssemblyTemplatesToEventsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(AssemblyTemplatesToEventsOrderBy || (AssemblyTemplatesToEventsOrderBy = {}));
/** Methods to use when ordering `Attachment`. */
export var AttachmentsOrderBy;
(function (AttachmentsOrderBy) {
    AttachmentsOrderBy["NATURAL"] = "NATURAL";
    AttachmentsOrderBy["ID_ASC"] = "ID_ASC";
    AttachmentsOrderBy["ID_DESC"] = "ID_DESC";
    AttachmentsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    AttachmentsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    AttachmentsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    AttachmentsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    AttachmentsOrderBy["IS_COMPLETED_ASC"] = "IS_COMPLETED_ASC";
    AttachmentsOrderBy["IS_COMPLETED_DESC"] = "IS_COMPLETED_DESC";
    AttachmentsOrderBy["KEY_ASC"] = "KEY_ASC";
    AttachmentsOrderBy["KEY_DESC"] = "KEY_DESC";
    AttachmentsOrderBy["FILE_NAME_ASC"] = "FILE_NAME_ASC";
    AttachmentsOrderBy["FILE_NAME_DESC"] = "FILE_NAME_DESC";
    AttachmentsOrderBy["FILE_SIZE_ASC"] = "FILE_SIZE_ASC";
    AttachmentsOrderBy["FILE_SIZE_DESC"] = "FILE_SIZE_DESC";
    AttachmentsOrderBy["CONTENT_TYPE_ASC"] = "CONTENT_TYPE_ASC";
    AttachmentsOrderBy["CONTENT_TYPE_DESC"] = "CONTENT_TYPE_DESC";
    AttachmentsOrderBy["ETAG_ASC"] = "ETAG_ASC";
    AttachmentsOrderBy["ETAG_DESC"] = "ETAG_DESC";
    AttachmentsOrderBy["HASH_ASC"] = "HASH_ASC";
    AttachmentsOrderBy["HASH_DESC"] = "HASH_DESC";
    AttachmentsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    AttachmentsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    AttachmentsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    AttachmentsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    AttachmentsOrderBy["METADATA_ASC"] = "METADATA_ASC";
    AttachmentsOrderBy["METADATA_DESC"] = "METADATA_DESC";
    AttachmentsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    AttachmentsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(AttachmentsOrderBy || (AttachmentsOrderBy = {}));
/** Methods to use when ordering `AttachmentsToChangeSet`. */
export var AttachmentsToChangeSetsOrderBy;
(function (AttachmentsToChangeSetsOrderBy) {
    AttachmentsToChangeSetsOrderBy["NATURAL"] = "NATURAL";
    AttachmentsToChangeSetsOrderBy["ATTACHMENT_ID_ASC"] = "ATTACHMENT_ID_ASC";
    AttachmentsToChangeSetsOrderBy["ATTACHMENT_ID_DESC"] = "ATTACHMENT_ID_DESC";
    AttachmentsToChangeSetsOrderBy["CHANGE_SET_ID_ASC"] = "CHANGE_SET_ID_ASC";
    AttachmentsToChangeSetsOrderBy["CHANGE_SET_ID_DESC"] = "CHANGE_SET_ID_DESC";
    AttachmentsToChangeSetsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    AttachmentsToChangeSetsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    AttachmentsToChangeSetsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    AttachmentsToChangeSetsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    AttachmentsToChangeSetsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    AttachmentsToChangeSetsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(AttachmentsToChangeSetsOrderBy || (AttachmentsToChangeSetsOrderBy = {}));
/** Methods to use when ordering `AttachmentsToDocument`. */
export var AttachmentsToDocumentsOrderBy;
(function (AttachmentsToDocumentsOrderBy) {
    AttachmentsToDocumentsOrderBy["NATURAL"] = "NATURAL";
    AttachmentsToDocumentsOrderBy["ATTACHMENT_ID_ASC"] = "ATTACHMENT_ID_ASC";
    AttachmentsToDocumentsOrderBy["ATTACHMENT_ID_DESC"] = "ATTACHMENT_ID_DESC";
    AttachmentsToDocumentsOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    AttachmentsToDocumentsOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    AttachmentsToDocumentsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    AttachmentsToDocumentsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    AttachmentsToDocumentsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    AttachmentsToDocumentsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    AttachmentsToDocumentsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    AttachmentsToDocumentsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(AttachmentsToDocumentsOrderBy || (AttachmentsToDocumentsOrderBy = {}));
/** Methods to use when ordering `AttachmentsToItem`. */
export var AttachmentsToItemsOrderBy;
(function (AttachmentsToItemsOrderBy) {
    AttachmentsToItemsOrderBy["NATURAL"] = "NATURAL";
    AttachmentsToItemsOrderBy["ID_ASC"] = "ID_ASC";
    AttachmentsToItemsOrderBy["ID_DESC"] = "ID_DESC";
    AttachmentsToItemsOrderBy["ATTACHMENT_ID_ASC"] = "ATTACHMENT_ID_ASC";
    AttachmentsToItemsOrderBy["ATTACHMENT_ID_DESC"] = "ATTACHMENT_ID_DESC";
    AttachmentsToItemsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    AttachmentsToItemsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    AttachmentsToItemsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    AttachmentsToItemsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    AttachmentsToItemsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    AttachmentsToItemsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    AttachmentsToItemsOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    AttachmentsToItemsOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    AttachmentsToItemsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    AttachmentsToItemsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    AttachmentsToItemsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    AttachmentsToItemsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    AttachmentsToItemsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    AttachmentsToItemsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(AttachmentsToItemsOrderBy || (AttachmentsToItemsOrderBy = {}));
export var AttachmentType;
(function (AttachmentType) {
    AttachmentType["GENERAL"] = "GENERAL";
    AttachmentType["INCIDENT_ANALYSIS"] = "INCIDENT_ANALYSIS";
    AttachmentType["CORRECTIVE_ACTION"] = "CORRECTIVE_ACTION";
    AttachmentType["CLOSEOUT"] = "CLOSEOUT";
    AttachmentType["WORK_ORDER_STEP"] = "WORK_ORDER_STEP";
    AttachmentType["DRAWING"] = "DRAWING";
    AttachmentType["PROCEDURE"] = "PROCEDURE";
    AttachmentType["PROCEDURE_EXECUTED_COPY"] = "PROCEDURE_EXECUTED_COPY";
    AttachmentType["REFERENCE_DOCUMENT"] = "REFERENCE_DOCUMENT";
})(AttachmentType || (AttachmentType = {}));
/** Methods to use when ordering `CalibrationQuota`. */
export var CalibrationQuotasOrderBy;
(function (CalibrationQuotasOrderBy) {
    CalibrationQuotasOrderBy["NATURAL"] = "NATURAL";
    CalibrationQuotasOrderBy["ID_ASC"] = "ID_ASC";
    CalibrationQuotasOrderBy["ID_DESC"] = "ID_DESC";
    CalibrationQuotasOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CalibrationQuotasOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CalibrationQuotasOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    CalibrationQuotasOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    CalibrationQuotasOrderBy["SIDE_ASC"] = "SIDE_ASC";
    CalibrationQuotasOrderBy["SIDE_DESC"] = "SIDE_DESC";
    CalibrationQuotasOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    CalibrationQuotasOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    CalibrationQuotasOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CalibrationQuotasOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CalibrationQuotasOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    CalibrationQuotasOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    CalibrationQuotasOrderBy["VALUE_ASC"] = "VALUE_ASC";
    CalibrationQuotasOrderBy["VALUE_DESC"] = "VALUE_DESC";
    CalibrationQuotasOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    CalibrationQuotasOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    CalibrationQuotasOrderBy["RANGE_TYPE_ASC"] = "RANGE_TYPE_ASC";
    CalibrationQuotasOrderBy["RANGE_TYPE_DESC"] = "RANGE_TYPE_DESC";
    CalibrationQuotasOrderBy["RANGE_VALUE_ASC"] = "RANGE_VALUE_ASC";
    CalibrationQuotasOrderBy["RANGE_VALUE_DESC"] = "RANGE_VALUE_DESC";
    CalibrationQuotasOrderBy["SERIAL_NUMBER_ASC"] = "SERIAL_NUMBER_ASC";
    CalibrationQuotasOrderBy["SERIAL_NUMBER_DESC"] = "SERIAL_NUMBER_DESC";
    CalibrationQuotasOrderBy["LOT_NUMBER_ASC"] = "LOT_NUMBER_ASC";
    CalibrationQuotasOrderBy["LOT_NUMBER_DESC"] = "LOT_NUMBER_DESC";
    CalibrationQuotasOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CalibrationQuotasOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CalibrationQuotasOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CalibrationQuotasOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CalibrationQuotasOrderBy["DUE_DATE_ASC"] = "DUE_DATE_ASC";
    CalibrationQuotasOrderBy["DUE_DATE_DESC"] = "DUE_DATE_DESC";
    CalibrationQuotasOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CalibrationQuotasOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CalibrationQuotasOrderBy || (CalibrationQuotasOrderBy = {}));
export var CalibrationType;
(function (CalibrationType) {
    CalibrationType["ANNUAL_CERTIFICATION"] = "ANNUAL_CERTIFICATION";
    CalibrationType["ANNUAL_LEAKAGE"] = "ANNUAL_LEAKAGE";
    CalibrationType["ANNUAL_SAMPLING"] = "ANNUAL_SAMPLING";
    CalibrationType["CALIBRATION"] = "CALIBRATION";
    CalibrationType["CLASS_I_INSPECTION"] = "CLASS_I_INSPECTION";
    CalibrationType["CLASS_II_INSPECTION"] = "CLASS_II_INSPECTION";
    CalibrationType["CLASS_III_INSPECTION"] = "CLASS_III_INSPECTION";
    CalibrationType["DOT_CERTIFICATION"] = "DOT_CERTIFICATION";
    CalibrationType["FILTER_CHANGE_OUT"] = "FILTER_CHANGE_OUT";
    CalibrationType["FUNCTIONAL_CHECK_OUT"] = "FUNCTIONAL_CHECK_OUT";
    CalibrationType["GENERAL"] = "GENERAL";
    CalibrationType["H2O_IODINE_TREATMENT"] = "H2O_IODINE_TREATMENT";
    CalibrationType["HYDROSTATIC_TEST"] = "HYDROSTATIC_TEST";
    CalibrationType["IN_HOUSE_CALIBRATION"] = "IN_HOUSE_CALIBRATION";
    CalibrationType["LOAD_CERTIFICATION"] = "LOAD_CERTIFICATION";
    CalibrationType["MONTHLY_SAMPLING"] = "MONTHLY_SAMPLING";
    CalibrationType["PNEUMATIC_TEST"] = "PNEUMATIC_TEST";
    CalibrationType["PREVENTATIVE_MAINTENANCE"] = "PREVENTATIVE_MAINTENANCE";
    CalibrationType["PROOF_PRESSURE_TEST"] = "PROOF_PRESSURE_TEST";
    CalibrationType["QUARTERLY_CERTIFICATION"] = "QUARTERLY_CERTIFICATION";
    CalibrationType["RADIATION_SAFETY_CERTIFICATE"] = "RADIATION_SAFETY_CERTIFICATE";
    CalibrationType["RELIEF_VALVE_CERTIFICATION"] = "RELIEF_VALVE_CERTIFICATION";
})(CalibrationType || (CalibrationType = {}));
/** Methods to use when ordering `ChangeLog`. */
export var ChangeLogsOrderBy;
(function (ChangeLogsOrderBy) {
    ChangeLogsOrderBy["NATURAL"] = "NATURAL";
    ChangeLogsOrderBy["ID_ASC"] = "ID_ASC";
    ChangeLogsOrderBy["ID_DESC"] = "ID_DESC";
    ChangeLogsOrderBy["CHANGE_SET_ID_ASC"] = "CHANGE_SET_ID_ASC";
    ChangeLogsOrderBy["CHANGE_SET_ID_DESC"] = "CHANGE_SET_ID_DESC";
    ChangeLogsOrderBy["CHANGE_DETAILS_ASC"] = "CHANGE_DETAILS_ASC";
    ChangeLogsOrderBy["CHANGE_DETAILS_DESC"] = "CHANGE_DETAILS_DESC";
    ChangeLogsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ChangeLogsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ChangeLogsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    ChangeLogsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    ChangeLogsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    ChangeLogsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    ChangeLogsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    ChangeLogsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    ChangeLogsOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    ChangeLogsOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    ChangeLogsOrderBy["INVENTORY_ID_ASC"] = "INVENTORY_ID_ASC";
    ChangeLogsOrderBy["INVENTORY_ID_DESC"] = "INVENTORY_ID_DESC";
    ChangeLogsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    ChangeLogsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    ChangeLogsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ChangeLogsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ChangeLogsOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    ChangeLogsOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    ChangeLogsOrderBy["DELIVERABLE_ID_ASC"] = "DELIVERABLE_ID_ASC";
    ChangeLogsOrderBy["DELIVERABLE_ID_DESC"] = "DELIVERABLE_ID_DESC";
    ChangeLogsOrderBy["DIGITAL_LIBRARY_RECORD_ID_ASC"] = "DIGITAL_LIBRARY_RECORD_ID_ASC";
    ChangeLogsOrderBy["DIGITAL_LIBRARY_RECORD_ID_DESC"] = "DIGITAL_LIBRARY_RECORD_ID_DESC";
    ChangeLogsOrderBy["COSMIC_EVENT_ID_ASC"] = "COSMIC_EVENT_ID_ASC";
    ChangeLogsOrderBy["COSMIC_EVENT_ID_DESC"] = "COSMIC_EVENT_ID_DESC";
    ChangeLogsOrderBy["WORK_ORDER_STEP_FORM_FIELD_ID_ASC"] = "WORK_ORDER_STEP_FORM_FIELD_ID_ASC";
    ChangeLogsOrderBy["WORK_ORDER_STEP_FORM_FIELD_ID_DESC"] = "WORK_ORDER_STEP_FORM_FIELD_ID_DESC";
    ChangeLogsOrderBy["CHANGE_SET_AUTHORITY_ID_ASC"] = "CHANGE_SET_AUTHORITY_ID_ASC";
    ChangeLogsOrderBy["CHANGE_SET_AUTHORITY_ID_DESC"] = "CHANGE_SET_AUTHORITY_ID_DESC";
    ChangeLogsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ChangeLogsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ChangeLogsOrderBy || (ChangeLogsOrderBy = {}));
export var ChangeLogType;
(function (ChangeLogType) {
    ChangeLogType["CHANGE_SET"] = "CHANGE_SET";
    ChangeLogType["ITEM_INSTANCE_MERGE"] = "ITEM_INSTANCE_MERGE";
    ChangeLogType["ENTITY_UPDATE"] = "ENTITY_UPDATE";
    ChangeLogType["ENTITY_CREATE"] = "ENTITY_CREATE";
    ChangeLogType["ENTITY_DELETE"] = "ENTITY_DELETE";
})(ChangeLogType || (ChangeLogType = {}));
/** Methods to use when ordering `ChangeSetAuthority`. */
export var ChangeSetAuthoritiesOrderBy;
(function (ChangeSetAuthoritiesOrderBy) {
    ChangeSetAuthoritiesOrderBy["NATURAL"] = "NATURAL";
    ChangeSetAuthoritiesOrderBy["ID_ASC"] = "ID_ASC";
    ChangeSetAuthoritiesOrderBy["ID_DESC"] = "ID_DESC";
    ChangeSetAuthoritiesOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    ChangeSetAuthoritiesOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    ChangeSetAuthoritiesOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    ChangeSetAuthoritiesOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    ChangeSetAuthoritiesOrderBy["CHANGE_SET_ID_ASC"] = "CHANGE_SET_ID_ASC";
    ChangeSetAuthoritiesOrderBy["CHANGE_SET_ID_DESC"] = "CHANGE_SET_ID_DESC";
    ChangeSetAuthoritiesOrderBy["TEMPLATE_ID_ASC"] = "TEMPLATE_ID_ASC";
    ChangeSetAuthoritiesOrderBy["TEMPLATE_ID_DESC"] = "TEMPLATE_ID_DESC";
    ChangeSetAuthoritiesOrderBy["IS_HISTORICAL_ASC"] = "IS_HISTORICAL_ASC";
    ChangeSetAuthoritiesOrderBy["IS_HISTORICAL_DESC"] = "IS_HISTORICAL_DESC";
    ChangeSetAuthoritiesOrderBy["STATUS_ASC"] = "STATUS_ASC";
    ChangeSetAuthoritiesOrderBy["STATUS_DESC"] = "STATUS_DESC";
    ChangeSetAuthoritiesOrderBy["QUALITY_CONTROL_STAMP_ASC"] = "QUALITY_CONTROL_STAMP_ASC";
    ChangeSetAuthoritiesOrderBy["QUALITY_CONTROL_STAMP_DESC"] = "QUALITY_CONTROL_STAMP_DESC";
    ChangeSetAuthoritiesOrderBy["AUTHORIZED_DATE_ASC"] = "AUTHORIZED_DATE_ASC";
    ChangeSetAuthoritiesOrderBy["AUTHORIZED_DATE_DESC"] = "AUTHORIZED_DATE_DESC";
    ChangeSetAuthoritiesOrderBy["PROJECTED_DATE_ASC"] = "PROJECTED_DATE_ASC";
    ChangeSetAuthoritiesOrderBy["PROJECTED_DATE_DESC"] = "PROJECTED_DATE_DESC";
    ChangeSetAuthoritiesOrderBy["GROUP_CODE_ASC"] = "GROUP_CODE_ASC";
    ChangeSetAuthoritiesOrderBy["GROUP_CODE_DESC"] = "GROUP_CODE_DESC";
    ChangeSetAuthoritiesOrderBy["REQUIRES_QUALITY_CONTROL_STAMP_ASC"] = "REQUIRES_QUALITY_CONTROL_STAMP_ASC";
    ChangeSetAuthoritiesOrderBy["REQUIRES_QUALITY_CONTROL_STAMP_DESC"] = "REQUIRES_QUALITY_CONTROL_STAMP_DESC";
    ChangeSetAuthoritiesOrderBy["REQUIRES_COMMENT_ASC"] = "REQUIRES_COMMENT_ASC";
    ChangeSetAuthoritiesOrderBy["REQUIRES_COMMENT_DESC"] = "REQUIRES_COMMENT_DESC";
    ChangeSetAuthoritiesOrderBy["MANUAL_INSPECTION_POINT_TYPE_ASC"] = "MANUAL_INSPECTION_POINT_TYPE_ASC";
    ChangeSetAuthoritiesOrderBy["MANUAL_INSPECTION_POINT_TYPE_DESC"] = "MANUAL_INSPECTION_POINT_TYPE_DESC";
    ChangeSetAuthoritiesOrderBy["MANUAL_INSPECTION_POINT_EXPLANATION_ASC"] = "MANUAL_INSPECTION_POINT_EXPLANATION_ASC";
    ChangeSetAuthoritiesOrderBy["MANUAL_INSPECTION_POINT_EXPLANATION_DESC"] = "MANUAL_INSPECTION_POINT_EXPLANATION_DESC";
    ChangeSetAuthoritiesOrderBy["AUTHORITY_TYPE_ASC"] = "AUTHORITY_TYPE_ASC";
    ChangeSetAuthoritiesOrderBy["AUTHORITY_TYPE_DESC"] = "AUTHORITY_TYPE_DESC";
    ChangeSetAuthoritiesOrderBy["AUTHORIZED_BY_USER_ID_ASC"] = "AUTHORIZED_BY_USER_ID_ASC";
    ChangeSetAuthoritiesOrderBy["AUTHORIZED_BY_USER_ID_DESC"] = "AUTHORIZED_BY_USER_ID_DESC";
    ChangeSetAuthoritiesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ChangeSetAuthoritiesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ChangeSetAuthoritiesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ChangeSetAuthoritiesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ChangeSetAuthoritiesOrderBy["IS_LINE_ITEM_ASC"] = "IS_LINE_ITEM_ASC";
    ChangeSetAuthoritiesOrderBy["IS_LINE_ITEM_DESC"] = "IS_LINE_ITEM_DESC";
    ChangeSetAuthoritiesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ChangeSetAuthoritiesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ChangeSetAuthoritiesOrderBy || (ChangeSetAuthoritiesOrderBy = {}));
export var ChangeSetAuthorityType;
(function (ChangeSetAuthorityType) {
    ChangeSetAuthorityType["DEFAULT"] = "DEFAULT";
    ChangeSetAuthorityType["MANUAL_INSPECTION_POINT"] = "MANUAL_INSPECTION_POINT";
    ChangeSetAuthorityType["GOVERNMENT_MANUAL_INSPECTION_POINT"] = "GOVERNMENT_MANUAL_INSPECTION_POINT";
})(ChangeSetAuthorityType || (ChangeSetAuthorityType = {}));
export var ChangeSetFilterStatus;
(function (ChangeSetFilterStatus) {
    ChangeSetFilterStatus["INCLUDE"] = "INCLUDE";
    ChangeSetFilterStatus["EXCLUDE"] = "EXCLUDE";
    ChangeSetFilterStatus["PENDING"] = "PENDING";
})(ChangeSetFilterStatus || (ChangeSetFilterStatus = {}));
/** Methods to use when ordering `ChangeSetInventoryTransaction`. */
export var ChangeSetInventoryTransactionsOrderBy;
(function (ChangeSetInventoryTransactionsOrderBy) {
    ChangeSetInventoryTransactionsOrderBy["NATURAL"] = "NATURAL";
    ChangeSetInventoryTransactionsOrderBy["CHANGE_SET_ID_ASC"] = "CHANGE_SET_ID_ASC";
    ChangeSetInventoryTransactionsOrderBy["CHANGE_SET_ID_DESC"] = "CHANGE_SET_ID_DESC";
    ChangeSetInventoryTransactionsOrderBy["TO_ORGANIZATION_CODE_ASC"] = "TO_ORGANIZATION_CODE_ASC";
    ChangeSetInventoryTransactionsOrderBy["TO_ORGANIZATION_CODE_DESC"] = "TO_ORGANIZATION_CODE_DESC";
    ChangeSetInventoryTransactionsOrderBy["TO_LOCATION_ID_ASC"] = "TO_LOCATION_ID_ASC";
    ChangeSetInventoryTransactionsOrderBy["TO_LOCATION_ID_DESC"] = "TO_LOCATION_ID_DESC";
    ChangeSetInventoryTransactionsOrderBy["TO_ISSUE_DATE_ASC"] = "TO_ISSUE_DATE_ASC";
    ChangeSetInventoryTransactionsOrderBy["TO_ISSUE_DATE_DESC"] = "TO_ISSUE_DATE_DESC";
    ChangeSetInventoryTransactionsOrderBy["EXPECTED_RETURN_DATE_ASC"] = "EXPECTED_RETURN_DATE_ASC";
    ChangeSetInventoryTransactionsOrderBy["EXPECTED_RETURN_DATE_DESC"] = "EXPECTED_RETURN_DATE_DESC";
    ChangeSetInventoryTransactionsOrderBy["QUANTITY_ASC"] = "QUANTITY_ASC";
    ChangeSetInventoryTransactionsOrderBy["QUANTITY_DESC"] = "QUANTITY_DESC";
    ChangeSetInventoryTransactionsOrderBy["STATUS_ASC"] = "STATUS_ASC";
    ChangeSetInventoryTransactionsOrderBy["STATUS_DESC"] = "STATUS_DESC";
    ChangeSetInventoryTransactionsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ChangeSetInventoryTransactionsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ChangeSetInventoryTransactionsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ChangeSetInventoryTransactionsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ChangeSetInventoryTransactionsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ChangeSetInventoryTransactionsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ChangeSetInventoryTransactionsOrderBy || (ChangeSetInventoryTransactionsOrderBy = {}));
export var ChangeSetInventoryTransactionStatus;
(function (ChangeSetInventoryTransactionStatus) {
    ChangeSetInventoryTransactionStatus["OPEN"] = "OPEN";
    ChangeSetInventoryTransactionStatus["APPLIED"] = "APPLIED";
})(ChangeSetInventoryTransactionStatus || (ChangeSetInventoryTransactionStatus = {}));
/** Methods to use when ordering `ChangeSet`. */
export var ChangeSetsOrderBy;
(function (ChangeSetsOrderBy) {
    ChangeSetsOrderBy["NATURAL"] = "NATURAL";
    ChangeSetsOrderBy["ID_ASC"] = "ID_ASC";
    ChangeSetsOrderBy["ID_DESC"] = "ID_DESC";
    ChangeSetsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    ChangeSetsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    ChangeSetsOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    ChangeSetsOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    ChangeSetsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    ChangeSetsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    ChangeSetsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    ChangeSetsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    ChangeSetsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    ChangeSetsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    ChangeSetsOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    ChangeSetsOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    ChangeSetsOrderBy["INVENTORY_ID_ASC"] = "INVENTORY_ID_ASC";
    ChangeSetsOrderBy["INVENTORY_ID_DESC"] = "INVENTORY_ID_DESC";
    ChangeSetsOrderBy["CHILD_INVENTORY_ID_ASC"] = "CHILD_INVENTORY_ID_ASC";
    ChangeSetsOrderBy["CHILD_INVENTORY_ID_DESC"] = "CHILD_INVENTORY_ID_DESC";
    ChangeSetsOrderBy["PAYLOAD_ASC"] = "PAYLOAD_ASC";
    ChangeSetsOrderBy["PAYLOAD_DESC"] = "PAYLOAD_DESC";
    ChangeSetsOrderBy["IS_APPLIED_ASC"] = "IS_APPLIED_ASC";
    ChangeSetsOrderBy["IS_APPLIED_DESC"] = "IS_APPLIED_DESC";
    ChangeSetsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ChangeSetsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ChangeSetsOrderBy["PROJECTED_DATE_ASC"] = "PROJECTED_DATE_ASC";
    ChangeSetsOrderBy["PROJECTED_DATE_DESC"] = "PROJECTED_DATE_DESC";
    ChangeSetsOrderBy["OPENED_DATE_ASC"] = "OPENED_DATE_ASC";
    ChangeSetsOrderBy["OPENED_DATE_DESC"] = "OPENED_DATE_DESC";
    ChangeSetsOrderBy["AUTHORITY_CODE_ASC"] = "AUTHORITY_CODE_ASC";
    ChangeSetsOrderBy["AUTHORITY_CODE_DESC"] = "AUTHORITY_CODE_DESC";
    ChangeSetsOrderBy["AUTHORITY_NUMBER_ASC"] = "AUTHORITY_NUMBER_ASC";
    ChangeSetsOrderBy["AUTHORITY_NUMBER_DESC"] = "AUTHORITY_NUMBER_DESC";
    ChangeSetsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ChangeSetsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ChangeSetsOrderBy["USER_GROUP_CODE_ASC"] = "USER_GROUP_CODE_ASC";
    ChangeSetsOrderBy["USER_GROUP_CODE_DESC"] = "USER_GROUP_CODE_DESC";
    ChangeSetsOrderBy["IS_LINE_ITEM_ASC"] = "IS_LINE_ITEM_ASC";
    ChangeSetsOrderBy["IS_LINE_ITEM_DESC"] = "IS_LINE_ITEM_DESC";
    ChangeSetsOrderBy["MEETING_ID_ASC"] = "MEETING_ID_ASC";
    ChangeSetsOrderBy["MEETING_ID_DESC"] = "MEETING_ID_DESC";
    ChangeSetsOrderBy["MEETING_ITEM_ID_ASC"] = "MEETING_ITEM_ID_ASC";
    ChangeSetsOrderBy["MEETING_ITEM_ID_DESC"] = "MEETING_ITEM_ID_DESC";
    ChangeSetsOrderBy["MEETING_SERIES_ID_ASC"] = "MEETING_SERIES_ID_ASC";
    ChangeSetsOrderBy["MEETING_SERIES_ID_DESC"] = "MEETING_SERIES_ID_DESC";
    ChangeSetsOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    ChangeSetsOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    ChangeSetsOrderBy["ENTITY_TYPE_ASC"] = "ENTITY_TYPE_ASC";
    ChangeSetsOrderBy["ENTITY_TYPE_DESC"] = "ENTITY_TYPE_DESC";
    ChangeSetsOrderBy["COMPUTED_CLOSED_DATE_ASC"] = "COMPUTED_CLOSED_DATE_ASC";
    ChangeSetsOrderBy["COMPUTED_CLOSED_DATE_DESC"] = "COMPUTED_CLOSED_DATE_DESC";
    ChangeSetsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ChangeSetsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ChangeSetsOrderBy || (ChangeSetsOrderBy = {}));
export var ChangeSetStatus;
(function (ChangeSetStatus) {
    ChangeSetStatus["APPROVED"] = "APPROVED";
    ChangeSetStatus["OPEN"] = "OPEN";
    ChangeSetStatus["REJECTED"] = "REJECTED";
})(ChangeSetStatus || (ChangeSetStatus = {}));
/** Methods to use when ordering `ChangeSetTemplate`. */
export var ChangeSetTemplatesOrderBy;
(function (ChangeSetTemplatesOrderBy) {
    ChangeSetTemplatesOrderBy["NATURAL"] = "NATURAL";
    ChangeSetTemplatesOrderBy["ID_ASC"] = "ID_ASC";
    ChangeSetTemplatesOrderBy["ID_DESC"] = "ID_DESC";
    ChangeSetTemplatesOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    ChangeSetTemplatesOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    ChangeSetTemplatesOrderBy["ITEM_MASTER_TYPE_ASC"] = "ITEM_MASTER_TYPE_ASC";
    ChangeSetTemplatesOrderBy["ITEM_MASTER_TYPE_DESC"] = "ITEM_MASTER_TYPE_DESC";
    ChangeSetTemplatesOrderBy["GROUP_CODE_ASC"] = "GROUP_CODE_ASC";
    ChangeSetTemplatesOrderBy["GROUP_CODE_DESC"] = "GROUP_CODE_DESC";
    ChangeSetTemplatesOrderBy["REQUIRES_QUALITY_CONTROL_STAMP_ASC"] = "REQUIRES_QUALITY_CONTROL_STAMP_ASC";
    ChangeSetTemplatesOrderBy["REQUIRES_QUALITY_CONTROL_STAMP_DESC"] = "REQUIRES_QUALITY_CONTROL_STAMP_DESC";
    ChangeSetTemplatesOrderBy["REQUIRES_COMMENT_ASC"] = "REQUIRES_COMMENT_ASC";
    ChangeSetTemplatesOrderBy["REQUIRES_COMMENT_DESC"] = "REQUIRES_COMMENT_DESC";
    ChangeSetTemplatesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ChangeSetTemplatesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ChangeSetTemplatesOrderBy["IS_ACTIVE_ASC"] = "IS_ACTIVE_ASC";
    ChangeSetTemplatesOrderBy["IS_ACTIVE_DESC"] = "IS_ACTIVE_DESC";
    ChangeSetTemplatesOrderBy["DOCUMENT_TYPE_ASC"] = "DOCUMENT_TYPE_ASC";
    ChangeSetTemplatesOrderBy["DOCUMENT_TYPE_DESC"] = "DOCUMENT_TYPE_DESC";
    ChangeSetTemplatesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ChangeSetTemplatesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ChangeSetTemplatesOrderBy["ITEM_INSTANCE_INVENTORY_TYPE_ASC"] = "ITEM_INSTANCE_INVENTORY_TYPE_ASC";
    ChangeSetTemplatesOrderBy["ITEM_INSTANCE_INVENTORY_TYPE_DESC"] = "ITEM_INSTANCE_INVENTORY_TYPE_DESC";
    ChangeSetTemplatesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ChangeSetTemplatesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ChangeSetTemplatesOrderBy || (ChangeSetTemplatesOrderBy = {}));
export var ChangeSetType;
(function (ChangeSetType) {
    ChangeSetType["CONFIGURATION_CHANGE_ORDER_LINE_ITEM"] = "CONFIGURATION_CHANGE_ORDER_LINE_ITEM";
    ChangeSetType["COSMIC_ACTION"] = "COSMIC_ACTION";
    ChangeSetType["COSMIC_DOCUMENT_INITIALIZATION"] = "COSMIC_DOCUMENT_INITIALIZATION";
    ChangeSetType["C_DISCREPANCY_REPORT_LINE_ITEM"] = "C_DISCREPANCY_REPORT_LINE_ITEM";
    ChangeSetType["C_EASY_TRANSFER_LINE_ITEM"] = "C_EASY_TRANSFER_LINE_ITEM";
    ChangeSetType["C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM_LINE_ITEM"] = "C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM_LINE_ITEM";
    ChangeSetType["C_RCA_LINE_ITEM"] = "C_RCA_LINE_ITEM";
    ChangeSetType["C_TPS_LINE_ITEM"] = "C_TPS_LINE_ITEM";
    ChangeSetType["C_WORKFLOW_LINE_ITEM"] = "C_WORKFLOW_LINE_ITEM";
    ChangeSetType["DISCREPANCY_REPORT_LINE_ITEM"] = "DISCREPANCY_REPORT_LINE_ITEM";
    ChangeSetType["EASY_TRANSFER_LINE_ITEM"] = "EASY_TRANSFER_LINE_ITEM";
    ChangeSetType["ENGINEERING_CHANGE_LINE_ITEM"] = "ENGINEERING_CHANGE_LINE_ITEM";
    ChangeSetType["FAILURE_INVESTIGATION_ANOMALY_REPORT_LINE_ITEM"] = "FAILURE_INVESTIGATION_ANOMALY_REPORT_LINE_ITEM";
    ChangeSetType["IRREGULAR_PARTS_AUTHORIZATION_REQUEST_LINE_ITEM"] = "IRREGULAR_PARTS_AUTHORIZATION_REQUEST_LINE_ITEM";
    ChangeSetType["ITEM_DRAWING_CODES_CHANGE"] = "ITEM_DRAWING_CODES_CHANGE";
    ChangeSetType["ITEM_DRAWING_VALID_SIZES_CHANGE"] = "ITEM_DRAWING_VALID_SIZES_CHANGE";
    ChangeSetType["ITEM_FOR_INVESTIGATION_LINE_ITEM"] = "ITEM_FOR_INVESTIGATION_LINE_ITEM";
    ChangeSetType["ITEM_INSTANCE_CONFIGURATION_CHANGE"] = "ITEM_INSTANCE_CONFIGURATION_CHANGE";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL"] = "ITEM_INSTANCE_INVENTORY_ASSEMBLY_INSTALL";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE"] = "ITEM_INSTANCE_INVENTORY_ASSEMBLY_REMOVE";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_CLASS_CHANGE"] = "ITEM_INSTANCE_INVENTORY_CLASS_CHANGE";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_COST_CHANGE"] = "ITEM_INSTANCE_INVENTORY_COST_CHANGE";
    ChangeSetType["ITEM_INSTANCE_INVENTORY_PROJECT_CODE_CHANGE"] = "ITEM_INSTANCE_INVENTORY_PROJECT_CODE_CHANGE";
    ChangeSetType["ITEM_INSTANCE_LIMITED_LIFE_CHANGE"] = "ITEM_INSTANCE_LIMITED_LIFE_CHANGE";
    ChangeSetType["ITEM_INSTANCE_ON_ORBIT_FAILURE"] = "ITEM_INSTANCE_ON_ORBIT_FAILURE";
    ChangeSetType["ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE"] = "ITEM_INSTANCE_SERIAL_OR_LOT_NUMBER_CHANGE";
    ChangeSetType["ITEM_MASTER_APPROVAL_CHANGE"] = "ITEM_MASTER_APPROVAL_CHANGE";
    ChangeSetType["ITEM_MASTER_EQUIPMENT_GROUP_CHANGE"] = "ITEM_MASTER_EQUIPMENT_GROUP_CHANGE";
    ChangeSetType["ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE"] = "ITEM_MASTER_FEDERAL_SUPPLY_OR_PROPERTY_CLASS_CHANGE";
    ChangeSetType["ITEM_MASTER_INITIALIZATION"] = "ITEM_MASTER_INITIALIZATION";
    ChangeSetType["ITEM_MASTER_INVENTORY_METHOD_CHANGE"] = "ITEM_MASTER_INVENTORY_METHOD_CHANGE";
    ChangeSetType["ITEM_MASTER_LIMITED_LIFE_CHANGE"] = "ITEM_MASTER_LIMITED_LIFE_CHANGE";
    ChangeSetType["ITEM_MASTER_REFURB_CHANGE"] = "ITEM_MASTER_REFURB_CHANGE";
    ChangeSetType["MATERIAL_USAGE_AGREEMENT_LINE_ITEM"] = "MATERIAL_USAGE_AGREEMENT_LINE_ITEM";
    ChangeSetType["NASA_SPACE_PART_AUTHORIZATION_REQUEST_LINE_ITEM"] = "NASA_SPACE_PART_AUTHORIZATION_REQUEST_LINE_ITEM";
    ChangeSetType["OTHER_DOCUMENT_LINE_ITEM"] = "OTHER_DOCUMENT_LINE_ITEM";
    ChangeSetType["OVER_DUE_TAG_LINE_ITEM"] = "OVER_DUE_TAG_LINE_ITEM";
    ChangeSetType["PROBLEM_REPORTING_AND_CORRECTIVE_ACTION_LINE_ITEM"] = "PROBLEM_REPORTING_AND_CORRECTIVE_ACTION_LINE_ITEM";
    ChangeSetType["PROCEDURE_LINE_ITEM"] = "PROCEDURE_LINE_ITEM";
    ChangeSetType["TPS_LINE_ITEM"] = "TPS_LINE_ITEM";
    ChangeSetType["WAIVER_LINE_ITEM"] = "WAIVER_LINE_ITEM";
    ChangeSetType["WORK_ORDER_STEP"] = "WORK_ORDER_STEP";
    ChangeSetType["YELLOW_TAG_NOTICE_LINE_ITEM"] = "YELLOW_TAG_NOTICE_LINE_ITEM";
    ChangeSetType["DOCUMENT_FLAG_AS_DUPLICATE"] = "DOCUMENT_FLAG_AS_DUPLICATE";
    ChangeSetType["ACCESS_REQUEST"] = "ACCESS_REQUEST";
    ChangeSetType["ACCEPTANCE_DATA_PACK_LINE_ITEM"] = "ACCEPTANCE_DATA_PACK_LINE_ITEM";
    ChangeSetType["BARCODE_DECLARATION_LINE_ITEM"] = "BARCODE_DECLARATION_LINE_ITEM";
    ChangeSetType["CR_LINE_ITEM"] = "CR_LINE_ITEM";
    ChangeSetType["DRAWING_LINE_ITEM"] = "DRAWING_LINE_ITEM";
    ChangeSetType["ELABEL_LINE_ITEM"] = "ELABEL_LINE_ITEM";
    ChangeSetType["ELROD_LINE_ITEM"] = "ELROD_LINE_ITEM";
    ChangeSetType["GCAR_LINE_ITEM"] = "GCAR_LINE_ITEM";
    ChangeSetType["HAZARD_RESPONSE_LEVEL_LINE_ITEM"] = "HAZARD_RESPONSE_LEVEL_LINE_ITEM";
    ChangeSetType["NINE_ZERO_SIX_LINE_ITEM"] = "NINE_ZERO_SIX_LINE_ITEM";
    ChangeSetType["OPS_NOM_DECLARATION_LINE_ITEM"] = "OPS_NOM_DECLARATION_LINE_ITEM";
    ChangeSetType["RSA_LINE_ITEM"] = "RSA_LINE_ITEM";
    ChangeSetType["VEHICLE_CERTIFICATION_LINE_ITEM"] = "VEHICLE_CERTIFICATION_LINE_ITEM";
    ChangeSetType["DELIVERY_ORDER_LINE_ITEM"] = "DELIVERY_ORDER_LINE_ITEM";
})(ChangeSetType || (ChangeSetType = {}));
/** Methods to use when ordering `Comment`. */
export var CommentsOrderBy;
(function (CommentsOrderBy) {
    CommentsOrderBy["NATURAL"] = "NATURAL";
    CommentsOrderBy["ID_ASC"] = "ID_ASC";
    CommentsOrderBy["ID_DESC"] = "ID_DESC";
    CommentsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CommentsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CommentsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CommentsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CommentsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    CommentsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    CommentsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    CommentsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    CommentsOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    CommentsOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    CommentsOrderBy["EVENT_ID_ASC"] = "EVENT_ID_ASC";
    CommentsOrderBy["EVENT_ID_DESC"] = "EVENT_ID_DESC";
    CommentsOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    CommentsOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    CommentsOrderBy["COMMENT_ASC"] = "COMMENT_ASC";
    CommentsOrderBy["COMMENT_DESC"] = "COMMENT_DESC";
    CommentsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CommentsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CommentsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    CommentsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    CommentsOrderBy["TRANSACTION_ID_ASC"] = "TRANSACTION_ID_ASC";
    CommentsOrderBy["TRANSACTION_ID_DESC"] = "TRANSACTION_ID_DESC";
    CommentsOrderBy["HARDWARE_LIST_ID_ASC"] = "HARDWARE_LIST_ID_ASC";
    CommentsOrderBy["HARDWARE_LIST_ID_DESC"] = "HARDWARE_LIST_ID_DESC";
    CommentsOrderBy["CHANGE_SET_ID_ASC"] = "CHANGE_SET_ID_ASC";
    CommentsOrderBy["CHANGE_SET_ID_DESC"] = "CHANGE_SET_ID_DESC";
    CommentsOrderBy["CHANGE_SET_AUTHORITY_ID_ASC"] = "CHANGE_SET_AUTHORITY_ID_ASC";
    CommentsOrderBy["CHANGE_SET_AUTHORITY_ID_DESC"] = "CHANGE_SET_AUTHORITY_ID_DESC";
    CommentsOrderBy["ORGANIZATION_CODE_ASC"] = "ORGANIZATION_CODE_ASC";
    CommentsOrderBy["ORGANIZATION_CODE_DESC"] = "ORGANIZATION_CODE_DESC";
    CommentsOrderBy["ACTIVITY_ID_ASC"] = "ACTIVITY_ID_ASC";
    CommentsOrderBy["ACTIVITY_ID_DESC"] = "ACTIVITY_ID_DESC";
    CommentsOrderBy["PHYSICAL_INVENTORY_AUDIT_ID_ASC"] = "PHYSICAL_INVENTORY_AUDIT_ID_ASC";
    CommentsOrderBy["PHYSICAL_INVENTORY_AUDIT_ID_DESC"] = "PHYSICAL_INVENTORY_AUDIT_ID_DESC";
    CommentsOrderBy["PHYSICAL_INVENTORY_AUDIT_ITEM_ID_ASC"] = "PHYSICAL_INVENTORY_AUDIT_ITEM_ID_ASC";
    CommentsOrderBy["PHYSICAL_INVENTORY_AUDIT_ITEM_ID_DESC"] = "PHYSICAL_INVENTORY_AUDIT_ITEM_ID_DESC";
    CommentsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CommentsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CommentsOrderBy["UPDATED_BY_USER_ID_ASC"] = "UPDATED_BY_USER_ID_ASC";
    CommentsOrderBy["UPDATED_BY_USER_ID_DESC"] = "UPDATED_BY_USER_ID_DESC";
    CommentsOrderBy["DIGITAL_LIBRARY_RECORD_ID_ASC"] = "DIGITAL_LIBRARY_RECORD_ID_ASC";
    CommentsOrderBy["DIGITAL_LIBRARY_RECORD_ID_DESC"] = "DIGITAL_LIBRARY_RECORD_ID_DESC";
    CommentsOrderBy["DIGITAL_LIBRARY_REVISION_ID_ASC"] = "DIGITAL_LIBRARY_REVISION_ID_ASC";
    CommentsOrderBy["DIGITAL_LIBRARY_REVISION_ID_DESC"] = "DIGITAL_LIBRARY_REVISION_ID_DESC";
    CommentsOrderBy["RISK_ID_ASC"] = "RISK_ID_ASC";
    CommentsOrderBy["RISK_ID_DESC"] = "RISK_ID_DESC";
    CommentsOrderBy["RISK_MITIGATION_ID_ASC"] = "RISK_MITIGATION_ID_ASC";
    CommentsOrderBy["RISK_MITIGATION_ID_DESC"] = "RISK_MITIGATION_ID_DESC";
    CommentsOrderBy["MEETING_ID_ASC"] = "MEETING_ID_ASC";
    CommentsOrderBy["MEETING_ID_DESC"] = "MEETING_ID_DESC";
    CommentsOrderBy["MEETING_ITEM_ID_ASC"] = "MEETING_ITEM_ID_ASC";
    CommentsOrderBy["MEETING_ITEM_ID_DESC"] = "MEETING_ITEM_ID_DESC";
    CommentsOrderBy["MEETING_SERIES_ID_ASC"] = "MEETING_SERIES_ID_ASC";
    CommentsOrderBy["MEETING_SERIES_ID_DESC"] = "MEETING_SERIES_ID_DESC";
    CommentsOrderBy["ASSEMBLY_TEMPLATE_ID_ASC"] = "ASSEMBLY_TEMPLATE_ID_ASC";
    CommentsOrderBy["ASSEMBLY_TEMPLATE_ID_DESC"] = "ASSEMBLY_TEMPLATE_ID_DESC";
    CommentsOrderBy["ENTITY_TYPE_ASC"] = "ENTITY_TYPE_ASC";
    CommentsOrderBy["ENTITY_TYPE_DESC"] = "ENTITY_TYPE_DESC";
    CommentsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CommentsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CommentsOrderBy || (CommentsOrderBy = {}));
export var CommentType;
(function (CommentType) {
    CommentType["ACTIVITY"] = "ACTIVITY";
    CommentType["AUDIT"] = "AUDIT";
    CommentType["AUTHORITY_COMMENT"] = "AUTHORITY_COMMENT";
    CommentType["CANCEL_REASON"] = "CANCEL_REASON";
    CommentType["CHANGE_NOTE"] = "CHANGE_NOTE";
    CommentType["CLOSURE"] = "CLOSURE";
    CommentType["DISPOSITION"] = "DISPOSITION";
    CommentType["EFFECTIVITY"] = "EFFECTIVITY";
    CommentType["ESEL"] = "ESEL";
    CommentType["GENERAL"] = "GENERAL";
    CommentType["INITIALIZATION_COMMENT"] = "INITIALIZATION_COMMENT";
    CommentType["LOGISTICS"] = "LOGISTICS";
    CommentType["MAINTENANCE"] = "MAINTENANCE";
    CommentType["NOTE"] = "NOTE";
    CommentType["OEM"] = "OEM";
    CommentType["PROCESSOR"] = "PROCESSOR";
    CommentType["SIZING"] = "SIZING";
    CommentType["STATUS"] = "STATUS";
    CommentType["SYSTEM"] = "SYSTEM";
    CommentType["TRANSACTION"] = "TRANSACTION";
    CommentType["TREND_NOTE"] = "TREND_NOTE";
    CommentType["REVIEW_DISPOSITION"] = "REVIEW_DISPOSITION";
    CommentType["FINAL_DISPOSITION"] = "FINAL_DISPOSITION";
    CommentType["INITIATOR_CONCURRENCE"] = "INITIATOR_CONCURRENCE";
    CommentType["LEAKAGE_HISTORY"] = "LEAKAGE_HISTORY";
    CommentType["EVENT_MILESTONE"] = "EVENT_MILESTONE";
    CommentType["LIMITED_LIFE"] = "LIMITED_LIFE";
    CommentType["NASA_APPROVAL"] = "NASA_APPROVAL";
    CommentType["NASA_RECLAMA"] = "NASA_RECLAMA";
    CommentType["ORB_NOTE"] = "ORB_NOTE";
    CommentType["MEETING_MINUTE"] = "MEETING_MINUTE";
})(CommentType || (CommentType = {}));
/** Methods to use when ordering `Contact`. */
export var ContactsOrderBy;
(function (ContactsOrderBy) {
    ContactsOrderBy["NATURAL"] = "NATURAL";
    ContactsOrderBy["ID_ASC"] = "ID_ASC";
    ContactsOrderBy["ID_DESC"] = "ID_DESC";
    ContactsOrderBy["EMAIL_ASC"] = "EMAIL_ASC";
    ContactsOrderBy["EMAIL_DESC"] = "EMAIL_DESC";
    ContactsOrderBy["AUID_ASC"] = "AUID_ASC";
    ContactsOrderBy["AUID_DESC"] = "AUID_DESC";
    ContactsOrderBy["UUPIC_ASC"] = "UUPIC_ASC";
    ContactsOrderBy["UUPIC_DESC"] = "UUPIC_DESC";
    ContactsOrderBy["FIRST_NAME_ASC"] = "FIRST_NAME_ASC";
    ContactsOrderBy["FIRST_NAME_DESC"] = "FIRST_NAME_DESC";
    ContactsOrderBy["MIDDLE_NAME_ASC"] = "MIDDLE_NAME_ASC";
    ContactsOrderBy["MIDDLE_NAME_DESC"] = "MIDDLE_NAME_DESC";
    ContactsOrderBy["LAST_NAME_ASC"] = "LAST_NAME_ASC";
    ContactsOrderBy["LAST_NAME_DESC"] = "LAST_NAME_DESC";
    ContactsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ContactsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ContactsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    ContactsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    ContactsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ContactsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ContactsOrderBy["SORT_NAME_ASC"] = "SORT_NAME_ASC";
    ContactsOrderBy["SORT_NAME_DESC"] = "SORT_NAME_DESC";
    ContactsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ContactsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ContactsOrderBy || (ContactsOrderBy = {}));
/** Methods to use when ordering `ContactsToNotification`. */
export var ContactsToNotificationsOrderBy;
(function (ContactsToNotificationsOrderBy) {
    ContactsToNotificationsOrderBy["NATURAL"] = "NATURAL";
    ContactsToNotificationsOrderBy["NOTIFICATION_ID_ASC"] = "NOTIFICATION_ID_ASC";
    ContactsToNotificationsOrderBy["NOTIFICATION_ID_DESC"] = "NOTIFICATION_ID_DESC";
    ContactsToNotificationsOrderBy["CONTACT_ID_ASC"] = "CONTACT_ID_ASC";
    ContactsToNotificationsOrderBy["CONTACT_ID_DESC"] = "CONTACT_ID_DESC";
    ContactsToNotificationsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ContactsToNotificationsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ContactsToNotificationsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ContactsToNotificationsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ContactsToNotificationsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ContactsToNotificationsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ContactsToNotificationsOrderBy || (ContactsToNotificationsOrderBy = {}));
/** Methods to use when ordering `Contract`. */
export var ContractsOrderBy;
(function (ContractsOrderBy) {
    ContractsOrderBy["NATURAL"] = "NATURAL";
    ContractsOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    ContractsOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    ContractsOrderBy["NAME_ASC"] = "NAME_ASC";
    ContractsOrderBy["NAME_DESC"] = "NAME_DESC";
    ContractsOrderBy["START_DATE_ASC"] = "START_DATE_ASC";
    ContractsOrderBy["START_DATE_DESC"] = "START_DATE_DESC";
    ContractsOrderBy["END_DATE_ASC"] = "END_DATE_ASC";
    ContractsOrderBy["END_DATE_DESC"] = "END_DATE_DESC";
    ContractsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ContractsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ContractsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ContractsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ContractsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ContractsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ContractsOrderBy || (ContractsOrderBy = {}));
/** Methods to use when ordering `CosmicAction`. */
export var CosmicActionsOrderBy;
(function (CosmicActionsOrderBy) {
    CosmicActionsOrderBy["NATURAL"] = "NATURAL";
    CosmicActionsOrderBy["ID_ASC"] = "ID_ASC";
    CosmicActionsOrderBy["ID_DESC"] = "ID_DESC";
    CosmicActionsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CosmicActionsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CosmicActionsOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    CosmicActionsOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    CosmicActionsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CosmicActionsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CosmicActionsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    CosmicActionsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    CosmicActionsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    CosmicActionsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    CosmicActionsOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    CosmicActionsOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    CosmicActionsOrderBy["INVENTORY_ID_ASC"] = "INVENTORY_ID_ASC";
    CosmicActionsOrderBy["INVENTORY_ID_DESC"] = "INVENTORY_ID_DESC";
    CosmicActionsOrderBy["CHILD_INVENTORY_ID_ASC"] = "CHILD_INVENTORY_ID_ASC";
    CosmicActionsOrderBy["CHILD_INVENTORY_ID_DESC"] = "CHILD_INVENTORY_ID_DESC";
    CosmicActionsOrderBy["PAYLOAD_ASC"] = "PAYLOAD_ASC";
    CosmicActionsOrderBy["PAYLOAD_DESC"] = "PAYLOAD_DESC";
    CosmicActionsOrderBy["IS_APPLIED_ASC"] = "IS_APPLIED_ASC";
    CosmicActionsOrderBy["IS_APPLIED_DESC"] = "IS_APPLIED_DESC";
    CosmicActionsOrderBy["PROJECTED_DATE_ASC"] = "PROJECTED_DATE_ASC";
    CosmicActionsOrderBy["PROJECTED_DATE_DESC"] = "PROJECTED_DATE_DESC";
    CosmicActionsOrderBy["OPENED_DATE_ASC"] = "OPENED_DATE_ASC";
    CosmicActionsOrderBy["OPENED_DATE_DESC"] = "OPENED_DATE_DESC";
    CosmicActionsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CosmicActionsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CosmicActionsOrderBy["USER_GROUP_CODE_ASC"] = "USER_GROUP_CODE_ASC";
    CosmicActionsOrderBy["USER_GROUP_CODE_DESC"] = "USER_GROUP_CODE_DESC";
    CosmicActionsOrderBy["IS_LINE_ITEM_ASC"] = "IS_LINE_ITEM_ASC";
    CosmicActionsOrderBy["IS_LINE_ITEM_DESC"] = "IS_LINE_ITEM_DESC";
    CosmicActionsOrderBy["MEETING_ID_ASC"] = "MEETING_ID_ASC";
    CosmicActionsOrderBy["MEETING_ID_DESC"] = "MEETING_ID_DESC";
    CosmicActionsOrderBy["MEETING_ITEM_ID_ASC"] = "MEETING_ITEM_ID_ASC";
    CosmicActionsOrderBy["MEETING_ITEM_ID_DESC"] = "MEETING_ITEM_ID_DESC";
    CosmicActionsOrderBy["MEETING_SERIES_ID_ASC"] = "MEETING_SERIES_ID_ASC";
    CosmicActionsOrderBy["MEETING_SERIES_ID_DESC"] = "MEETING_SERIES_ID_DESC";
    CosmicActionsOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    CosmicActionsOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    CosmicActionsOrderBy["ENTITY_TYPE_ASC"] = "ENTITY_TYPE_ASC";
    CosmicActionsOrderBy["ENTITY_TYPE_DESC"] = "ENTITY_TYPE_DESC";
    CosmicActionsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CosmicActionsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CosmicActionsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    CosmicActionsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    CosmicActionsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    CosmicActionsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    CosmicActionsOrderBy["PRIORITY_ASC"] = "PRIORITY_ASC";
    CosmicActionsOrderBy["PRIORITY_DESC"] = "PRIORITY_DESC";
    CosmicActionsOrderBy["PERCENT_COMPLETE_ASC"] = "PERCENT_COMPLETE_ASC";
    CosmicActionsOrderBy["PERCENT_COMPLETE_DESC"] = "PERCENT_COMPLETE_DESC";
    CosmicActionsOrderBy["DUE_DATE_ASC"] = "DUE_DATE_ASC";
    CosmicActionsOrderBy["DUE_DATE_DESC"] = "DUE_DATE_DESC";
    CosmicActionsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CosmicActionsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CosmicActionsOrderBy || (CosmicActionsOrderBy = {}));
/** Methods to use when ordering `CosmicDocumentDr`. */
export var CosmicDocumentDrsOrderBy;
(function (CosmicDocumentDrsOrderBy) {
    CosmicDocumentDrsOrderBy["NATURAL"] = "NATURAL";
    CosmicDocumentDrsOrderBy["ID_ASC"] = "ID_ASC";
    CosmicDocumentDrsOrderBy["ID_DESC"] = "ID_DESC";
    CosmicDocumentDrsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CosmicDocumentDrsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CosmicDocumentDrsOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    CosmicDocumentDrsOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    CosmicDocumentDrsOrderBy["TITLE_ASC"] = "TITLE_ASC";
    CosmicDocumentDrsOrderBy["TITLE_DESC"] = "TITLE_DESC";
    CosmicDocumentDrsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    CosmicDocumentDrsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    CosmicDocumentDrsOrderBy["OVERRIDE_CLOSE_DATE_ASC"] = "OVERRIDE_CLOSE_DATE_ASC";
    CosmicDocumentDrsOrderBy["OVERRIDE_CLOSE_DATE_DESC"] = "OVERRIDE_CLOSE_DATE_DESC";
    CosmicDocumentDrsOrderBy["OVERRIDE_OPEN_DATE_ASC"] = "OVERRIDE_OPEN_DATE_ASC";
    CosmicDocumentDrsOrderBy["OVERRIDE_OPEN_DATE_DESC"] = "OVERRIDE_OPEN_DATE_DESC";
    CosmicDocumentDrsOrderBy["ARCHIVE_BOX_NUMBER_ASC"] = "ARCHIVE_BOX_NUMBER_ASC";
    CosmicDocumentDrsOrderBy["ARCHIVE_BOX_NUMBER_DESC"] = "ARCHIVE_BOX_NUMBER_DESC";
    CosmicDocumentDrsOrderBy["RELEASE_STATUS_ASC"] = "RELEASE_STATUS_ASC";
    CosmicDocumentDrsOrderBy["RELEASE_STATUS_DESC"] = "RELEASE_STATUS_DESC";
    CosmicDocumentDrsOrderBy["OVERRIDE_REVISION_NUMBER_ASC"] = "OVERRIDE_REVISION_NUMBER_ASC";
    CosmicDocumentDrsOrderBy["OVERRIDE_REVISION_NUMBER_DESC"] = "OVERRIDE_REVISION_NUMBER_DESC";
    CosmicDocumentDrsOrderBy["RELEASE_DATE_ASC"] = "RELEASE_DATE_ASC";
    CosmicDocumentDrsOrderBy["RELEASE_DATE_DESC"] = "RELEASE_DATE_DESC";
    CosmicDocumentDrsOrderBy["LAST_REVIEW_DATE_ASC"] = "LAST_REVIEW_DATE_ASC";
    CosmicDocumentDrsOrderBy["LAST_REVIEW_DATE_DESC"] = "LAST_REVIEW_DATE_DESC";
    CosmicDocumentDrsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    CosmicDocumentDrsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    CosmicDocumentDrsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    CosmicDocumentDrsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    CosmicDocumentDrsOrderBy["JOB_NUMBER_ASC"] = "JOB_NUMBER_ASC";
    CosmicDocumentDrsOrderBy["JOB_NUMBER_DESC"] = "JOB_NUMBER_DESC";
    CosmicDocumentDrsOrderBy["IS_EXPORT_CONTROLLED_ASC"] = "IS_EXPORT_CONTROLLED_ASC";
    CosmicDocumentDrsOrderBy["IS_EXPORT_CONTROLLED_DESC"] = "IS_EXPORT_CONTROLLED_DESC";
    CosmicDocumentDrsOrderBy["IS_PROPRIETARY_ASC"] = "IS_PROPRIETARY_ASC";
    CosmicDocumentDrsOrderBy["IS_PROPRIETARY_DESC"] = "IS_PROPRIETARY_DESC";
    CosmicDocumentDrsOrderBy["PROJECT_CODE_ASC"] = "PROJECT_CODE_ASC";
    CosmicDocumentDrsOrderBy["PROJECT_CODE_DESC"] = "PROJECT_CODE_DESC";
    CosmicDocumentDrsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_ASC"] = "RESPONSIBLE_ORGANIZATION_CODE_ASC";
    CosmicDocumentDrsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_DESC"] = "RESPONSIBLE_ORGANIZATION_CODE_DESC";
    CosmicDocumentDrsOrderBy["PERFORMING_ORGANIZATION_CODE_ASC"] = "PERFORMING_ORGANIZATION_CODE_ASC";
    CosmicDocumentDrsOrderBy["PERFORMING_ORGANIZATION_CODE_DESC"] = "PERFORMING_ORGANIZATION_CODE_DESC";
    CosmicDocumentDrsOrderBy["TAGS_ASC"] = "TAGS_ASC";
    CosmicDocumentDrsOrderBy["TAGS_DESC"] = "TAGS_DESC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_DEFECT_CODE_ASC"] = "FINAL_DISPOSITION_DEFECT_CODE_ASC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_DEFECT_CODE_DESC"] = "FINAL_DISPOSITION_DEFECT_CODE_DESC";
    CosmicDocumentDrsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CosmicDocumentDrsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CosmicDocumentDrsOrderBy["OVERRIDE_START_DATE_ASC"] = "OVERRIDE_START_DATE_ASC";
    CosmicDocumentDrsOrderBy["OVERRIDE_START_DATE_DESC"] = "OVERRIDE_START_DATE_DESC";
    CosmicDocumentDrsOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    CosmicDocumentDrsOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    CosmicDocumentDrsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CosmicDocumentDrsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CosmicDocumentDrsOrderBy["DUPLICATE_OF_DOCUMENT_ID_ASC"] = "DUPLICATE_OF_DOCUMENT_ID_ASC";
    CosmicDocumentDrsOrderBy["DUPLICATE_OF_DOCUMENT_ID_DESC"] = "DUPLICATE_OF_DOCUMENT_ID_DESC";
    CosmicDocumentDrsOrderBy["IS_DUPLICATE_ASC"] = "IS_DUPLICATE_ASC";
    CosmicDocumentDrsOrderBy["IS_DUPLICATE_DESC"] = "IS_DUPLICATE_DESC";
    CosmicDocumentDrsOrderBy["REVIEW_REQUIRED_DAYS_ASC"] = "REVIEW_REQUIRED_DAYS_ASC";
    CosmicDocumentDrsOrderBy["REVIEW_REQUIRED_DAYS_DESC"] = "REVIEW_REQUIRED_DAYS_DESC";
    CosmicDocumentDrsOrderBy["ESTIMATED_CLOSE_DATE_ASC"] = "ESTIMATED_CLOSE_DATE_ASC";
    CosmicDocumentDrsOrderBy["ESTIMATED_CLOSE_DATE_DESC"] = "ESTIMATED_CLOSE_DATE_DESC";
    CosmicDocumentDrsOrderBy["REFERENCE_DOCUMENTS_ASC"] = "REFERENCE_DOCUMENTS_ASC";
    CosmicDocumentDrsOrderBy["REFERENCE_DOCUMENTS_DESC"] = "REFERENCE_DOCUMENTS_DESC";
    CosmicDocumentDrsOrderBy["ENFORCE_SEQUENTIAL_STEPS_ASC"] = "ENFORCE_SEQUENTIAL_STEPS_ASC";
    CosmicDocumentDrsOrderBy["ENFORCE_SEQUENTIAL_STEPS_DESC"] = "ENFORCE_SEQUENTIAL_STEPS_DESC";
    CosmicDocumentDrsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CosmicDocumentDrsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_CLOSURE_RATIONALE_ASC"] = "FINAL_DISPOSITION_CLOSURE_RATIONALE_ASC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_CLOSURE_RATIONALE_DESC"] = "FINAL_DISPOSITION_CLOSURE_RATIONALE_DESC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_SUMMARY_ASC"] = "FINAL_DISPOSITION_SUMMARY_ASC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_SUMMARY_DESC"] = "FINAL_DISPOSITION_SUMMARY_DESC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_IS_RECURRENCE_CONTROL_REQUIRED_ASC"] = "FINAL_DISPOSITION_IS_RECURRENCE_CONTROL_REQUIRED_ASC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_IS_RECURRENCE_CONTROL_REQUIRED_DESC"] = "FINAL_DISPOSITION_IS_RECURRENCE_CONTROL_REQUIRED_DESC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_RECURRENCE_CONTROL_SUMMARY_ASC"] = "FINAL_DISPOSITION_RECURRENCE_CONTROL_SUMMARY_ASC";
    CosmicDocumentDrsOrderBy["FINAL_DISPOSITION_RECURRENCE_CONTROL_SUMMARY_DESC"] = "FINAL_DISPOSITION_RECURRENCE_CONTROL_SUMMARY_DESC";
    CosmicDocumentDrsOrderBy["INCIDENT_DATE_ASC"] = "INCIDENT_DATE_ASC";
    CosmicDocumentDrsOrderBy["INCIDENT_DATE_DESC"] = "INCIDENT_DATE_DESC";
    CosmicDocumentDrsOrderBy["INCIDENT_DESCRIPTION_ASC"] = "INCIDENT_DESCRIPTION_ASC";
    CosmicDocumentDrsOrderBy["INCIDENT_DESCRIPTION_DESC"] = "INCIDENT_DESCRIPTION_DESC";
    CosmicDocumentDrsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    CosmicDocumentDrsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    CosmicDocumentDrsOrderBy["COMPUTED_CLOSE_DATE_ASC"] = "COMPUTED_CLOSE_DATE_ASC";
    CosmicDocumentDrsOrderBy["COMPUTED_CLOSE_DATE_DESC"] = "COMPUTED_CLOSE_DATE_DESC";
    CosmicDocumentDrsOrderBy["COMPUTED_START_DATE_ASC"] = "COMPUTED_START_DATE_ASC";
    CosmicDocumentDrsOrderBy["COMPUTED_START_DATE_DESC"] = "COMPUTED_START_DATE_DESC";
    CosmicDocumentDrsOrderBy["COMPUTED_STATUS_ASC"] = "COMPUTED_STATUS_ASC";
    CosmicDocumentDrsOrderBy["COMPUTED_STATUS_DESC"] = "COMPUTED_STATUS_DESC";
    CosmicDocumentDrsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CosmicDocumentDrsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CosmicDocumentDrsOrderBy || (CosmicDocumentDrsOrderBy = {}));
/** Methods to use when ordering `CosmicDocumentEzt`. */
export var CosmicDocumentEztsOrderBy;
(function (CosmicDocumentEztsOrderBy) {
    CosmicDocumentEztsOrderBy["NATURAL"] = "NATURAL";
    CosmicDocumentEztsOrderBy["ID_ASC"] = "ID_ASC";
    CosmicDocumentEztsOrderBy["ID_DESC"] = "ID_DESC";
    CosmicDocumentEztsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CosmicDocumentEztsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CosmicDocumentEztsOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    CosmicDocumentEztsOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    CosmicDocumentEztsOrderBy["TITLE_ASC"] = "TITLE_ASC";
    CosmicDocumentEztsOrderBy["TITLE_DESC"] = "TITLE_DESC";
    CosmicDocumentEztsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    CosmicDocumentEztsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    CosmicDocumentEztsOrderBy["OVERRIDE_CLOSE_DATE_ASC"] = "OVERRIDE_CLOSE_DATE_ASC";
    CosmicDocumentEztsOrderBy["OVERRIDE_CLOSE_DATE_DESC"] = "OVERRIDE_CLOSE_DATE_DESC";
    CosmicDocumentEztsOrderBy["OVERRIDE_OPEN_DATE_ASC"] = "OVERRIDE_OPEN_DATE_ASC";
    CosmicDocumentEztsOrderBy["OVERRIDE_OPEN_DATE_DESC"] = "OVERRIDE_OPEN_DATE_DESC";
    CosmicDocumentEztsOrderBy["ARCHIVE_BOX_NUMBER_ASC"] = "ARCHIVE_BOX_NUMBER_ASC";
    CosmicDocumentEztsOrderBy["ARCHIVE_BOX_NUMBER_DESC"] = "ARCHIVE_BOX_NUMBER_DESC";
    CosmicDocumentEztsOrderBy["RELEASE_STATUS_ASC"] = "RELEASE_STATUS_ASC";
    CosmicDocumentEztsOrderBy["RELEASE_STATUS_DESC"] = "RELEASE_STATUS_DESC";
    CosmicDocumentEztsOrderBy["OVERRIDE_REVISION_NUMBER_ASC"] = "OVERRIDE_REVISION_NUMBER_ASC";
    CosmicDocumentEztsOrderBy["OVERRIDE_REVISION_NUMBER_DESC"] = "OVERRIDE_REVISION_NUMBER_DESC";
    CosmicDocumentEztsOrderBy["RELEASE_DATE_ASC"] = "RELEASE_DATE_ASC";
    CosmicDocumentEztsOrderBy["RELEASE_DATE_DESC"] = "RELEASE_DATE_DESC";
    CosmicDocumentEztsOrderBy["LAST_REVIEW_DATE_ASC"] = "LAST_REVIEW_DATE_ASC";
    CosmicDocumentEztsOrderBy["LAST_REVIEW_DATE_DESC"] = "LAST_REVIEW_DATE_DESC";
    CosmicDocumentEztsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    CosmicDocumentEztsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    CosmicDocumentEztsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    CosmicDocumentEztsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    CosmicDocumentEztsOrderBy["JOB_NUMBER_ASC"] = "JOB_NUMBER_ASC";
    CosmicDocumentEztsOrderBy["JOB_NUMBER_DESC"] = "JOB_NUMBER_DESC";
    CosmicDocumentEztsOrderBy["IS_EXPORT_CONTROLLED_ASC"] = "IS_EXPORT_CONTROLLED_ASC";
    CosmicDocumentEztsOrderBy["IS_EXPORT_CONTROLLED_DESC"] = "IS_EXPORT_CONTROLLED_DESC";
    CosmicDocumentEztsOrderBy["IS_PROPRIETARY_ASC"] = "IS_PROPRIETARY_ASC";
    CosmicDocumentEztsOrderBy["IS_PROPRIETARY_DESC"] = "IS_PROPRIETARY_DESC";
    CosmicDocumentEztsOrderBy["PROJECT_CODE_ASC"] = "PROJECT_CODE_ASC";
    CosmicDocumentEztsOrderBy["PROJECT_CODE_DESC"] = "PROJECT_CODE_DESC";
    CosmicDocumentEztsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_ASC"] = "RESPONSIBLE_ORGANIZATION_CODE_ASC";
    CosmicDocumentEztsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_DESC"] = "RESPONSIBLE_ORGANIZATION_CODE_DESC";
    CosmicDocumentEztsOrderBy["PERFORMING_ORGANIZATION_CODE_ASC"] = "PERFORMING_ORGANIZATION_CODE_ASC";
    CosmicDocumentEztsOrderBy["PERFORMING_ORGANIZATION_CODE_DESC"] = "PERFORMING_ORGANIZATION_CODE_DESC";
    CosmicDocumentEztsOrderBy["TAGS_ASC"] = "TAGS_ASC";
    CosmicDocumentEztsOrderBy["TAGS_DESC"] = "TAGS_DESC";
    CosmicDocumentEztsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CosmicDocumentEztsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CosmicDocumentEztsOrderBy["OVERRIDE_START_DATE_ASC"] = "OVERRIDE_START_DATE_ASC";
    CosmicDocumentEztsOrderBy["OVERRIDE_START_DATE_DESC"] = "OVERRIDE_START_DATE_DESC";
    CosmicDocumentEztsOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    CosmicDocumentEztsOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    CosmicDocumentEztsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CosmicDocumentEztsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CosmicDocumentEztsOrderBy["DUPLICATE_OF_DOCUMENT_ID_ASC"] = "DUPLICATE_OF_DOCUMENT_ID_ASC";
    CosmicDocumentEztsOrderBy["DUPLICATE_OF_DOCUMENT_ID_DESC"] = "DUPLICATE_OF_DOCUMENT_ID_DESC";
    CosmicDocumentEztsOrderBy["IS_DUPLICATE_ASC"] = "IS_DUPLICATE_ASC";
    CosmicDocumentEztsOrderBy["IS_DUPLICATE_DESC"] = "IS_DUPLICATE_DESC";
    CosmicDocumentEztsOrderBy["REVIEW_REQUIRED_DAYS_ASC"] = "REVIEW_REQUIRED_DAYS_ASC";
    CosmicDocumentEztsOrderBy["REVIEW_REQUIRED_DAYS_DESC"] = "REVIEW_REQUIRED_DAYS_DESC";
    CosmicDocumentEztsOrderBy["ESTIMATED_CLOSE_DATE_ASC"] = "ESTIMATED_CLOSE_DATE_ASC";
    CosmicDocumentEztsOrderBy["ESTIMATED_CLOSE_DATE_DESC"] = "ESTIMATED_CLOSE_DATE_DESC";
    CosmicDocumentEztsOrderBy["REFERENCE_DOCUMENTS_ASC"] = "REFERENCE_DOCUMENTS_ASC";
    CosmicDocumentEztsOrderBy["REFERENCE_DOCUMENTS_DESC"] = "REFERENCE_DOCUMENTS_DESC";
    CosmicDocumentEztsOrderBy["ENFORCE_SEQUENTIAL_STEPS_ASC"] = "ENFORCE_SEQUENTIAL_STEPS_ASC";
    CosmicDocumentEztsOrderBy["ENFORCE_SEQUENTIAL_STEPS_DESC"] = "ENFORCE_SEQUENTIAL_STEPS_DESC";
    CosmicDocumentEztsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CosmicDocumentEztsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CosmicDocumentEztsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    CosmicDocumentEztsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    CosmicDocumentEztsOrderBy["DESTINATION_ORGANIZATION_CODE_ASC"] = "DESTINATION_ORGANIZATION_CODE_ASC";
    CosmicDocumentEztsOrderBy["DESTINATION_ORGANIZATION_CODE_DESC"] = "DESTINATION_ORGANIZATION_CODE_DESC";
    CosmicDocumentEztsOrderBy["EZT_TYPE_ASC"] = "EZT_TYPE_ASC";
    CosmicDocumentEztsOrderBy["EZT_TYPE_DESC"] = "EZT_TYPE_DESC";
    CosmicDocumentEztsOrderBy["COMPUTED_CLOSE_DATE_ASC"] = "COMPUTED_CLOSE_DATE_ASC";
    CosmicDocumentEztsOrderBy["COMPUTED_CLOSE_DATE_DESC"] = "COMPUTED_CLOSE_DATE_DESC";
    CosmicDocumentEztsOrderBy["COMPUTED_START_DATE_ASC"] = "COMPUTED_START_DATE_ASC";
    CosmicDocumentEztsOrderBy["COMPUTED_START_DATE_DESC"] = "COMPUTED_START_DATE_DESC";
    CosmicDocumentEztsOrderBy["COMPUTED_STATUS_ASC"] = "COMPUTED_STATUS_ASC";
    CosmicDocumentEztsOrderBy["COMPUTED_STATUS_DESC"] = "COMPUTED_STATUS_DESC";
    CosmicDocumentEztsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CosmicDocumentEztsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CosmicDocumentEztsOrderBy || (CosmicDocumentEztsOrderBy = {}));
/** Methods to use when ordering `CosmicDocumentFracas`. */
export var CosmicDocumentFracasOrderBy;
(function (CosmicDocumentFracasOrderBy) {
    CosmicDocumentFracasOrderBy["NATURAL"] = "NATURAL";
    CosmicDocumentFracasOrderBy["ID_ASC"] = "ID_ASC";
    CosmicDocumentFracasOrderBy["ID_DESC"] = "ID_DESC";
    CosmicDocumentFracasOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CosmicDocumentFracasOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CosmicDocumentFracasOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    CosmicDocumentFracasOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    CosmicDocumentFracasOrderBy["TITLE_ASC"] = "TITLE_ASC";
    CosmicDocumentFracasOrderBy["TITLE_DESC"] = "TITLE_DESC";
    CosmicDocumentFracasOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    CosmicDocumentFracasOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    CosmicDocumentFracasOrderBy["OVERRIDE_CLOSE_DATE_ASC"] = "OVERRIDE_CLOSE_DATE_ASC";
    CosmicDocumentFracasOrderBy["OVERRIDE_CLOSE_DATE_DESC"] = "OVERRIDE_CLOSE_DATE_DESC";
    CosmicDocumentFracasOrderBy["OVERRIDE_OPEN_DATE_ASC"] = "OVERRIDE_OPEN_DATE_ASC";
    CosmicDocumentFracasOrderBy["OVERRIDE_OPEN_DATE_DESC"] = "OVERRIDE_OPEN_DATE_DESC";
    CosmicDocumentFracasOrderBy["ARCHIVE_BOX_NUMBER_ASC"] = "ARCHIVE_BOX_NUMBER_ASC";
    CosmicDocumentFracasOrderBy["ARCHIVE_BOX_NUMBER_DESC"] = "ARCHIVE_BOX_NUMBER_DESC";
    CosmicDocumentFracasOrderBy["RELEASE_STATUS_ASC"] = "RELEASE_STATUS_ASC";
    CosmicDocumentFracasOrderBy["RELEASE_STATUS_DESC"] = "RELEASE_STATUS_DESC";
    CosmicDocumentFracasOrderBy["OVERRIDE_REVISION_NUMBER_ASC"] = "OVERRIDE_REVISION_NUMBER_ASC";
    CosmicDocumentFracasOrderBy["OVERRIDE_REVISION_NUMBER_DESC"] = "OVERRIDE_REVISION_NUMBER_DESC";
    CosmicDocumentFracasOrderBy["RELEASE_DATE_ASC"] = "RELEASE_DATE_ASC";
    CosmicDocumentFracasOrderBy["RELEASE_DATE_DESC"] = "RELEASE_DATE_DESC";
    CosmicDocumentFracasOrderBy["LAST_REVIEW_DATE_ASC"] = "LAST_REVIEW_DATE_ASC";
    CosmicDocumentFracasOrderBy["LAST_REVIEW_DATE_DESC"] = "LAST_REVIEW_DATE_DESC";
    CosmicDocumentFracasOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    CosmicDocumentFracasOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    CosmicDocumentFracasOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    CosmicDocumentFracasOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    CosmicDocumentFracasOrderBy["JOB_NUMBER_ASC"] = "JOB_NUMBER_ASC";
    CosmicDocumentFracasOrderBy["JOB_NUMBER_DESC"] = "JOB_NUMBER_DESC";
    CosmicDocumentFracasOrderBy["IS_EXPORT_CONTROLLED_ASC"] = "IS_EXPORT_CONTROLLED_ASC";
    CosmicDocumentFracasOrderBy["IS_EXPORT_CONTROLLED_DESC"] = "IS_EXPORT_CONTROLLED_DESC";
    CosmicDocumentFracasOrderBy["IS_PROPRIETARY_ASC"] = "IS_PROPRIETARY_ASC";
    CosmicDocumentFracasOrderBy["IS_PROPRIETARY_DESC"] = "IS_PROPRIETARY_DESC";
    CosmicDocumentFracasOrderBy["PROJECT_CODE_ASC"] = "PROJECT_CODE_ASC";
    CosmicDocumentFracasOrderBy["PROJECT_CODE_DESC"] = "PROJECT_CODE_DESC";
    CosmicDocumentFracasOrderBy["RESPONSIBLE_ORGANIZATION_CODE_ASC"] = "RESPONSIBLE_ORGANIZATION_CODE_ASC";
    CosmicDocumentFracasOrderBy["RESPONSIBLE_ORGANIZATION_CODE_DESC"] = "RESPONSIBLE_ORGANIZATION_CODE_DESC";
    CosmicDocumentFracasOrderBy["PERFORMING_ORGANIZATION_CODE_ASC"] = "PERFORMING_ORGANIZATION_CODE_ASC";
    CosmicDocumentFracasOrderBy["PERFORMING_ORGANIZATION_CODE_DESC"] = "PERFORMING_ORGANIZATION_CODE_DESC";
    CosmicDocumentFracasOrderBy["TAGS_ASC"] = "TAGS_ASC";
    CosmicDocumentFracasOrderBy["TAGS_DESC"] = "TAGS_DESC";
    CosmicDocumentFracasOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CosmicDocumentFracasOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CosmicDocumentFracasOrderBy["OVERRIDE_START_DATE_ASC"] = "OVERRIDE_START_DATE_ASC";
    CosmicDocumentFracasOrderBy["OVERRIDE_START_DATE_DESC"] = "OVERRIDE_START_DATE_DESC";
    CosmicDocumentFracasOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    CosmicDocumentFracasOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    CosmicDocumentFracasOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CosmicDocumentFracasOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CosmicDocumentFracasOrderBy["DUPLICATE_OF_DOCUMENT_ID_ASC"] = "DUPLICATE_OF_DOCUMENT_ID_ASC";
    CosmicDocumentFracasOrderBy["DUPLICATE_OF_DOCUMENT_ID_DESC"] = "DUPLICATE_OF_DOCUMENT_ID_DESC";
    CosmicDocumentFracasOrderBy["IS_DUPLICATE_ASC"] = "IS_DUPLICATE_ASC";
    CosmicDocumentFracasOrderBy["IS_DUPLICATE_DESC"] = "IS_DUPLICATE_DESC";
    CosmicDocumentFracasOrderBy["REVIEW_REQUIRED_DAYS_ASC"] = "REVIEW_REQUIRED_DAYS_ASC";
    CosmicDocumentFracasOrderBy["REVIEW_REQUIRED_DAYS_DESC"] = "REVIEW_REQUIRED_DAYS_DESC";
    CosmicDocumentFracasOrderBy["ESTIMATED_CLOSE_DATE_ASC"] = "ESTIMATED_CLOSE_DATE_ASC";
    CosmicDocumentFracasOrderBy["ESTIMATED_CLOSE_DATE_DESC"] = "ESTIMATED_CLOSE_DATE_DESC";
    CosmicDocumentFracasOrderBy["REFERENCE_DOCUMENTS_ASC"] = "REFERENCE_DOCUMENTS_ASC";
    CosmicDocumentFracasOrderBy["REFERENCE_DOCUMENTS_DESC"] = "REFERENCE_DOCUMENTS_DESC";
    CosmicDocumentFracasOrderBy["ENFORCE_SEQUENTIAL_STEPS_ASC"] = "ENFORCE_SEQUENTIAL_STEPS_ASC";
    CosmicDocumentFracasOrderBy["ENFORCE_SEQUENTIAL_STEPS_DESC"] = "ENFORCE_SEQUENTIAL_STEPS_DESC";
    CosmicDocumentFracasOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CosmicDocumentFracasOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CosmicDocumentFracasOrderBy["SUB_SYSTEM_ASC"] = "SUB_SYSTEM_ASC";
    CosmicDocumentFracasOrderBy["SUB_SYSTEM_DESC"] = "SUB_SYSTEM_DESC";
    CosmicDocumentFracasOrderBy["SUB_SYSTEM_ITEM_INSTANCE_ID_ASC"] = "SUB_SYSTEM_ITEM_INSTANCE_ID_ASC";
    CosmicDocumentFracasOrderBy["SUB_SYSTEM_ITEM_INSTANCE_ID_DESC"] = "SUB_SYSTEM_ITEM_INSTANCE_ID_DESC";
    CosmicDocumentFracasOrderBy["SUB_SYSTEM_PART_NUMBER_ASC"] = "SUB_SYSTEM_PART_NUMBER_ASC";
    CosmicDocumentFracasOrderBy["SUB_SYSTEM_PART_NUMBER_DESC"] = "SUB_SYSTEM_PART_NUMBER_DESC";
    CosmicDocumentFracasOrderBy["PARENT_ITEM_INSTANCE_ID_ASC"] = "PARENT_ITEM_INSTANCE_ID_ASC";
    CosmicDocumentFracasOrderBy["PARENT_ITEM_INSTANCE_ID_DESC"] = "PARENT_ITEM_INSTANCE_ID_DESC";
    CosmicDocumentFracasOrderBy["PARENT_PART_NUMBER_ASC"] = "PARENT_PART_NUMBER_ASC";
    CosmicDocumentFracasOrderBy["PARENT_PART_NUMBER_DESC"] = "PARENT_PART_NUMBER_DESC";
    CosmicDocumentFracasOrderBy["OVERRIDE_DISCREPANCY_REPORT_INCIDENT_DATE_ASC"] = "OVERRIDE_DISCREPANCY_REPORT_INCIDENT_DATE_ASC";
    CosmicDocumentFracasOrderBy["OVERRIDE_DISCREPANCY_REPORT_INCIDENT_DATE_DESC"] = "OVERRIDE_DISCREPANCY_REPORT_INCIDENT_DATE_DESC";
    CosmicDocumentFracasOrderBy["TECHNICIAN_CONTACT_ID_ASC"] = "TECHNICIAN_CONTACT_ID_ASC";
    CosmicDocumentFracasOrderBy["TECHNICIAN_CONTACT_ID_DESC"] = "TECHNICIAN_CONTACT_ID_DESC";
    CosmicDocumentFracasOrderBy["FAILURE_OCCURRED_ON_ORBIT_ASC"] = "FAILURE_OCCURRED_ON_ORBIT_ASC";
    CosmicDocumentFracasOrderBy["FAILURE_OCCURRED_ON_ORBIT_DESC"] = "FAILURE_OCCURRED_ON_ORBIT_DESC";
    CosmicDocumentFracasOrderBy["FAILURE_LOCATION_ID_ASC"] = "FAILURE_LOCATION_ID_ASC";
    CosmicDocumentFracasOrderBy["FAILURE_LOCATION_ID_DESC"] = "FAILURE_LOCATION_ID_DESC";
    CosmicDocumentFracasOrderBy["FAILURE_PHASE_ASC"] = "FAILURE_PHASE_ASC";
    CosmicDocumentFracasOrderBy["FAILURE_PHASE_DESC"] = "FAILURE_PHASE_DESC";
    CosmicDocumentFracasOrderBy["FAILURE_ENVIRONMENT_ASC"] = "FAILURE_ENVIRONMENT_ASC";
    CosmicDocumentFracasOrderBy["FAILURE_ENVIRONMENT_DESC"] = "FAILURE_ENVIRONMENT_DESC";
    CosmicDocumentFracasOrderBy["SOFTWARE_VERSION_ASC"] = "SOFTWARE_VERSION_ASC";
    CosmicDocumentFracasOrderBy["SOFTWARE_VERSION_DESC"] = "SOFTWARE_VERSION_DESC";
    CosmicDocumentFracasOrderBy["IS_TRENDING_ASC"] = "IS_TRENDING_ASC";
    CosmicDocumentFracasOrderBy["IS_TRENDING_DESC"] = "IS_TRENDING_DESC";
    CosmicDocumentFracasOrderBy["ANALYSIS_START_DATE_ASC"] = "ANALYSIS_START_DATE_ASC";
    CosmicDocumentFracasOrderBy["ANALYSIS_START_DATE_DESC"] = "ANALYSIS_START_DATE_DESC";
    CosmicDocumentFracasOrderBy["ANALYSIS_BY_CONTACT_ID_ASC"] = "ANALYSIS_BY_CONTACT_ID_ASC";
    CosmicDocumentFracasOrderBy["ANALYSIS_BY_CONTACT_ID_DESC"] = "ANALYSIS_BY_CONTACT_ID_DESC";
    CosmicDocumentFracasOrderBy["ANALYSIS_DESCRIPTION_ASC"] = "ANALYSIS_DESCRIPTION_ASC";
    CosmicDocumentFracasOrderBy["ANALYSIS_DESCRIPTION_DESC"] = "ANALYSIS_DESCRIPTION_DESC";
    CosmicDocumentFracasOrderBy["CAUSES_OF_FAILURE_ASC"] = "CAUSES_OF_FAILURE_ASC";
    CosmicDocumentFracasOrderBy["CAUSES_OF_FAILURE_DESC"] = "CAUSES_OF_FAILURE_DESC";
    CosmicDocumentFracasOrderBy["FAILURE_MODE_ASC"] = "FAILURE_MODE_ASC";
    CosmicDocumentFracasOrderBy["FAILURE_MODE_DESC"] = "FAILURE_MODE_DESC";
    CosmicDocumentFracasOrderBy["FAILURE_TYPE_ASC"] = "FAILURE_TYPE_ASC";
    CosmicDocumentFracasOrderBy["FAILURE_TYPE_DESC"] = "FAILURE_TYPE_DESC";
    CosmicDocumentFracasOrderBy["SCORING_ASC"] = "SCORING_ASC";
    CosmicDocumentFracasOrderBy["SCORING_DESC"] = "SCORING_DESC";
    CosmicDocumentFracasOrderBy["ANALYSIS_STATUS_ASC"] = "ANALYSIS_STATUS_ASC";
    CosmicDocumentFracasOrderBy["ANALYSIS_STATUS_DESC"] = "ANALYSIS_STATUS_DESC";
    CosmicDocumentFracasOrderBy["IS_PRACA_REPORTABLE_ASC"] = "IS_PRACA_REPORTABLE_ASC";
    CosmicDocumentFracasOrderBy["IS_PRACA_REPORTABLE_DESC"] = "IS_PRACA_REPORTABLE_DESC";
    CosmicDocumentFracasOrderBy["PRACA_REPORTED_DATE_ASC"] = "PRACA_REPORTED_DATE_ASC";
    CosmicDocumentFracasOrderBy["PRACA_REPORTED_DATE_DESC"] = "PRACA_REPORTED_DATE_DESC";
    CosmicDocumentFracasOrderBy["PRACA_CONTACT_ID_ASC"] = "PRACA_CONTACT_ID_ASC";
    CosmicDocumentFracasOrderBy["PRACA_CONTACT_ID_DESC"] = "PRACA_CONTACT_ID_DESC";
    CosmicDocumentFracasOrderBy["PRACA_RATIONALE_ASC"] = "PRACA_RATIONALE_ASC";
    CosmicDocumentFracasOrderBy["PRACA_RATIONALE_DESC"] = "PRACA_RATIONALE_DESC";
    CosmicDocumentFracasOrderBy["PRACA_LINK_ASC"] = "PRACA_LINK_ASC";
    CosmicDocumentFracasOrderBy["PRACA_LINK_DESC"] = "PRACA_LINK_DESC";
    CosmicDocumentFracasOrderBy["CORRECTIVE_ACTION_EFFECTIVITY_DATE_ASC"] = "CORRECTIVE_ACTION_EFFECTIVITY_DATE_ASC";
    CosmicDocumentFracasOrderBy["CORRECTIVE_ACTION_EFFECTIVITY_DATE_DESC"] = "CORRECTIVE_ACTION_EFFECTIVITY_DATE_DESC";
    CosmicDocumentFracasOrderBy["CORRECTIVE_ACTION_CONTACT_ID_ASC"] = "CORRECTIVE_ACTION_CONTACT_ID_ASC";
    CosmicDocumentFracasOrderBy["CORRECTIVE_ACTION_CONTACT_ID_DESC"] = "CORRECTIVE_ACTION_CONTACT_ID_DESC";
    CosmicDocumentFracasOrderBy["CORRECTIVE_ACTION_DESCRIPTION_ASC"] = "CORRECTIVE_ACTION_DESCRIPTION_ASC";
    CosmicDocumentFracasOrderBy["CORRECTIVE_ACTION_DESCRIPTION_DESC"] = "CORRECTIVE_ACTION_DESCRIPTION_DESC";
    CosmicDocumentFracasOrderBy["CLOSEOUT_CONTACT_ID_ASC"] = "CLOSEOUT_CONTACT_ID_ASC";
    CosmicDocumentFracasOrderBy["CLOSEOUT_CONTACT_ID_DESC"] = "CLOSEOUT_CONTACT_ID_DESC";
    CosmicDocumentFracasOrderBy["CLOSEOUT_DESCRIPTION_ASC"] = "CLOSEOUT_DESCRIPTION_ASC";
    CosmicDocumentFracasOrderBy["CLOSEOUT_DESCRIPTION_DESC"] = "CLOSEOUT_DESCRIPTION_DESC";
    CosmicDocumentFracasOrderBy["OVERRIDE_FAILURE_LOCATION_ASC"] = "OVERRIDE_FAILURE_LOCATION_ASC";
    CosmicDocumentFracasOrderBy["OVERRIDE_FAILURE_LOCATION_DESC"] = "OVERRIDE_FAILURE_LOCATION_DESC";
    CosmicDocumentFracasOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    CosmicDocumentFracasOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    CosmicDocumentFracasOrderBy["PRACA_NUMBER_ASC"] = "PRACA_NUMBER_ASC";
    CosmicDocumentFracasOrderBy["PRACA_NUMBER_DESC"] = "PRACA_NUMBER_DESC";
    CosmicDocumentFracasOrderBy["OVERRIDE_EQUIPMENT_GROUP_ASC"] = "OVERRIDE_EQUIPMENT_GROUP_ASC";
    CosmicDocumentFracasOrderBy["OVERRIDE_EQUIPMENT_GROUP_DESC"] = "OVERRIDE_EQUIPMENT_GROUP_DESC";
    CosmicDocumentFracasOrderBy["COMPUTED_CLOSE_DATE_ASC"] = "COMPUTED_CLOSE_DATE_ASC";
    CosmicDocumentFracasOrderBy["COMPUTED_CLOSE_DATE_DESC"] = "COMPUTED_CLOSE_DATE_DESC";
    CosmicDocumentFracasOrderBy["COMPUTED_START_DATE_ASC"] = "COMPUTED_START_DATE_ASC";
    CosmicDocumentFracasOrderBy["COMPUTED_START_DATE_DESC"] = "COMPUTED_START_DATE_DESC";
    CosmicDocumentFracasOrderBy["COMPUTED_STATUS_ASC"] = "COMPUTED_STATUS_ASC";
    CosmicDocumentFracasOrderBy["COMPUTED_STATUS_DESC"] = "COMPUTED_STATUS_DESC";
    CosmicDocumentFracasOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CosmicDocumentFracasOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CosmicDocumentFracasOrderBy || (CosmicDocumentFracasOrderBy = {}));
/** Methods to use when ordering `CosmicDocumentRca`. */
export var CosmicDocumentRcasOrderBy;
(function (CosmicDocumentRcasOrderBy) {
    CosmicDocumentRcasOrderBy["NATURAL"] = "NATURAL";
    CosmicDocumentRcasOrderBy["ID_ASC"] = "ID_ASC";
    CosmicDocumentRcasOrderBy["ID_DESC"] = "ID_DESC";
    CosmicDocumentRcasOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CosmicDocumentRcasOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CosmicDocumentRcasOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    CosmicDocumentRcasOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    CosmicDocumentRcasOrderBy["TITLE_ASC"] = "TITLE_ASC";
    CosmicDocumentRcasOrderBy["TITLE_DESC"] = "TITLE_DESC";
    CosmicDocumentRcasOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    CosmicDocumentRcasOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    CosmicDocumentRcasOrderBy["OVERRIDE_CLOSE_DATE_ASC"] = "OVERRIDE_CLOSE_DATE_ASC";
    CosmicDocumentRcasOrderBy["OVERRIDE_CLOSE_DATE_DESC"] = "OVERRIDE_CLOSE_DATE_DESC";
    CosmicDocumentRcasOrderBy["OVERRIDE_OPEN_DATE_ASC"] = "OVERRIDE_OPEN_DATE_ASC";
    CosmicDocumentRcasOrderBy["OVERRIDE_OPEN_DATE_DESC"] = "OVERRIDE_OPEN_DATE_DESC";
    CosmicDocumentRcasOrderBy["ARCHIVE_BOX_NUMBER_ASC"] = "ARCHIVE_BOX_NUMBER_ASC";
    CosmicDocumentRcasOrderBy["ARCHIVE_BOX_NUMBER_DESC"] = "ARCHIVE_BOX_NUMBER_DESC";
    CosmicDocumentRcasOrderBy["RELEASE_STATUS_ASC"] = "RELEASE_STATUS_ASC";
    CosmicDocumentRcasOrderBy["RELEASE_STATUS_DESC"] = "RELEASE_STATUS_DESC";
    CosmicDocumentRcasOrderBy["OVERRIDE_REVISION_NUMBER_ASC"] = "OVERRIDE_REVISION_NUMBER_ASC";
    CosmicDocumentRcasOrderBy["OVERRIDE_REVISION_NUMBER_DESC"] = "OVERRIDE_REVISION_NUMBER_DESC";
    CosmicDocumentRcasOrderBy["RELEASE_DATE_ASC"] = "RELEASE_DATE_ASC";
    CosmicDocumentRcasOrderBy["RELEASE_DATE_DESC"] = "RELEASE_DATE_DESC";
    CosmicDocumentRcasOrderBy["LAST_REVIEW_DATE_ASC"] = "LAST_REVIEW_DATE_ASC";
    CosmicDocumentRcasOrderBy["LAST_REVIEW_DATE_DESC"] = "LAST_REVIEW_DATE_DESC";
    CosmicDocumentRcasOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    CosmicDocumentRcasOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    CosmicDocumentRcasOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    CosmicDocumentRcasOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    CosmicDocumentRcasOrderBy["JOB_NUMBER_ASC"] = "JOB_NUMBER_ASC";
    CosmicDocumentRcasOrderBy["JOB_NUMBER_DESC"] = "JOB_NUMBER_DESC";
    CosmicDocumentRcasOrderBy["IS_EXPORT_CONTROLLED_ASC"] = "IS_EXPORT_CONTROLLED_ASC";
    CosmicDocumentRcasOrderBy["IS_EXPORT_CONTROLLED_DESC"] = "IS_EXPORT_CONTROLLED_DESC";
    CosmicDocumentRcasOrderBy["IS_PROPRIETARY_ASC"] = "IS_PROPRIETARY_ASC";
    CosmicDocumentRcasOrderBy["IS_PROPRIETARY_DESC"] = "IS_PROPRIETARY_DESC";
    CosmicDocumentRcasOrderBy["PROJECT_CODE_ASC"] = "PROJECT_CODE_ASC";
    CosmicDocumentRcasOrderBy["PROJECT_CODE_DESC"] = "PROJECT_CODE_DESC";
    CosmicDocumentRcasOrderBy["RESPONSIBLE_ORGANIZATION_CODE_ASC"] = "RESPONSIBLE_ORGANIZATION_CODE_ASC";
    CosmicDocumentRcasOrderBy["RESPONSIBLE_ORGANIZATION_CODE_DESC"] = "RESPONSIBLE_ORGANIZATION_CODE_DESC";
    CosmicDocumentRcasOrderBy["PERFORMING_ORGANIZATION_CODE_ASC"] = "PERFORMING_ORGANIZATION_CODE_ASC";
    CosmicDocumentRcasOrderBy["PERFORMING_ORGANIZATION_CODE_DESC"] = "PERFORMING_ORGANIZATION_CODE_DESC";
    CosmicDocumentRcasOrderBy["TAGS_ASC"] = "TAGS_ASC";
    CosmicDocumentRcasOrderBy["TAGS_DESC"] = "TAGS_DESC";
    CosmicDocumentRcasOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CosmicDocumentRcasOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CosmicDocumentRcasOrderBy["OVERRIDE_START_DATE_ASC"] = "OVERRIDE_START_DATE_ASC";
    CosmicDocumentRcasOrderBy["OVERRIDE_START_DATE_DESC"] = "OVERRIDE_START_DATE_DESC";
    CosmicDocumentRcasOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    CosmicDocumentRcasOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    CosmicDocumentRcasOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CosmicDocumentRcasOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CosmicDocumentRcasOrderBy["DUPLICATE_OF_DOCUMENT_ID_ASC"] = "DUPLICATE_OF_DOCUMENT_ID_ASC";
    CosmicDocumentRcasOrderBy["DUPLICATE_OF_DOCUMENT_ID_DESC"] = "DUPLICATE_OF_DOCUMENT_ID_DESC";
    CosmicDocumentRcasOrderBy["IS_DUPLICATE_ASC"] = "IS_DUPLICATE_ASC";
    CosmicDocumentRcasOrderBy["IS_DUPLICATE_DESC"] = "IS_DUPLICATE_DESC";
    CosmicDocumentRcasOrderBy["REVIEW_REQUIRED_DAYS_ASC"] = "REVIEW_REQUIRED_DAYS_ASC";
    CosmicDocumentRcasOrderBy["REVIEW_REQUIRED_DAYS_DESC"] = "REVIEW_REQUIRED_DAYS_DESC";
    CosmicDocumentRcasOrderBy["ESTIMATED_CLOSE_DATE_ASC"] = "ESTIMATED_CLOSE_DATE_ASC";
    CosmicDocumentRcasOrderBy["ESTIMATED_CLOSE_DATE_DESC"] = "ESTIMATED_CLOSE_DATE_DESC";
    CosmicDocumentRcasOrderBy["REFERENCE_DOCUMENTS_ASC"] = "REFERENCE_DOCUMENTS_ASC";
    CosmicDocumentRcasOrderBy["REFERENCE_DOCUMENTS_DESC"] = "REFERENCE_DOCUMENTS_DESC";
    CosmicDocumentRcasOrderBy["ENFORCE_SEQUENTIAL_STEPS_ASC"] = "ENFORCE_SEQUENTIAL_STEPS_ASC";
    CosmicDocumentRcasOrderBy["ENFORCE_SEQUENTIAL_STEPS_DESC"] = "ENFORCE_SEQUENTIAL_STEPS_DESC";
    CosmicDocumentRcasOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CosmicDocumentRcasOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CosmicDocumentRcasOrderBy["RCA_OWNER_CONTACT_ID_ASC"] = "RCA_OWNER_CONTACT_ID_ASC";
    CosmicDocumentRcasOrderBy["RCA_OWNER_CONTACT_ID_DESC"] = "RCA_OWNER_CONTACT_ID_DESC";
    CosmicDocumentRcasOrderBy["PROCESS_ESCAPE_ASC"] = "PROCESS_ESCAPE_ASC";
    CosmicDocumentRcasOrderBy["PROCESS_ESCAPE_DESC"] = "PROCESS_ESCAPE_DESC";
    CosmicDocumentRcasOrderBy["AWARD_FEE_WRITE_UP_REQUIRED_ASC"] = "AWARD_FEE_WRITE_UP_REQUIRED_ASC";
    CosmicDocumentRcasOrderBy["AWARD_FEE_WRITE_UP_REQUIRED_DESC"] = "AWARD_FEE_WRITE_UP_REQUIRED_DESC";
    CosmicDocumentRcasOrderBy["AWARD_FEE_ASC"] = "AWARD_FEE_ASC";
    CosmicDocumentRcasOrderBy["AWARD_FEE_DESC"] = "AWARD_FEE_DESC";
    CosmicDocumentRcasOrderBy["ROOT_CAUSE_ASC"] = "ROOT_CAUSE_ASC";
    CosmicDocumentRcasOrderBy["ROOT_CAUSE_DESC"] = "ROOT_CAUSE_DESC";
    CosmicDocumentRcasOrderBy["ROOT_CAUSE_CATEGORY_ASC"] = "ROOT_CAUSE_CATEGORY_ASC";
    CosmicDocumentRcasOrderBy["ROOT_CAUSE_CATEGORY_DESC"] = "ROOT_CAUSE_CATEGORY_DESC";
    CosmicDocumentRcasOrderBy["CONTAINMENT_ASC"] = "CONTAINMENT_ASC";
    CosmicDocumentRcasOrderBy["CONTAINMENT_DESC"] = "CONTAINMENT_DESC";
    CosmicDocumentRcasOrderBy["VERIFICATION_ASC"] = "VERIFICATION_ASC";
    CosmicDocumentRcasOrderBy["VERIFICATION_DESC"] = "VERIFICATION_DESC";
    CosmicDocumentRcasOrderBy["BASE_LOCATION_ORGANIZATION_CODE_ASC"] = "BASE_LOCATION_ORGANIZATION_CODE_ASC";
    CosmicDocumentRcasOrderBy["BASE_LOCATION_ORGANIZATION_CODE_DESC"] = "BASE_LOCATION_ORGANIZATION_CODE_DESC";
    CosmicDocumentRcasOrderBy["ADDITIONAL_LOCATION_INFORMATION_ASC"] = "ADDITIONAL_LOCATION_INFORMATION_ASC";
    CosmicDocumentRcasOrderBy["ADDITIONAL_LOCATION_INFORMATION_DESC"] = "ADDITIONAL_LOCATION_INFORMATION_DESC";
    CosmicDocumentRcasOrderBy["TECHNICAL_POC_CONTACT_ID_ASC"] = "TECHNICAL_POC_CONTACT_ID_ASC";
    CosmicDocumentRcasOrderBy["TECHNICAL_POC_CONTACT_ID_DESC"] = "TECHNICAL_POC_CONTACT_ID_DESC";
    CosmicDocumentRcasOrderBy["IPT_ASSIGNED_GROUP_CODE_ASC"] = "IPT_ASSIGNED_GROUP_CODE_ASC";
    CosmicDocumentRcasOrderBy["IPT_ASSIGNED_GROUP_CODE_DESC"] = "IPT_ASSIGNED_GROUP_CODE_DESC";
    CosmicDocumentRcasOrderBy["INITIATED_BY_NASA_ASC"] = "INITIATED_BY_NASA_ASC";
    CosmicDocumentRcasOrderBy["INITIATED_BY_NASA_DESC"] = "INITIATED_BY_NASA_DESC";
    CosmicDocumentRcasOrderBy["REQUIRES_NASA_AWARENESS_ASC"] = "REQUIRES_NASA_AWARENESS_ASC";
    CosmicDocumentRcasOrderBy["REQUIRES_NASA_AWARENESS_DESC"] = "REQUIRES_NASA_AWARENESS_DESC";
    CosmicDocumentRcasOrderBy["NASA_POC_CONTACT_ID_ASC"] = "NASA_POC_CONTACT_ID_ASC";
    CosmicDocumentRcasOrderBy["NASA_POC_CONTACT_ID_DESC"] = "NASA_POC_CONTACT_ID_DESC";
    CosmicDocumentRcasOrderBy["RISK_INITIAL_LIKELIHOOD_ASC"] = "RISK_INITIAL_LIKELIHOOD_ASC";
    CosmicDocumentRcasOrderBy["RISK_INITIAL_LIKELIHOOD_DESC"] = "RISK_INITIAL_LIKELIHOOD_DESC";
    CosmicDocumentRcasOrderBy["RISK_INITIAL_CONSEQUENCE_ASC"] = "RISK_INITIAL_CONSEQUENCE_ASC";
    CosmicDocumentRcasOrderBy["RISK_INITIAL_CONSEQUENCE_DESC"] = "RISK_INITIAL_CONSEQUENCE_DESC";
    CosmicDocumentRcasOrderBy["RISK_FINAL_LIKELIHOOD_ASC"] = "RISK_FINAL_LIKELIHOOD_ASC";
    CosmicDocumentRcasOrderBy["RISK_FINAL_LIKELIHOOD_DESC"] = "RISK_FINAL_LIKELIHOOD_DESC";
    CosmicDocumentRcasOrderBy["RISK_FINAL_CONSEQUENCE_ASC"] = "RISK_FINAL_CONSEQUENCE_ASC";
    CosmicDocumentRcasOrderBy["RISK_FINAL_CONSEQUENCE_DESC"] = "RISK_FINAL_CONSEQUENCE_DESC";
    CosmicDocumentRcasOrderBy["RISK_SCENARIO_ASC"] = "RISK_SCENARIO_ASC";
    CosmicDocumentRcasOrderBy["RISK_SCENARIO_DESC"] = "RISK_SCENARIO_DESC";
    CosmicDocumentRcasOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    CosmicDocumentRcasOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    CosmicDocumentRcasOrderBy["LESSONS_LEARNED_ASC"] = "LESSONS_LEARNED_ASC";
    CosmicDocumentRcasOrderBy["LESSONS_LEARNED_DESC"] = "LESSONS_LEARNED_DESC";
    CosmicDocumentRcasOrderBy["NASA_CLOSURE_DATE_ASC"] = "NASA_CLOSURE_DATE_ASC";
    CosmicDocumentRcasOrderBy["NASA_CLOSURE_DATE_DESC"] = "NASA_CLOSURE_DATE_DESC";
    CosmicDocumentRcasOrderBy["CAP_APPROVAL_DATE_ASC"] = "CAP_APPROVAL_DATE_ASC";
    CosmicDocumentRcasOrderBy["CAP_APPROVAL_DATE_DESC"] = "CAP_APPROVAL_DATE_DESC";
    CosmicDocumentRcasOrderBy["COR_AWARENESS_ASC"] = "COR_AWARENESS_ASC";
    CosmicDocumentRcasOrderBy["COR_AWARENESS_DESC"] = "COR_AWARENESS_DESC";
    CosmicDocumentRcasOrderBy["NASA_NOTIFICATION_TYPE_ASC"] = "NASA_NOTIFICATION_TYPE_ASC";
    CosmicDocumentRcasOrderBy["NASA_NOTIFICATION_TYPE_DESC"] = "NASA_NOTIFICATION_TYPE_DESC";
    CosmicDocumentRcasOrderBy["CLOSURE_METHOD_ASC"] = "CLOSURE_METHOD_ASC";
    CosmicDocumentRcasOrderBy["CLOSURE_METHOD_DESC"] = "CLOSURE_METHOD_DESC";
    CosmicDocumentRcasOrderBy["COMPUTED_CLOSE_DATE_ASC"] = "COMPUTED_CLOSE_DATE_ASC";
    CosmicDocumentRcasOrderBy["COMPUTED_CLOSE_DATE_DESC"] = "COMPUTED_CLOSE_DATE_DESC";
    CosmicDocumentRcasOrderBy["COMPUTED_START_DATE_ASC"] = "COMPUTED_START_DATE_ASC";
    CosmicDocumentRcasOrderBy["COMPUTED_START_DATE_DESC"] = "COMPUTED_START_DATE_DESC";
    CosmicDocumentRcasOrderBy["COMPUTED_STATUS_ASC"] = "COMPUTED_STATUS_ASC";
    CosmicDocumentRcasOrderBy["COMPUTED_STATUS_DESC"] = "COMPUTED_STATUS_DESC";
    CosmicDocumentRcasOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CosmicDocumentRcasOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CosmicDocumentRcasOrderBy || (CosmicDocumentRcasOrderBy = {}));
export var CosmicDocumentStatus;
(function (CosmicDocumentStatus) {
    CosmicDocumentStatus["DRAFT"] = "DRAFT";
    CosmicDocumentStatus["PENDING"] = "PENDING";
    CosmicDocumentStatus["REJECTED"] = "REJECTED";
    CosmicDocumentStatus["OPEN"] = "OPEN";
    CosmicDocumentStatus["WORK_ORDER_STEP_REJECTED"] = "WORK_ORDER_STEP_REJECTED";
    CosmicDocumentStatus["WORK_ORDER_STEPS_COMPLETE"] = "WORK_ORDER_STEPS_COMPLETE";
    CosmicDocumentStatus["CLOSED"] = "CLOSED";
})(CosmicDocumentStatus || (CosmicDocumentStatus = {}));
/** Methods to use when ordering `CosmicDocumentTps`. */
export var CosmicDocumentTpsOrderBy;
(function (CosmicDocumentTpsOrderBy) {
    CosmicDocumentTpsOrderBy["NATURAL"] = "NATURAL";
    CosmicDocumentTpsOrderBy["ID_ASC"] = "ID_ASC";
    CosmicDocumentTpsOrderBy["ID_DESC"] = "ID_DESC";
    CosmicDocumentTpsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CosmicDocumentTpsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CosmicDocumentTpsOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    CosmicDocumentTpsOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    CosmicDocumentTpsOrderBy["TITLE_ASC"] = "TITLE_ASC";
    CosmicDocumentTpsOrderBy["TITLE_DESC"] = "TITLE_DESC";
    CosmicDocumentTpsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    CosmicDocumentTpsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    CosmicDocumentTpsOrderBy["OVERRIDE_CLOSE_DATE_ASC"] = "OVERRIDE_CLOSE_DATE_ASC";
    CosmicDocumentTpsOrderBy["OVERRIDE_CLOSE_DATE_DESC"] = "OVERRIDE_CLOSE_DATE_DESC";
    CosmicDocumentTpsOrderBy["OVERRIDE_OPEN_DATE_ASC"] = "OVERRIDE_OPEN_DATE_ASC";
    CosmicDocumentTpsOrderBy["OVERRIDE_OPEN_DATE_DESC"] = "OVERRIDE_OPEN_DATE_DESC";
    CosmicDocumentTpsOrderBy["ARCHIVE_BOX_NUMBER_ASC"] = "ARCHIVE_BOX_NUMBER_ASC";
    CosmicDocumentTpsOrderBy["ARCHIVE_BOX_NUMBER_DESC"] = "ARCHIVE_BOX_NUMBER_DESC";
    CosmicDocumentTpsOrderBy["RELEASE_STATUS_ASC"] = "RELEASE_STATUS_ASC";
    CosmicDocumentTpsOrderBy["RELEASE_STATUS_DESC"] = "RELEASE_STATUS_DESC";
    CosmicDocumentTpsOrderBy["OVERRIDE_REVISION_NUMBER_ASC"] = "OVERRIDE_REVISION_NUMBER_ASC";
    CosmicDocumentTpsOrderBy["OVERRIDE_REVISION_NUMBER_DESC"] = "OVERRIDE_REVISION_NUMBER_DESC";
    CosmicDocumentTpsOrderBy["RELEASE_DATE_ASC"] = "RELEASE_DATE_ASC";
    CosmicDocumentTpsOrderBy["RELEASE_DATE_DESC"] = "RELEASE_DATE_DESC";
    CosmicDocumentTpsOrderBy["LAST_REVIEW_DATE_ASC"] = "LAST_REVIEW_DATE_ASC";
    CosmicDocumentTpsOrderBy["LAST_REVIEW_DATE_DESC"] = "LAST_REVIEW_DATE_DESC";
    CosmicDocumentTpsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    CosmicDocumentTpsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    CosmicDocumentTpsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    CosmicDocumentTpsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    CosmicDocumentTpsOrderBy["JOB_NUMBER_ASC"] = "JOB_NUMBER_ASC";
    CosmicDocumentTpsOrderBy["JOB_NUMBER_DESC"] = "JOB_NUMBER_DESC";
    CosmicDocumentTpsOrderBy["IS_EXPORT_CONTROLLED_ASC"] = "IS_EXPORT_CONTROLLED_ASC";
    CosmicDocumentTpsOrderBy["IS_EXPORT_CONTROLLED_DESC"] = "IS_EXPORT_CONTROLLED_DESC";
    CosmicDocumentTpsOrderBy["IS_PROPRIETARY_ASC"] = "IS_PROPRIETARY_ASC";
    CosmicDocumentTpsOrderBy["IS_PROPRIETARY_DESC"] = "IS_PROPRIETARY_DESC";
    CosmicDocumentTpsOrderBy["PROJECT_CODE_ASC"] = "PROJECT_CODE_ASC";
    CosmicDocumentTpsOrderBy["PROJECT_CODE_DESC"] = "PROJECT_CODE_DESC";
    CosmicDocumentTpsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_ASC"] = "RESPONSIBLE_ORGANIZATION_CODE_ASC";
    CosmicDocumentTpsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_DESC"] = "RESPONSIBLE_ORGANIZATION_CODE_DESC";
    CosmicDocumentTpsOrderBy["PERFORMING_ORGANIZATION_CODE_ASC"] = "PERFORMING_ORGANIZATION_CODE_ASC";
    CosmicDocumentTpsOrderBy["PERFORMING_ORGANIZATION_CODE_DESC"] = "PERFORMING_ORGANIZATION_CODE_DESC";
    CosmicDocumentTpsOrderBy["TAGS_ASC"] = "TAGS_ASC";
    CosmicDocumentTpsOrderBy["TAGS_DESC"] = "TAGS_DESC";
    CosmicDocumentTpsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CosmicDocumentTpsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CosmicDocumentTpsOrderBy["OVERRIDE_START_DATE_ASC"] = "OVERRIDE_START_DATE_ASC";
    CosmicDocumentTpsOrderBy["OVERRIDE_START_DATE_DESC"] = "OVERRIDE_START_DATE_DESC";
    CosmicDocumentTpsOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    CosmicDocumentTpsOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    CosmicDocumentTpsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CosmicDocumentTpsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CosmicDocumentTpsOrderBy["DUPLICATE_OF_DOCUMENT_ID_ASC"] = "DUPLICATE_OF_DOCUMENT_ID_ASC";
    CosmicDocumentTpsOrderBy["DUPLICATE_OF_DOCUMENT_ID_DESC"] = "DUPLICATE_OF_DOCUMENT_ID_DESC";
    CosmicDocumentTpsOrderBy["IS_DUPLICATE_ASC"] = "IS_DUPLICATE_ASC";
    CosmicDocumentTpsOrderBy["IS_DUPLICATE_DESC"] = "IS_DUPLICATE_DESC";
    CosmicDocumentTpsOrderBy["REVIEW_REQUIRED_DAYS_ASC"] = "REVIEW_REQUIRED_DAYS_ASC";
    CosmicDocumentTpsOrderBy["REVIEW_REQUIRED_DAYS_DESC"] = "REVIEW_REQUIRED_DAYS_DESC";
    CosmicDocumentTpsOrderBy["ESTIMATED_CLOSE_DATE_ASC"] = "ESTIMATED_CLOSE_DATE_ASC";
    CosmicDocumentTpsOrderBy["ESTIMATED_CLOSE_DATE_DESC"] = "ESTIMATED_CLOSE_DATE_DESC";
    CosmicDocumentTpsOrderBy["REFERENCE_DOCUMENTS_ASC"] = "REFERENCE_DOCUMENTS_ASC";
    CosmicDocumentTpsOrderBy["REFERENCE_DOCUMENTS_DESC"] = "REFERENCE_DOCUMENTS_DESC";
    CosmicDocumentTpsOrderBy["ENFORCE_SEQUENTIAL_STEPS_ASC"] = "ENFORCE_SEQUENTIAL_STEPS_ASC";
    CosmicDocumentTpsOrderBy["ENFORCE_SEQUENTIAL_STEPS_DESC"] = "ENFORCE_SEQUENTIAL_STEPS_DESC";
    CosmicDocumentTpsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CosmicDocumentTpsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CosmicDocumentTpsOrderBy["TPS_TYPE_ASC"] = "TPS_TYPE_ASC";
    CosmicDocumentTpsOrderBy["TPS_TYPE_DESC"] = "TPS_TYPE_DESC";
    CosmicDocumentTpsOrderBy["TPS_TYPE_EXPLANATION_ASC"] = "TPS_TYPE_EXPLANATION_ASC";
    CosmicDocumentTpsOrderBy["TPS_TYPE_EXPLANATION_DESC"] = "TPS_TYPE_EXPLANATION_DESC";
    CosmicDocumentTpsOrderBy["IS_HAZARDOUS_ASC"] = "IS_HAZARDOUS_ASC";
    CosmicDocumentTpsOrderBy["IS_HAZARDOUS_DESC"] = "IS_HAZARDOUS_DESC";
    CosmicDocumentTpsOrderBy["HAZARD_ANALYSIS_ASC"] = "HAZARD_ANALYSIS_ASC";
    CosmicDocumentTpsOrderBy["HAZARD_ANALYSIS_DESC"] = "HAZARD_ANALYSIS_DESC";
    CosmicDocumentTpsOrderBy["CRITICALITY_ASC"] = "CRITICALITY_ASC";
    CosmicDocumentTpsOrderBy["CRITICALITY_DESC"] = "CRITICALITY_DESC";
    CosmicDocumentTpsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    CosmicDocumentTpsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    CosmicDocumentTpsOrderBy["COMPUTED_CLOSE_DATE_ASC"] = "COMPUTED_CLOSE_DATE_ASC";
    CosmicDocumentTpsOrderBy["COMPUTED_CLOSE_DATE_DESC"] = "COMPUTED_CLOSE_DATE_DESC";
    CosmicDocumentTpsOrderBy["COMPUTED_START_DATE_ASC"] = "COMPUTED_START_DATE_ASC";
    CosmicDocumentTpsOrderBy["COMPUTED_START_DATE_DESC"] = "COMPUTED_START_DATE_DESC";
    CosmicDocumentTpsOrderBy["COMPUTED_STATUS_ASC"] = "COMPUTED_STATUS_ASC";
    CosmicDocumentTpsOrderBy["COMPUTED_STATUS_DESC"] = "COMPUTED_STATUS_DESC";
    CosmicDocumentTpsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CosmicDocumentTpsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CosmicDocumentTpsOrderBy || (CosmicDocumentTpsOrderBy = {}));
/** Methods to use when ordering `CosmicDocumentWorkflow`. */
export var CosmicDocumentWorkflowsOrderBy;
(function (CosmicDocumentWorkflowsOrderBy) {
    CosmicDocumentWorkflowsOrderBy["NATURAL"] = "NATURAL";
    CosmicDocumentWorkflowsOrderBy["ID_ASC"] = "ID_ASC";
    CosmicDocumentWorkflowsOrderBy["ID_DESC"] = "ID_DESC";
    CosmicDocumentWorkflowsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CosmicDocumentWorkflowsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CosmicDocumentWorkflowsOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    CosmicDocumentWorkflowsOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    CosmicDocumentWorkflowsOrderBy["TITLE_ASC"] = "TITLE_ASC";
    CosmicDocumentWorkflowsOrderBy["TITLE_DESC"] = "TITLE_DESC";
    CosmicDocumentWorkflowsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    CosmicDocumentWorkflowsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    CosmicDocumentWorkflowsOrderBy["OVERRIDE_CLOSE_DATE_ASC"] = "OVERRIDE_CLOSE_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["OVERRIDE_CLOSE_DATE_DESC"] = "OVERRIDE_CLOSE_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["OVERRIDE_OPEN_DATE_ASC"] = "OVERRIDE_OPEN_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["OVERRIDE_OPEN_DATE_DESC"] = "OVERRIDE_OPEN_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["ARCHIVE_BOX_NUMBER_ASC"] = "ARCHIVE_BOX_NUMBER_ASC";
    CosmicDocumentWorkflowsOrderBy["ARCHIVE_BOX_NUMBER_DESC"] = "ARCHIVE_BOX_NUMBER_DESC";
    CosmicDocumentWorkflowsOrderBy["RELEASE_STATUS_ASC"] = "RELEASE_STATUS_ASC";
    CosmicDocumentWorkflowsOrderBy["RELEASE_STATUS_DESC"] = "RELEASE_STATUS_DESC";
    CosmicDocumentWorkflowsOrderBy["OVERRIDE_REVISION_NUMBER_ASC"] = "OVERRIDE_REVISION_NUMBER_ASC";
    CosmicDocumentWorkflowsOrderBy["OVERRIDE_REVISION_NUMBER_DESC"] = "OVERRIDE_REVISION_NUMBER_DESC";
    CosmicDocumentWorkflowsOrderBy["RELEASE_DATE_ASC"] = "RELEASE_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["RELEASE_DATE_DESC"] = "RELEASE_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["LAST_REVIEW_DATE_ASC"] = "LAST_REVIEW_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["LAST_REVIEW_DATE_DESC"] = "LAST_REVIEW_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    CosmicDocumentWorkflowsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    CosmicDocumentWorkflowsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    CosmicDocumentWorkflowsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    CosmicDocumentWorkflowsOrderBy["JOB_NUMBER_ASC"] = "JOB_NUMBER_ASC";
    CosmicDocumentWorkflowsOrderBy["JOB_NUMBER_DESC"] = "JOB_NUMBER_DESC";
    CosmicDocumentWorkflowsOrderBy["IS_EXPORT_CONTROLLED_ASC"] = "IS_EXPORT_CONTROLLED_ASC";
    CosmicDocumentWorkflowsOrderBy["IS_EXPORT_CONTROLLED_DESC"] = "IS_EXPORT_CONTROLLED_DESC";
    CosmicDocumentWorkflowsOrderBy["IS_PROPRIETARY_ASC"] = "IS_PROPRIETARY_ASC";
    CosmicDocumentWorkflowsOrderBy["IS_PROPRIETARY_DESC"] = "IS_PROPRIETARY_DESC";
    CosmicDocumentWorkflowsOrderBy["PROJECT_CODE_ASC"] = "PROJECT_CODE_ASC";
    CosmicDocumentWorkflowsOrderBy["PROJECT_CODE_DESC"] = "PROJECT_CODE_DESC";
    CosmicDocumentWorkflowsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_ASC"] = "RESPONSIBLE_ORGANIZATION_CODE_ASC";
    CosmicDocumentWorkflowsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_DESC"] = "RESPONSIBLE_ORGANIZATION_CODE_DESC";
    CosmicDocumentWorkflowsOrderBy["PERFORMING_ORGANIZATION_CODE_ASC"] = "PERFORMING_ORGANIZATION_CODE_ASC";
    CosmicDocumentWorkflowsOrderBy["PERFORMING_ORGANIZATION_CODE_DESC"] = "PERFORMING_ORGANIZATION_CODE_DESC";
    CosmicDocumentWorkflowsOrderBy["TAGS_ASC"] = "TAGS_ASC";
    CosmicDocumentWorkflowsOrderBy["TAGS_DESC"] = "TAGS_DESC";
    CosmicDocumentWorkflowsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CosmicDocumentWorkflowsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CosmicDocumentWorkflowsOrderBy["OVERRIDE_START_DATE_ASC"] = "OVERRIDE_START_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["OVERRIDE_START_DATE_DESC"] = "OVERRIDE_START_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    CosmicDocumentWorkflowsOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    CosmicDocumentWorkflowsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CosmicDocumentWorkflowsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CosmicDocumentWorkflowsOrderBy["DUPLICATE_OF_DOCUMENT_ID_ASC"] = "DUPLICATE_OF_DOCUMENT_ID_ASC";
    CosmicDocumentWorkflowsOrderBy["DUPLICATE_OF_DOCUMENT_ID_DESC"] = "DUPLICATE_OF_DOCUMENT_ID_DESC";
    CosmicDocumentWorkflowsOrderBy["IS_DUPLICATE_ASC"] = "IS_DUPLICATE_ASC";
    CosmicDocumentWorkflowsOrderBy["IS_DUPLICATE_DESC"] = "IS_DUPLICATE_DESC";
    CosmicDocumentWorkflowsOrderBy["REVIEW_REQUIRED_DAYS_ASC"] = "REVIEW_REQUIRED_DAYS_ASC";
    CosmicDocumentWorkflowsOrderBy["REVIEW_REQUIRED_DAYS_DESC"] = "REVIEW_REQUIRED_DAYS_DESC";
    CosmicDocumentWorkflowsOrderBy["ESTIMATED_CLOSE_DATE_ASC"] = "ESTIMATED_CLOSE_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["ESTIMATED_CLOSE_DATE_DESC"] = "ESTIMATED_CLOSE_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["REFERENCE_DOCUMENTS_ASC"] = "REFERENCE_DOCUMENTS_ASC";
    CosmicDocumentWorkflowsOrderBy["REFERENCE_DOCUMENTS_DESC"] = "REFERENCE_DOCUMENTS_DESC";
    CosmicDocumentWorkflowsOrderBy["ENFORCE_SEQUENTIAL_STEPS_ASC"] = "ENFORCE_SEQUENTIAL_STEPS_ASC";
    CosmicDocumentWorkflowsOrderBy["ENFORCE_SEQUENTIAL_STEPS_DESC"] = "ENFORCE_SEQUENTIAL_STEPS_DESC";
    CosmicDocumentWorkflowsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CosmicDocumentWorkflowsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CosmicDocumentWorkflowsOrderBy["WORKFLOW_NOTES_ASC"] = "WORKFLOW_NOTES_ASC";
    CosmicDocumentWorkflowsOrderBy["WORKFLOW_NOTES_DESC"] = "WORKFLOW_NOTES_DESC";
    CosmicDocumentWorkflowsOrderBy["WORKFLOW_LETTER_NUMBER_ASC"] = "WORKFLOW_LETTER_NUMBER_ASC";
    CosmicDocumentWorkflowsOrderBy["WORKFLOW_LETTER_NUMBER_DESC"] = "WORKFLOW_LETTER_NUMBER_DESC";
    CosmicDocumentWorkflowsOrderBy["WORKFLOW_DOCUMENT_NUMBER_ASC"] = "WORKFLOW_DOCUMENT_NUMBER_ASC";
    CosmicDocumentWorkflowsOrderBy["WORKFLOW_DOCUMENT_NUMBER_DESC"] = "WORKFLOW_DOCUMENT_NUMBER_DESC";
    CosmicDocumentWorkflowsOrderBy["WORKFLOW_RECEIVED_DATE_ASC"] = "WORKFLOW_RECEIVED_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["WORKFLOW_RECEIVED_DATE_DESC"] = "WORKFLOW_RECEIVED_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    CosmicDocumentWorkflowsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    CosmicDocumentWorkflowsOrderBy["DUE_DATE_ASC"] = "DUE_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["DUE_DATE_DESC"] = "DUE_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["COMPUTED_CLOSE_DATE_ASC"] = "COMPUTED_CLOSE_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["COMPUTED_CLOSE_DATE_DESC"] = "COMPUTED_CLOSE_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["COMPUTED_START_DATE_ASC"] = "COMPUTED_START_DATE_ASC";
    CosmicDocumentWorkflowsOrderBy["COMPUTED_START_DATE_DESC"] = "COMPUTED_START_DATE_DESC";
    CosmicDocumentWorkflowsOrderBy["COMPUTED_STATUS_ASC"] = "COMPUTED_STATUS_ASC";
    CosmicDocumentWorkflowsOrderBy["COMPUTED_STATUS_DESC"] = "COMPUTED_STATUS_DESC";
    CosmicDocumentWorkflowsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CosmicDocumentWorkflowsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CosmicDocumentWorkflowsOrderBy || (CosmicDocumentWorkflowsOrderBy = {}));
export var CosmicEntityType;
(function (CosmicEntityType) {
    CosmicEntityType["ACTIVITY"] = "ACTIVITY";
    CosmicEntityType["CHANGE_SET"] = "CHANGE_SET";
    CosmicEntityType["CREW"] = "CREW";
    CosmicEntityType["DOCUMENT"] = "DOCUMENT";
    CosmicEntityType["EVENT"] = "EVENT";
    CosmicEntityType["HARDWARE_LIST"] = "HARDWARE_LIST";
    CosmicEntityType["ITEM_DRAWING"] = "ITEM_DRAWING";
    CosmicEntityType["ITEM_INSTANCE"] = "ITEM_INSTANCE";
    CosmicEntityType["ITEM_MASTER"] = "ITEM_MASTER";
    CosmicEntityType["ORGANIZATION"] = "ORGANIZATION";
    CosmicEntityType["PHYSICAL_INVENTORY_AUDIT"] = "PHYSICAL_INVENTORY_AUDIT";
    CosmicEntityType["PHYSICAL_INVENTORY_AUDIT_ITEM"] = "PHYSICAL_INVENTORY_AUDIT_ITEM";
    CosmicEntityType["TRANSACTION"] = "TRANSACTION";
    CosmicEntityType["DIGITAL_LIBRARY_RECORD"] = "DIGITAL_LIBRARY_RECORD";
    CosmicEntityType["DIGITAL_LIBRARY_REVISION"] = "DIGITAL_LIBRARY_REVISION";
    CosmicEntityType["DELIVERY_MATRIX_ITEM"] = "DELIVERY_MATRIX_ITEM";
    CosmicEntityType["RISK"] = "RISK";
    CosmicEntityType["RISK_MITIGATION"] = "RISK_MITIGATION";
    CosmicEntityType["MEETING_ITEM"] = "MEETING_ITEM";
    CosmicEntityType["MEETING"] = "MEETING";
    CosmicEntityType["MEETING_SERIES"] = "MEETING_SERIES";
    CosmicEntityType["NON_ACTUALIZED_ITEM_INSTANCE"] = "NON_ACTUALIZED_ITEM_INSTANCE";
    CosmicEntityType["ASSEMBLY_TEMPLATE"] = "ASSEMBLY_TEMPLATE";
})(CosmicEntityType || (CosmicEntityType = {}));
/** Methods to use when ordering `CosmicLineItem`. */
export var CosmicLineItemsOrderBy;
(function (CosmicLineItemsOrderBy) {
    CosmicLineItemsOrderBy["NATURAL"] = "NATURAL";
    CosmicLineItemsOrderBy["ID_ASC"] = "ID_ASC";
    CosmicLineItemsOrderBy["ID_DESC"] = "ID_DESC";
    CosmicLineItemsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    CosmicLineItemsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    CosmicLineItemsOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    CosmicLineItemsOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    CosmicLineItemsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    CosmicLineItemsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    CosmicLineItemsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    CosmicLineItemsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    CosmicLineItemsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    CosmicLineItemsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    CosmicLineItemsOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    CosmicLineItemsOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    CosmicLineItemsOrderBy["PAYLOAD_ASC"] = "PAYLOAD_ASC";
    CosmicLineItemsOrderBy["PAYLOAD_DESC"] = "PAYLOAD_DESC";
    CosmicLineItemsOrderBy["IS_APPLIED_ASC"] = "IS_APPLIED_ASC";
    CosmicLineItemsOrderBy["IS_APPLIED_DESC"] = "IS_APPLIED_DESC";
    CosmicLineItemsOrderBy["PROJECTED_DATE_ASC"] = "PROJECTED_DATE_ASC";
    CosmicLineItemsOrderBy["PROJECTED_DATE_DESC"] = "PROJECTED_DATE_DESC";
    CosmicLineItemsOrderBy["OPENED_DATE_ASC"] = "OPENED_DATE_ASC";
    CosmicLineItemsOrderBy["OPENED_DATE_DESC"] = "OPENED_DATE_DESC";
    CosmicLineItemsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CosmicLineItemsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CosmicLineItemsOrderBy["USER_GROUP_CODE_ASC"] = "USER_GROUP_CODE_ASC";
    CosmicLineItemsOrderBy["USER_GROUP_CODE_DESC"] = "USER_GROUP_CODE_DESC";
    CosmicLineItemsOrderBy["IS_LINE_ITEM_ASC"] = "IS_LINE_ITEM_ASC";
    CosmicLineItemsOrderBy["IS_LINE_ITEM_DESC"] = "IS_LINE_ITEM_DESC";
    CosmicLineItemsOrderBy["MEETING_ID_ASC"] = "MEETING_ID_ASC";
    CosmicLineItemsOrderBy["MEETING_ID_DESC"] = "MEETING_ID_DESC";
    CosmicLineItemsOrderBy["MEETING_ITEM_ID_ASC"] = "MEETING_ITEM_ID_ASC";
    CosmicLineItemsOrderBy["MEETING_ITEM_ID_DESC"] = "MEETING_ITEM_ID_DESC";
    CosmicLineItemsOrderBy["MEETING_SERIES_ID_ASC"] = "MEETING_SERIES_ID_ASC";
    CosmicLineItemsOrderBy["MEETING_SERIES_ID_DESC"] = "MEETING_SERIES_ID_DESC";
    CosmicLineItemsOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    CosmicLineItemsOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    CosmicLineItemsOrderBy["ENTITY_TYPE_ASC"] = "ENTITY_TYPE_ASC";
    CosmicLineItemsOrderBy["ENTITY_TYPE_DESC"] = "ENTITY_TYPE_DESC";
    CosmicLineItemsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CosmicLineItemsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CosmicLineItemsOrderBy["LINE_ITEM_LOCATION_ID_ASC"] = "LINE_ITEM_LOCATION_ID_ASC";
    CosmicLineItemsOrderBy["LINE_ITEM_LOCATION_ID_DESC"] = "LINE_ITEM_LOCATION_ID_DESC";
    CosmicLineItemsOrderBy["LINE_ITEM_PROJECT_CODE_ASC"] = "LINE_ITEM_PROJECT_CODE_ASC";
    CosmicLineItemsOrderBy["LINE_ITEM_PROJECT_CODE_DESC"] = "LINE_ITEM_PROJECT_CODE_DESC";
    CosmicLineItemsOrderBy["LINE_ITEM_QUANTITY_ASC"] = "LINE_ITEM_QUANTITY_ASC";
    CosmicLineItemsOrderBy["LINE_ITEM_QUANTITY_DESC"] = "LINE_ITEM_QUANTITY_DESC";
    CosmicLineItemsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    CosmicLineItemsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    CosmicLineItemsOrderBy["LINE_ITEM_ITEM_CLASS_ASC"] = "LINE_ITEM_ITEM_CLASS_ASC";
    CosmicLineItemsOrderBy["LINE_ITEM_ITEM_CLASS_DESC"] = "LINE_ITEM_ITEM_CLASS_DESC";
    CosmicLineItemsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CosmicLineItemsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CosmicLineItemsOrderBy || (CosmicLineItemsOrderBy = {}));
/** Methods to use when ordering `CosmicLineItemsToWorkOrderStep`. */
export var CosmicLineItemsToWorkOrderStepsOrderBy;
(function (CosmicLineItemsToWorkOrderStepsOrderBy) {
    CosmicLineItemsToWorkOrderStepsOrderBy["NATURAL"] = "NATURAL";
    CosmicLineItemsToWorkOrderStepsOrderBy["COSMIC_LINE_ITEM_ID_ASC"] = "COSMIC_LINE_ITEM_ID_ASC";
    CosmicLineItemsToWorkOrderStepsOrderBy["COSMIC_LINE_ITEM_ID_DESC"] = "COSMIC_LINE_ITEM_ID_DESC";
    CosmicLineItemsToWorkOrderStepsOrderBy["WORK_ORDER_STEP_ID_ASC"] = "WORK_ORDER_STEP_ID_ASC";
    CosmicLineItemsToWorkOrderStepsOrderBy["WORK_ORDER_STEP_ID_DESC"] = "WORK_ORDER_STEP_ID_DESC";
    CosmicLineItemsToWorkOrderStepsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    CosmicLineItemsToWorkOrderStepsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    CosmicLineItemsToWorkOrderStepsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    CosmicLineItemsToWorkOrderStepsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    CosmicLineItemsToWorkOrderStepsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    CosmicLineItemsToWorkOrderStepsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(CosmicLineItemsToWorkOrderStepsOrderBy || (CosmicLineItemsToWorkOrderStepsOrderBy = {}));
export var CriticalityCode;
(function (CriticalityCode) {
    CriticalityCode["_1_1"] = "_1_1";
    CriticalityCode["_2_2"] = "_2_2";
    CriticalityCode["_3_3"] = "_3_3";
    CriticalityCode["_2_1R"] = "_2_1R";
    CriticalityCode["_3_1R"] = "_3_1R";
    CriticalityCode["_3_2R"] = "_3_2R";
})(CriticalityCode || (CriticalityCode = {}));
export var DocumentRelationType;
(function (DocumentRelationType) {
    DocumentRelationType["FRACAS_DISCREPANCY_REPORT"] = "FRACAS_DISCREPANCY_REPORT";
    DocumentRelationType["FRACAS_DISCREPANCY_REPORT_FOR"] = "FRACAS_DISCREPANCY_REPORT_FOR";
    DocumentRelationType["REFERENCE_DOCUMENT"] = "REFERENCE_DOCUMENT";
    DocumentRelationType["REFERENCE_DOCUMENT_FOR"] = "REFERENCE_DOCUMENT_FOR";
    DocumentRelationType["SOURCE_DOCUMENT"] = "SOURCE_DOCUMENT";
    DocumentRelationType["SOURCE_DOCUMENT_FOR"] = "SOURCE_DOCUMENT_FOR";
    DocumentRelationType["RID"] = "RID";
    DocumentRelationType["OTHER"] = "OTHER";
})(DocumentRelationType || (DocumentRelationType = {}));
export var DocumentReleaseStatus;
(function (DocumentReleaseStatus) {
    DocumentReleaseStatus["AVAILABLE"] = "AVAILABLE";
    DocumentReleaseStatus["CANCELLED"] = "CANCELLED";
    DocumentReleaseStatus["IN_WORK"] = "IN_WORK";
    DocumentReleaseStatus["INACTIVE"] = "INACTIVE";
    DocumentReleaseStatus["OBSOLETE"] = "OBSOLETE";
    DocumentReleaseStatus["RELEASED"] = "RELEASED";
    DocumentReleaseStatus["UNKNOWN"] = "UNKNOWN";
})(DocumentReleaseStatus || (DocumentReleaseStatus = {}));
/** Methods to use when ordering `Document`. */
export var DocumentsOrderBy;
(function (DocumentsOrderBy) {
    DocumentsOrderBy["NATURAL"] = "NATURAL";
    DocumentsOrderBy["ID_ASC"] = "ID_ASC";
    DocumentsOrderBy["ID_DESC"] = "ID_DESC";
    DocumentsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    DocumentsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    DocumentsOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    DocumentsOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    DocumentsOrderBy["TITLE_ASC"] = "TITLE_ASC";
    DocumentsOrderBy["TITLE_DESC"] = "TITLE_DESC";
    DocumentsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    DocumentsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    DocumentsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    DocumentsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    DocumentsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    DocumentsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    DocumentsOrderBy["OVERRIDE_CLOSE_DATE_ASC"] = "OVERRIDE_CLOSE_DATE_ASC";
    DocumentsOrderBy["OVERRIDE_CLOSE_DATE_DESC"] = "OVERRIDE_CLOSE_DATE_DESC";
    DocumentsOrderBy["OVERRIDE_OPEN_DATE_ASC"] = "OVERRIDE_OPEN_DATE_ASC";
    DocumentsOrderBy["OVERRIDE_OPEN_DATE_DESC"] = "OVERRIDE_OPEN_DATE_DESC";
    DocumentsOrderBy["ARCHIVE_BOX_NUMBER_ASC"] = "ARCHIVE_BOX_NUMBER_ASC";
    DocumentsOrderBy["ARCHIVE_BOX_NUMBER_DESC"] = "ARCHIVE_BOX_NUMBER_DESC";
    DocumentsOrderBy["RELEASE_STATUS_ASC"] = "RELEASE_STATUS_ASC";
    DocumentsOrderBy["RELEASE_STATUS_DESC"] = "RELEASE_STATUS_DESC";
    DocumentsOrderBy["OVERRIDE_REVISION_NUMBER_ASC"] = "OVERRIDE_REVISION_NUMBER_ASC";
    DocumentsOrderBy["OVERRIDE_REVISION_NUMBER_DESC"] = "OVERRIDE_REVISION_NUMBER_DESC";
    DocumentsOrderBy["RELEASE_DATE_ASC"] = "RELEASE_DATE_ASC";
    DocumentsOrderBy["RELEASE_DATE_DESC"] = "RELEASE_DATE_DESC";
    DocumentsOrderBy["LAST_REVIEW_DATE_ASC"] = "LAST_REVIEW_DATE_ASC";
    DocumentsOrderBy["LAST_REVIEW_DATE_DESC"] = "LAST_REVIEW_DATE_DESC";
    DocumentsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    DocumentsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    DocumentsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    DocumentsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    DocumentsOrderBy["JOB_NUMBER_ASC"] = "JOB_NUMBER_ASC";
    DocumentsOrderBy["JOB_NUMBER_DESC"] = "JOB_NUMBER_DESC";
    DocumentsOrderBy["IS_EXPORT_CONTROLLED_ASC"] = "IS_EXPORT_CONTROLLED_ASC";
    DocumentsOrderBy["IS_EXPORT_CONTROLLED_DESC"] = "IS_EXPORT_CONTROLLED_DESC";
    DocumentsOrderBy["IS_PROPRIETARY_ASC"] = "IS_PROPRIETARY_ASC";
    DocumentsOrderBy["IS_PROPRIETARY_DESC"] = "IS_PROPRIETARY_DESC";
    DocumentsOrderBy["PROJECT_CODE_ASC"] = "PROJECT_CODE_ASC";
    DocumentsOrderBy["PROJECT_CODE_DESC"] = "PROJECT_CODE_DESC";
    DocumentsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_ASC"] = "RESPONSIBLE_ORGANIZATION_CODE_ASC";
    DocumentsOrderBy["RESPONSIBLE_ORGANIZATION_CODE_DESC"] = "RESPONSIBLE_ORGANIZATION_CODE_DESC";
    DocumentsOrderBy["PERFORMING_ORGANIZATION_CODE_ASC"] = "PERFORMING_ORGANIZATION_CODE_ASC";
    DocumentsOrderBy["PERFORMING_ORGANIZATION_CODE_DESC"] = "PERFORMING_ORGANIZATION_CODE_DESC";
    DocumentsOrderBy["TAGS_ASC"] = "TAGS_ASC";
    DocumentsOrderBy["TAGS_DESC"] = "TAGS_DESC";
    DocumentsOrderBy["FINAL_DISPOSITION_DEFECT_CODE_ASC"] = "FINAL_DISPOSITION_DEFECT_CODE_ASC";
    DocumentsOrderBy["FINAL_DISPOSITION_DEFECT_CODE_DESC"] = "FINAL_DISPOSITION_DEFECT_CODE_DESC";
    DocumentsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    DocumentsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    DocumentsOrderBy["OVERRIDE_START_DATE_ASC"] = "OVERRIDE_START_DATE_ASC";
    DocumentsOrderBy["OVERRIDE_START_DATE_DESC"] = "OVERRIDE_START_DATE_DESC";
    DocumentsOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    DocumentsOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    DocumentsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    DocumentsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    DocumentsOrderBy["DUPLICATE_OF_DOCUMENT_ID_ASC"] = "DUPLICATE_OF_DOCUMENT_ID_ASC";
    DocumentsOrderBy["DUPLICATE_OF_DOCUMENT_ID_DESC"] = "DUPLICATE_OF_DOCUMENT_ID_DESC";
    DocumentsOrderBy["IS_DUPLICATE_ASC"] = "IS_DUPLICATE_ASC";
    DocumentsOrderBy["IS_DUPLICATE_DESC"] = "IS_DUPLICATE_DESC";
    DocumentsOrderBy["REVIEW_REQUIRED_DAYS_ASC"] = "REVIEW_REQUIRED_DAYS_ASC";
    DocumentsOrderBy["REVIEW_REQUIRED_DAYS_DESC"] = "REVIEW_REQUIRED_DAYS_DESC";
    DocumentsOrderBy["ESTIMATED_CLOSE_DATE_ASC"] = "ESTIMATED_CLOSE_DATE_ASC";
    DocumentsOrderBy["ESTIMATED_CLOSE_DATE_DESC"] = "ESTIMATED_CLOSE_DATE_DESC";
    DocumentsOrderBy["REFERENCE_DOCUMENTS_ASC"] = "REFERENCE_DOCUMENTS_ASC";
    DocumentsOrderBy["REFERENCE_DOCUMENTS_DESC"] = "REFERENCE_DOCUMENTS_DESC";
    DocumentsOrderBy["ENFORCE_SEQUENTIAL_STEPS_ASC"] = "ENFORCE_SEQUENTIAL_STEPS_ASC";
    DocumentsOrderBy["ENFORCE_SEQUENTIAL_STEPS_DESC"] = "ENFORCE_SEQUENTIAL_STEPS_DESC";
    DocumentsOrderBy["COMPUTED_CLOSE_DATE_ASC"] = "COMPUTED_CLOSE_DATE_ASC";
    DocumentsOrderBy["COMPUTED_CLOSE_DATE_DESC"] = "COMPUTED_CLOSE_DATE_DESC";
    DocumentsOrderBy["COMPUTED_OPEN_DATE_ASC"] = "COMPUTED_OPEN_DATE_ASC";
    DocumentsOrderBy["COMPUTED_OPEN_DATE_DESC"] = "COMPUTED_OPEN_DATE_DESC";
    DocumentsOrderBy["COMPUTED_PROJECTED_CLOSE_DATE_ASC"] = "COMPUTED_PROJECTED_CLOSE_DATE_ASC";
    DocumentsOrderBy["COMPUTED_PROJECTED_CLOSE_DATE_DESC"] = "COMPUTED_PROJECTED_CLOSE_DATE_DESC";
    DocumentsOrderBy["COMPUTED_REVIEW_DATE_ASC"] = "COMPUTED_REVIEW_DATE_ASC";
    DocumentsOrderBy["COMPUTED_REVIEW_DATE_DESC"] = "COMPUTED_REVIEW_DATE_DESC";
    DocumentsOrderBy["COMPUTED_STATUS_ASC"] = "COMPUTED_STATUS_ASC";
    DocumentsOrderBy["COMPUTED_STATUS_DESC"] = "COMPUTED_STATUS_DESC";
    DocumentsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    DocumentsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(DocumentsOrderBy || (DocumentsOrderBy = {}));
export var DocumentType;
(function (DocumentType) {
    DocumentType["ACCEPTANCE_DATA_PACK"] = "ACCEPTANCE_DATA_PACK";
    DocumentType["BARCODE_DECLARATION"] = "BARCODE_DECLARATION";
    DocumentType["CONFIGURATION_CHANGE_ORDER"] = "CONFIGURATION_CHANGE_ORDER";
    DocumentType["CR"] = "CR";
    DocumentType["C_DISCREPANCY_REPORT"] = "C_DISCREPANCY_REPORT";
    DocumentType["C_EASY_TRANSFER"] = "C_EASY_TRANSFER";
    DocumentType["C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM"] = "C_FAILURE_REPORTING_ANALYSIS_CORRECTIVE_ACTION_SYSTEM";
    DocumentType["C_TPS"] = "C_TPS";
    DocumentType["DISCREPANCY_REPORT"] = "DISCREPANCY_REPORT";
    DocumentType["DRAWING"] = "DRAWING";
    DocumentType["EASY_TRANSFER"] = "EASY_TRANSFER";
    DocumentType["ELABEL"] = "ELABEL";
    DocumentType["ELROD"] = "ELROD";
    DocumentType["ENGINEERING_CHANGE"] = "ENGINEERING_CHANGE";
    DocumentType["FAILURE_INVESTIGATION_ANOMALY_REPORT"] = "FAILURE_INVESTIGATION_ANOMALY_REPORT";
    DocumentType["GCAR"] = "GCAR";
    DocumentType["HAZARD_RESPONSE_LEVEL"] = "HAZARD_RESPONSE_LEVEL";
    DocumentType["IRREGULAR_PARTS_AUTHORIZATION_REQUEST"] = "IRREGULAR_PARTS_AUTHORIZATION_REQUEST";
    DocumentType["ITEM_FOR_INVESTIGATION"] = "ITEM_FOR_INVESTIGATION";
    DocumentType["MATERIAL_USAGE_AGREEMENT"] = "MATERIAL_USAGE_AGREEMENT";
    DocumentType["NASA_SPACE_PART_AUTHORIZATION_REQUEST"] = "NASA_SPACE_PART_AUTHORIZATION_REQUEST";
    DocumentType["NINE_ZERO_SIX"] = "NINE_ZERO_SIX";
    DocumentType["OPS_NOM_DECLARATION"] = "OPS_NOM_DECLARATION";
    DocumentType["OTHER_DOCUMENT"] = "OTHER_DOCUMENT";
    DocumentType["OVER_DUE_TAG"] = "OVER_DUE_TAG";
    DocumentType["PROBLEM_REPORTING_AND_CORRECTIVE_ACTION"] = "PROBLEM_REPORTING_AND_CORRECTIVE_ACTION";
    DocumentType["PROCEDURE"] = "PROCEDURE";
    DocumentType["RSA"] = "RSA";
    DocumentType["TPS"] = "TPS";
    DocumentType["VEHICLE_CERTIFICATION"] = "VEHICLE_CERTIFICATION";
    DocumentType["WAIVER"] = "WAIVER";
    DocumentType["YELLOW_TAG_NOTICE"] = "YELLOW_TAG_NOTICE";
    DocumentType["C_WORKFLOW"] = "C_WORKFLOW";
    DocumentType["C_RCA"] = "C_RCA";
    DocumentType["C_DIGITAL_RECORD_REVIEW_ITEM"] = "C_DIGITAL_RECORD_REVIEW_ITEM";
    DocumentType["DELIVERY_ORDER"] = "DELIVERY_ORDER";
})(DocumentType || (DocumentType = {}));
export var DrDocumentClosureRationale;
(function (DrDocumentClosureRationale) {
    DrDocumentClosureRationale["NON_MRB"] = "NON_MRB";
    DrDocumentClosureRationale["MAJOR_MRB"] = "MAJOR_MRB";
    DrDocumentClosureRationale["UNDETERMINED"] = "UNDETERMINED";
    DrDocumentClosureRationale["MINOR_MRB"] = "MINOR_MRB";
    DrDocumentClosureRationale["PMRB"] = "PMRB";
    DrDocumentClosureRationale["WRITTEN_IN_ERROR_NOT_VALID"] = "WRITTEN_IN_ERROR_NOT_VALID";
})(DrDocumentClosureRationale || (DrDocumentClosureRationale = {}));
export var DrDocumentDefectCode;
(function (DrDocumentDefectCode) {
    DrDocumentDefectCode["N_A"] = "N_A";
    DrDocumentDefectCode["DA1"] = "DA1";
    DrDocumentDefectCode["DC1"] = "DC1";
    DrDocumentDefectCode["DC2"] = "DC2";
    DrDocumentDefectCode["DD1"] = "DD1";
    DrDocumentDefectCode["DD2"] = "DD2";
    DrDocumentDefectCode["DD3"] = "DD3";
    DrDocumentDefectCode["DD4"] = "DD4";
    DrDocumentDefectCode["DD5"] = "DD5";
    DrDocumentDefectCode["DD6"] = "DD6";
    DrDocumentDefectCode["DD7"] = "DD7";
    DrDocumentDefectCode["DD8"] = "DD8";
    DrDocumentDefectCode["DE"] = "DE";
    DrDocumentDefectCode["DE1"] = "DE1";
    DrDocumentDefectCode["DE2"] = "DE2";
    DrDocumentDefectCode["DE3"] = "DE3";
    DrDocumentDefectCode["DE4"] = "DE4";
    DrDocumentDefectCode["DE5"] = "DE5";
    DrDocumentDefectCode["DE6"] = "DE6";
    DrDocumentDefectCode["DE7"] = "DE7";
    DrDocumentDefectCode["DL1"] = "DL1";
    DrDocumentDefectCode["DM1"] = "DM1";
    DrDocumentDefectCode["DM2"] = "DM2";
    DrDocumentDefectCode["DM3"] = "DM3";
    DrDocumentDefectCode["DM4"] = "DM4";
    DrDocumentDefectCode["DM5"] = "DM5";
    DrDocumentDefectCode["DM6"] = "DM6";
    DrDocumentDefectCode["DM7"] = "DM7";
    DrDocumentDefectCode["DM8"] = "DM8";
    DrDocumentDefectCode["DP1"] = "DP1";
    DrDocumentDefectCode["DP2"] = "DP2";
    DrDocumentDefectCode["DP3"] = "DP3";
    DrDocumentDefectCode["DP4"] = "DP4";
    DrDocumentDefectCode["DP5"] = "DP5";
    DrDocumentDefectCode["DR1"] = "DR1";
    DrDocumentDefectCode["DR2"] = "DR2";
    DrDocumentDefectCode["DR3"] = "DR3";
    DrDocumentDefectCode["DR4"] = "DR4";
    DrDocumentDefectCode["DR5"] = "DR5";
    DrDocumentDefectCode["DR6"] = "DR6";
    DrDocumentDefectCode["DS1"] = "DS1";
    DrDocumentDefectCode["DS2"] = "DS2";
    DrDocumentDefectCode["DS3"] = "DS3";
    DrDocumentDefectCode["DS4"] = "DS4";
    DrDocumentDefectCode["DS5"] = "DS5";
    DrDocumentDefectCode["DS6"] = "DS6";
    DrDocumentDefectCode["DS7"] = "DS7";
    DrDocumentDefectCode["DT"] = "DT";
    DrDocumentDefectCode["DT1"] = "DT1";
    DrDocumentDefectCode["DT2"] = "DT2";
})(DrDocumentDefectCode || (DrDocumentDefectCode = {}));
/** Methods to use when ordering `ErrorLog`. */
export var ErrorLogsOrderBy;
(function (ErrorLogsOrderBy) {
    ErrorLogsOrderBy["NATURAL"] = "NATURAL";
    ErrorLogsOrderBy["ID_ASC"] = "ID_ASC";
    ErrorLogsOrderBy["ID_DESC"] = "ID_DESC";
    ErrorLogsOrderBy["ORGANIZATION_CODE_ASC"] = "ORGANIZATION_CODE_ASC";
    ErrorLogsOrderBy["ORGANIZATION_CODE_DESC"] = "ORGANIZATION_CODE_DESC";
    ErrorLogsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    ErrorLogsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    ErrorLogsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    ErrorLogsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    ErrorLogsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ErrorLogsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ErrorLogsOrderBy["BROWSER_ASC"] = "BROWSER_ASC";
    ErrorLogsOrderBy["BROWSER_DESC"] = "BROWSER_DESC";
    ErrorLogsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ErrorLogsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ErrorLogsOrderBy["SANITIZED_QUERY_ASC"] = "SANITIZED_QUERY_ASC";
    ErrorLogsOrderBy["SANITIZED_QUERY_DESC"] = "SANITIZED_QUERY_DESC";
    ErrorLogsOrderBy["SANITIZED_PATH_ASC"] = "SANITIZED_PATH_ASC";
    ErrorLogsOrderBy["SANITIZED_PATH_DESC"] = "SANITIZED_PATH_DESC";
    ErrorLogsOrderBy["BROWSER_TYPE_ASC"] = "BROWSER_TYPE_ASC";
    ErrorLogsOrderBy["BROWSER_TYPE_DESC"] = "BROWSER_TYPE_DESC";
    ErrorLogsOrderBy["BROWSER_VERSION_ASC"] = "BROWSER_VERSION_ASC";
    ErrorLogsOrderBy["BROWSER_VERSION_DESC"] = "BROWSER_VERSION_DESC";
    ErrorLogsOrderBy["TO_URL_ASC"] = "TO_URL_ASC";
    ErrorLogsOrderBy["TO_URL_DESC"] = "TO_URL_DESC";
    ErrorLogsOrderBy["FROM_URL_ASC"] = "FROM_URL_ASC";
    ErrorLogsOrderBy["FROM_URL_DESC"] = "FROM_URL_DESC";
    ErrorLogsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ErrorLogsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ErrorLogsOrderBy || (ErrorLogsOrderBy = {}));
/** Methods to use when ordering `Event`. */
export var EventsOrderBy;
(function (EventsOrderBy) {
    EventsOrderBy["NATURAL"] = "NATURAL";
    EventsOrderBy["ID_ASC"] = "ID_ASC";
    EventsOrderBy["ID_DESC"] = "ID_DESC";
    EventsOrderBy["LOCATION_ID_ASC"] = "LOCATION_ID_ASC";
    EventsOrderBy["LOCATION_ID_DESC"] = "LOCATION_ID_DESC";
    EventsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    EventsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    EventsOrderBy["NAME_ASC"] = "NAME_ASC";
    EventsOrderBy["NAME_DESC"] = "NAME_DESC";
    EventsOrderBy["IS_HARDWARE_LOCATION_RETURN_ASC"] = "IS_HARDWARE_LOCATION_RETURN_ASC";
    EventsOrderBy["IS_HARDWARE_LOCATION_RETURN_DESC"] = "IS_HARDWARE_LOCATION_RETURN_DESC";
    EventsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    EventsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    EventsOrderBy["START_DATE_ASC"] = "START_DATE_ASC";
    EventsOrderBy["START_DATE_DESC"] = "START_DATE_DESC";
    EventsOrderBy["END_DATE_ASC"] = "END_DATE_ASC";
    EventsOrderBy["END_DATE_DESC"] = "END_DATE_DESC";
    EventsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    EventsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    EventsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    EventsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    EventsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    EventsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    EventsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    EventsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    EventsOrderBy["VEHICLE_ID_ASC"] = "VEHICLE_ID_ASC";
    EventsOrderBy["VEHICLE_ID_DESC"] = "VEHICLE_ID_DESC";
    EventsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    EventsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(EventsOrderBy || (EventsOrderBy = {}));
export var EventType;
(function (EventType) {
    EventType["CHAMBER"] = "CHAMBER";
    EventType["DIGITAL_RECORD_REVIEW"] = "DIGITAL_RECORD_REVIEW";
    EventType["DISPOSAL"] = "DISPOSAL";
    EventType["EVA"] = "EVA";
    EventType["FIT_CHECK"] = "FIT_CHECK";
    EventType["GENERAL"] = "GENERAL";
    EventType["GLOVE_FIT_CHECK"] = "GLOVE_FIT_CHECK";
    EventType["LAUNCH"] = "LAUNCH";
    EventType["MISHAP"] = "MISHAP";
    EventType["RETURN"] = "RETURN";
    EventType["TRAINING"] = "TRAINING";
    EventType["WAIVER"] = "WAIVER";
    EventType["ALLOCATION_WINDOW"] = "ALLOCATION_WINDOW";
})(EventType || (EventType = {}));
export var EztDocumentType;
(function (EztDocumentType) {
    EztDocumentType["ISSUE"] = "ISSUE";
    EztDocumentType["RECEIPT"] = "RECEIPT";
    EztDocumentType["TRANSFER_ISSUED"] = "TRANSFER_ISSUED";
})(EztDocumentType || (EztDocumentType = {}));
export var FederalSupplyClass;
(function (FederalSupplyClass) {
    FederalSupplyClass["ABRASIVE_MATERIALS"] = "ABRASIVE_MATERIALS";
    FederalSupplyClass["ACCESSORIES_FOR_SECONDARY_METALWORKING"] = "ACCESSORIES_FOR_SECONDARY_METALWORKING";
    FederalSupplyClass["ACCOUNTING_AND_CALCULATING_MACHINES"] = "ACCOUNTING_AND_CALCULATING_MACHINES";
    FederalSupplyClass["ADDITIVE_METAL_MATERIALS_AND_MASTER_ALLOYS"] = "ADDITIVE_METAL_MATERIALS_AND_MASTER_ALLOYS";
    FederalSupplyClass["ADHESIVES"] = "ADHESIVES";
    FederalSupplyClass["ADPE_SYSTEMS_CONFIGURATION"] = "ADPE_SYSTEMS_CONFIGURATION";
    FederalSupplyClass["ADP_CENTRAL_PROCESSING_UNIT_CC_ANALOG"] = "ADP_CENTRAL_PROCESSING_UNIT_CC_ANALOG";
    FederalSupplyClass["ADP_CENTRAL_PROCESSING_UNIT_CC_DIGITAL"] = "ADP_CENTRAL_PROCESSING_UNIT_CC_DIGITAL";
    FederalSupplyClass["ADP_CENTRAL_PROCESSING_UNIT_CC_HYBRID"] = "ADP_CENTRAL_PROCESSING_UNIT_CC_HYBRID";
    FederalSupplyClass["ADP_COMPONENTS"] = "ADP_COMPONENTS";
    FederalSupplyClass["ADP_INPUT_OUTPUT_AND_STORAGE_DEVICES"] = "ADP_INPUT_OUTPUT_AND_STORAGE_DEVICES";
    FederalSupplyClass["ADP_SOFTWARE"] = "ADP_SOFTWARE";
    FederalSupplyClass["ADP_SUPPLIES"] = "ADP_SUPPLIES";
    FederalSupplyClass["ADP_SUPPORT_EQUIPMENT"] = "ADP_SUPPORT_EQUIPMENT";
    FederalSupplyClass["AIRCRAFT_ALARM_AND_SIGNAL_SYSTEMS"] = "AIRCRAFT_ALARM_AND_SIGNAL_SYSTEMS";
    FederalSupplyClass["AIRCRAFT_MAINTENANCE_AND_REPAIR_SHOP"] = "AIRCRAFT_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["AIR_CONDITIONING_EQUIPMENT"] = "AIR_CONDITIONING_EQUIPMENT";
    FederalSupplyClass["AIR_PURIFICATION_EQUIPMENT"] = "AIR_PURIFICATION_EQUIPMENT";
    FederalSupplyClass["AMIMAL_DRAWN_VEHICLES_AND_FARM_TRAILERS"] = "AMIMAL_DRAWN_VEHICLES_AND_FARM_TRAILERS";
    FederalSupplyClass["AMMUNITION_AND_NUCLEAR_ORDNANCE_BOXES"] = "AMMUNITION_AND_NUCLEAR_ORDNANCE_BOXES";
    FederalSupplyClass["AMMUNITION_MAINTENANCE_REPAIR_AND_CHECKOUT"] = "AMMUNITION_MAINTENANCE_REPAIR_AND_CHECKOUT";
    FederalSupplyClass["ANTENNAS_WAVEGUIDE_AND_RELATED_EQUIPMENT"] = "ANTENNAS_WAVEGUIDE_AND_RELATED_EQUIPMENT";
    FederalSupplyClass["ARMAMENT_TRAINING_DEVICES"] = "ARMAMENT_TRAINING_DEVICES";
    FederalSupplyClass["ARMOR_PERSONAL"] = "ARMOR_PERSONAL";
    FederalSupplyClass["ATHLETIC_AND_SPORTING_EQUIPMENT"] = "ATHLETIC_AND_SPORTING_EQUIPMENT";
    FederalSupplyClass["ATTENUATORS"] = "ATTENUATORS";
    FederalSupplyClass["AUTOMATIC_PILOT_MECHANISMS_AND_AIRBORNE"] = "AUTOMATIC_PILOT_MECHANISMS_AND_AIRBORNE";
    FederalSupplyClass["BADGES_AND_INSIGNIA"] = "BADGES_AND_INSIGNIA";
    FederalSupplyClass["BAGS_AND_SACKS"] = "BAGS_AND_SACKS";
    FederalSupplyClass["BAKERY_AND_CEREAL_PRODUCTS"] = "BAKERY_AND_CEREAL_PRODUCTS";
    FederalSupplyClass["BALLASTS_LAMPHOLDERS_AND_STARTERS"] = "BALLASTS_LAMPHOLDERS_AND_STARTERS";
    FederalSupplyClass["BARS_AND_RODS_IRON_AND_STEEL"] = "BARS_AND_RODS_IRON_AND_STEEL";
    FederalSupplyClass["BARS_AND_RODS_NONFERROUS_BASE_METAL"] = "BARS_AND_RODS_NONFERROUS_BASE_METAL";
    FederalSupplyClass["BATTERIES_NONRECHARGEABLE"] = "BATTERIES_NONRECHARGEABLE";
    FederalSupplyClass["BATTERIES_RECHARGEABLE"] = "BATTERIES_RECHARGEABLE";
    FederalSupplyClass["BEARINGS_ANTIFRICTION_UNMOUNTED"] = "BEARINGS_ANTIFRICTION_UNMOUNTED";
    FederalSupplyClass["BEARINGS_PLAIN_UNMOUNTED"] = "BEARINGS_PLAIN_UNMOUNTED";
    FederalSupplyClass["BEARINGS_UNMOUNTED"] = "BEARINGS_UNMOUNTED";
    FederalSupplyClass["BELTING_DRIVE_BELTS_FAN_BELTS_AND_ACCESSORIES"] = "BELTING_DRIVE_BELTS_FAN_BELTS_AND_ACCESSORIES";
    FederalSupplyClass["BENDING_AND_FORMING_MACHINES"] = "BENDING_AND_FORMING_MACHINES";
    FederalSupplyClass["BEVERAGES_ALCOHOLIC"] = "BEVERAGES_ALCOHOLIC";
    FederalSupplyClass["BEVERAGES_NONALCOHOLIC_H20_WATER"] = "BEVERAGES_NONALCOHOLIC_H20_WATER";
    FederalSupplyClass["BLOCKS_TACKLE_RIGGING_AND_SLINGS"] = "BLOCKS_TACKLE_RIGGING_AND_SLINGS";
    FederalSupplyClass["BOLTS"] = "BOLTS";
    FederalSupplyClass["BOOKS_AND_PAMPHLETS"] = "BOOKS_AND_PAMPHLETS";
    FederalSupplyClass["BORING_MACHINES"] = "BORING_MACHINES";
    FederalSupplyClass["BOTTLES_AND_JARS"] = "BOTTLES_AND_JARS";
    FederalSupplyClass["BOXES_CARTONS_AND_CRATES"] = "BOXES_CARTONS_AND_CRATES";
    FederalSupplyClass["BRACKETS"] = "BRACKETS";
    FederalSupplyClass["BRIDGES_FIXED_AND_FLOATING"] = "BRIDGES_FIXED_AND_FLOATING";
    FederalSupplyClass["BROACHING_MACHINES"] = "BROACHING_MACHINES";
    FederalSupplyClass["BROOMS_BRUSHES_MOPS_AND_SPONGES"] = "BROOMS_BRUSHES_MOPS_AND_SPONGES";
    FederalSupplyClass["BUILDING_COMPONENTS_PREFABRICATED"] = "BUILDING_COMPONENTS_PREFABRICATED";
    FederalSupplyClass["BUILDING_GLASS_TILE_BRICK_AND_BLOCK"] = "BUILDING_GLASS_TILE_BRICK_AND_BLOCK";
    FederalSupplyClass["BUSHINGS_RINGS_SHIMS_AND_SPACERS"] = "BUSHINGS_RINGS_SHIMS_AND_SPACERS";
    FederalSupplyClass["CABINETS_LOCKERS_BINS_AND_SHELVING"] = "CABINETS_LOCKERS_BINS_AND_SHELVING";
    FederalSupplyClass["CABLE_CORD_AND_WIRE_ASSEMBLIES_COMMUNICATION"] = "CABLE_CORD_AND_WIRE_ASSEMBLIES_COMMUNICATION";
    FederalSupplyClass["CAMERAS_MOTION_PICTURE"] = "CAMERAS_MOTION_PICTURE";
    FederalSupplyClass["CAMERAS_STILL_PICTURE"] = "CAMERAS_STILL_PICTURE";
    FederalSupplyClass["CAMOUFLAGE_AND_DECEPTION_EQUIPMENT"] = "CAMOUFLAGE_AND_DECEPTION_EQUIPMENT";
    FederalSupplyClass["CAPACITORS"] = "CAPACITORS";
    FederalSupplyClass["CARTRIDGE_AND_PROPELLANT_ACTUATED_DEVICES"] = "CARTRIDGE_AND_PROPELLANT_ACTUATED_DEVICES";
    FederalSupplyClass["CENTRIFUGALS_SEPARATORS_H20_FILTER_AND_PRESSURE"] = "CENTRIFUGALS_SEPARATORS_H20_FILTER_AND_PRESSURE";
    FederalSupplyClass["CHAIN_AND_WIRE_ROPE"] = "CHAIN_AND_WIRE_ROPE";
    FederalSupplyClass["CHEMICALS"] = "CHEMICALS";
    FederalSupplyClass["CHEMICAL_ANALYSIS_INSTRUMENTS"] = "CHEMICAL_ANALYSIS_INSTRUMENTS";
    FederalSupplyClass["CHEMICAL_AND_PHARMACEUTICAL_PRODUCTS"] = "CHEMICAL_AND_PHARMACEUTICAL_PRODUCTS";
    FederalSupplyClass["CHILDRENS_AND_INFANTS_APPAREL_ACCESSORIES"] = "CHILDRENS_AND_INFANTS_APPAREL_ACCESSORIES";
    FederalSupplyClass["CIRCUIT_BREAKERS"] = "CIRCUIT_BREAKERS";
    FederalSupplyClass["CLAY_AND_CONCRETE_PRODUCTS_INDUSTRIES"] = "CLAY_AND_CONCRETE_PRODUCTS_INDUSTRIES";
    FederalSupplyClass["CLEANING_AND_POLISHING_COMPOUNDS"] = "CLEANING_AND_POLISHING_COMPOUNDS";
    FederalSupplyClass["CLEAN_WORK_STATIONS_FLOWBENCH_CONTROLLED_ENVIR"] = "CLEAN_WORK_STATIONS_FLOWBENCH_CONTROLLED_ENVIR";
    FederalSupplyClass["CLOTHING_SPECIAL_PURPOSE"] = "CLOTHING_SPECIAL_PURPOSE";
    FederalSupplyClass["COFFEE_TEA_AND_COCOA"] = "COFFEE_TEA_AND_COCOA";
    FederalSupplyClass["COILFLAT_AND_WIRE_SPRINGS"] = "COILFLAT_AND_WIRE_SPRINGS";
    FederalSupplyClass["COILS_AND_TRANSFORMERS"] = "COILS_AND_TRANSFORMERS";
    FederalSupplyClass["COIL_FLAT_AND_WIRE_SPRINGS"] = "COIL_FLAT_AND_WIRE_SPRINGS";
    FederalSupplyClass["COLLECTORS_AND_OR_HISTORICAL_ITEMS"] = "COLLECTORS_AND_OR_HISTORICAL_ITEMS";
    FederalSupplyClass["COMBINATION_AND_MISCELLANEOUS_INSTRUMENTS"] = "COMBINATION_AND_MISCELLANEOUS_INSTRUMENTS";
    FederalSupplyClass["COMMERCIAL_AND_INDUSTIAL_GAS_CYLINDERS"] = "COMMERCIAL_AND_INDUSTIAL_GAS_CYLINDERS";
    FederalSupplyClass["COMMUNICATION_TRAINING_DEVICES"] = "COMMUNICATION_TRAINING_DEVICES";
    FederalSupplyClass["COMPOSITE_FOOD_PACKAGES"] = "COMPOSITE_FOOD_PACKAGES";
    FederalSupplyClass["COMPRESSORS_AND_VACUUM_PUMPS"] = "COMPRESSORS_AND_VACUUM_PUMPS";
    FederalSupplyClass["CONDIMENTS_AND_RELATED_PRODUCTS"] = "CONDIMENTS_AND_RELATED_PRODUCTS";
    FederalSupplyClass["CONNECTORS_ELECTRICAL"] = "CONNECTORS_ELECTRICAL";
    FederalSupplyClass["CONVERTERS_ELECTRICAL_NONROTATING"] = "CONVERTERS_ELECTRICAL_NONROTATING";
    FederalSupplyClass["CONVERTERS_ELECTRICAL_ROTATING_RESIDUE"] = "CONVERTERS_ELECTRICAL_ROTATING_RESIDUE";
    FederalSupplyClass["CONVEYORS"] = "CONVEYORS";
    FederalSupplyClass["COUPLERS_SPLITTERS_AND_MIXERS"] = "COUPLERS_SPLITTERS_AND_MIXERS";
    FederalSupplyClass["CRANES_AND_CRANE_SHOVELS"] = "CRANES_AND_CRANE_SHOVELS";
    FederalSupplyClass["CRANE_AND_CRANE_SHOVEL_ATTACHMENTS"] = "CRANE_AND_CRANE_SHOVEL_ATTACHMENTS";
    FederalSupplyClass["CRUDE_GRADES_OF_PLANT_MATERIALS"] = "CRUDE_GRADES_OF_PLANT_MATERIALS";
    FederalSupplyClass["CRYSTAL_AND_GLASS_INDUSTRIES_MACHINERY"] = "CRYSTAL_AND_GLASS_INDUSTRIES_MACHINERY";
    FederalSupplyClass["COMMUNICATIONS_SECURITY_EQUIPMENT"] = "COMMUNICATIONS_SECURITY_EQUIPMENT";
    FederalSupplyClass["CUTLERY_AND_FLATWARE"] = "CUTLERY_AND_FLATWARE";
    FederalSupplyClass["CUTTING_AND_FORMING_TOOLS_FOR_SECONDARY"] = "CUTTING_AND_FORMING_TOOLS_FOR_SECONDARY";
    FederalSupplyClass["CUTTING_TOOLS_AND_MACHINE_TOOLS"] = "CUTTING_TOOLS_AND_MACHINE_TOOLS";
    FederalSupplyClass["DAIRY_FOODS_AND_EGGS"] = "DAIRY_FOODS_AND_EGGS";
    FederalSupplyClass["DAIRY_POULTRY_AND_LIVESTOCK_EQUIPMENT"] = "DAIRY_POULTRY_AND_LIVESTOCK_EQUIPMENT";
    FederalSupplyClass["DECONTAMINATING_AND_IMPREGNATING_EQUIPMENT"] = "DECONTAMINATING_AND_IMPREGNATING_EQUIPMENT";
    FederalSupplyClass["DENTAL_INSTRUMENTS_EQUIPMENT_AND_SUPPLIES"] = "DENTAL_INSTRUMENTS_EQUIPMENT_AND_SUPPLIES";
    FederalSupplyClass["DEPTH_CHARGES_AND_UNDERWATER_MINES_MAINTENANCE"] = "DEPTH_CHARGES_AND_UNDERWATER_MINES_MAINTENANCE";
    FederalSupplyClass["DISKS_AND_STONES_ABRASIVE"] = "DISKS_AND_STONES_ABRASIVE";
    FederalSupplyClass["DRAFTING_SURVEYING_AND_MAPPING_INSTRUMENTS"] = "DRAFTING_SURVEYING_AND_MAPPING_INSTRUMENTS";
    FederalSupplyClass["DRAPERIES_AWNINGS_AND_SHADES"] = "DRAPERIES_AWNINGS_AND_SHADES";
    FederalSupplyClass["DRAWINGS_AND_SPECIFICATIONS"] = "DRAWINGS_AND_SPECIFICATIONS";
    FederalSupplyClass["DRIERS_DEHYDRATORS_AND_ANHYDRATORS"] = "DRIERS_DEHYDRATORS_AND_ANHYDRATORS";
    FederalSupplyClass["DRILLING_AND_TAPPING_MACHINES"] = "DRILLING_AND_TAPPING_MACHINES";
    FederalSupplyClass["DRILL_BITS_COUNTERBORES_AND_COUNTERSINKS"] = "DRILL_BITS_COUNTERBORES_AND_COUNTERSINKS";
    FederalSupplyClass["DRUGS_AND_BIOLOGICALS"] = "DRUGS_AND_BIOLOGICALS";
    FederalSupplyClass["DRUMS_AND_CANS"] = "DRUMS_AND_CANS";
    FederalSupplyClass["DYES"] = "DYES";
    FederalSupplyClass["EARTH_MOVING_AND_EXCAVATING_EQUIPMENT"] = "EARTH_MOVING_AND_EXCAVATING_EQUIPMENT";
    FederalSupplyClass["ECCLESIASTICAL_EQUIPMENT_FURNISHINGS"] = "ECCLESIASTICAL_EQUIPMENT_FURNISHINGS";
    FederalSupplyClass["ELECTRICAL_AND_ELECTRONIC_ASSEMBLIES_BOARDS"] = "ELECTRICAL_AND_ELECTRONIC_ASSEMBLIES_BOARDS";
    FederalSupplyClass["ELECTRICAL_AND_ELECTRONIC_PROPERTIES"] = "ELECTRICAL_AND_ELECTRONIC_PROPERTIES";
    FederalSupplyClass["ELECTRICAL_AND_ULTRASONIC_EROSION_MACHINES"] = "ELECTRICAL_AND_ULTRASONIC_EROSION_MACHINES";
    FederalSupplyClass["ELECTRICAL_CONTACT_BRUSHES_AND_ELECTRODES"] = "ELECTRICAL_CONTACT_BRUSHES_AND_ELECTRODES";
    FederalSupplyClass["ELECTRICAL_CONTROL_EQUIPMENT"] = "ELECTRICAL_CONTROL_EQUIPMENT";
    FederalSupplyClass["ELECTRICAL_HARDWARE_AND_SUPPLIES"] = "ELECTRICAL_HARDWARE_AND_SUPPLIES";
    FederalSupplyClass["ELECTRICAL_INSULATORS_AND_INSULATING_MATERIALS"] = "ELECTRICAL_INSULATORS_AND_INSULATING_MATERIALS";
    FederalSupplyClass["ELECTRIC_ARC_WELDING_EQUIPMENT"] = "ELECTRIC_ARC_WELDING_EQUIPMENT";
    FederalSupplyClass["ELECTRIC_LAMPS"] = "ELECTRIC_LAMPS";
    FederalSupplyClass["ELECTRIC_PORTABLE_AND_HAND_LIGHTING_EQUIPMENT"] = "ELECTRIC_PORTABLE_AND_HAND_LIGHTING_EQUIPMENT";
    FederalSupplyClass["ELECTRIC_RESISTANCE_WELDING_EQUIPMENT"] = "ELECTRIC_RESISTANCE_WELDING_EQUIPMENT";
    FederalSupplyClass["ELECTRIC_VEHICULAR_LIGHTS_AND_FIXTURES"] = "ELECTRIC_VEHICULAR_LIGHTS_AND_FIXTURES";
    FederalSupplyClass["ELECTRONIC_COUNTERMEASURES_COUNTER"] = "ELECTRONIC_COUNTERMEASURES_COUNTER";
    FederalSupplyClass["ELECTRONIC_MODULES"] = "ELECTRONIC_MODULES";
    FederalSupplyClass["ELECTRON_TUBES_ASSOCIATED_HARDWARE"] = "ELECTRON_TUBES_ASSOCIATED_HARDWARE";
    FederalSupplyClass["ELEVATORS_AND_ESCALATORS"] = "ELEVATORS_AND_ESCALATORS";
    FederalSupplyClass["ENGINE_AIR_AND_OIL_FILTERS_STRAINERS_NONAIRCRAFT"] = "ENGINE_AIR_AND_OIL_FILTERS_STRAINERS_NONAIRCRAFT";
    FederalSupplyClass["ENGINE_COOLING_SYSTEM_COMPONENTS_NONAIRCRAFT"] = "ENGINE_COOLING_SYSTEM_COMPONENTS_NONAIRCRAFT";
    FederalSupplyClass["ENGINE_ELECTRICAL_SYSTEM_COMPONENTS_NONAIRCRAFT"] = "ENGINE_ELECTRICAL_SYSTEM_COMPONENTS_NONAIRCRAFT";
    FederalSupplyClass["ENGINE_INSTRUMENTS"] = "ENGINE_INSTRUMENTS";
    FederalSupplyClass["ENVIRONMENTAL_CHAMBERS_AND_RELATED_EQUIPMENT"] = "ENVIRONMENTAL_CHAMBERS_AND_RELATED_EQUIPMENT";
    FederalSupplyClass["EXPLOSIVE_AND_PYROTECHNIC_COMPONENTS"] = "EXPLOSIVE_AND_PYROTECHNIC_COMPONENTS";
    FederalSupplyClass["FANS_AIR_CIRCULATORS_AND_BLOWER_EQUIPMENT"] = "FANS_AIR_CIRCULATORS_AND_BLOWER_EQUIPMENT";
    FederalSupplyClass["FASTENING_DEVICES"] = "FASTENING_DEVICES";
    FederalSupplyClass["FENCING_FENCES_AND_GATES"] = "FENCING_FENCES_AND_GATES";
    FederalSupplyClass["FERTILIZERS"] = "FERTILIZERS";
    FederalSupplyClass["FIBERS_VEGETABLE_ANIMAL_AND_SYNTHETIC"] = "FIBERS_VEGETABLE_ANIMAL_AND_SYNTHETIC";
    FederalSupplyClass["FIBER_OPIC_LIGHT_TRANSFER_AND_IMAGE_TRANSFER"] = "FIBER_OPIC_LIGHT_TRANSFER_AND_IMAGE_TRANSFER";
    FederalSupplyClass["FIBER_OPTIC_ACCESSORIES_AND_SUPPLIES"] = "FIBER_OPTIC_ACCESSORIES_AND_SUPPLIES";
    FederalSupplyClass["FIBER_OPTIC_CABLES"] = "FIBER_OPTIC_CABLES";
    FederalSupplyClass["FIBER_OPTIC_CABLE_ASSEMBLIES_AND_HARNESSES"] = "FIBER_OPTIC_CABLE_ASSEMBLIES_AND_HARNESSES";
    FederalSupplyClass["FIBER_OPTIC_CONDUCTORS"] = "FIBER_OPTIC_CONDUCTORS";
    FederalSupplyClass["FIBER_OPTIC_DEVICES"] = "FIBER_OPTIC_DEVICES";
    FederalSupplyClass["FIBER_OPTIC_INTERCONNECTORS"] = "FIBER_OPTIC_INTERCONNECTORS";
    FederalSupplyClass["FIBER_OPTIC_KITS_AND_SETS"] = "FIBER_OPTIC_KITS_AND_SETS";
    FederalSupplyClass["FIBER_OPTIC_LIGHT_SOURCES"] = "FIBER_OPTIC_LIGHT_SOURCES";
    FederalSupplyClass["FIBER_OPTIC_MODULATORS_DEMODULATORS"] = "FIBER_OPTIC_MODULATORS_DEMODULATORS";
    FederalSupplyClass["FIBER_OPTIC_PASSIVE_DEVICES"] = "FIBER_OPTIC_PASSIVE_DEVICES";
    FederalSupplyClass["FIBER_OPTIC_PHOTO_DETECTORS"] = "FIBER_OPTIC_PHOTO_DETECTORS";
    FederalSupplyClass["FIBER_OPTIC_RECEIVERS"] = "FIBER_OPTIC_RECEIVERS";
    FederalSupplyClass["FIBER_OPTIC_SENSORS"] = "FIBER_OPTIC_SENSORS";
    FederalSupplyClass["FIBER_OPTIC_SWITCHES"] = "FIBER_OPTIC_SWITCHES";
    FederalSupplyClass["FIBER_OPTIC_TRANSMITTERS"] = "FIBER_OPTIC_TRANSMITTERS";
    FederalSupplyClass["FIBER_ROPE_CORDAGE_AND_TWINE"] = "FIBER_ROPE_CORDAGE_AND_TWINE";
    FederalSupplyClass["FILM_PROCESSED"] = "FILM_PROCESSED";
    FederalSupplyClass["FILTERS"] = "FILTERS";
    FederalSupplyClass["FILTERS_AND_NETWORKS"] = "FILTERS_AND_NETWORKS";
    FederalSupplyClass["FIRE_CONTROL_MAINTENANCE_AND_REPAIR_SHOP"] = "FIRE_CONTROL_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["FIRE_FIGHTING_EQUIPMENT"] = "FIRE_FIGHTING_EQUIPMENT";
    FederalSupplyClass["FITTINGS_AND_SPECIALTIES_HOSE_PIPEAND_TUBE"] = "FITTINGS_AND_SPECIALTIES_HOSE_PIPEAND_TUBE";
    FederalSupplyClass["FITTINGS_FOR_ROPE_CABLE_AND_CHAIN"] = "FITTINGS_FOR_ROPE_CABLE_AND_CHAIN";
    FederalSupplyClass["FLAGS_AND_PENNANTS"] = "FLAGS_AND_PENNANTS";
    FederalSupplyClass["FLIGHT_INSTRUMENTS"] = "FLIGHT_INSTRUMENTS";
    FederalSupplyClass["FLOOR_COVERINGS"] = "FLOOR_COVERINGS";
    FederalSupplyClass["FLOOR_POLISHERS_AND_VACUUM_CLEANING_EQUIPMENT"] = "FLOOR_POLISHERS_AND_VACUUM_CLEANING_EQUIPMENT";
    FederalSupplyClass["FOOD_COOKING_BAKING_AND_SERVING_EQUIPMENT"] = "FOOD_COOKING_BAKING_AND_SERVING_EQUIPMENT";
    FederalSupplyClass["FOOD_OILS_AND_FATS"] = "FOOD_OILS_AND_FATS";
    FederalSupplyClass["FOOD_PRODUCTS_MACHINERY_AND_EQUIPMENT"] = "FOOD_PRODUCTS_MACHINERY_AND_EQUIPMENT";
    FederalSupplyClass["FOOTWEAR_MENS"] = "FOOTWEAR_MENS";
    FederalSupplyClass["FOOTWEAR_WOMENS"] = "FOOTWEAR_WOMENS";
    FederalSupplyClass["FORAGE_AND_FEED"] = "FORAGE_AND_FEED";
    FederalSupplyClass["FORGING_MACHINERY_AND_HAMMERS"] = "FORGING_MACHINERY_AND_HAMMERS";
    FederalSupplyClass["FOUNDRY_MACHINERY_RELATED_EQUIPMENT_AND_SUPPLIES"] = "FOUNDRY_MACHINERY_RELATED_EQUIPMENT_AND_SUPPLIES";
    FederalSupplyClass["FRUITS_AND_VEGETABLES"] = "FRUITS_AND_VEGETABLES";
    FederalSupplyClass["FUELS_SOLID"] = "FUELS_SOLID";
    FederalSupplyClass["FUEL_BURNING_EQUIPMENT_UNITS"] = "FUEL_BURNING_EQUIPMENT_UNITS";
    FederalSupplyClass["FUEL_CELL_POWER_UNITS_COMPONENTS"] = "FUEL_CELL_POWER_UNITS_COMPONENTS";
    FederalSupplyClass["FUEL_OILS"] = "FUEL_OILS";
    FederalSupplyClass["FUR_MATERIALS"] = "FUR_MATERIALS";
    FederalSupplyClass["FUSES_ARRESTERS_ABSORBERS_AND_PROTECTORS"] = "FUSES_ARRESTERS_ABSORBERS_AND_PROTECTORS";
    FederalSupplyClass["GAMES_TOYS_AND_WHEELED_GOODS"] = "GAMES_TOYS_AND_WHEELED_GOODS";
    FederalSupplyClass["GARDENING_IMPLEMENTS_AND_TOOLS"] = "GARDENING_IMPLEMENTS_AND_TOOLS";
    FederalSupplyClass["GASES_COMPRESSED_AND_LIQUEFIED"] = "GASES_COMPRESSED_AND_LIQUEFIED";
    FederalSupplyClass["GAS_GENERATING_AND_DISPENSING_SYSTEMS_FIXED"] = "GAS_GENERATING_AND_DISPENSING_SYSTEMS_FIXED";
    FederalSupplyClass["GAS_WELDING_HEAT_CUTTING_AND_METALIZING"] = "GAS_WELDING_HEAT_CUTTING_AND_METALIZING";
    FederalSupplyClass["GEARS_PULLEYS_SPROCKETS_AND_TRANSMISSION"] = "GEARS_PULLEYS_SPROCKETS_AND_TRANSMISSION";
    FederalSupplyClass["GEAR_CUTTING_AND_FINISHING_MACHINES"] = "GEAR_CUTTING_AND_FINISHING_MACHINES";
    FederalSupplyClass["GENERATORS_AND_GENERATOR_SETS_ELECTRICAL"] = "GENERATORS_AND_GENERATOR_SETS_ELECTRICAL";
    FederalSupplyClass["GEOPHYSICAL_INSTRUMENTS"] = "GEOPHYSICAL_INSTRUMENTS";
    FederalSupplyClass["GLASS_FABRICATED_MATERIALS"] = "GLASS_FABRICATED_MATERIALS";
    FederalSupplyClass["GRINDING_MACHINES"] = "GRINDING_MACHINES";
    FederalSupplyClass["GROUP_10_WEAPONS"] = "GROUP_10_WEAPONS";
    FederalSupplyClass["GROUP_11_NUCLEAR_ORDNANCE"] = "GROUP_11_NUCLEAR_ORDNANCE";
    FederalSupplyClass["GROUP_12_FIRE_CONTROL_EQUIPMENT"] = "GROUP_12_FIRE_CONTROL_EQUIPMENT";
    FederalSupplyClass["GROUP_13_AMMUNITION_AND_EXPLOSIVES"] = "GROUP_13_AMMUNITION_AND_EXPLOSIVES";
    FederalSupplyClass["GROUP_14_GUIDED_MISSLES"] = "GROUP_14_GUIDED_MISSLES";
    FederalSupplyClass["GROUP_15_AIRCRAFT_AND_AIRFRAME_STRUCTURAL_EQUIP"] = "GROUP_15_AIRCRAFT_AND_AIRFRAME_STRUCTURAL_EQUIP";
    FederalSupplyClass["GROUP_16_AIRCRAFT_COMPONENTS_AND_ACCESSORIES"] = "GROUP_16_AIRCRAFT_COMPONENTS_AND_ACCESSORIES";
    FederalSupplyClass["GROUP_17_AIRCRAFT_LAUNCH_LAND_AND_GROUND_EQUIP"] = "GROUP_17_AIRCRAFT_LAUNCH_LAND_AND_GROUND_EQUIP";
    FederalSupplyClass["GROUP_19_SHIPS_SM_CRAFTS_PONTOONS_FLOATING_DOCKS"] = "GROUP_19_SHIPS_SM_CRAFTS_PONTOONS_FLOATING_DOCKS";
    FederalSupplyClass["GROUP_20_SHIP_AND_MARINE_EQUIPMENT"] = "GROUP_20_SHIP_AND_MARINE_EQUIPMENT";
    FederalSupplyClass["GROUP_22_RAILWAY_EQUIPMENT"] = "GROUP_22_RAILWAY_EQUIPMENT";
    FederalSupplyClass["GROUP_23_GROUND_AND_MOTOR_VEHICLES_TRAILERS_CYCLES"] = "GROUP_23_GROUND_AND_MOTOR_VEHICLES_TRAILERS_CYCLES";
    FederalSupplyClass["GROUP_24_TRACTORS"] = "GROUP_24_TRACTORS";
    FederalSupplyClass["GROUP_25_VEHICULAR_EQUIPMENT_COMPONENTS"] = "GROUP_25_VEHICULAR_EQUIPMENT_COMPONENTS";
    FederalSupplyClass["GROUP_26_TIRES_AND_TUBES"] = "GROUP_26_TIRES_AND_TUBES";
    FederalSupplyClass["GROUP_28_ENGINES_TURBINES_AND_COMPONENTS"] = "GROUP_28_ENGINES_TURBINES_AND_COMPONENTS";
    FederalSupplyClass["GROUP_29_ENGINE_ACCESSORIES"] = "GROUP_29_ENGINE_ACCESSORIES";
    FederalSupplyClass["GROUP_30_MECHANICAL_POWERED_TRANSMISSION_EQUIP"] = "GROUP_30_MECHANICAL_POWERED_TRANSMISSION_EQUIP";
    FederalSupplyClass["GROUP_31_BEARINGS"] = "GROUP_31_BEARINGS";
    FederalSupplyClass["GROUP_32_WOODWORKING_MACHINERY_AND_EQUIPMENT"] = "GROUP_32_WOODWORKING_MACHINERY_AND_EQUIPMENT";
    FederalSupplyClass["GROUP_34_METALWORKING_MACHINERY"] = "GROUP_34_METALWORKING_MACHINERY";
    FederalSupplyClass["GROUP_35_SERVICE_AND_TRADE_EQUIPMENT"] = "GROUP_35_SERVICE_AND_TRADE_EQUIPMENT";
    FederalSupplyClass["GROUP_36_SPECIAL_INDUSTRY_MACHINERY"] = "GROUP_36_SPECIAL_INDUSTRY_MACHINERY";
    FederalSupplyClass["GROUP_37_AGRICULTURAL_MACHINERY_AND_EQUIPMENT"] = "GROUP_37_AGRICULTURAL_MACHINERY_AND_EQUIPMENT";
    FederalSupplyClass["GROUP_38_CONSTRUCTION_MINING_EXCAVATING_ROAD_EQUIP"] = "GROUP_38_CONSTRUCTION_MINING_EXCAVATING_ROAD_EQUIP";
    FederalSupplyClass["GROUP_39_MATERIAL_HANDLING_EQUIPMENT"] = "GROUP_39_MATERIAL_HANDLING_EQUIPMENT";
    FederalSupplyClass["GROUP_40_ROPE_CABLE_CHAIN_AND_FITTINGS"] = "GROUP_40_ROPE_CABLE_CHAIN_AND_FITTINGS";
    FederalSupplyClass["GROUP_41_REFRIGERATION_AIRCONDITIONING_CIRC_EQUIP"] = "GROUP_41_REFRIGERATION_AIRCONDITIONING_CIRC_EQUIP";
    FederalSupplyClass["GROUP_42_FIRE_FIGHTING_RESCUE_AND_SAFETY_EQUIP"] = "GROUP_42_FIRE_FIGHTING_RESCUE_AND_SAFETY_EQUIP";
    FederalSupplyClass["GROUP_43_PUMPS_AND_COMPRESSORS"] = "GROUP_43_PUMPS_AND_COMPRESSORS";
    FederalSupplyClass["GROUP_44_FURNANCE_STEAM_DRYING_EQUIP_NUC_REACTOR"] = "GROUP_44_FURNANCE_STEAM_DRYING_EQUIP_NUC_REACTOR";
    FederalSupplyClass["GROUP_45_PLUMBING_HEATING_AND_SANITATION_EQUIP"] = "GROUP_45_PLUMBING_HEATING_AND_SANITATION_EQUIP";
    FederalSupplyClass["GROUP_46_WATER_PURIFICATION_EQUIPMENT"] = "GROUP_46_WATER_PURIFICATION_EQUIPMENT";
    FederalSupplyClass["GROUP_47_PIPE_TUBING_HOSE_AND_FITTINGS"] = "GROUP_47_PIPE_TUBING_HOSE_AND_FITTINGS";
    FederalSupplyClass["GROUP_48_VALVES"] = "GROUP_48_VALVES";
    FederalSupplyClass["GROUP_49_MAINTENANCE_AND_REPAIR_SHOP_EQUIP"] = "GROUP_49_MAINTENANCE_AND_REPAIR_SHOP_EQUIP";
    FederalSupplyClass["GROUP_51_HAND_TOOLS"] = "GROUP_51_HAND_TOOLS";
    FederalSupplyClass["GROUP_52_MEASURING_TOOLS"] = "GROUP_52_MEASURING_TOOLS";
    FederalSupplyClass["GROUP_53_HARDWARE_AND_ABRASIVES"] = "GROUP_53_HARDWARE_AND_ABRASIVES";
    FederalSupplyClass["GROUP_54_PREFABRICATED_STRUCTURES_AND_SCAFFOLDING"] = "GROUP_54_PREFABRICATED_STRUCTURES_AND_SCAFFOLDING";
    FederalSupplyClass["GROUP_55_LUMBER_MILLWORK_PLYWOOD_AND_VANEER"] = "GROUP_55_LUMBER_MILLWORK_PLYWOOD_AND_VANEER";
    FederalSupplyClass["GROUP_56_CONSTRUCTION_AND_BUILDING_MATERIAL"] = "GROUP_56_CONSTRUCTION_AND_BUILDING_MATERIAL";
    FederalSupplyClass["GROUP_58_COMMUNICATION_DETECTION_CO_RADIATION_EQUP"] = "GROUP_58_COMMUNICATION_DETECTION_CO_RADIATION_EQUP";
    FederalSupplyClass["GROUP_59_ELECTRICAL_ELECTRONIC_EQUIP_COMPONENTS"] = "GROUP_59_ELECTRICAL_ELECTRONIC_EQUIP_COMPONENTS";
    FederalSupplyClass["GROUP_60_FIBER_OPTIC_MAT_COMPONENTS_ASSY_ACCESSARY"] = "GROUP_60_FIBER_OPTIC_MAT_COMPONENTS_ASSY_ACCESSARY";
    FederalSupplyClass["GROUP_61_ELECTRIC_WIRE_POWER_DISTRIBUTION_EQUIP"] = "GROUP_61_ELECTRIC_WIRE_POWER_DISTRIBUTION_EQUIP";
    FederalSupplyClass["GROUP_62_LIGHTING_FIXTURES_AND_LAMPS"] = "GROUP_62_LIGHTING_FIXTURES_AND_LAMPS";
    FederalSupplyClass["GROUP_63_ALARM_SIGNAL_SECURITY_DETECTION_SYS"] = "GROUP_63_ALARM_SIGNAL_SECURITY_DETECTION_SYS";
    FederalSupplyClass["GROUP_65_MEDICAL_DENTAL_AND_VET_EQUIP_SUPPLIES"] = "GROUP_65_MEDICAL_DENTAL_AND_VET_EQUIP_SUPPLIES";
    FederalSupplyClass["GROUP_66_INSTRUMENTS_AND_LABRATORY_EQUIPMENT"] = "GROUP_66_INSTRUMENTS_AND_LABRATORY_EQUIPMENT";
    FederalSupplyClass["GROUP_67_PHOTOGRAPHIC_EQUIPMENT"] = "GROUP_67_PHOTOGRAPHIC_EQUIPMENT";
    FederalSupplyClass["GROUP_68_CHEMICALS_AND_CHEMICAL_PRODUCTS"] = "GROUP_68_CHEMICALS_AND_CHEMICAL_PRODUCTS";
    FederalSupplyClass["GROUP_69_TRAINING_AIDS_AND_DEVICES"] = "GROUP_69_TRAINING_AIDS_AND_DEVICES";
    FederalSupplyClass["GROUP_70_ADPE_EQUIP_SOFTWARE_SUPPLIES_AND_SUPPORT"] = "GROUP_70_ADPE_EQUIP_SOFTWARE_SUPPLIES_AND_SUPPORT";
    FederalSupplyClass["GROUP_71_FURNITURE"] = "GROUP_71_FURNITURE";
    FederalSupplyClass["GROUP_72_HOUSE_COMMERCIAL_FURNISHING_APPLIANCES"] = "GROUP_72_HOUSE_COMMERCIAL_FURNISHING_APPLIANCES";
    FederalSupplyClass["GROUP_73_FOOD_PREPARATION_AND_SERVICE_EQUIPMENT"] = "GROUP_73_FOOD_PREPARATION_AND_SERVICE_EQUIPMENT";
    FederalSupplyClass["GROUP_74_OFFICE_MACH_TEXT_VISIBLE_RECORD_EQUIP"] = "GROUP_74_OFFICE_MACH_TEXT_VISIBLE_RECORD_EQUIP";
    FederalSupplyClass["GROUP_75_OFFICE_SUPPLIES_AND_DEVICES"] = "GROUP_75_OFFICE_SUPPLIES_AND_DEVICES";
    FederalSupplyClass["GROUP_76_BOOKS_MAPS_AND_OTHER_PUBLICATIONS"] = "GROUP_76_BOOKS_MAPS_AND_OTHER_PUBLICATIONS";
    FederalSupplyClass["GROUP_77_MUSIC_INSTRUMENT_PHONOGRAPH_HOME_RADIO"] = "GROUP_77_MUSIC_INSTRUMENT_PHONOGRAPH_HOME_RADIO";
    FederalSupplyClass["GROUP_78_RECREATIONAL_AND_ATHLETIC_EQUIPMENT"] = "GROUP_78_RECREATIONAL_AND_ATHLETIC_EQUIPMENT";
    FederalSupplyClass["GROUP_79_CLEANING_EQUIPMENT_AND_SUPPLIES"] = "GROUP_79_CLEANING_EQUIPMENT_AND_SUPPLIES";
    FederalSupplyClass["GROUP_80_BRUSHES_PAINTS_SEALERS_AND_ADHESIVES"] = "GROUP_80_BRUSHES_PAINTS_SEALERS_AND_ADHESIVES";
    FederalSupplyClass["GROUP_81_CONTAINERS_PACKAGES_PACKING_SUPPLIES"] = "GROUP_81_CONTAINERS_PACKAGES_PACKING_SUPPLIES";
    FederalSupplyClass["GROUP_83_TEXTILESFURSAPPARELSHOESTENTSFLAGS"] = "GROUP_83_TEXTILESFURSAPPARELSHOESTENTSFLAGS";
    FederalSupplyClass["GROUP_84_CLOTHING_INDIVIUAL_EQUIPMENT_AND_INSIGNIA"] = "GROUP_84_CLOTHING_INDIVIUAL_EQUIPMENT_AND_INSIGNIA";
    FederalSupplyClass["GROUP_85_TOILETRIES"] = "GROUP_85_TOILETRIES";
    FederalSupplyClass["GROUP_87_AGRICULTURE_SUPPLIES"] = "GROUP_87_AGRICULTURE_SUPPLIES";
    FederalSupplyClass["GROUP_88_LIVE_ANIMALS"] = "GROUP_88_LIVE_ANIMALS";
    FederalSupplyClass["GROUP_89_SUBSISTENCE"] = "GROUP_89_SUBSISTENCE";
    FederalSupplyClass["GROUP_91_FUELS_LUBRICATIONS_OILS_AND_WAXES"] = "GROUP_91_FUELS_LUBRICATIONS_OILS_AND_WAXES";
    FederalSupplyClass["GROUP_93_NON_METALLIC_FABRICATION_MATERIAL"] = "GROUP_93_NON_METALLIC_FABRICATION_MATERIAL";
    FederalSupplyClass["GROUP_94_NON_METALLIC_RUDE_MATERIAL"] = "GROUP_94_NON_METALLIC_RUDE_MATERIAL";
    FederalSupplyClass["GROUP_95_METAL_BARS_SHEETS_AND_SHAPES"] = "GROUP_95_METAL_BARS_SHEETS_AND_SHAPES";
    FederalSupplyClass["GROUP_96_ORES_MINERALS_THEIR_PRIMARY_PRODUCTS"] = "GROUP_96_ORES_MINERALS_THEIR_PRIMARY_PRODUCTS";
    FederalSupplyClass["GROUP_99_MISCELLANEOUS"] = "GROUP_99_MISCELLANEOUS";
    FederalSupplyClass["GUIDED_MISSILE_MAINTENANCE_REPAIR"] = "GUIDED_MISSILE_MAINTENANCE_REPAIR";
    FederalSupplyClass["GUNS_OVER_125MM_THROUGH_150MM"] = "GUNS_OVER_125MM_THROUGH_150MM";
    FederalSupplyClass["HAND_TOOLS_EDGED_NONPOWERED"] = "HAND_TOOLS_EDGED_NONPOWERED";
    FederalSupplyClass["HAND_TOOLS_NONEDGED_NONPOWERED"] = "HAND_TOOLS_NONEDGED_NONPOWERED";
    FederalSupplyClass["HAND_TOOLS_POWER_DRIVEN"] = "HAND_TOOLS_POWER_DRIVEN";
    FederalSupplyClass["HARVESTING_EQUIPMENT"] = "HARVESTING_EQUIPMENT";
    FederalSupplyClass["HAZARD_DETECTING_INSTRUMENTS_AND_APPARATUS"] = "HAZARD_DETECTING_INSTRUMENTS_AND_APPARATUS";
    FederalSupplyClass["HEADSETS_HANDSETS_MICROPHONES_AND_SPEAKERS"] = "HEADSETS_HANDSETS_MICROPHONES_AND_SPEAKERS";
    FederalSupplyClass["HEAT_EXCHANGERS_AND_STEAM_CONDENSERS"] = "HEAT_EXCHANGERS_AND_STEAM_CONDENSERS";
    FederalSupplyClass["HOSE_AND_TUBING_FLEXIBLE"] = "HOSE_AND_TUBING_FLEXIBLE";
    FederalSupplyClass["HOSIERY_HANDWEAR_AND_CLOTHING_MENS"] = "HOSIERY_HANDWEAR_AND_CLOTHING_MENS";
    FederalSupplyClass["HOSIERY_HANDWEAR_BADGES_AND_CLOTHING_WOMENS"] = "HOSIERY_HANDWEAR_BADGES_AND_CLOTHING_WOMENS";
    FederalSupplyClass["HOSPITAL_AND_SURGICAL_CLOTHING_AND_RELATED"] = "HOSPITAL_AND_SURGICAL_CLOTHING_AND_RELATED";
    FederalSupplyClass["HOSPITAL_FURNITURE_EQUIPMENT_UTENSILS"] = "HOSPITAL_FURNITURE_EQUIPMENT_UTENSILS";
    FederalSupplyClass["HOUSEHOLD_AND_COMMERCIAL_UTILITY_CONTAINERS"] = "HOUSEHOLD_AND_COMMERCIAL_UTILITY_CONTAINERS";
    FederalSupplyClass["HOUSEHOLD_FURNISHINGS"] = "HOUSEHOLD_FURNISHINGS";
    FederalSupplyClass["HOUSEHOLD_FURNITURE"] = "HOUSEHOLD_FURNITURE";
    FederalSupplyClass["HYDRAULIC_AND_PNEUMATIC_PRESSES_POWER"] = "HYDRAULIC_AND_PNEUMATIC_PRESSES_POWER";
    FederalSupplyClass["INDIVIDUAL_EQUIPMENT_SUNGLASSES"] = "INDIVIDUAL_EQUIPMENT_SUNGLASSES";
    FederalSupplyClass["INDOOR_AND_OUTDOOR_ELECTRIC_LIGHTING_FIXTURES"] = "INDOOR_AND_OUTDOOR_ELECTRIC_LIGHTING_FIXTURES";
    FederalSupplyClass["INDUSTRIAL_ASSEMBLY_MACHINES"] = "INDUSTRIAL_ASSEMBLY_MACHINES";
    FederalSupplyClass["INDUSTRIAL_BOILERS"] = "INDUSTRIAL_BOILERS";
    FederalSupplyClass["INDUSTRIAL_FURNACES_KILNS_LEHRS_AND_OVENS"] = "INDUSTRIAL_FURNACES_KILNS_LEHRS_AND_OVENS";
    FederalSupplyClass["INDUSTRIAL_MARKING_MACHINES"] = "INDUSTRIAL_MARKING_MACHINES";
    FederalSupplyClass["INDUSTRIAL_SEWING_MACHINES_MOBILE_TEXTILE"] = "INDUSTRIAL_SEWING_MACHINES_MOBILE_TEXTILE";
    FederalSupplyClass["INDUSTRIAL_SIZE_REDUCTION_MACHINERY"] = "INDUSTRIAL_SIZE_REDUCTION_MACHINERY";
    FederalSupplyClass["INSPECTION_GAGES_AND_PRECISION_LAYOUT_TOOLS"] = "INSPECTION_GAGES_AND_PRECISION_LAYOUT_TOOLS";
    FederalSupplyClass["INTEGRATED_OPTICAL_CIRCUITS"] = "INTEGRATED_OPTICAL_CIRCUITS";
    FederalSupplyClass["INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_AIRBORNE"] = "INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_AIRBORNE";
    FederalSupplyClass["INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_EXCEPT"] = "INTERCOMMUNICATION_AND_PUBLIC_ADDRESS_S_EXCEPT";
    FederalSupplyClass["IN_VITRO_DIAGNOSTIC_SUBSTANCES_REAGENTS_TEST"] = "IN_VITRO_DIAGNOSTIC_SUBSTANCES_REAGENTS_TEST";
    FederalSupplyClass["IRON_AND_STEEL_PRIMARY_AND_SEMIFINISHED_PRODUCTS"] = "IRON_AND_STEEL_PRIMARY_AND_SEMIFINISHED_PRODUCTS";
    FederalSupplyClass["IRON_AND_STEEL_SCRAP"] = "IRON_AND_STEEL_SCRAP";
    FederalSupplyClass["JAMS_JELLIES_AND_PRESERVES"] = "JAMS_JELLIES_AND_PRESERVES";
    FederalSupplyClass["JEWELRY"] = "JEWELRY";
    FederalSupplyClass["KITCHEN_EQUIPMENT_AND_APPLIANCES"] = "KITCHEN_EQUIPMENT_AND_APPLIANCES";
    FederalSupplyClass["KITCHEN_HAND_TOOLS_AND_UTENSILS"] = "KITCHEN_HAND_TOOLS_AND_UTENSILS";
    FederalSupplyClass["KNOBS_AND_POINTERS"] = "KNOBS_AND_POINTERS";
    FederalSupplyClass["LABORATORY_EQUIPMENT_AND_SUPPLIES"] = "LABORATORY_EQUIPMENT_AND_SUPPLIES";
    FederalSupplyClass["LATHES"] = "LATHES";
    FederalSupplyClass["LAUNDRY_AND_DRY_CLEANING_EQUIPMENT"] = "LAUNDRY_AND_DRY_CLEANING_EQUIPMENT";
    FederalSupplyClass["LEATHER"] = "LEATHER";
    FederalSupplyClass["LEATHER_TANNING_AND_LEATHER_WORKING"] = "LEATHER_TANNING_AND_LEATHER_WORKING";
    FederalSupplyClass["LIQUID_AND_GAS_FLOW_LIQUID_LEVEL"] = "LIQUID_AND_GAS_FLOW_LIQUID_LEVEL";
    FederalSupplyClass["LIQUID_PROPELLANTS_AND_FUELS_PETROLEUM_BASE"] = "LIQUID_PROPELLANTS_AND_FUELS_PETROLEUM_BASE";
    FederalSupplyClass["LIQUID_PROPELLANTS_AND_OXIDIZERS"] = "LIQUID_PROPELLANTS_AND_OXIDIZERS";
    FederalSupplyClass["LIVE_ANIMALS_NOT_RAISED_FOR_FOOD"] = "LIVE_ANIMALS_NOT_RAISED_FOR_FOOD";
    FederalSupplyClass["LIVE_ANIMALS_RAISED_FOR_FOOD"] = "LIVE_ANIMALS_RAISED_FOR_FOOD";
    FederalSupplyClass["LUBRICATION_AND_FUEL_DISPENSING_EQUIPMENT"] = "LUBRICATION_AND_FUEL_DISPENSING_EQUIPMENT";
    FederalSupplyClass["LUGGAGE"] = "LUGGAGE";
    FederalSupplyClass["LUGS_TERMINALS_AND_TERMINAL_STRIPS"] = "LUGS_TERMINALS_AND_TERMINAL_STRIPS";
    FederalSupplyClass["LUMBER_AND_RELATED_BASIC_WOOD_MATERIALS"] = "LUMBER_AND_RELATED_BASIC_WOOD_MATERIALS";
    FederalSupplyClass["MACHINE_SHOP_SETS_KITS_AND_OUTFITS"] = "MACHINE_SHOP_SETS_KITS_AND_OUTFITS";
    FederalSupplyClass["MACHINE_TOOLS_PORTABLE"] = "MACHINE_TOOLS_PORTABLE";
    FederalSupplyClass["MACHINE_TOOL_ACCESSORIES"] = "MACHINE_TOOL_ACCESSORIES";
    FederalSupplyClass["MACHINING_CENTERS_AND_WAY_TYPE_MACHINES"] = "MACHINING_CENTERS_AND_WAY_TYPE_MACHINES";
    FederalSupplyClass["MANUAL_PRESSES"] = "MANUAL_PRESSES";
    FederalSupplyClass["MAPS_ATLASES_CHARTS_AND_GLOBES"] = "MAPS_ATLASES_CHARTS_AND_GLOBES";
    FederalSupplyClass["MARINE_LIFESAVING_AND_DIVING_EQUIPMENT"] = "MARINE_LIFESAVING_AND_DIVING_EQUIPMENT";
    FederalSupplyClass["MATERIALS_FEEDERS"] = "MATERIALS_FEEDERS";
    FederalSupplyClass["MATERIALS_HANDLING_EQUIPMENT_NONSELFPROPELLED"] = "MATERIALS_HANDLING_EQUIPMENT_NONSELFPROPELLED";
    FederalSupplyClass["MEASURING_TOOLS_CRAFTSMENS"] = "MEASURING_TOOLS_CRAFTSMENS";
    FederalSupplyClass["MEAT_POULTRY_AND_FISH"] = "MEAT_POULTRY_AND_FISH";
    FederalSupplyClass["MECHANICAL_POWER_TRANSMISSION_EQUIPMENT"] = "MECHANICAL_POWER_TRANSMISSION_EQUIPMENT";
    FederalSupplyClass["MECHANICAL_PRESSES_POWER_DRIVEN"] = "MECHANICAL_PRESSES_POWER_DRIVEN";
    FederalSupplyClass["MEDICAL_AND_SURGICAL_INSTRUMENTS_EQUIPMENT"] = "MEDICAL_AND_SURGICAL_INSTRUMENTS_EQUIPMENT";
    FederalSupplyClass["MEDICATED_COSMETICS_AND_TOILETRIES"] = "MEDICATED_COSMETICS_AND_TOILETRIES";
    FederalSupplyClass["MEMORIALS_CEMETERIAL_AND_MORTUARY_EQUIPMENT"] = "MEMORIALS_CEMETERIAL_AND_MORTUARY_EQUIPMENT";
    FederalSupplyClass["METAL_FINISHING_EQUIPMENT"] = "METAL_FINISHING_EQUIPMENT";
    FederalSupplyClass["METAL_HEAT_TREATING_AND_NON_THERMAL_TREATING"] = "METAL_HEAT_TREATING_AND_NON_THERMAL_TREATING";
    FederalSupplyClass["METAL_SCREENING"] = "METAL_SCREENING";
    FederalSupplyClass["METEROLOGICAL_INSTRUMENTS_AND_APPARATUS"] = "METEROLOGICAL_INSTRUMENTS_AND_APPARATUS";
    FederalSupplyClass["MICROCIRCUITS_ELECTRONIC"] = "MICROCIRCUITS_ELECTRONIC";
    FederalSupplyClass["MICROFILM_PROCESSED"] = "MICROFILM_PROCESSED";
    FederalSupplyClass["MILLING_MACHINES"] = "MILLING_MACHINES";
    FederalSupplyClass["MILLWORK"] = "MILLWORK";
    FederalSupplyClass["MINERALS_NATURAL_AND_SYNTHETIC"] = "MINERALS_NATURAL_AND_SYNTHETIC";
    FederalSupplyClass["MINERAL_CONSTRUCTION_MATERIALS_BULK"] = "MINERAL_CONSTRUCTION_MATERIALS_BULK";
    FederalSupplyClass["MINING_ROCK_DRILLING_EARTH_BORING"] = "MINING_ROCK_DRILLING_EARTH_BORING";
    FederalSupplyClass["MINI_AND_MICRO_COMPUTER_CONTROL_DEVICES"] = "MINI_AND_MICRO_COMPUTER_CONTROL_DEVICES";
    FederalSupplyClass["MISCELLANEOUS_ALARM_SIGNAL_AND_SECURITY"] = "MISCELLANEOUS_ALARM_SIGNAL_AND_SECURITY";
    FederalSupplyClass["MISCELLANEOUS_BATTERY_RETAINING_FIXTURES_LINERS"] = "MISCELLANEOUS_BATTERY_RETAINING_FIXTURES_LINERS";
    FederalSupplyClass["MISCELLANEOUS_CHEMICAL_SPECIALTIES"] = "MISCELLANEOUS_CHEMICAL_SPECIALTIES";
    FederalSupplyClass["MISCELLANEOUS_COMMUNICATION_EQUIPMENT"] = "MISCELLANEOUS_COMMUNICATION_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_CONSTRUCTION_EQUIPMENT"] = "MISCELLANEOUS_CONSTRUCTION_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_CONSTRUCTION_MATERIALS"] = "MISCELLANEOUS_CONSTRUCTION_MATERIALS";
    FederalSupplyClass["MISCELLANEOUS_CRUDE_AGRICULTURAL_AND_FORESTRY"] = "MISCELLANEOUS_CRUDE_AGRICULTURAL_AND_FORESTRY";
    FederalSupplyClass["MISCELLANEOUS_CRUDE_ANIMAL_PRODUCTS"] = "MISCELLANEOUS_CRUDE_ANIMAL_PRODUCTS";
    FederalSupplyClass["MISCELLANEOUS_ELECTRICAL_AND_ELECTRONIC_COMPONENT"] = "MISCELLANEOUS_ELECTRICAL_AND_ELECTRONIC_COMPONENT";
    FederalSupplyClass["MISCELLANEOUS_ELECTRIC_POWER_AND_DISTRIBUTION"] = "MISCELLANEOUS_ELECTRIC_POWER_AND_DISTRIBUTION";
    FederalSupplyClass["MISCELLANEOUS_ENGINES_AND_COMPONENTS"] = "MISCELLANEOUS_ENGINES_AND_COMPONENTS";
    FederalSupplyClass["MISCELLANEOUS_ENGINE_ACCESSORIES_NONAIRCRAFT"] = "MISCELLANEOUS_ENGINE_ACCESSORIES_NONAIRCRAFT";
    FederalSupplyClass["MISCELLANEOUS_FABRICATED_NONMETALLIC"] = "MISCELLANEOUS_FABRICATED_NONMETALLIC";
    FederalSupplyClass["MISCELLANEOUS_FABRICATED_STRUCTURES"] = "MISCELLANEOUS_FABRICATED_STRUCTURES";
    FederalSupplyClass["MISCELLANEOUS_FIBER_OPTIC_COMPONENTS"] = "MISCELLANEOUS_FIBER_OPTIC_COMPONENTS";
    FederalSupplyClass["MISCELLANEOUS_FIRE_CONTROL_EQUIPMENT"] = "MISCELLANEOUS_FIRE_CONTROL_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_FURNITURE_AND_FIXTURES"] = "MISCELLANEOUS_FURNITURE_AND_FIXTURES";
    FederalSupplyClass["MISCELLANEOUS_HARDWARE"] = "MISCELLANEOUS_HARDWARE";
    FederalSupplyClass["MISCELLANEOUS_HOUSEHOLD_AND_COMMERCIAL"] = "MISCELLANEOUS_HOUSEHOLD_AND_COMMERCIAL";
    FederalSupplyClass["MISCELLANEOUS_ITEMS"] = "MISCELLANEOUS_ITEMS";
    FederalSupplyClass["MISCELLANEOUS_MACHINE_TOOLS"] = "MISCELLANEOUS_MACHINE_TOOLS";
    FederalSupplyClass["MISCELLANEOUS_MAINTENANCE_AND_REPAIR_SHOP"] = "MISCELLANEOUS_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["MISCELLANEOUS_MATERIALS_HANDLING_EQUIPMENT"] = "MISCELLANEOUS_MATERIALS_HANDLING_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_OFFICE_MACHINES"] = "MISCELLANEOUS_OFFICE_MACHINES";
    FederalSupplyClass["MISCELLANEOUS_PLUMBING_HEATING_AND_SANITAION"] = "MISCELLANEOUS_PLUMBING_HEATING_AND_SANITAION";
    FederalSupplyClass["MISCELLANEOUS_POWER_TRANSMISSION_EQUIPMENT"] = "MISCELLANEOUS_POWER_TRANSMISSION_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_PRINTED_MATTER"] = "MISCELLANEOUS_PRINTED_MATTER";
    FederalSupplyClass["MISCELLANEOUS_SECONDARY_METAL_FORMING"] = "MISCELLANEOUS_SECONDARY_METAL_FORMING";
    FederalSupplyClass["MISCELLANEOUS_SERVICE_AND_TRADE_EQUIPMENT"] = "MISCELLANEOUS_SERVICE_AND_TRADE_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_SPECIAL_INDUSTRY_MACHINERY"] = "MISCELLANEOUS_SPECIAL_INDUSTRY_MACHINERY";
    FederalSupplyClass["MISCELLANEOUS_VEHICULAR_COMPONENTS"] = "MISCELLANEOUS_VEHICULAR_COMPONENTS";
    FederalSupplyClass["MISCELLANEOUS_WAXES_OILS_AND_FATS"] = "MISCELLANEOUS_WAXES_OILS_AND_FATS";
    FederalSupplyClass["MISCELLANEOUS_WELDING_EQUIPMENT"] = "MISCELLANEOUS_WELDING_EQUIPMENT";
    FederalSupplyClass["MISCELLANEOUS_WELDING_SOLDERING_AND_BRAZING"] = "MISCELLANEOUS_WELDING_SOLDERING_AND_BRAZING";
    FederalSupplyClass["MOTORS_ELECTRICAL"] = "MOTORS_ELECTRICAL";
    FederalSupplyClass["MOTOR_VEHICLE_MAINTENANCE_AND_REPAIR_SHOP"] = "MOTOR_VEHICLE_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["MUSICAL_INSTRUMENTS"] = "MUSICAL_INSTRUMENTS";
    FederalSupplyClass["MUSICAL_INSTRUMENT_PARTS_AND_ACCESSORIES"] = "MUSICAL_INSTRUMENT_PARTS_AND_ACCESSORIES";
    FederalSupplyClass["NAILS_KEYS_AND_PINS"] = "NAILS_KEYS_AND_PINS";
    FederalSupplyClass["NAVIGATIONAL_INSTRUMENTS"] = "NAVIGATIONAL_INSTRUMENTS";
    FederalSupplyClass["NEWSPAPERS_AND_PERIODICALS"] = "NEWSPAPERS_AND_PERIODICALS";
    FederalSupplyClass["NIGHT_VISION_EQUIPMENT_EMITTED_AND_REFLECTED"] = "NIGHT_VISION_EQUIPMENT_EMITTED_AND_REFLECTED";
    FederalSupplyClass["NONELECTRICAL_LIGHTING_FIXTURES"] = "NONELECTRICAL_LIGHTING_FIXTURES";
    FederalSupplyClass["NONFERROUS_BASE_METAL_REFINERY"] = "NONFERROUS_BASE_METAL_REFINERY";
    FederalSupplyClass["NONFERROUS_METAL_SCRAP"] = "NONFERROUS_METAL_SCRAP";
    FederalSupplyClass["NONMETALLIC_SCRAP_EXCEPT_TEXTILE"] = "NONMETALLIC_SCRAP_EXCEPT_TEXTILE";
    FederalSupplyClass["NOTIONS_AND_APPAREL_FINDINGS"] = "NOTIONS_AND_APPAREL_FINDINGS";
    FederalSupplyClass["NUTS_AND_WASHERS"] = "NUTS_AND_WASHERS";
    FederalSupplyClass["OFFICE_DEVICES_AND_ACCESSORIES"] = "OFFICE_DEVICES_AND_ACCESSORIES";
    FederalSupplyClass["OFFICE_FURNITURE"] = "OFFICE_FURNITURE";
    FederalSupplyClass["OFFICE_INFORMATION_SYSTEM_EQUIPMENT"] = "OFFICE_INFORMATION_SYSTEM_EQUIPMENT";
    FederalSupplyClass["OFFICE_SUPPLIES"] = "OFFICE_SUPPLIES";
    FederalSupplyClass["OFFICE_TYPE_SOUND_RECORDING_AND_REPRODUCING"] = "OFFICE_TYPE_SOUND_RECORDING_AND_REPRODUCING";
    FederalSupplyClass["OILS_AND_GREASES_CUTTING_LUBRICATING"] = "OILS_AND_GREASES_CUTTING_LUBRICATING";
    FederalSupplyClass["OPERATION_TRAINING_DEVICES"] = "OPERATION_TRAINING_DEVICES";
    FederalSupplyClass["OPHTHALMIC_INSTRUMENT_EQUIPMENT"] = "OPHTHALMIC_INSTRUMENT_EQUIPMENT";
    FederalSupplyClass["OPTICAL_INSTRUMENTS_TEST_EQUIPMENT_COMPONENTS"] = "OPTICAL_INSTRUMENTS_TEST_EQUIPMENT_COMPONENTS";
    FederalSupplyClass["OPTICAL_MULTIPLEXERS_DEMULTIPLEXERS"] = "OPTICAL_MULTIPLEXERS_DEMULTIPLEXERS";
    FederalSupplyClass["OPTICAL_REPEATERS"] = "OPTICAL_REPEATERS";
    FederalSupplyClass["OPTICAL_SIGHTING_AND_RANGING_EQUIPMENT"] = "OPTICAL_SIGHTING_AND_RANGING_EQUIPMENT";
    FederalSupplyClass["OPTOELECTRONIC_DEVICES_AND_ASSOCIATED_HARDWARE"] = "OPTOELECTRONIC_DEVICES_AND_ASSOCIATED_HARDWARE";
    FederalSupplyClass["ORES"] = "ORES";
    FederalSupplyClass["OSCILLATORS_AND_PIEZOELECTRIC_CRYSTALS"] = "OSCILLATORS_AND_PIEZOELECTRIC_CRYSTALS";
    FederalSupplyClass["OTHER_CRYPTOLOGIC_EQUIPMENT_AND_COMPONENTS"] = "OTHER_CRYPTOLOGIC_EQUIPMENT_AND_COMPONENTS";
    FederalSupplyClass["OUTERWEAR_MEN_S"] = "OUTERWEAR_MEN_S";
    FederalSupplyClass["OUTERWEAR_WOMEN_S"] = "OUTERWEAR_WOMEN_S";
    FederalSupplyClass["O_RINGS_RINGS_SHIMS_AND_SPACERS"] = "O_RINGS_RINGS_SHIMS_AND_SPACERS";
    FederalSupplyClass["PACKAGING_AND_PACKING_BULK_MATERIALS"] = "PACKAGING_AND_PACKING_BULK_MATERIALS";
    FederalSupplyClass["PACKING_AND_GASKET_MATERIALS"] = "PACKING_AND_GASKET_MATERIALS";
    FederalSupplyClass["PADDING_AND_STUFFING_MATERIALS"] = "PADDING_AND_STUFFING_MATERIALS";
    FederalSupplyClass["PAINTS_DOPES_VARNISHES_AND_RELATED_PRODUCTS"] = "PAINTS_DOPES_VARNISHES_AND_RELATED_PRODUCTS";
    FederalSupplyClass["PAINT_AND_ARTISTS_BRUSHES"] = "PAINT_AND_ARTISTS_BRUSHES";
    FederalSupplyClass["PAPER_AND_PAPERBOARD"] = "PAPER_AND_PAPERBOARD";
    FederalSupplyClass["PARACHUTES_AERIAL_PICK_UP_DELIVERY_RECOVERY"] = "PARACHUTES_AERIAL_PICK_UP_DELIVERY_RECOVERY";
    FederalSupplyClass["PERFUMES_TOILET_PREPARATIONS_AND_POWDERS"] = "PERFUMES_TOILET_PREPARATIONS_AND_POWDERS";
    FederalSupplyClass["PERSONAL_TOILETRY_ARTICLES"] = "PERSONAL_TOILETRY_ARTICLES";
    FederalSupplyClass["PEST_CONTROL_AGENTS_AND_DISINFECTANTS"] = "PEST_CONTROL_AGENTS_AND_DISINFECTANTS";
    FederalSupplyClass["PEST_DISEASE_AND_FROST_CONTROL_EQUIPMENT"] = "PEST_DISEASE_AND_FROST_CONTROL_EQUIPMENT";
    FederalSupplyClass["PETROLEUM_PRODUCTION_AND_DISTRIBUTION"] = "PETROLEUM_PRODUCTION_AND_DISTRIBUTION";
    FederalSupplyClass["PHONOGRAPHS_RADIOS_AND_TELEVISION_SETS"] = "PHONOGRAPHS_RADIOS_AND_TELEVISION_SETS";
    FederalSupplyClass["PHONOGRAPH_RECORDS"] = "PHONOGRAPH_RECORDS";
    FederalSupplyClass["PHOTOGRAPHIC_DEVELOPING_AND_FINISHING"] = "PHOTOGRAPHIC_DEVELOPING_AND_FINISHING";
    FederalSupplyClass["PHOTOGRAPHIC_EQUIPMENT_AND_ACCESSORIES"] = "PHOTOGRAPHIC_EQUIPMENT_AND_ACCESSORIES";
    FederalSupplyClass["PHOTOGRAPHIC_PROJECTION_EQUIPMENT"] = "PHOTOGRAPHIC_PROJECTION_EQUIPMENT";
    FederalSupplyClass["PHOTOGRAPHIC_SETS_KITS_AND_OUTFITS"] = "PHOTOGRAPHIC_SETS_KITS_AND_OUTFITS";
    FederalSupplyClass["PHOTOGRAPHIC_SUPPLIES"] = "PHOTOGRAPHIC_SUPPLIES";
    FederalSupplyClass["PHYSICAL_PROPERTIES_TESTING_EQUIPMENT"] = "PHYSICAL_PROPERTIES_TESTING_EQUIPMENT";
    FederalSupplyClass["PIPE_AND_CONDUIT_NONMETALLIC"] = "PIPE_AND_CONDUIT_NONMETALLIC";
    FederalSupplyClass["PIPE_AND_TUBE"] = "PIPE_AND_TUBE";
    FederalSupplyClass["PLANERS_AND_SHAPERS"] = "PLANERS_AND_SHAPERS";
    FederalSupplyClass["PLASTICS_FABRICATED_MATERIALS"] = "PLASTICS_FABRICATED_MATERIALS";
    FederalSupplyClass["PLATE_SHEET_STRIP_AND_FOIL"] = "PLATE_SHEET_STRIP_AND_FOIL";
    FederalSupplyClass["PLATE_SHEET_STRIP_AND_FOIL_IRON_AND_STEEL"] = "PLATE_SHEET_STRIP_AND_FOIL_IRON_AND_STEEL";
    FederalSupplyClass["PLATE_SHEET_STRIP_FOIL_AND_WIRE"] = "PLATE_SHEET_STRIP_FOIL_AND_WIRE";
    FederalSupplyClass["PLUMBING_FIXTURES_TOWELS_AND_ACCESSORIES"] = "PLUMBING_FIXTURES_TOWELS_AND_ACCESSORIES";
    FederalSupplyClass["PLYWOOD_AND_VENEER"] = "PLYWOOD_AND_VENEER";
    FederalSupplyClass["POWER_AND_HAND_PUMPS"] = "POWER_AND_HAND_PUMPS";
    FederalSupplyClass["PRECIOUS_METALS_PRIMARY_FORMS"] = "PRECIOUS_METALS_PRIMARY_FORMS";
    FederalSupplyClass["PREFABRICATED_AND_PORTABLE_BUILDINGS"] = "PREFABRICATED_AND_PORTABLE_BUILDINGS";
    FederalSupplyClass["PREFABRICATED_TOWER_STRUCTURES"] = "PREFABRICATED_TOWER_STRUCTURES";
    FederalSupplyClass["PRESERVATIVE_AND_SEALING_COMPOUNDS"] = "PRESERVATIVE_AND_SEALING_COMPOUNDS";
    FederalSupplyClass["PRESSURE_TEMPERATURE_AND_HUMIDITY_MEASURING"] = "PRESSURE_TEMPERATURE_AND_HUMIDITY_MEASURING";
    FederalSupplyClass["PRINTING_DUPLICATING_AND_BOOKBINDING"] = "PRINTING_DUPLICATING_AND_BOOKBINDING";
    FederalSupplyClass["PRODUCTION_JIGS_FIXTURES_AND_TEMPLATES"] = "PRODUCTION_JIGS_FIXTURES_AND_TEMPLATES";
    FederalSupplyClass["PULP_AND_PAPER_INDUSTRIES_MACHINERY"] = "PULP_AND_PAPER_INDUSTRIES_MACHINERY";
    FederalSupplyClass["PUNCHED_CARD_EQUIPMENT"] = "PUNCHED_CARD_EQUIPMENT";
    FederalSupplyClass["PUNCHING_AND_SHEARING_MACHINES"] = "PUNCHING_AND_SHEARING_MACHINES";
    FederalSupplyClass["PYROTECHNICS"] = "PYROTECHNICS";
    FederalSupplyClass["RADAR_EQUIPMENT_AIRBORNE"] = "RADAR_EQUIPMENT_AIRBORNE";
    FederalSupplyClass["RADAR_EQUIPMENT_EXCEPT_AIRBORNE"] = "RADAR_EQUIPMENT_EXCEPT_AIRBORNE";
    FederalSupplyClass["RADIO_AND_TELEVISION_COMMUNICATION_AIRBORNE"] = "RADIO_AND_TELEVISION_COMMUNICATION_AIRBORNE";
    FederalSupplyClass["RADIO_AND_TELEVISION_COMMUNICATION_EXCEPT"] = "RADIO_AND_TELEVISION_COMMUNICATION_EXCEPT";
    FederalSupplyClass["RADIO_NAVIGATION_EQUIPMENT_AIRBORNE"] = "RADIO_NAVIGATION_EQUIPMENT_AIRBORNE";
    FederalSupplyClass["RADIO_NAVIGATION_EQUIPMENT_EXCEPT_AIRBORNE"] = "RADIO_NAVIGATION_EQUIPMENT_EXCEPT_AIRBORNE";
    FederalSupplyClass["RAILROAD_SIGNAL_AND_WARNING_DEVICES"] = "RAILROAD_SIGNAL_AND_WARNING_DEVICES";
    FederalSupplyClass["RECREATIONAL_AND_GYMNASTIC_EQUIPMENT"] = "RECREATIONAL_AND_GYMNASTIC_EQUIPMENT";
    FederalSupplyClass["REELS_AND_SPOOLS"] = "REELS_AND_SPOOLS";
    FederalSupplyClass["REFRACTORIES_AND_FIRE_SURFACING_MATERIALS"] = "REFRACTORIES_AND_FIRE_SURFACING_MATERIALS";
    FederalSupplyClass["REFRIGERATION_AND_AIR_CONDITIONING_COMPONENTS"] = "REFRIGERATION_AND_AIR_CONDITIONING_COMPONENTS";
    FederalSupplyClass["REFRIGERATION_EQUIPMENT"] = "REFRIGERATION_EQUIPMENT";
    FederalSupplyClass["RELAYS_AND_SOLENOIDS"] = "RELAYS_AND_SOLENOIDS";
    FederalSupplyClass["REPLENISHABLE_FIELD_MEDICAL_SETS_KITS"] = "REPLENISHABLE_FIELD_MEDICAL_SETS_KITS";
    FederalSupplyClass["RESISTORS"] = "RESISTORS";
    FederalSupplyClass["RIGID_WALL_SHELTERS"] = "RIGID_WALL_SHELTERS";
    FederalSupplyClass["RIVETING_MACHINES"] = "RIVETING_MACHINES";
    FederalSupplyClass["RIVETS"] = "RIVETS";
    FederalSupplyClass["ROAD_CLEARING_AND_CLEANING_EQUIPMENT"] = "ROAD_CLEARING_AND_CLEANING_EQUIPMENT";
    FederalSupplyClass["ROCKETS_ROCKET_AMMUNITION_AND_ROCKET_COMPONENTS"] = "ROCKETS_ROCKET_AMMUNITION_AND_ROCKET_COMPONENTS";
    FederalSupplyClass["ROCKET_MAINTENANCE_REPAIR_AND_CHECKOUT"] = "ROCKET_MAINTENANCE_REPAIR_AND_CHECKOUT";
    FederalSupplyClass["ROLLING_MILLS_AND_DRAWING_MACHINES"] = "ROLLING_MILLS_AND_DRAWING_MACHINES";
    FederalSupplyClass["ROOFING_AND_SIDING_MATERIALS"] = "ROOFING_AND_SIDING_MATERIALS";
    FederalSupplyClass["ROTARY_JOINTS"] = "ROTARY_JOINTS";
    FederalSupplyClass["RUBBER_AND_PLASTICS_WORKING_MACHINERY"] = "RUBBER_AND_PLASTICS_WORKING_MACHINERY";
    FederalSupplyClass["RUBBER_FABRICATED_MATERIALS_CUSHIONS"] = "RUBBER_FABRICATED_MATERIALS_CUSHIONS";
    FederalSupplyClass["SADDLERY_HARNESS_WHIPS_AND_RELATED_ANIMAL"] = "SADDLERY_HARNESS_WHIPS_AND_RELATED_ANIMAL";
    FederalSupplyClass["SAFETY_AND_RESCUE_EQUIPMENT"] = "SAFETY_AND_RESCUE_EQUIPMENT";
    FederalSupplyClass["SAWMILL_AND_PLANING_MILL_MACHINERY"] = "SAWMILL_AND_PLANING_MILL_MACHINERY";
    FederalSupplyClass["SAWS_AND_FILING_MACHINES"] = "SAWS_AND_FILING_MACHINES";
    FederalSupplyClass["SCAFFOLDING_EQUIPMENT_AND_CONCRETE_FORMS"] = "SCAFFOLDING_EQUIPMENT_AND_CONCRETE_FORMS";
    FederalSupplyClass["SCALES_AND_BALANCES"] = "SCALES_AND_BALANCES";
    FederalSupplyClass["SCREWS"] = "SCREWS";
    FederalSupplyClass["SEEDS_AND_NURSERY_STOCK"] = "SEEDS_AND_NURSERY_STOCK";
    FederalSupplyClass["SEMICONDUCTOR_DEVICES_AND_ASSOCIATED_HARDWARE"] = "SEMICONDUCTOR_DEVICES_AND_ASSOCIATED_HARDWARE";
    FederalSupplyClass["SETS_KITS_AND_OUTFITS_FOOD_PREPARATION"] = "SETS_KITS_AND_OUTFITS_FOOD_PREPARATION";
    FederalSupplyClass["SETS_KITS_AND_OUTFITS_OF_HAND_TOOLS"] = "SETS_KITS_AND_OUTFITS_OF_HAND_TOOLS";
    FederalSupplyClass["SETS_KITS_AND_OUTFITS_OF_MEASURING_TOOLS"] = "SETS_KITS_AND_OUTFITS_OF_MEASURING_TOOLS";
    FederalSupplyClass["SEWAGE_TREATMENT_EQUIPMENT"] = "SEWAGE_TREATMENT_EQUIPMENT";
    FederalSupplyClass["SHEET_AND_BOOK_MUSIC"] = "SHEET_AND_BOOK_MUSIC";
    FederalSupplyClass["SHIPBOARD_ALARM_AND_SIGNAL_SYSTEMS"] = "SHIPBOARD_ALARM_AND_SIGNAL_SYSTEMS";
    FederalSupplyClass["SHOE_FINDINGS_AND_SOLING_MATERIALS"] = "SHOE_FINDINGS_AND_SOLING_MATERIALS";
    FederalSupplyClass["SHOE_REPAIRING_EQUIPMENT"] = "SHOE_REPAIRING_EQUIPMENT";
    FederalSupplyClass["SIGNS_ADVERTISING_DISPLAYS_AND_IDENTIFICATION"] = "SIGNS_ADVERTISING_DISPLAYS_AND_IDENTIFICATION";
    FederalSupplyClass["SMALL_CRAFT_POWERED_AND_UNPOWERED_SMALL_CRAFT"] = "SMALL_CRAFT_POWERED_AND_UNPOWERED_SMALL_CRAFT";
    FederalSupplyClass["SMOKERS_ARTICLES_AND_MATCHES"] = "SMOKERS_ARTICLES_AND_MATCHES";
    FederalSupplyClass["SOIL_PREPARATION_EQUIPMENT"] = "SOIL_PREPARATION_EQUIPMENT";
    FederalSupplyClass["SOLAR_ELECTRIC_POWER_SYSTEMS"] = "SOLAR_ELECTRIC_POWER_SYSTEMS";
    FederalSupplyClass["SOUND_RECORDING_AND_REPRODUCING_EQUIPMENT"] = "SOUND_RECORDING_AND_REPRODUCING_EQUIPMENT";
    FederalSupplyClass["SOUPS_AND_BOUILLONS"] = "SOUPS_AND_BOUILLONS";
    FederalSupplyClass["SPACE_HEATING_EQUIPMENT_AND_DOMESTIC"] = "SPACE_HEATING_EQUIPMENT_AND_DOMESTIC";
    FederalSupplyClass["SPACE_VEHICLE_COMPONENTS"] = "SPACE_VEHICLE_COMPONENTS";
    FederalSupplyClass["SPACE_VEHICLE_HANDLING_AND_SERVICING_EQUIPMENT"] = "SPACE_VEHICLE_HANDLING_AND_SERVICING_EQUIPMENT";
    FederalSupplyClass["SPACE_VEHICLE_MAINTENANCE_REPAIR_AND_CHECKOUT"] = "SPACE_VEHICLE_MAINTENANCE_REPAIR_AND_CHECKOUT";
    FederalSupplyClass["SPECIALIZED_AMMUNITION_AND_ORDNANCE_MACHINERY"] = "SPECIALIZED_AMMUNITION_AND_ORDNANCE_MACHINERY";
    FederalSupplyClass["SPECIALIZED_FLIGHT_CLOTHING_ACCESSORIES"] = "SPECIALIZED_FLIGHT_CLOTHING_ACCESSORIES";
    FederalSupplyClass["SPECIALIZED_METAL_CONTAINER_MANUFACTURING"] = "SPECIALIZED_METAL_CONTAINER_MANUFACTURING";
    FederalSupplyClass["SPECIALIZED_SEMICONDUCTOR_MICROCIRCUIT"] = "SPECIALIZED_SEMICONDUCTOR_MICROCIRCUIT";
    FederalSupplyClass["SPECIALIZED_SHIPPING_AND_STORAGE_CONTAINERS"] = "SPECIALIZED_SHIPPING_AND_STORAGE_CONTAINERS";
    FederalSupplyClass["SPECIALIZED_TEST_AND_HANDLING_EQUIPMENT"] = "SPECIALIZED_TEST_AND_HANDLING_EQUIPMENT";
    FederalSupplyClass["SPECIAL_DIETARY_FOODS_AND_FOOD_SPECIALTY"] = "SPECIAL_DIETARY_FOODS_AND_FOOD_SPECIALTY";
    FederalSupplyClass["STANDARD_FORMS"] = "STANDARD_FORMS";
    FederalSupplyClass["STATIONERY_AND_RECORD_FORMS"] = "STATIONERY_AND_RECORD_FORMS";
    FederalSupplyClass["STIMULATED_COHERENT_RADIATION_DEVICES_COMPONENTS"] = "STIMULATED_COHERENT_RADIATION_DEVICES_COMPONENTS";
    FederalSupplyClass["STORAGE_TANKS"] = "STORAGE_TANKS";
    FederalSupplyClass["STRUCTURAL_SHAPES_IRON_AND_STEEL"] = "STRUCTURAL_SHAPES_IRON_AND_STEEL";
    FederalSupplyClass["STRUCTURAL_SHAPES_NONFERROUS_BASE_METAL"] = "STRUCTURAL_SHAPES_NONFERROUS_BASE_METAL";
    FederalSupplyClass["STUDS"] = "STUDS";
    FederalSupplyClass["SUGAR_CONFECTIONERY_AND_NUTS"] = "SUGAR_CONFECTIONERY_AND_NUTS";
    FederalSupplyClass["SURGICAL_DRESSING_MATERIALS"] = "SURGICAL_DRESSING_MATERIALS";
    FederalSupplyClass["SWITCHES"] = "SWITCHES";
    FederalSupplyClass["SYNCHROS_AND_RESOLVERS"] = "SYNCHROS_AND_RESOLVERS";
    FederalSupplyClass["TABLEWARE"] = "TABLEWARE";
    FederalSupplyClass["TAPS_DIES_AND_COLLETS_HAND_AND_MACHINE"] = "TAPS_DIES_AND_COLLETS_HAND_AND_MACHINE";
    FederalSupplyClass["TELEPHONE_AND_TELEGRAPH_EQUIPMENT"] = "TELEPHONE_AND_TELEGRAPH_EQUIPMENT";
    FederalSupplyClass["TELETYPE_AND_FACSIMILE_EQUIPMENT"] = "TELETYPE_AND_FACSIMILE_EQUIPMENT";
    FederalSupplyClass["TENTS_AND_TARPAULILNS"] = "TENTS_AND_TARPAULILNS";
    FederalSupplyClass["TEXTILE_FABRICS"] = "TEXTILE_FABRICS";
    FederalSupplyClass["TEXTILE_INDUSTRIES_MACHINERY"] = "TEXTILE_INDUSTRIES_MACHINERY";
    FederalSupplyClass["TIME_MEASURING_INSTRUMENTS"] = "TIME_MEASURING_INSTRUMENTS";
    FederalSupplyClass["TIRES_SOLID_AND_CUSHION"] = "TIRES_SOLID_AND_CUSHION";
    FederalSupplyClass["TOBACCO_MANUFACTURING_MACHINERY"] = "TOBACCO_MANUFACTURING_MACHINERY";
    FederalSupplyClass["TOBACCO_PRODUCTS"] = "TOBACCO_PRODUCTS";
    FederalSupplyClass["TOILETRY_PAPER_PRODUCTS"] = "TOILETRY_PAPER_PRODUCTS";
    FederalSupplyClass["TOILET_SOAP_SHAVING_PREPARATIONS_DENTIFRICES"] = "TOILET_SOAP_SHAVING_PREPARATIONS_DENTIFRICES";
    FederalSupplyClass["TOOLS_AND_ATTACHMENTS_FOR_WOODWORKING_MACHINERY"] = "TOOLS_AND_ATTACHMENTS_FOR_WOODWORKING_MACHINERY";
    FederalSupplyClass["TOOL_AND_HARDWARE_BOXES"] = "TOOL_AND_HARDWARE_BOXES";
    FederalSupplyClass["TORPEDO_MAINTENANCE_REPAIR_AND_CHECKOUT"] = "TORPEDO_MAINTENANCE_REPAIR_AND_CHECKOUT";
    FederalSupplyClass["TRAFFIC_AND_TRANSIT_SIGNAL_SYSTEMS"] = "TRAFFIC_AND_TRANSIT_SIGNAL_SYSTEMS";
    FederalSupplyClass["TRAINING_AIDS"] = "TRAINING_AIDS";
    FederalSupplyClass["TRANSFORMERS_DISTRIBUTION_AND_POWER_STATION"] = "TRANSFORMERS_DISTRIBUTION_AND_POWER_STATION";
    FederalSupplyClass["TRUCKS_TRUCK_TRACTORS_WHEELED"] = "TRUCKS_TRUCK_TRACTORS_WHEELED";
    FederalSupplyClass["TRUCK_AND_TRACTOR_ATTACHMENTS"] = "TRUCK_AND_TRACTOR_ATTACHMENTS";
    FederalSupplyClass["TURBOSUPERCHARGERS"] = "TURBOSUPERCHARGERS";
    FederalSupplyClass["TYPEWRITERS_AND_OFFICE_TYPE_COMPOSING"] = "TYPEWRITERS_AND_OFFICE_TYPE_COMPOSING";
    FederalSupplyClass["UNDERWATER_SOUND_EQUIPMENT"] = "UNDERWATER_SOUND_EQUIPMENT";
    FederalSupplyClass["UNDERWEAR_AND_NIGHTWEAR_MEN_S"] = "UNDERWEAR_AND_NIGHTWEAR_MEN_S";
    FederalSupplyClass["UNDERWEAR_AND_NIGHTWEAR_WOMEN_S"] = "UNDERWEAR_AND_NIGHTWEAR_WOMEN_S";
    FederalSupplyClass["UNKNOWN"] = "UNKNOWN";
    FederalSupplyClass["VALVES_NONPOWERED"] = "VALVES_NONPOWERED";
    FederalSupplyClass["VALVES_POWERED"] = "VALVES_POWERED";
    FederalSupplyClass["VENDING_AND_COIN_OPERATED_MACHINES"] = "VENDING_AND_COIN_OPERATED_MACHINES";
    FederalSupplyClass["VIDEO_RECORDING_AND_REPRODUCING_EQUIPMENT"] = "VIDEO_RECORDING_AND_REPRODUCING_EQUIPMENT";
    FederalSupplyClass["VISIBLE_AND_INVISIBLE_LIGHT_COMMUNICATION"] = "VISIBLE_AND_INVISIBLE_LIGHT_COMMUNICATION";
    FederalSupplyClass["VISIBLE_RECORD_EQUIPMENT"] = "VISIBLE_RECORD_EQUIPMENT";
    FederalSupplyClass["WALLBOARD_BUILDING_PAPER_AND_THERMAL"] = "WALLBOARD_BUILDING_PAPER_AND_THERMAL";
    FederalSupplyClass["WAREHOUSE_TRUCKS_AND_TRACTORS_SELFPROPELLED"] = "WAREHOUSE_TRUCKS_AND_TRACTORS_SELFPROPELLED";
    FederalSupplyClass["WATER_DISTILLATION_EQUIPMENT_MARINE"] = "WATER_DISTILLATION_EQUIPMENT_MARINE";
    FederalSupplyClass["WATER_PURIFICATION_EQUIPMENT"] = "WATER_PURIFICATION_EQUIPMENT";
    FederalSupplyClass["WEAPONS_MAINTENANCE_AND_REPAIR_SHOP"] = "WEAPONS_MAINTENANCE_AND_REPAIR_SHOP";
    FederalSupplyClass["WELDING_POSITIONERS_AND_MANIPULATORS"] = "WELDING_POSITIONERS_AND_MANIPULATORS";
    FederalSupplyClass["WINCHES_HOISTS_CRANES_AND_DERRICKS"] = "WINCHES_HOISTS_CRANES_AND_DERRICKS";
    FederalSupplyClass["WIRE_AND_CABLE_ELECTRICAL"] = "WIRE_AND_CABLE_ELECTRICAL";
    FederalSupplyClass["WIRE_AND_METAL_RIBBON_FORMING_MACHINES"] = "WIRE_AND_METAL_RIBBON_FORMING_MACHINES";
    FederalSupplyClass["WIRE_NONELECTRICAL_IRON_AND_STEEL"] = "WIRE_NONELECTRICAL_IRON_AND_STEEL";
    FederalSupplyClass["WIRE_NONELECTRICAL_NONFERROUS_BASE_METAL"] = "WIRE_NONELECTRICAL_NONFERROUS_BASE_METAL";
    FederalSupplyClass["WOODWORKING_MACHINES"] = "WOODWORKING_MACHINES";
    FederalSupplyClass["WRAPPING_AND_PACKAGING_MACHINERY"] = "WRAPPING_AND_PACKAGING_MACHINERY";
    FederalSupplyClass["X_RAY_EQUIPMENT_AND_SUPPLIES_MEDICAL_DENTAL"] = "X_RAY_EQUIPMENT_AND_SUPPLIES_MEDICAL_DENTAL";
    FederalSupplyClass["YARN_AND_THREAD"] = "YARN_AND_THREAD";
    FederalSupplyClass["SPACE_SURVIVAL_EQUIPMENT"] = "SPACE_SURVIVAL_EQUIPMENT";
})(FederalSupplyClass || (FederalSupplyClass = {}));
export var FracasAnalysisStatus;
(function (FracasAnalysisStatus) {
    FracasAnalysisStatus["NOT_STARTED"] = "NOT_STARTED";
    FracasAnalysisStatus["IN_WORK"] = "IN_WORK";
    FracasAnalysisStatus["COMPLETE"] = "COMPLETE";
})(FracasAnalysisStatus || (FracasAnalysisStatus = {}));
export var FracasCauseOfFailure;
(function (FracasCauseOfFailure) {
    FracasCauseOfFailure["UNKNOWN"] = "UNKNOWN";
})(FracasCauseOfFailure || (FracasCauseOfFailure = {}));
export var FracasFailureEnvironment;
(function (FracasFailureEnvironment) {
    FracasFailureEnvironment["DVT_TESTING"] = "DVT_TESTING";
    FracasFailureEnvironment["QUALITY_TESTING"] = "QUALITY_TESTING";
    FracasFailureEnvironment["ACCEPTANCE_TESTING"] = "ACCEPTANCE_TESTING";
    FracasFailureEnvironment["ISS"] = "ISS";
    FracasFailureEnvironment["GATEWAY"] = "GATEWAY";
    FracasFailureEnvironment["LUNAR"] = "LUNAR";
    FracasFailureEnvironment["HLS"] = "HLS";
})(FracasFailureEnvironment || (FracasFailureEnvironment = {}));
export var FracasFailureMode;
(function (FracasFailureMode) {
    FracasFailureMode["UNKNOWN"] = "UNKNOWN";
})(FracasFailureMode || (FracasFailureMode = {}));
export var FracasFailurePhase;
(function (FracasFailurePhase) {
    FracasFailurePhase["INSPECTION"] = "INSPECTION";
    FracasFailurePhase["TESTING"] = "TESTING";
    FracasFailurePhase["ON_ORBIT"] = "ON_ORBIT";
})(FracasFailurePhase || (FracasFailurePhase = {}));
export var FracasFailureType;
(function (FracasFailureType) {
    FracasFailureType["ANOMALY"] = "ANOMALY";
    FracasFailureType["DISCREPANCY"] = "DISCREPANCY";
    FracasFailureType["FAILURE"] = "FAILURE";
    FracasFailureType["NON_CRITICAL"] = "NON_CRITICAL";
})(FracasFailureType || (FracasFailureType = {}));
export var FracasScoring;
(function (FracasScoring) {
    FracasScoring["NON_ESSENTIAL_FUNCTION"] = "NON_ESSENTIAL_FUNCTION";
    FracasScoring["ESSENTIAL_FUNCTIONMISSION_CRITICAL"] = "ESSENTIAL_FUNCTIONMISSION_CRITICAL";
})(FracasScoring || (FracasScoring = {}));
export var FracasSubSystem;
(function (FracasSubSystem) {
    FracasSubSystem["X_PLSS"] = "X_PLSS";
    FracasSubSystem["X_PGS"] = "X_PGS";
    FracasSubSystem["X_INFO"] = "X_INFO";
    FracasSubSystem["SOFTWARE"] = "SOFTWARE";
})(FracasSubSystem || (FracasSubSystem = {}));
export var GreenProcurement;
(function (GreenProcurement) {
    GreenProcurement["C1"] = "C1";
    GreenProcurement["C2"] = "C2";
    GreenProcurement["C4"] = "C4";
    GreenProcurement["C5"] = "C5";
    GreenProcurement["C6"] = "C6";
    GreenProcurement["C7"] = "C7";
    GreenProcurement["C8"] = "C8";
    GreenProcurement["C9"] = "C9";
    GreenProcurement["C10"] = "C10";
    GreenProcurement["C11"] = "C11";
    GreenProcurement["C12"] = "C12";
    GreenProcurement["C13"] = "C13";
    GreenProcurement["C14"] = "C14";
    GreenProcurement["C15"] = "C15";
    GreenProcurement["C16"] = "C16";
    GreenProcurement["C17"] = "C17";
    GreenProcurement["C18"] = "C18";
    GreenProcurement["C19"] = "C19";
    GreenProcurement["C20"] = "C20";
    GreenProcurement["C21"] = "C21";
    GreenProcurement["C22"] = "C22";
    GreenProcurement["C23"] = "C23";
    GreenProcurement["C24"] = "C24";
    GreenProcurement["C25"] = "C25";
    GreenProcurement["C26"] = "C26";
    GreenProcurement["C27"] = "C27";
    GreenProcurement["C28"] = "C28";
    GreenProcurement["C29"] = "C29";
    GreenProcurement["L1"] = "L1";
    GreenProcurement["L2"] = "L2";
    GreenProcurement["L3"] = "L3";
    GreenProcurement["L4"] = "L4";
    GreenProcurement["L5"] = "L5";
    GreenProcurement["L6"] = "L6";
    GreenProcurement["L7"] = "L7";
    GreenProcurement["L8"] = "L8";
    GreenProcurement["L9"] = "L9";
    GreenProcurement["M1"] = "M1";
    GreenProcurement["M2"] = "M2";
    GreenProcurement["M3"] = "M3";
    GreenProcurement["M4"] = "M4";
    GreenProcurement["M5"] = "M5";
    GreenProcurement["M6"] = "M6";
    GreenProcurement["M7"] = "M7";
    GreenProcurement["M8"] = "M8";
    GreenProcurement["M9"] = "M9";
    GreenProcurement["M10"] = "M10";
    GreenProcurement["M11"] = "M11";
    GreenProcurement["M12"] = "M12";
    GreenProcurement["M13"] = "M13";
    GreenProcurement["M14"] = "M14";
    GreenProcurement["M15"] = "M15";
    GreenProcurement["M16"] = "M16";
    GreenProcurement["M17"] = "M17";
    GreenProcurement["M18"] = "M18";
    GreenProcurement["M19"] = "M19";
    GreenProcurement["M20"] = "M20";
    GreenProcurement["M21"] = "M21";
    GreenProcurement["M22"] = "M22";
    GreenProcurement["M23"] = "M23";
    GreenProcurement["M24"] = "M24";
    GreenProcurement["M25"] = "M25";
    GreenProcurement["M26"] = "M26";
    GreenProcurement["M27"] = "M27";
    GreenProcurement["N1"] = "N1";
    GreenProcurement["N2"] = "N2";
    GreenProcurement["N3"] = "N3";
    GreenProcurement["N4"] = "N4";
    GreenProcurement["N5"] = "N5";
    GreenProcurement["N6"] = "N6";
    GreenProcurement["N7"] = "N7";
    GreenProcurement["N8"] = "N8";
    GreenProcurement["N9"] = "N9";
    GreenProcurement["N10"] = "N10";
    GreenProcurement["N11"] = "N11";
    GreenProcurement["N12"] = "N12";
    GreenProcurement["N13"] = "N13";
    GreenProcurement["N16"] = "N16";
    GreenProcurement["N17"] = "N17";
    GreenProcurement["N18"] = "N18";
    GreenProcurement["N19"] = "N19";
    GreenProcurement["N20"] = "N20";
    GreenProcurement["N21"] = "N21";
    GreenProcurement["N22"] = "N22";
    GreenProcurement["N23"] = "N23";
    GreenProcurement["N24"] = "N24";
    GreenProcurement["N25"] = "N25";
    GreenProcurement["P1"] = "P1";
    GreenProcurement["P2"] = "P2";
    GreenProcurement["P3"] = "P3";
    GreenProcurement["P4"] = "P4";
    GreenProcurement["P5"] = "P5";
    GreenProcurement["P6"] = "P6";
    GreenProcurement["P7"] = "P7";
    GreenProcurement["P8"] = "P8";
    GreenProcurement["P9"] = "P9";
    GreenProcurement["P10"] = "P10";
    GreenProcurement["P11"] = "P11";
    GreenProcurement["P12"] = "P12";
    GreenProcurement["P13"] = "P13";
    GreenProcurement["P14"] = "P14";
    GreenProcurement["P15"] = "P15";
    GreenProcurement["P16"] = "P16";
    GreenProcurement["P17"] = "P17";
    GreenProcurement["P18"] = "P18";
    GreenProcurement["P19"] = "P19";
    GreenProcurement["P20"] = "P20";
    GreenProcurement["P21"] = "P21";
    GreenProcurement["P22"] = "P22";
    GreenProcurement["P23"] = "P23";
    GreenProcurement["P24"] = "P24";
    GreenProcurement["P25"] = "P25";
    GreenProcurement["P26"] = "P26";
    GreenProcurement["P27"] = "P27";
    GreenProcurement["P28"] = "P28";
    GreenProcurement["P29"] = "P29";
    GreenProcurement["P30"] = "P30";
    GreenProcurement["P31"] = "P31";
    GreenProcurement["P32"] = "P32";
    GreenProcurement["P33"] = "P33";
    GreenProcurement["P34"] = "P34";
    GreenProcurement["P35"] = "P35";
    GreenProcurement["P36"] = "P36";
    GreenProcurement["R1"] = "R1";
    GreenProcurement["R2"] = "R2";
    GreenProcurement["R3"] = "R3";
    GreenProcurement["R4"] = "R4";
    GreenProcurement["R5"] = "R5";
    GreenProcurement["R6"] = "R6";
    GreenProcurement["R7"] = "R7";
    GreenProcurement["R8"] = "R8";
    GreenProcurement["R9"] = "R9";
    GreenProcurement["T1"] = "T1";
    GreenProcurement["T2"] = "T2";
    GreenProcurement["T3"] = "T3";
    GreenProcurement["T4"] = "T4";
    GreenProcurement["T5"] = "T5";
    GreenProcurement["T6"] = "T6";
    GreenProcurement["T7"] = "T7";
    GreenProcurement["T8"] = "T8";
    GreenProcurement["T9"] = "T9";
    GreenProcurement["T10"] = "T10";
    GreenProcurement["T11"] = "T11";
    GreenProcurement["T12"] = "T12";
    GreenProcurement["T13"] = "T13";
    GreenProcurement["T14"] = "T14";
    GreenProcurement["T15"] = "T15";
    GreenProcurement["V1"] = "V1";
    GreenProcurement["V2"] = "V2";
    GreenProcurement["V3"] = "V3";
})(GreenProcurement || (GreenProcurement = {}));
export var GroundSupportEquipmentType;
(function (GroundSupportEquipmentType) {
    GroundSupportEquipmentType["_2_KN_LOAD_CELL"] = "_2_KN_LOAD_CELL";
    GroundSupportEquipmentType["AIR_FLOW_MONITOR"] = "AIR_FLOW_MONITOR";
    GroundSupportEquipmentType["ALARM_PANEL"] = "ALARM_PANEL";
    GroundSupportEquipmentType["AMPLIFIER"] = "AMPLIFIER";
    GroundSupportEquipmentType["ANALYZER"] = "ANALYZER";
    GroundSupportEquipmentType["APFR_GSE_TETHER_ASSY"] = "APFR_GSE_TETHER_ASSY";
    GroundSupportEquipmentType["ATTENUATOR"] = "ATTENUATOR";
    GroundSupportEquipmentType["BATTERY_CHARGER_DISCHARGER"] = "BATTERY_CHARGER_DISCHARGER";
    GroundSupportEquipmentType["BATTERY_CHARGER_EMU_LIB"] = "BATTERY_CHARGER_EMU_LIB";
    GroundSupportEquipmentType["BATTERY_HITESTER"] = "BATTERY_HITESTER";
    GroundSupportEquipmentType["BLEED_FIXTURE"] = "BLEED_FIXTURE";
    GroundSupportEquipmentType["BREAKOUT_BOX"] = "BREAKOUT_BOX";
    GroundSupportEquipmentType["BUBBLE_FLOWMETER_KIT"] = "BUBBLE_FLOWMETER_KIT";
    GroundSupportEquipmentType["CABLE"] = "CABLE";
    GroundSupportEquipmentType["CALIBRATED_LEAK"] = "CALIBRATED_LEAK";
    GroundSupportEquipmentType["CALIPER"] = "CALIPER";
    GroundSupportEquipmentType["CARRYING_FRAME"] = "CARRYING_FRAME";
    GroundSupportEquipmentType["CCC_SIMULATOR"] = "CCC_SIMULATOR";
    GroundSupportEquipmentType["CLEAN_ROOM_LSS"] = "CLEAN_ROOM_LSS";
    GroundSupportEquipmentType["CLEAN_ROOM_SOP"] = "CLEAN_ROOM_SOP";
    GroundSupportEquipmentType["CONTACT_TOOL"] = "CONTACT_TOOL";
    GroundSupportEquipmentType["COOLER"] = "COOLER";
    GroundSupportEquipmentType["COUNTER"] = "COUNTER";
    GroundSupportEquipmentType["CRIMP_TOOL"] = "CRIMP_TOOL";
    GroundSupportEquipmentType["CURRENT_METER"] = "CURRENT_METER";
    GroundSupportEquipmentType["DATA_ACQUISITION_UNIT"] = "DATA_ACQUISITION_UNIT";
    GroundSupportEquipmentType["DECADE_RESISTOR"] = "DECADE_RESISTOR";
    GroundSupportEquipmentType["DESKTOP_COMPUTER"] = "DESKTOP_COMPUTER";
    GroundSupportEquipmentType["DIA_PUMP"] = "DIA_PUMP";
    GroundSupportEquipmentType["DIAL_CHAMFER_GAUGE"] = "DIAL_CHAMFER_GAUGE";
    GroundSupportEquipmentType["DIGITAL_LEVEL"] = "DIGITAL_LEVEL";
    GroundSupportEquipmentType["DIT_MCO_2_CABLE_TEST_SYSTEM_ASSEMBLY"] = "DIT_MCO_2_CABLE_TEST_SYSTEM_ASSEMBLY";
    GroundSupportEquipmentType["ELECTRICAL_GLOVES"] = "ELECTRICAL_GLOVES";
    GroundSupportEquipmentType["ELECTRONIC_LOAD"] = "ELECTRONIC_LOAD";
    GroundSupportEquipmentType["EMU_DISPLAY_STAND_ASSY"] = "EMU_DISPLAY_STAND_ASSY";
    GroundSupportEquipmentType["EMU_GROUND_TEST_FIXTURE_FRAME"] = "EMU_GROUND_TEST_FIXTURE_FRAME";
    GroundSupportEquipmentType["EMU_GROUND_TEST_FIXTURE"] = "EMU_GROUND_TEST_FIXTURE";
    GroundSupportEquipmentType["EMU_LIFTING_FIXTURE"] = "EMU_LIFTING_FIXTURE";
    GroundSupportEquipmentType["EMU_OFFSET_HOLDING_FIXTURE"] = "EMU_OFFSET_HOLDING_FIXTURE";
    GroundSupportEquipmentType["EQUIPMENT_TBD"] = "EQUIPMENT_TBD";
    GroundSupportEquipmentType["FACILITY_B_A_SYSTEM"] = "FACILITY_B_A_SYSTEM";
    GroundSupportEquipmentType["FACILITY_BA_SYSTEM"] = "FACILITY_BA_SYSTEM";
    GroundSupportEquipmentType["FACILITY_DA_SYSTEM"] = "FACILITY_DA_SYSTEM";
    GroundSupportEquipmentType["FACILITY_H20_SYSTEM"] = "FACILITY_H20_SYSTEM";
    GroundSupportEquipmentType["FACILITY_H2O_SYSTEM"] = "FACILITY_H2O_SYSTEM";
    GroundSupportEquipmentType["FACILITY_N2_SYSTEM"] = "FACILITY_N2_SYSTEM";
    GroundSupportEquipmentType["FACILITY_O2_SYSTEM"] = "FACILITY_O2_SYSTEM";
    GroundSupportEquipmentType["FC_FLOW_CONTROLLER"] = "FC_FLOW_CONTROLLER";
    GroundSupportEquipmentType["FCD_FLOW_CONTROL_DISPLAY"] = "FCD_FLOW_CONTROL_DISPLAY";
    GroundSupportEquipmentType["FILTER"] = "FILTER";
    GroundSupportEquipmentType["FIXTURE"] = "FIXTURE";
    GroundSupportEquipmentType["FLEXHOSE"] = "FLEXHOSE";
    GroundSupportEquipmentType["FLOW_BENCH"] = "FLOW_BENCH";
    GroundSupportEquipmentType["FLOWMETER"] = "FLOWMETER";
    GroundSupportEquipmentType["FORCE_GAUGE"] = "FORCE_GAUGE";
    GroundSupportEquipmentType["FORK_HOOK"] = "FORK_HOOK";
    GroundSupportEquipmentType["FORKLIFT"] = "FORKLIFT";
    GroundSupportEquipmentType["FT_FLOW_TRANSDUCER"] = "FT_FLOW_TRANSDUCER";
    GroundSupportEquipmentType["FTD_FLOW_TRANSDUCER_DISPLAY"] = "FTD_FLOW_TRANSDUCER_DISPLAY";
    GroundSupportEquipmentType["FUME_HOOD"] = "FUME_HOOD";
    GroundSupportEquipmentType["FUNCTION_GENERATOR"] = "FUNCTION_GENERATOR";
    GroundSupportEquipmentType["GAGE_ACTIVE_WIF_PROBE"] = "GAGE_ACTIVE_WIF_PROBE";
    GroundSupportEquipmentType["GHD_HOISTING_SLING"] = "GHD_HOISTING_SLING";
    GroundSupportEquipmentType["GLOVE_MEASURE_DEVICE"] = "GLOVE_MEASURE_DEVICE";
    GroundSupportEquipmentType["GLUE_BENCH"] = "GLUE_BENCH";
    GroundSupportEquipmentType["GO_GAGE"] = "GO_GAGE";
    GroundSupportEquipmentType["GO_NO_GO_GAUGE"] = "GO_NO_GO_GAUGE";
    GroundSupportEquipmentType["GROUND_HANDLING_DEVICE"] = "GROUND_HANDLING_DEVICE";
    GroundSupportEquipmentType["HEIGHT_EXTENDER_ASSY"] = "HEIGHT_EXTENDER_ASSY";
    GroundSupportEquipmentType["HELIUM_LEAK_PROBE"] = "HELIUM_LEAK_PROBE";
    GroundSupportEquipmentType["HIGH_POT_TESTER"] = "HIGH_POT_TESTER";
    GroundSupportEquipmentType["HIGH_RESISTANCE_METER"] = "HIGH_RESISTANCE_METER";
    GroundSupportEquipmentType["HOIST"] = "HOIST";
    GroundSupportEquipmentType["HOSE_ASSEMBLY"] = "HOSE_ASSEMBLY";
    GroundSupportEquipmentType["HOSE_SWAGING_CART"] = "HOSE_SWAGING_CART";
    GroundSupportEquipmentType["HYGRO_THERMOMETER"] = "HYGRO_THERMOMETER";
    GroundSupportEquipmentType["IMPACT_O_GRAPH"] = "IMPACT_O_GRAPH";
    GroundSupportEquipmentType["IONIZER"] = "IONIZER";
    GroundSupportEquipmentType["IR_THERMOMETER"] = "IR_THERMOMETER";
    GroundSupportEquipmentType["LAB_TOP_COMPUTER"] = "LAB_TOP_COMPUTER";
    GroundSupportEquipmentType["LAUNCH_ENCLOSURE_LIFTING_EYE"] = "LAUNCH_ENCLOSURE_LIFTING_EYE";
    GroundSupportEquipmentType["LIFTING_BEAM"] = "LIFTING_BEAM";
    GroundSupportEquipmentType["LIFTING_FIXTURE"] = "LIFTING_FIXTURE";
    GroundSupportEquipmentType["LIFTING_STRAP"] = "LIFTING_STRAP";
    GroundSupportEquipmentType["LTA_SUSPENSION_ALIGNMENT_FIXTURE"] = "LTA_SUSPENSION_ALIGNMENT_FIXTURE";
    GroundSupportEquipmentType["MAN_LIFT"] = "MAN_LIFT";
    GroundSupportEquipmentType["MEASURING_TAPE"] = "MEASURING_TAPE";
    GroundSupportEquipmentType["MERCURY_VAPOR_ANALYZER"] = "MERCURY_VAPOR_ANALYZER";
    GroundSupportEquipmentType["MICROMETER"] = "MICROMETER";
    GroundSupportEquipmentType["MODULATION_METER"] = "MODULATION_METER";
    GroundSupportEquipmentType["MOISTURE_MONITOR"] = "MOISTURE_MONITOR";
    GroundSupportEquipmentType["MONITOR_GROUND"] = "MONITOR_GROUND";
    GroundSupportEquipmentType["MOUNTING_FRAME"] = "MOUNTING_FRAME";
    GroundSupportEquipmentType["MULITPLE_WATER_CONNECTOR"] = "MULITPLE_WATER_CONNECTOR";
    GroundSupportEquipmentType["MULTIMETER"] = "MULTIMETER";
    GroundSupportEquipmentType["MULTIPLE_WATER_CONNECTOR_"] = "MULTIPLE_WATER_CONNECTOR_";
    GroundSupportEquipmentType["MULTITESTER"] = "MULTITESTER";
    GroundSupportEquipmentType["NECK_PLATE_INTERFACE_FIXTURE"] = "NECK_PLATE_INTERFACE_FIXTURE";
    GroundSupportEquipmentType["O2_MONITOR"] = "O2_MONITOR";
    GroundSupportEquipmentType["OHMMETER"] = "OHMMETER";
    GroundSupportEquipmentType["OSCILLATOR"] = "OSCILLATOR";
    GroundSupportEquipmentType["OSCILLOSCOPE"] = "OSCILLOSCOPE";
    GroundSupportEquipmentType["OVEN"] = "OVEN";
    GroundSupportEquipmentType["PALLET_JACK"] = "PALLET_JACK";
    GroundSupportEquipmentType["PANEL_METER"] = "PANEL_METER";
    GroundSupportEquipmentType["PARTICLE_COUNTER"] = "PARTICLE_COUNTER";
    GroundSupportEquipmentType["PH_METER"] = "PH_METER";
    GroundSupportEquipmentType["PIN_RETENTION_TOOL"] = "PIN_RETENTION_TOOL";
    GroundSupportEquipmentType["PLATE_MILLIOHM_REFERENCE"] = "PLATE_MILLIOHM_REFERENCE";
    GroundSupportEquipmentType["PLSS_WEIGHT_BOX"] = "PLSS_WEIGHT_BOX";
    GroundSupportEquipmentType["POWER_SUPPLY"] = "POWER_SUPPLY";
    GroundSupportEquipmentType["PRESSURE_GAUGE"] = "PRESSURE_GAUGE";
    GroundSupportEquipmentType["PRESSURE_REGULATOR"] = "PRESSURE_REGULATOR";
    GroundSupportEquipmentType["PRESSURE_SWITCH"] = "PRESSURE_SWITCH";
    GroundSupportEquipmentType["PROBE"] = "PROBE";
    GroundSupportEquipmentType["PT_PRESSURE_TRANSDUCER"] = "PT_PRESSURE_TRANSDUCER";
    GroundSupportEquipmentType["PTD_PRESSURE_TRANSDUCER_DISPLAY"] = "PTD_PRESSURE_TRANSDUCER_DISPLAY";
    GroundSupportEquipmentType["RADIO_TEST_SET"] = "RADIO_TEST_SET";
    GroundSupportEquipmentType["RECEIVER"] = "RECEIVER";
    GroundSupportEquipmentType["RECORDERS"] = "RECORDERS";
    GroundSupportEquipmentType["REGULATOR"] = "REGULATOR";
    GroundSupportEquipmentType["RELIEF_VALVE"] = "RELIEF_VALVE";
    GroundSupportEquipmentType["SAMPLE_CYLINDER"] = "SAMPLE_CYLINDER";
    GroundSupportEquipmentType["SCALE"] = "SCALE";
    GroundSupportEquipmentType["SEMU_LIFTING_BAR_ASSY"] = "SEMU_LIFTING_BAR_ASSY";
    GroundSupportEquipmentType["SENSOR_MODULE"] = "SENSOR_MODULE";
    GroundSupportEquipmentType["SHACKLE"] = "SHACKLE";
    GroundSupportEquipmentType["SHIPPING_CONTAINER"] = "SHIPPING_CONTAINER";
    GroundSupportEquipmentType["SHUCK_TEST_MICROMETER_GAUGE_ASSY"] = "SHUCK_TEST_MICROMETER_GAUGE_ASSY";
    GroundSupportEquipmentType["SOLDERING_STATION"] = "SOLDERING_STATION";
    GroundSupportEquipmentType["SOUND_LEVEL_CALIBRATOR"] = "SOUND_LEVEL_CALIBRATOR";
    GroundSupportEquipmentType["SOUND_LEVEL_METER"] = "SOUND_LEVEL_METER";
    GroundSupportEquipmentType["SOUND_PRESSURE_METER"] = "SOUND_PRESSURE_METER";
    GroundSupportEquipmentType["STATIC_LOCATOR"] = "STATIC_LOCATOR";
    GroundSupportEquipmentType["SUPPORT_PLATFORM_EMU_ADJUSTIBLE_HEIGHT"] = "SUPPORT_PLATFORM_EMU_ADJUSTIBLE_HEIGHT";
    GroundSupportEquipmentType["SURFACE_RESIST_IND"] = "SURFACE_RESIST_IND";
    GroundSupportEquipmentType["SURFACE_RESISTIVITY_METER"] = "SURFACE_RESISTIVITY_METER";
    GroundSupportEquipmentType["TD_TEMPERATURE_DISPLAY"] = "TD_TEMPERATURE_DISPLAY";
    GroundSupportEquipmentType["TEMP_HUMIDITY_RECORDER"] = "TEMP_HUMIDITY_RECORDER";
    GroundSupportEquipmentType["TEMPERATURE_CONTROLLER"] = "TEMPERATURE_CONTROLLER";
    GroundSupportEquipmentType["TEMPERATURE_LIMIT_CONTROLLER"] = "TEMPERATURE_LIMIT_CONTROLLER";
    GroundSupportEquipmentType["TEMPERATURE_RECORDER"] = "TEMPERATURE_RECORDER";
    GroundSupportEquipmentType["TEST_STAND"] = "TEST_STAND";
    GroundSupportEquipmentType["THERMOCOUPLE_DISPLAY"] = "THERMOCOUPLE_DISPLAY";
    GroundSupportEquipmentType["THERMOMETER"] = "THERMOMETER";
    GroundSupportEquipmentType["TIMER_CLOCK"] = "TIMER_CLOCK";
    GroundSupportEquipmentType["TIMER"] = "TIMER";
    GroundSupportEquipmentType["TORQUE_DRIVER"] = "TORQUE_DRIVER";
    GroundSupportEquipmentType["TORQUE_METER"] = "TORQUE_METER";
    GroundSupportEquipmentType["TORQUE_TESTER"] = "TORQUE_TESTER";
    GroundSupportEquipmentType["TORQUE_TRANSDUCER"] = "TORQUE_TRANSDUCER";
    GroundSupportEquipmentType["TORQUE_WRENCH"] = "TORQUE_WRENCH";
    GroundSupportEquipmentType["TT_TEMPERATURE_TRANSMITTER"] = "TT_TEMPERATURE_TRANSMITTER";
    GroundSupportEquipmentType["VACUUM_CHAMBER_ASSY_22_LITER"] = "VACUUM_CHAMBER_ASSY_22_LITER";
    GroundSupportEquipmentType["VACUUM_DISPLAY"] = "VACUUM_DISPLAY";
    GroundSupportEquipmentType["VACUUM_OVEN"] = "VACUUM_OVEN";
    GroundSupportEquipmentType["VACUUM_PUMP"] = "VACUUM_PUMP";
    GroundSupportEquipmentType["VACUUM_SENSOR"] = "VACUUM_SENSOR";
    GroundSupportEquipmentType["VEHICLE_BOBTAIL_TRUCK"] = "VEHICLE_BOBTAIL_TRUCK";
    GroundSupportEquipmentType["VELOCITY_METER"] = "VELOCITY_METER";
    GroundSupportEquipmentType["VENT_HOOD"] = "VENT_HOOD";
    GroundSupportEquipmentType["VITAL_SIGNS_SIMILATOR"] = "VITAL_SIGNS_SIMILATOR";
    GroundSupportEquipmentType["VOLTMETER"] = "VOLTMETER";
    GroundSupportEquipmentType["VRMS_METER"] = "VRMS_METER";
    GroundSupportEquipmentType["WATER_CONNECTOR_AIRLOCK"] = "WATER_CONNECTOR_AIRLOCK";
    GroundSupportEquipmentType["WATER_TANK"] = "WATER_TANK";
    GroundSupportEquipmentType["WATER_TRAP"] = "WATER_TRAP";
    GroundSupportEquipmentType["WAVEFORM_GENERATOR"] = "WAVEFORM_GENERATOR";
    GroundSupportEquipmentType["WAVEFORM_MONITOR"] = "WAVEFORM_MONITOR";
    GroundSupportEquipmentType["WEIGHT_BOX"] = "WEIGHT_BOX";
    GroundSupportEquipmentType["WELD_EXHAUST_HOOD"] = "WELD_EXHAUST_HOOD";
    GroundSupportEquipmentType["WIRE_CRIMPER"] = "WIRE_CRIMPER";
    GroundSupportEquipmentType["WIRE_STRIPPER"] = "WIRE_STRIPPER";
    GroundSupportEquipmentType["WRIST_STRAP_FOOTWEAR_CHECKER"] = "WRIST_STRAP_FOOTWEAR_CHECKER";
    GroundSupportEquipmentType["X_RAY_MACHINE"] = "X_RAY_MACHINE";
})(GroundSupportEquipmentType || (GroundSupportEquipmentType = {}));
/** Methods to use when ordering `HardwareListAssembly`. */
export var HardwareListAssembliesOrderBy;
(function (HardwareListAssembliesOrderBy) {
    HardwareListAssembliesOrderBy["NATURAL"] = "NATURAL";
    HardwareListAssembliesOrderBy["ID_ASC"] = "ID_ASC";
    HardwareListAssembliesOrderBy["ID_DESC"] = "ID_DESC";
    HardwareListAssembliesOrderBy["SUPPORT_ORGANIZATION_CODE_ASC"] = "SUPPORT_ORGANIZATION_CODE_ASC";
    HardwareListAssembliesOrderBy["SUPPORT_ORGANIZATION_CODE_DESC"] = "SUPPORT_ORGANIZATION_CODE_DESC";
    HardwareListAssembliesOrderBy["HARDWARE_LIST_ID_ASC"] = "HARDWARE_LIST_ID_ASC";
    HardwareListAssembliesOrderBy["HARDWARE_LIST_ID_DESC"] = "HARDWARE_LIST_ID_DESC";
    HardwareListAssembliesOrderBy["SEQUENCE_ASC"] = "SEQUENCE_ASC";
    HardwareListAssembliesOrderBy["SEQUENCE_DESC"] = "SEQUENCE_DESC";
    HardwareListAssembliesOrderBy["QUANTITY_ASC"] = "QUANTITY_ASC";
    HardwareListAssembliesOrderBy["QUANTITY_DESC"] = "QUANTITY_DESC";
    HardwareListAssembliesOrderBy["ADDENDUM_NUMBER_ASC"] = "ADDENDUM_NUMBER_ASC";
    HardwareListAssembliesOrderBy["ADDENDUM_NUMBER_DESC"] = "ADDENDUM_NUMBER_DESC";
    HardwareListAssembliesOrderBy["ADDENDUM_DATE_ASC"] = "ADDENDUM_DATE_ASC";
    HardwareListAssembliesOrderBy["ADDENDUM_DATE_DESC"] = "ADDENDUM_DATE_DESC";
    HardwareListAssembliesOrderBy["NOTES_ASC"] = "NOTES_ASC";
    HardwareListAssembliesOrderBy["NOTES_DESC"] = "NOTES_DESC";
    HardwareListAssembliesOrderBy["IS_EXTERNAL_ASC"] = "IS_EXTERNAL_ASC";
    HardwareListAssembliesOrderBy["IS_EXTERNAL_DESC"] = "IS_EXTERNAL_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_DESCRIPTION_ASC"] = "EXTERNAL_DESCRIPTION_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_DESCRIPTION_DESC"] = "EXTERNAL_DESCRIPTION_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_DRAWING_NUMBER_ASC"] = "EXTERNAL_DRAWING_NUMBER_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_DRAWING_NUMBER_DESC"] = "EXTERNAL_DRAWING_NUMBER_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_AS_BUILT_NUMBER_ASC"] = "EXTERNAL_AS_BUILT_NUMBER_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_AS_BUILT_NUMBER_DESC"] = "EXTERNAL_AS_BUILT_NUMBER_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_SIDE_ASC"] = "EXTERNAL_SIDE_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_SIDE_DESC"] = "EXTERNAL_SIDE_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_SERIAL_NUMBER_ASC"] = "EXTERNAL_SERIAL_NUMBER_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_SERIAL_NUMBER_DESC"] = "EXTERNAL_SERIAL_NUMBER_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_LOT_NUMBER_ASC"] = "EXTERNAL_LOT_NUMBER_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_LOT_NUMBER_DESC"] = "EXTERNAL_LOT_NUMBER_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_CLASS_ASC"] = "EXTERNAL_CLASS_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_CLASS_DESC"] = "EXTERNAL_CLASS_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_IS_TOOL_ASC"] = "EXTERNAL_IS_TOOL_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_IS_TOOL_DESC"] = "EXTERNAL_IS_TOOL_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_CONTRACT_ENDING_ITEM_NUMBER_ASC"] = "EXTERNAL_CONTRACT_ENDING_ITEM_NUMBER_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_CONTRACT_ENDING_ITEM_NUMBER_DESC"] = "EXTERNAL_CONTRACT_ENDING_ITEM_NUMBER_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_CALIBRATION_NUMBER_ASC"] = "EXTERNAL_CALIBRATION_NUMBER_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_CALIBRATION_NUMBER_DESC"] = "EXTERNAL_CALIBRATION_NUMBER_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_CALIBRATION_DATE_ASC"] = "EXTERNAL_CALIBRATION_DATE_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_CALIBRATION_DATE_DESC"] = "EXTERNAL_CALIBRATION_DATE_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_BIRTH_DATE_ASC"] = "EXTERNAL_BIRTH_DATE_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_BIRTH_DATE_DESC"] = "EXTERNAL_BIRTH_DATE_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_SHELF_LIFE_DATE_ASC"] = "EXTERNAL_SHELF_LIFE_DATE_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_SHELF_LIFE_DATE_DESC"] = "EXTERNAL_SHELF_LIFE_DATE_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_USAGE_LIFE_DATE_ASC"] = "EXTERNAL_USAGE_LIFE_DATE_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_USAGE_LIFE_DATE_DESC"] = "EXTERNAL_USAGE_LIFE_DATE_DESC";
    HardwareListAssembliesOrderBy["EXTERNAL_LOCATION_ASC"] = "EXTERNAL_LOCATION_ASC";
    HardwareListAssembliesOrderBy["EXTERNAL_LOCATION_DESC"] = "EXTERNAL_LOCATION_DESC";
    HardwareListAssembliesOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    HardwareListAssembliesOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    HardwareListAssembliesOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    HardwareListAssembliesOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    HardwareListAssembliesOrderBy["SIDE_ASC"] = "SIDE_ASC";
    HardwareListAssembliesOrderBy["SIDE_DESC"] = "SIDE_DESC";
    HardwareListAssembliesOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    HardwareListAssembliesOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    HardwareListAssembliesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    HardwareListAssembliesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    HardwareListAssembliesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    HardwareListAssembliesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    HardwareListAssembliesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    HardwareListAssembliesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(HardwareListAssembliesOrderBy || (HardwareListAssembliesOrderBy = {}));
/** Methods to use when ordering `HardwareListPartsToWorkOrderStep`. */
export var HardwareListPartsToWorkOrderStepsOrderBy;
(function (HardwareListPartsToWorkOrderStepsOrderBy) {
    HardwareListPartsToWorkOrderStepsOrderBy["NATURAL"] = "NATURAL";
    HardwareListPartsToWorkOrderStepsOrderBy["HARDWARE_LIST_PART_ID_ASC"] = "HARDWARE_LIST_PART_ID_ASC";
    HardwareListPartsToWorkOrderStepsOrderBy["HARDWARE_LIST_PART_ID_DESC"] = "HARDWARE_LIST_PART_ID_DESC";
    HardwareListPartsToWorkOrderStepsOrderBy["WORK_ORDER_STEP_ID_ASC"] = "WORK_ORDER_STEP_ID_ASC";
    HardwareListPartsToWorkOrderStepsOrderBy["WORK_ORDER_STEP_ID_DESC"] = "WORK_ORDER_STEP_ID_DESC";
    HardwareListPartsToWorkOrderStepsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    HardwareListPartsToWorkOrderStepsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    HardwareListPartsToWorkOrderStepsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    HardwareListPartsToWorkOrderStepsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    HardwareListPartsToWorkOrderStepsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    HardwareListPartsToWorkOrderStepsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(HardwareListPartsToWorkOrderStepsOrderBy || (HardwareListPartsToWorkOrderStepsOrderBy = {}));
export var HardwareListReportInclusionReason;
(function (HardwareListReportInclusionReason) {
    HardwareListReportInclusionReason["ITEM_INSTANCE_LISTED"] = "ITEM_INSTANCE_LISTED";
    HardwareListReportInclusionReason["ASSEMBLY_CHILD_LISTED"] = "ASSEMBLY_CHILD_LISTED";
    HardwareListReportInclusionReason["ASSEMBLY_PARENT_LISTED"] = "ASSEMBLY_PARENT_LISTED";
})(HardwareListReportInclusionReason || (HardwareListReportInclusionReason = {}));
/** Methods to use when ordering `HardwareList`. */
export var HardwareListsOrderBy;
(function (HardwareListsOrderBy) {
    HardwareListsOrderBy["NATURAL"] = "NATURAL";
    HardwareListsOrderBy["ID_ASC"] = "ID_ASC";
    HardwareListsOrderBy["ID_DESC"] = "ID_DESC";
    HardwareListsOrderBy["NUMBER_ASC"] = "NUMBER_ASC";
    HardwareListsOrderBy["NUMBER_DESC"] = "NUMBER_DESC";
    HardwareListsOrderBy["EVENT_ID_ASC"] = "EVENT_ID_ASC";
    HardwareListsOrderBy["EVENT_ID_DESC"] = "EVENT_ID_DESC";
    HardwareListsOrderBy["HARDWARE_LIST_TEMPLATE_ID_ASC"] = "HARDWARE_LIST_TEMPLATE_ID_ASC";
    HardwareListsOrderBy["HARDWARE_LIST_TEMPLATE_ID_DESC"] = "HARDWARE_LIST_TEMPLATE_ID_DESC";
    HardwareListsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    HardwareListsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    HardwareListsOrderBy["NAME_ASC"] = "NAME_ASC";
    HardwareListsOrderBy["NAME_DESC"] = "NAME_DESC";
    HardwareListsOrderBy["REVISION_ASC"] = "REVISION_ASC";
    HardwareListsOrderBy["REVISION_DESC"] = "REVISION_DESC";
    HardwareListsOrderBy["OPEN_DATE_ASC"] = "OPEN_DATE_ASC";
    HardwareListsOrderBy["OPEN_DATE_DESC"] = "OPEN_DATE_DESC";
    HardwareListsOrderBy["CLOSE_DATE_ASC"] = "CLOSE_DATE_ASC";
    HardwareListsOrderBy["CLOSE_DATE_DESC"] = "CLOSE_DATE_DESC";
    HardwareListsOrderBy["ADDENDUM_NUMBER_ASC"] = "ADDENDUM_NUMBER_ASC";
    HardwareListsOrderBy["ADDENDUM_NUMBER_DESC"] = "ADDENDUM_NUMBER_DESC";
    HardwareListsOrderBy["ADDENDUM_DATE_ASC"] = "ADDENDUM_DATE_ASC";
    HardwareListsOrderBy["ADDENDUM_DATE_DESC"] = "ADDENDUM_DATE_DESC";
    HardwareListsOrderBy["AUTHORIZER_ASC"] = "AUTHORIZER_ASC";
    HardwareListsOrderBy["AUTHORIZER_DESC"] = "AUTHORIZER_DESC";
    HardwareListsOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    HardwareListsOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    HardwareListsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    HardwareListsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    HardwareListsOrderBy["CHANGE_SET_ID_ASC"] = "CHANGE_SET_ID_ASC";
    HardwareListsOrderBy["CHANGE_SET_ID_DESC"] = "CHANGE_SET_ID_DESC";
    HardwareListsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    HardwareListsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    HardwareListsOrderBy["IS_CRITICAL_ASC"] = "IS_CRITICAL_ASC";
    HardwareListsOrderBy["IS_CRITICAL_DESC"] = "IS_CRITICAL_DESC";
    HardwareListsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    HardwareListsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(HardwareListsOrderBy || (HardwareListsOrderBy = {}));
/** Methods to use when ordering `HardwareListTemplatePart`. */
export var HardwareListTemplatePartsOrderBy;
(function (HardwareListTemplatePartsOrderBy) {
    HardwareListTemplatePartsOrderBy["NATURAL"] = "NATURAL";
    HardwareListTemplatePartsOrderBy["ID_ASC"] = "ID_ASC";
    HardwareListTemplatePartsOrderBy["ID_DESC"] = "ID_DESC";
    HardwareListTemplatePartsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    HardwareListTemplatePartsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    HardwareListTemplatePartsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    HardwareListTemplatePartsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    HardwareListTemplatePartsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    HardwareListTemplatePartsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    HardwareListTemplatePartsOrderBy["HARDWARE_LIST_TEMPLATE_SLOT_ID_ASC"] = "HARDWARE_LIST_TEMPLATE_SLOT_ID_ASC";
    HardwareListTemplatePartsOrderBy["HARDWARE_LIST_TEMPLATE_SLOT_ID_DESC"] = "HARDWARE_LIST_TEMPLATE_SLOT_ID_DESC";
    HardwareListTemplatePartsOrderBy["ASSEMBLY_TEMPLATE_ID_ASC"] = "ASSEMBLY_TEMPLATE_ID_ASC";
    HardwareListTemplatePartsOrderBy["ASSEMBLY_TEMPLATE_ID_DESC"] = "ASSEMBLY_TEMPLATE_ID_DESC";
    HardwareListTemplatePartsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    HardwareListTemplatePartsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    HardwareListTemplatePartsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    HardwareListTemplatePartsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    HardwareListTemplatePartsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    HardwareListTemplatePartsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(HardwareListTemplatePartsOrderBy || (HardwareListTemplatePartsOrderBy = {}));
/** Methods to use when ordering `HardwareListTemplateSlot`. */
export var HardwareListTemplateSlotsOrderBy;
(function (HardwareListTemplateSlotsOrderBy) {
    HardwareListTemplateSlotsOrderBy["NATURAL"] = "NATURAL";
    HardwareListTemplateSlotsOrderBy["ID_ASC"] = "ID_ASC";
    HardwareListTemplateSlotsOrderBy["ID_DESC"] = "ID_DESC";
    HardwareListTemplateSlotsOrderBy["HARDWARE_LIST_TEMPLATE_ID_ASC"] = "HARDWARE_LIST_TEMPLATE_ID_ASC";
    HardwareListTemplateSlotsOrderBy["HARDWARE_LIST_TEMPLATE_ID_DESC"] = "HARDWARE_LIST_TEMPLATE_ID_DESC";
    HardwareListTemplateSlotsOrderBy["NAME_ASC"] = "NAME_ASC";
    HardwareListTemplateSlotsOrderBy["NAME_DESC"] = "NAME_DESC";
    HardwareListTemplateSlotsOrderBy["SEQUENCE_ASC"] = "SEQUENCE_ASC";
    HardwareListTemplateSlotsOrderBy["SEQUENCE_DESC"] = "SEQUENCE_DESC";
    HardwareListTemplateSlotsOrderBy["MIN_QUANTITY_ASC"] = "MIN_QUANTITY_ASC";
    HardwareListTemplateSlotsOrderBy["MIN_QUANTITY_DESC"] = "MIN_QUANTITY_DESC";
    HardwareListTemplateSlotsOrderBy["MAX_QUANTITY_ASC"] = "MAX_QUANTITY_ASC";
    HardwareListTemplateSlotsOrderBy["MAX_QUANTITY_DESC"] = "MAX_QUANTITY_DESC";
    HardwareListTemplateSlotsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    HardwareListTemplateSlotsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    HardwareListTemplateSlotsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    HardwareListTemplateSlotsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    HardwareListTemplateSlotsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    HardwareListTemplateSlotsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(HardwareListTemplateSlotsOrderBy || (HardwareListTemplateSlotsOrderBy = {}));
/** Methods to use when ordering `HardwareListTemplate`. */
export var HardwareListTemplatesOrderBy;
(function (HardwareListTemplatesOrderBy) {
    HardwareListTemplatesOrderBy["NATURAL"] = "NATURAL";
    HardwareListTemplatesOrderBy["ID_ASC"] = "ID_ASC";
    HardwareListTemplatesOrderBy["ID_DESC"] = "ID_DESC";
    HardwareListTemplatesOrderBy["NAME_ASC"] = "NAME_ASC";
    HardwareListTemplatesOrderBy["NAME_DESC"] = "NAME_DESC";
    HardwareListTemplatesOrderBy["PBS_ITEM_ID_ASC"] = "PBS_ITEM_ID_ASC";
    HardwareListTemplatesOrderBy["PBS_ITEM_ID_DESC"] = "PBS_ITEM_ID_DESC";
    HardwareListTemplatesOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    HardwareListTemplatesOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    HardwareListTemplatesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    HardwareListTemplatesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    HardwareListTemplatesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    HardwareListTemplatesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    HardwareListTemplatesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    HardwareListTemplatesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(HardwareListTemplatesOrderBy || (HardwareListTemplatesOrderBy = {}));
export var HardwareListType;
(function (HardwareListType) {
    HardwareListType["CREWMEMBER"] = "CREWMEMBER";
    HardwareListType["EMU_HARDWARE"] = "EMU_HARDWARE";
    HardwareListType["FLIGHT_SUPPORT_HARDWARE"] = "FLIGHT_SUPPORT_HARDWARE";
    HardwareListType["GROUND_SUPPORT_EQUIPMENT"] = "GROUND_SUPPORT_EQUIPMENT";
    HardwareListType["HARDWARE"] = "HARDWARE";
    HardwareListType["TOOLS"] = "TOOLS";
    /** Auto-generated for work order steps. */
    HardwareListType["WORK_ORDER_STEP_HARDWARE"] = "WORK_ORDER_STEP_HARDWARE";
    /** Auto-generated for work order steps. */
    HardwareListType["WORK_ORDER_STEP_TOOLS"] = "WORK_ORDER_STEP_TOOLS";
    /** Auto-generated for managed documents. */
    HardwareListType["COSMIC_DOCUMENT_HARDWARE"] = "COSMIC_DOCUMENT_HARDWARE";
    /** Auto-generated for managed documents */
    HardwareListType["COSMIC_DOCUMENT_TOOLS"] = "COSMIC_DOCUMENT_TOOLS";
    HardwareListType["DELIVERY_MATRIX"] = "DELIVERY_MATRIX";
})(HardwareListType || (HardwareListType = {}));
/** Methods to use when ordering `Inventory`. */
export var InventoriesOrderBy;
(function (InventoriesOrderBy) {
    InventoriesOrderBy["NATURAL"] = "NATURAL";
    InventoriesOrderBy["ID_ASC"] = "ID_ASC";
    InventoriesOrderBy["ID_DESC"] = "ID_DESC";
    InventoriesOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    InventoriesOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    InventoriesOrderBy["LOCATION_ID_ASC"] = "LOCATION_ID_ASC";
    InventoriesOrderBy["LOCATION_ID_DESC"] = "LOCATION_ID_DESC";
    InventoriesOrderBy["WORKING_ASSEMBLY_ID_ASC"] = "WORKING_ASSEMBLY_ID_ASC";
    InventoriesOrderBy["WORKING_ASSEMBLY_ID_DESC"] = "WORKING_ASSEMBLY_ID_DESC";
    InventoriesOrderBy["STATUS_ASC"] = "STATUS_ASC";
    InventoriesOrderBy["STATUS_DESC"] = "STATUS_DESC";
    InventoriesOrderBy["UNIT_COST_ASC"] = "UNIT_COST_ASC";
    InventoriesOrderBy["UNIT_COST_DESC"] = "UNIT_COST_DESC";
    InventoriesOrderBy["IS_COST_ESTIMATED_ASC"] = "IS_COST_ESTIMATED_ASC";
    InventoriesOrderBy["IS_COST_ESTIMATED_DESC"] = "IS_COST_ESTIMATED_DESC";
    InventoriesOrderBy["QUANTITY_ASC"] = "QUANTITY_ASC";
    InventoriesOrderBy["QUANTITY_DESC"] = "QUANTITY_DESC";
    InventoriesOrderBy["PROJECT_CODE_ASC"] = "PROJECT_CODE_ASC";
    InventoriesOrderBy["PROJECT_CODE_DESC"] = "PROJECT_CODE_DESC";
    InventoriesOrderBy["INITIAL_RECEIPT_DATE_ASC"] = "INITIAL_RECEIPT_DATE_ASC";
    InventoriesOrderBy["INITIAL_RECEIPT_DATE_DESC"] = "INITIAL_RECEIPT_DATE_DESC";
    InventoriesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    InventoriesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    InventoriesOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    InventoriesOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    InventoriesOrderBy["ISSUE_DESTINATION_ORGANIZATION_CODE_ASC"] = "ISSUE_DESTINATION_ORGANIZATION_CODE_ASC";
    InventoriesOrderBy["ISSUE_DESTINATION_ORGANIZATION_CODE_DESC"] = "ISSUE_DESTINATION_ORGANIZATION_CODE_DESC";
    InventoriesOrderBy["ISSUE_DESTINATION_LOCATION_ID_ASC"] = "ISSUE_DESTINATION_LOCATION_ID_ASC";
    InventoriesOrderBy["ISSUE_DESTINATION_LOCATION_ID_DESC"] = "ISSUE_DESTINATION_LOCATION_ID_DESC";
    InventoriesOrderBy["EXPECTED_RETURN_DATE_ASC"] = "EXPECTED_RETURN_DATE_ASC";
    InventoriesOrderBy["EXPECTED_RETURN_DATE_DESC"] = "EXPECTED_RETURN_DATE_DESC";
    InventoriesOrderBy["EXPECTED_RETURN_ORGANIZATION_CODE_ASC"] = "EXPECTED_RETURN_ORGANIZATION_CODE_ASC";
    InventoriesOrderBy["EXPECTED_RETURN_ORGANIZATION_CODE_DESC"] = "EXPECTED_RETURN_ORGANIZATION_CODE_DESC";
    InventoriesOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    InventoriesOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    InventoriesOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    InventoriesOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    InventoriesOrderBy["SIDE_ASC"] = "SIDE_ASC";
    InventoriesOrderBy["SIDE_DESC"] = "SIDE_DESC";
    InventoriesOrderBy["TOTAL_COST_ASC"] = "TOTAL_COST_ASC";
    InventoriesOrderBy["TOTAL_COST_DESC"] = "TOTAL_COST_DESC";
    InventoriesOrderBy["IS_ARCHIVED_ASC"] = "IS_ARCHIVED_ASC";
    InventoriesOrderBy["IS_ARCHIVED_DESC"] = "IS_ARCHIVED_DESC";
    InventoriesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    InventoriesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    InventoriesOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    InventoriesOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    InventoriesOrderBy["COMPUTED_INSTALLED_DATE_ASC"] = "COMPUTED_INSTALLED_DATE_ASC";
    InventoriesOrderBy["COMPUTED_INSTALLED_DATE_DESC"] = "COMPUTED_INSTALLED_DATE_DESC";
    InventoriesOrderBy["COMPUTED_IS_APPROVED_AS_DESIGN_ASC"] = "COMPUTED_IS_APPROVED_AS_DESIGN_ASC";
    InventoriesOrderBy["COMPUTED_IS_APPROVED_AS_DESIGN_DESC"] = "COMPUTED_IS_APPROVED_AS_DESIGN_DESC";
    InventoriesOrderBy["ITEM_MASTER_SUB_TYPE_ASC"] = "ITEM_MASTER_SUB_TYPE_ASC";
    InventoriesOrderBy["ITEM_MASTER_SUB_TYPE_DESC"] = "ITEM_MASTER_SUB_TYPE_DESC";
    InventoriesOrderBy["LOT_NUMBER_ASC"] = "LOT_NUMBER_ASC";
    InventoriesOrderBy["LOT_NUMBER_DESC"] = "LOT_NUMBER_DESC";
    InventoriesOrderBy["SERIAL_NUMBER_ASC"] = "SERIAL_NUMBER_ASC";
    InventoriesOrderBy["SERIAL_NUMBER_DESC"] = "SERIAL_NUMBER_DESC";
    InventoriesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    InventoriesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(InventoriesOrderBy || (InventoriesOrderBy = {}));
export var InventoryClass;
(function (InventoryClass) {
    InventoryClass["CLASS_I"] = "CLASS_I";
    InventoryClass["CLASS_IE"] = "CLASS_IE";
    InventoryClass["CLASS_II"] = "CLASS_II";
    InventoryClass["CLASS_III"] = "CLASS_III";
    InventoryClass["CLASS_IIIC"] = "CLASS_IIIC";
    InventoryClass["CLASS_IIIW"] = "CLASS_IIIW";
    InventoryClass["GROUND_SUPPORT_EQUIPMENT"] = "GROUND_SUPPORT_EQUIPMENT";
    InventoryClass["SPECIAL_TEST_EQUIPMENT"] = "SPECIAL_TEST_EQUIPMENT";
    InventoryClass["PUBLIC_AFFAIRS_ONLY"] = "PUBLIC_AFFAIRS_ONLY";
    InventoryClass["PROGRAM_SUPPORT_EQUIPMENT"] = "PROGRAM_SUPPORT_EQUIPMENT";
    InventoryClass["_PROTOTYPE"] = "_PROTOTYPE";
    InventoryClass["UC"] = "UC";
    InventoryClass["UNKNOWN"] = "UNKNOWN";
    InventoryClass["WORKSITE_INTERFACE_FIXTURE"] = "WORKSITE_INTERFACE_FIXTURE";
    InventoryClass["MULTILPLE"] = "MULTILPLE";
})(InventoryClass || (InventoryClass = {}));
export var InventoryControl;
(function (InventoryControl) {
    InventoryControl["LOT"] = "LOT";
    InventoryControl["SERIAL"] = "SERIAL";
})(InventoryControl || (InventoryControl = {}));
export var InventoryMethod;
(function (InventoryMethod) {
    InventoryMethod["ARTICLE"] = "ARTICLE";
    InventoryMethod["BAG"] = "BAG";
    InventoryMethod["BOTTLE"] = "BOTTLE";
    InventoryMethod["BOX"] = "BOX";
    InventoryMethod["BUNDLE"] = "BUNDLE";
    InventoryMethod["CAN"] = "CAN";
    InventoryMethod["CARD"] = "CARD";
    InventoryMethod["CARTON"] = "CARTON";
    InventoryMethod["CASE"] = "CASE";
    InventoryMethod["CYLINDER"] = "CYLINDER";
    InventoryMethod["DOZEN"] = "DOZEN";
    InventoryMethod["DRUM"] = "DRUM";
    InventoryMethod["EACH"] = "EACH";
    InventoryMethod["GROSS"] = "GROSS";
    InventoryMethod["JAR"] = "JAR";
    InventoryMethod["JOB"] = "JOB";
    InventoryMethod["KIT"] = "KIT";
    InventoryMethod["LOT"] = "LOT";
    InventoryMethod["PACKAGE"] = "PACKAGE";
    InventoryMethod["PAD"] = "PAD";
    InventoryMethod["PAGE"] = "PAGE";
    InventoryMethod["PAIL"] = "PAIL";
    InventoryMethod["PAIR"] = "PAIR";
    InventoryMethod["PER_THOUSAND"] = "PER_THOUSAND";
    InventoryMethod["PIECE"] = "PIECE";
    InventoryMethod["REAM"] = "REAM";
    InventoryMethod["REEL"] = "REEL";
    InventoryMethod["ROLL"] = "ROLL";
    InventoryMethod["SET"] = "SET";
    InventoryMethod["SHEET"] = "SHEET";
    InventoryMethod["SPOOL"] = "SPOOL";
    InventoryMethod["TUBE"] = "TUBE";
    InventoryMethod["UNIT"] = "UNIT";
    InventoryMethod["VIAL"] = "VIAL";
    InventoryMethod["ROD"] = "ROD";
    InventoryMethod["CONTAINER"] = "CONTAINER";
})(InventoryMethod || (InventoryMethod = {}));
/** Methods to use when ordering `InventoryQuota`. */
export var InventoryQuotasOrderBy;
(function (InventoryQuotasOrderBy) {
    InventoryQuotasOrderBy["NATURAL"] = "NATURAL";
    InventoryQuotasOrderBy["ID_ASC"] = "ID_ASC";
    InventoryQuotasOrderBy["ID_DESC"] = "ID_DESC";
    InventoryQuotasOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    InventoryQuotasOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    InventoryQuotasOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    InventoryQuotasOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    InventoryQuotasOrderBy["SIDE_ASC"] = "SIDE_ASC";
    InventoryQuotasOrderBy["SIDE_DESC"] = "SIDE_DESC";
    InventoryQuotasOrderBy["MIN_STOCK_ASC"] = "MIN_STOCK_ASC";
    InventoryQuotasOrderBy["MIN_STOCK_DESC"] = "MIN_STOCK_DESC";
    InventoryQuotasOrderBy["MAX_STOCK_ASC"] = "MAX_STOCK_ASC";
    InventoryQuotasOrderBy["MAX_STOCK_DESC"] = "MAX_STOCK_DESC";
    InventoryQuotasOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    InventoryQuotasOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    InventoryQuotasOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    InventoryQuotasOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    InventoryQuotasOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    InventoryQuotasOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    InventoryQuotasOrderBy["UPDATED_BY_USER_ID_ASC"] = "UPDATED_BY_USER_ID_ASC";
    InventoryQuotasOrderBy["UPDATED_BY_USER_ID_DESC"] = "UPDATED_BY_USER_ID_DESC";
    InventoryQuotasOrderBy["ITEM_CLASS_ASC"] = "ITEM_CLASS_ASC";
    InventoryQuotasOrderBy["ITEM_CLASS_DESC"] = "ITEM_CLASS_DESC";
    InventoryQuotasOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    InventoryQuotasOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(InventoryQuotasOrderBy || (InventoryQuotasOrderBy = {}));
export var InventoryStatus;
(function (InventoryStatus) {
    InventoryStatus["IN_INVENTORY"] = "IN_INVENTORY";
    InventoryStatus["ISSUED"] = "ISSUED";
    InventoryStatus["IN_TRANSIT"] = "IN_TRANSIT";
    InventoryStatus["ON_DOCK"] = "ON_DOCK";
    InventoryStatus["NEEDS_INSPECTION"] = "NEEDS_INSPECTION";
    InventoryStatus["IN_GRIEF"] = "IN_GRIEF";
    InventoryStatus["NEEDS_TRANSFER"] = "NEEDS_TRANSFER";
    InventoryStatus["REQUESTED"] = "REQUESTED";
})(InventoryStatus || (InventoryStatus = {}));
/** Methods to use when ordering `ItemDrawing`. */
export var ItemDrawingsOrderBy;
(function (ItemDrawingsOrderBy) {
    ItemDrawingsOrderBy["NATURAL"] = "NATURAL";
    ItemDrawingsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    ItemDrawingsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    ItemDrawingsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ItemDrawingsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ItemDrawingsOrderBy["DRAWING_DOCUMENT_ID_ASC"] = "DRAWING_DOCUMENT_ID_ASC";
    ItemDrawingsOrderBy["DRAWING_DOCUMENT_ID_DESC"] = "DRAWING_DOCUMENT_ID_DESC";
    ItemDrawingsOrderBy["SIZES_ASC"] = "SIZES_ASC";
    ItemDrawingsOrderBy["SIZES_DESC"] = "SIZES_DESC";
    ItemDrawingsOrderBy["ALIASES_ASC"] = "ALIASES_ASC";
    ItemDrawingsOrderBy["ALIASES_DESC"] = "ALIASES_DESC";
    ItemDrawingsOrderBy["EEE_IDENTIFIER_ASC"] = "EEE_IDENTIFIER_ASC";
    ItemDrawingsOrderBy["EEE_IDENTIFIER_DESC"] = "EEE_IDENTIFIER_DESC";
    ItemDrawingsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    ItemDrawingsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    ItemDrawingsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ItemDrawingsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ItemDrawingsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    ItemDrawingsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    ItemDrawingsOrderBy["MEAN_TIME_BETWEEN_FAILURE_ASC"] = "MEAN_TIME_BETWEEN_FAILURE_ASC";
    ItemDrawingsOrderBy["MEAN_TIME_BETWEEN_FAILURE_DESC"] = "MEAN_TIME_BETWEEN_FAILURE_DESC";
    ItemDrawingsOrderBy["MEAN_TIME_TO_REPAIR_ASC"] = "MEAN_TIME_TO_REPAIR_ASC";
    ItemDrawingsOrderBy["MEAN_TIME_TO_REPAIR_DESC"] = "MEAN_TIME_TO_REPAIR_DESC";
    ItemDrawingsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    ItemDrawingsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    ItemDrawingsOrderBy["IS_ARCHIVED_ASC"] = "IS_ARCHIVED_ASC";
    ItemDrawingsOrderBy["IS_ARCHIVED_DESC"] = "IS_ARCHIVED_DESC";
    ItemDrawingsOrderBy["POINT_OF_CONTACT_ID_ASC"] = "POINT_OF_CONTACT_ID_ASC";
    ItemDrawingsOrderBy["POINT_OF_CONTACT_ID_DESC"] = "POINT_OF_CONTACT_ID_DESC";
    ItemDrawingsOrderBy["INVENTORY_QUANTITY_ASC"] = "INVENTORY_QUANTITY_ASC";
    ItemDrawingsOrderBy["INVENTORY_QUANTITY_DESC"] = "INVENTORY_QUANTITY_DESC";
    ItemDrawingsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ItemDrawingsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ItemDrawingsOrderBy || (ItemDrawingsOrderBy = {}));
export var ItemInstanceReturnableStatus;
(function (ItemInstanceReturnableStatus) {
    ItemInstanceReturnableStatus["RETURNABLE_INVENTORY"] = "RETURNABLE_INVENTORY";
    ItemInstanceReturnableStatus["NON_RETURNABLE_INVENTORY"] = "NON_RETURNABLE_INVENTORY";
    ItemInstanceReturnableStatus["RETURNABLE_ITEM_INSTANCE"] = "RETURNABLE_ITEM_INSTANCE";
})(ItemInstanceReturnableStatus || (ItemInstanceReturnableStatus = {}));
/** Methods to use when ordering `ItemInstance`. */
export var ItemInstancesOrderBy;
(function (ItemInstancesOrderBy) {
    ItemInstancesOrderBy["NATURAL"] = "NATURAL";
    ItemInstancesOrderBy["ID_ASC"] = "ID_ASC";
    ItemInstancesOrderBy["ID_DESC"] = "ID_DESC";
    ItemInstancesOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    ItemInstancesOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    ItemInstancesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ItemInstancesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ItemInstancesOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    ItemInstancesOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    ItemInstancesOrderBy["SIDE_ASC"] = "SIDE_ASC";
    ItemInstancesOrderBy["SIDE_DESC"] = "SIDE_DESC";
    ItemInstancesOrderBy["AS_DESIGN_NUMBER_ASC"] = "AS_DESIGN_NUMBER_ASC";
    ItemInstancesOrderBy["AS_DESIGN_NUMBER_DESC"] = "AS_DESIGN_NUMBER_DESC";
    ItemInstancesOrderBy["SERIAL_NUMBER_ASC"] = "SERIAL_NUMBER_ASC";
    ItemInstancesOrderBy["SERIAL_NUMBER_DESC"] = "SERIAL_NUMBER_DESC";
    ItemInstancesOrderBy["LOT_NUMBER_ASC"] = "LOT_NUMBER_ASC";
    ItemInstancesOrderBy["LOT_NUMBER_DESC"] = "LOT_NUMBER_DESC";
    ItemInstancesOrderBy["SIZE_ASC"] = "SIZE_ASC";
    ItemInstancesOrderBy["SIZE_DESC"] = "SIZE_DESC";
    ItemInstancesOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    ItemInstancesOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    ItemInstancesOrderBy["ACQUISITION_DATE_ASC"] = "ACQUISITION_DATE_ASC";
    ItemInstancesOrderBy["ACQUISITION_DATE_DESC"] = "ACQUISITION_DATE_DESC";
    ItemInstancesOrderBy["BIRTH_DATE_ASC"] = "BIRTH_DATE_ASC";
    ItemInstancesOrderBy["BIRTH_DATE_DESC"] = "BIRTH_DATE_DESC";
    ItemInstancesOrderBy["OPERATIONAL_START_DATE_ASC"] = "OPERATIONAL_START_DATE_ASC";
    ItemInstancesOrderBy["OPERATIONAL_START_DATE_DESC"] = "OPERATIONAL_START_DATE_DESC";
    ItemInstancesOrderBy["MANUFACTURE_DATE_ASC"] = "MANUFACTURE_DATE_ASC";
    ItemInstancesOrderBy["MANUFACTURE_DATE_DESC"] = "MANUFACTURE_DATE_DESC";
    ItemInstancesOrderBy["CURE_DATE_ASC"] = "CURE_DATE_ASC";
    ItemInstancesOrderBy["CURE_DATE_DESC"] = "CURE_DATE_DESC";
    ItemInstancesOrderBy["PURCHASE_REQUEST_DATE_ASC"] = "PURCHASE_REQUEST_DATE_ASC";
    ItemInstancesOrderBy["PURCHASE_REQUEST_DATE_DESC"] = "PURCHASE_REQUEST_DATE_DESC";
    ItemInstancesOrderBy["OPEN_DATE_ASC"] = "OPEN_DATE_ASC";
    ItemInstancesOrderBy["OPEN_DATE_DESC"] = "OPEN_DATE_DESC";
    ItemInstancesOrderBy["CLOSE_DATE_ASC"] = "CLOSE_DATE_ASC";
    ItemInstancesOrderBy["CLOSE_DATE_DESC"] = "CLOSE_DATE_DESC";
    ItemInstancesOrderBy["PIA_DATE_ASC"] = "PIA_DATE_ASC";
    ItemInstancesOrderBy["PIA_DATE_DESC"] = "PIA_DATE_DESC";
    ItemInstancesOrderBy["PDA_DATE_ASC"] = "PDA_DATE_ASC";
    ItemInstancesOrderBy["PDA_DATE_DESC"] = "PDA_DATE_DESC";
    ItemInstancesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ItemInstancesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ItemInstancesOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    ItemInstancesOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    ItemInstancesOrderBy["EQUIPMENT_CONTROL_NUMBER_ASC"] = "EQUIPMENT_CONTROL_NUMBER_ASC";
    ItemInstancesOrderBy["EQUIPMENT_CONTROL_NUMBER_DESC"] = "EQUIPMENT_CONTROL_NUMBER_DESC";
    ItemInstancesOrderBy["SERIALIZED_FROM_ITEM_INSTANCE_ID_ASC"] = "SERIALIZED_FROM_ITEM_INSTANCE_ID_ASC";
    ItemInstancesOrderBy["SERIALIZED_FROM_ITEM_INSTANCE_ID_DESC"] = "SERIALIZED_FROM_ITEM_INSTANCE_ID_DESC";
    ItemInstancesOrderBy["OVERRIDE_HAZARDOUS_MATERIAL_TEXT_ASC"] = "OVERRIDE_HAZARDOUS_MATERIAL_TEXT_ASC";
    ItemInstancesOrderBy["OVERRIDE_HAZARDOUS_MATERIAL_TEXT_DESC"] = "OVERRIDE_HAZARDOUS_MATERIAL_TEXT_DESC";
    ItemInstancesOrderBy["INHERIT_LIMITED_LIFE_FROM_MASTER_ASC"] = "INHERIT_LIMITED_LIFE_FROM_MASTER_ASC";
    ItemInstancesOrderBy["INHERIT_LIMITED_LIFE_FROM_MASTER_DESC"] = "INHERIT_LIMITED_LIFE_FROM_MASTER_DESC";
    ItemInstancesOrderBy["OVERRIDE_SOFTWARE_VERSION_ASC"] = "OVERRIDE_SOFTWARE_VERSION_ASC";
    ItemInstancesOrderBy["OVERRIDE_SOFTWARE_VERSION_DESC"] = "OVERRIDE_SOFTWARE_VERSION_DESC";
    ItemInstancesOrderBy["OVERRIDE_MEAN_TIME_TO_REPAIR_ASC"] = "OVERRIDE_MEAN_TIME_TO_REPAIR_ASC";
    ItemInstancesOrderBy["OVERRIDE_MEAN_TIME_TO_REPAIR_DESC"] = "OVERRIDE_MEAN_TIME_TO_REPAIR_DESC";
    ItemInstancesOrderBy["IS_ARCHIVED_ASC"] = "IS_ARCHIVED_ASC";
    ItemInstancesOrderBy["IS_ARCHIVED_DESC"] = "IS_ARCHIVED_DESC";
    ItemInstancesOrderBy["GSE_ETCA_NOMENCLATURE_ASC"] = "GSE_ETCA_NOMENCLATURE_ASC";
    ItemInstancesOrderBy["GSE_ETCA_NOMENCLATURE_DESC"] = "GSE_ETCA_NOMENCLATURE_DESC";
    ItemInstancesOrderBy["GSE_GAUGE_TRACKING_COMMENTS_ASC"] = "GSE_GAUGE_TRACKING_COMMENTS_ASC";
    ItemInstancesOrderBy["GSE_GAUGE_TRACKING_COMMENTS_DESC"] = "GSE_GAUGE_TRACKING_COMMENTS_DESC";
    ItemInstancesOrderBy["GSE_SUB_TYPE_ASC"] = "GSE_SUB_TYPE_ASC";
    ItemInstancesOrderBy["GSE_SUB_TYPE_DESC"] = "GSE_SUB_TYPE_DESC";
    ItemInstancesOrderBy["GSE_TEST_STAND_NAME_ASC"] = "GSE_TEST_STAND_NAME_ASC";
    ItemInstancesOrderBy["GSE_TEST_STAND_NAME_DESC"] = "GSE_TEST_STAND_NAME_DESC";
    ItemInstancesOrderBy["GSE_TEST_STAND_NUMBER_ASC"] = "GSE_TEST_STAND_NUMBER_ASC";
    ItemInstancesOrderBy["GSE_TEST_STAND_NUMBER_DESC"] = "GSE_TEST_STAND_NUMBER_DESC";
    ItemInstancesOrderBy["PART_REFERENCE_ID_ASC"] = "PART_REFERENCE_ID_ASC";
    ItemInstancesOrderBy["PART_REFERENCE_ID_DESC"] = "PART_REFERENCE_ID_DESC";
    ItemInstancesOrderBy["EXTERNAL_ID_ASC"] = "EXTERNAL_ID_ASC";
    ItemInstancesOrderBy["EXTERNAL_ID_DESC"] = "EXTERNAL_ID_DESC";
    ItemInstancesOrderBy["GROUND_SUPPORT_EQUIPMENT_ASC"] = "GROUND_SUPPORT_EQUIPMENT_ASC";
    ItemInstancesOrderBy["GROUND_SUPPORT_EQUIPMENT_DESC"] = "GROUND_SUPPORT_EQUIPMENT_DESC";
    ItemInstancesOrderBy["COMPUTED_IS_APPROVED_AS_DESIGN_ASC"] = "COMPUTED_IS_APPROVED_AS_DESIGN_ASC";
    ItemInstancesOrderBy["COMPUTED_IS_APPROVED_AS_DESIGN_DESC"] = "COMPUTED_IS_APPROVED_AS_DESIGN_DESC";
    ItemInstancesOrderBy["COMPUTED_LAST_INSPECTION_DATE_ASC"] = "COMPUTED_LAST_INSPECTION_DATE_ASC";
    ItemInstancesOrderBy["COMPUTED_LAST_INSPECTION_DATE_DESC"] = "COMPUTED_LAST_INSPECTION_DATE_DESC";
    ItemInstancesOrderBy["COMPUTED_LAST_SERVICE_DATE_ASC"] = "COMPUTED_LAST_SERVICE_DATE_ASC";
    ItemInstancesOrderBy["COMPUTED_LAST_SERVICE_DATE_DESC"] = "COMPUTED_LAST_SERVICE_DATE_DESC";
    ItemInstancesOrderBy["COMPUTED_MANNED_PRESSURIZED_TIME_ASC"] = "COMPUTED_MANNED_PRESSURIZED_TIME_ASC";
    ItemInstancesOrderBy["COMPUTED_MANNED_PRESSURIZED_TIME_DESC"] = "COMPUTED_MANNED_PRESSURIZED_TIME_DESC";
    ItemInstancesOrderBy["COMPUTED_MANNED_PRESSURIZED_TIME_SINCE_LAST_SERV_ASC"] = "COMPUTED_MANNED_PRESSURIZED_TIME_SINCE_LAST_SERV_ASC";
    ItemInstancesOrderBy["COMPUTED_MANNED_PRESSURIZED_TIME_SINCE_LAST_SERV_DESC"] = "COMPUTED_MANNED_PRESSURIZED_TIME_SINCE_LAST_SERV_DESC";
    ItemInstancesOrderBy["COMPUTED_RETURNABLE_STATUS_ASC"] = "COMPUTED_RETURNABLE_STATUS_ASC";
    ItemInstancesOrderBy["COMPUTED_RETURNABLE_STATUS_DESC"] = "COMPUTED_RETURNABLE_STATUS_DESC";
    ItemInstancesOrderBy["COMPUTED_UNMANNED_PRESSURIZED_TIME_ASC"] = "COMPUTED_UNMANNED_PRESSURIZED_TIME_ASC";
    ItemInstancesOrderBy["COMPUTED_UNMANNED_PRESSURIZED_TIME_DESC"] = "COMPUTED_UNMANNED_PRESSURIZED_TIME_DESC";
    ItemInstancesOrderBy["COMPUTED_UNMANNED_PRESSURIZED_TIME_SINCE_LAST_SE_ASC"] = "COMPUTED_UNMANNED_PRESSURIZED_TIME_SINCE_LAST_SE_ASC";
    ItemInstancesOrderBy["COMPUTED_UNMANNED_PRESSURIZED_TIME_SINCE_LAST_SE_DESC"] = "COMPUTED_UNMANNED_PRESSURIZED_TIME_SINCE_LAST_SE_DESC";
    ItemInstancesOrderBy["INVENTORY_QUANTITY_ASC"] = "INVENTORY_QUANTITY_ASC";
    ItemInstancesOrderBy["INVENTORY_QUANTITY_DESC"] = "INVENTORY_QUANTITY_DESC";
    ItemInstancesOrderBy["ITEM_MASTER_SUB_TYPE_ASC"] = "ITEM_MASTER_SUB_TYPE_ASC";
    ItemInstancesOrderBy["ITEM_MASTER_SUB_TYPE_DESC"] = "ITEM_MASTER_SUB_TYPE_DESC";
    ItemInstancesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ItemInstancesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ItemInstancesOrderBy || (ItemInstancesOrderBy = {}));
export var ItemMasterCategoryCode;
(function (ItemMasterCategoryCode) {
    ItemMasterCategoryCode["AB"] = "AB";
    ItemMasterCategoryCode["BE"] = "BE";
    ItemMasterCategoryCode["CA"] = "CA";
    ItemMasterCategoryCode["CC"] = "CC";
    ItemMasterCategoryCode["CD"] = "CD";
    ItemMasterCategoryCode["CE"] = "CE";
    ItemMasterCategoryCode["CH"] = "CH";
    ItemMasterCategoryCode["CP"] = "CP";
    ItemMasterCategoryCode["CR"] = "CR";
    ItemMasterCategoryCode["CS"] = "CS";
    ItemMasterCategoryCode["DA"] = "DA";
    ItemMasterCategoryCode["DC"] = "DC";
    ItemMasterCategoryCode["DD"] = "DD";
    ItemMasterCategoryCode["DS"] = "DS";
    ItemMasterCategoryCode["DT"] = "DT";
    ItemMasterCategoryCode["EE"] = "EE";
    ItemMasterCategoryCode["EH"] = "EH";
    ItemMasterCategoryCode["EL"] = "EL";
    ItemMasterCategoryCode["ES"] = "ES";
    ItemMasterCategoryCode["FC"] = "FC";
    ItemMasterCategoryCode["FE"] = "FE";
    ItemMasterCategoryCode["FF"] = "FF";
    ItemMasterCategoryCode["FL"] = "FL";
    ItemMasterCategoryCode["FS"] = "FS";
    ItemMasterCategoryCode["GS"] = "GS";
    ItemMasterCategoryCode["HF"] = "HF";
    ItemMasterCategoryCode["JH"] = "JH";
    ItemMasterCategoryCode["JP"] = "JP";
    ItemMasterCategoryCode["JS"] = "JS";
    ItemMasterCategoryCode["LS"] = "LS";
    ItemMasterCategoryCode["LT"] = "LT";
    ItemMasterCategoryCode["MS"] = "MS";
    ItemMasterCategoryCode["NF"] = "NF";
    ItemMasterCategoryCode["NI"] = "NI";
    ItemMasterCategoryCode["NL"] = "NL";
    ItemMasterCategoryCode["OC"] = "OC";
    ItemMasterCategoryCode["PR"] = "PR";
    ItemMasterCategoryCode["PS"] = "PS";
    ItemMasterCategoryCode["PT"] = "PT";
    ItemMasterCategoryCode["RF"] = "RF";
    ItemMasterCategoryCode["RM"] = "RM";
    ItemMasterCategoryCode["SC"] = "SC";
    ItemMasterCategoryCode["SD"] = "SD";
    ItemMasterCategoryCode["SE"] = "SE";
    ItemMasterCategoryCode["SF"] = "SF";
    ItemMasterCategoryCode["SG"] = "SG";
    ItemMasterCategoryCode["SL"] = "SL";
    ItemMasterCategoryCode["SP"] = "SP";
    ItemMasterCategoryCode["SR"] = "SR";
    ItemMasterCategoryCode["SS"] = "SS";
    ItemMasterCategoryCode["ST"] = "ST";
    ItemMasterCategoryCode["SV"] = "SV";
    ItemMasterCategoryCode["SW"] = "SW";
    ItemMasterCategoryCode["TA"] = "TA";
    ItemMasterCategoryCode["TB"] = "TB";
    ItemMasterCategoryCode["TR"] = "TR";
    ItemMasterCategoryCode["TS"] = "TS";
    ItemMasterCategoryCode["TX"] = "TX";
    ItemMasterCategoryCode["UU"] = "UU";
    ItemMasterCategoryCode["WH"] = "WH";
    ItemMasterCategoryCode["WW"] = "WW";
    ItemMasterCategoryCode["ZA"] = "ZA";
    ItemMasterCategoryCode["ZB"] = "ZB";
    ItemMasterCategoryCode["ZU"] = "ZU";
    ItemMasterCategoryCode["ZZ"] = "ZZ";
    ItemMasterCategoryCode["XE"] = "XE";
})(ItemMasterCategoryCode || (ItemMasterCategoryCode = {}));
export var ItemMasterLimitedLifeCode;
(function (ItemMasterLimitedLifeCode) {
    ItemMasterLimitedLifeCode["CYC"] = "CYC";
    ItemMasterLimitedLifeCode["BOT"] = "BOT";
    ItemMasterLimitedLifeCode["CCC"] = "CCC";
    ItemMasterLimitedLifeCode["REG"] = "REG";
    ItemMasterLimitedLifeCode["SSA"] = "SSA";
    ItemMasterLimitedLifeCode["BAT"] = "BAT";
    ItemMasterLimitedLifeCode["FAN"] = "FAN";
    ItemMasterLimitedLifeCode["CAL"] = "CAL";
})(ItemMasterLimitedLifeCode || (ItemMasterLimitedLifeCode = {}));
/** Methods to use when ordering `ItemMastersAlternative`. */
export var ItemMastersAlternativesOrderBy;
(function (ItemMastersAlternativesOrderBy) {
    ItemMastersAlternativesOrderBy["NATURAL"] = "NATURAL";
    ItemMastersAlternativesOrderBy["ID_ASC"] = "ID_ASC";
    ItemMastersAlternativesOrderBy["ID_DESC"] = "ID_DESC";
    ItemMastersAlternativesOrderBy["PARENT_DRAWING_NUMBER_ASC"] = "PARENT_DRAWING_NUMBER_ASC";
    ItemMastersAlternativesOrderBy["PARENT_DRAWING_NUMBER_DESC"] = "PARENT_DRAWING_NUMBER_DESC";
    ItemMastersAlternativesOrderBy["PARENT_AS_BUILT_NUMBER_ASC"] = "PARENT_AS_BUILT_NUMBER_ASC";
    ItemMastersAlternativesOrderBy["PARENT_AS_BUILT_NUMBER_DESC"] = "PARENT_AS_BUILT_NUMBER_DESC";
    ItemMastersAlternativesOrderBy["PARENT_SIDE_ASC"] = "PARENT_SIDE_ASC";
    ItemMastersAlternativesOrderBy["PARENT_SIDE_DESC"] = "PARENT_SIDE_DESC";
    ItemMastersAlternativesOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    ItemMastersAlternativesOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    ItemMastersAlternativesOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    ItemMastersAlternativesOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    ItemMastersAlternativesOrderBy["SIDE_ASC"] = "SIDE_ASC";
    ItemMastersAlternativesOrderBy["SIDE_DESC"] = "SIDE_DESC";
    ItemMastersAlternativesOrderBy["IS_FOR_TRAINING_ASC"] = "IS_FOR_TRAINING_ASC";
    ItemMastersAlternativesOrderBy["IS_FOR_TRAINING_DESC"] = "IS_FOR_TRAINING_DESC";
    ItemMastersAlternativesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ItemMastersAlternativesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ItemMastersAlternativesOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    ItemMastersAlternativesOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    ItemMastersAlternativesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ItemMastersAlternativesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ItemMastersAlternativesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ItemMastersAlternativesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ItemMastersAlternativesOrderBy || (ItemMastersAlternativesOrderBy = {}));
/** Methods to use when ordering `ItemMaster`. */
export var ItemMastersOrderBy;
(function (ItemMastersOrderBy) {
    ItemMastersOrderBy["NATURAL"] = "NATURAL";
    ItemMastersOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    ItemMastersOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    ItemMastersOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    ItemMastersOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    ItemMastersOrderBy["SIDE_ASC"] = "SIDE_ASC";
    ItemMastersOrderBy["SIDE_DESC"] = "SIDE_DESC";
    ItemMastersOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ItemMastersOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ItemMastersOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    ItemMastersOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    ItemMastersOrderBy["SIZES_ASC"] = "SIZES_ASC";
    ItemMastersOrderBy["SIZES_DESC"] = "SIZES_DESC";
    ItemMastersOrderBy["INITIALIZATION_CODE_ASC"] = "INITIALIZATION_CODE_ASC";
    ItemMastersOrderBy["INITIALIZATION_CODE_DESC"] = "INITIALIZATION_CODE_DESC";
    ItemMastersOrderBy["INITIALIZATION_AUTHORITY_NUMBER_ASC"] = "INITIALIZATION_AUTHORITY_NUMBER_ASC";
    ItemMastersOrderBy["INITIALIZATION_AUTHORITY_NUMBER_DESC"] = "INITIALIZATION_AUTHORITY_NUMBER_DESC";
    ItemMastersOrderBy["CONTRACT_ENDING_ITEM_NUMBER_ASC"] = "CONTRACT_ENDING_ITEM_NUMBER_ASC";
    ItemMastersOrderBy["CONTRACT_ENDING_ITEM_NUMBER_DESC"] = "CONTRACT_ENDING_ITEM_NUMBER_DESC";
    ItemMastersOrderBy["OPS_NOM_ASC"] = "OPS_NOM_ASC";
    ItemMastersOrderBy["OPS_NOM_DESC"] = "OPS_NOM_DESC";
    ItemMastersOrderBy["IS_SPECIAL_HANDLING_ASC"] = "IS_SPECIAL_HANDLING_ASC";
    ItemMastersOrderBy["IS_SPECIAL_HANDLING_DESC"] = "IS_SPECIAL_HANDLING_DESC";
    ItemMastersOrderBy["SPECIAL_HANDLING_TEXT_ASC"] = "SPECIAL_HANDLING_TEXT_ASC";
    ItemMastersOrderBy["SPECIAL_HANDLING_TEXT_DESC"] = "SPECIAL_HANDLING_TEXT_DESC";
    ItemMastersOrderBy["REFERENCE_DESIGNATOR_ASC"] = "REFERENCE_DESIGNATOR_ASC";
    ItemMastersOrderBy["REFERENCE_DESIGNATOR_DESC"] = "REFERENCE_DESIGNATOR_DESC";
    ItemMastersOrderBy["CATEGORY_CODE_ASC"] = "CATEGORY_CODE_ASC";
    ItemMastersOrderBy["CATEGORY_CODE_DESC"] = "CATEGORY_CODE_DESC";
    ItemMastersOrderBy["GREEN_PROCUREMENT_CODE_ASC"] = "GREEN_PROCUREMENT_CODE_ASC";
    ItemMastersOrderBy["GREEN_PROCUREMENT_CODE_DESC"] = "GREEN_PROCUREMENT_CODE_DESC";
    ItemMastersOrderBy["PRECIOUS_METAL_CODE_ASC"] = "PRECIOUS_METAL_CODE_ASC";
    ItemMastersOrderBy["PRECIOUS_METAL_CODE_DESC"] = "PRECIOUS_METAL_CODE_DESC";
    ItemMastersOrderBy["IS_APPROVED_DATA_PACK_ASC"] = "IS_APPROVED_DATA_PACK_ASC";
    ItemMastersOrderBy["IS_APPROVED_DATA_PACK_DESC"] = "IS_APPROVED_DATA_PACK_DESC";
    ItemMastersOrderBy["IS_CLEANLINESS_TRACKING_ASC"] = "IS_CLEANLINESS_TRACKING_ASC";
    ItemMastersOrderBy["IS_CLEANLINESS_TRACKING_DESC"] = "IS_CLEANLINESS_TRACKING_DESC";
    ItemMastersOrderBy["IS_FRACTURE_CRITICAL_ASC"] = "IS_FRACTURE_CRITICAL_ASC";
    ItemMastersOrderBy["IS_FRACTURE_CRITICAL_DESC"] = "IS_FRACTURE_CRITICAL_DESC";
    ItemMastersOrderBy["IS_HAZARDOUS_MATERIAL_ASC"] = "IS_HAZARDOUS_MATERIAL_ASC";
    ItemMastersOrderBy["IS_HAZARDOUS_MATERIAL_DESC"] = "IS_HAZARDOUS_MATERIAL_DESC";
    ItemMastersOrderBy["IS_MANIFESTABLE_ASC"] = "IS_MANIFESTABLE_ASC";
    ItemMastersOrderBy["IS_MANIFESTABLE_DESC"] = "IS_MANIFESTABLE_DESC";
    ItemMastersOrderBy["IS_PHANTOM_ASC"] = "IS_PHANTOM_ASC";
    ItemMastersOrderBy["IS_PHANTOM_DESC"] = "IS_PHANTOM_DESC";
    ItemMastersOrderBy["IS_TOOL_ASC"] = "IS_TOOL_ASC";
    ItemMastersOrderBy["IS_TOOL_DESC"] = "IS_TOOL_DESC";
    ItemMastersOrderBy["IS_REFURBISHABLE_ASC"] = "IS_REFURBISHABLE_ASC";
    ItemMastersOrderBy["IS_REFURBISHABLE_DESC"] = "IS_REFURBISHABLE_DESC";
    ItemMastersOrderBy["CRITICALITY_CODE_ASC"] = "CRITICALITY_CODE_ASC";
    ItemMastersOrderBy["CRITICALITY_CODE_DESC"] = "CRITICALITY_CODE_DESC";
    ItemMastersOrderBy["FEDERAL_SUPPLY_CLASS_ASC"] = "FEDERAL_SUPPLY_CLASS_ASC";
    ItemMastersOrderBy["FEDERAL_SUPPLY_CLASS_DESC"] = "FEDERAL_SUPPLY_CLASS_DESC";
    ItemMastersOrderBy["OBJECT_CLASS_ASC"] = "OBJECT_CLASS_ASC";
    ItemMastersOrderBy["OBJECT_CLASS_DESC"] = "OBJECT_CLASS_DESC";
    ItemMastersOrderBy["PROPERTY_CLASS_ASC"] = "PROPERTY_CLASS_ASC";
    ItemMastersOrderBy["PROPERTY_CLASS_DESC"] = "PROPERTY_CLASS_DESC";
    ItemMastersOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    ItemMastersOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    ItemMastersOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ItemMastersOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    ItemMastersOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    ItemMastersOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    ItemMastersOrderBy["INVENTORY_DISABLE_ALERTS_ASC"] = "INVENTORY_DISABLE_ALERTS_ASC";
    ItemMastersOrderBy["INVENTORY_DISABLE_ALERTS_DESC"] = "INVENTORY_DISABLE_ALERTS_DESC";
    ItemMastersOrderBy["INVENTORY_CONTROL_ASC"] = "INVENTORY_CONTROL_ASC";
    ItemMastersOrderBy["INVENTORY_CONTROL_DESC"] = "INVENTORY_CONTROL_DESC";
    ItemMastersOrderBy["INVENTORY_METHOD_ASC"] = "INVENTORY_METHOD_ASC";
    ItemMastersOrderBy["INVENTORY_METHOD_DESC"] = "INVENTORY_METHOD_DESC";
    ItemMastersOrderBy["INVENTORY_UNIT_TYPE_ASC"] = "INVENTORY_UNIT_TYPE_ASC";
    ItemMastersOrderBy["INVENTORY_UNIT_TYPE_DESC"] = "INVENTORY_UNIT_TYPE_DESC";
    ItemMastersOrderBy["CLEANLINESS_TRACKING_TEXT_ASC"] = "CLEANLINESS_TRACKING_TEXT_ASC";
    ItemMastersOrderBy["CLEANLINESS_TRACKING_TEXT_DESC"] = "CLEANLINESS_TRACKING_TEXT_DESC";
    ItemMastersOrderBy["HAZARDOUS_MATERIAL_TEXT_ASC"] = "HAZARDOUS_MATERIAL_TEXT_ASC";
    ItemMastersOrderBy["HAZARDOUS_MATERIAL_TEXT_DESC"] = "HAZARDOUS_MATERIAL_TEXT_DESC";
    ItemMastersOrderBy["IS_SPECIAL_PACKAGING_ASC"] = "IS_SPECIAL_PACKAGING_ASC";
    ItemMastersOrderBy["IS_SPECIAL_PACKAGING_DESC"] = "IS_SPECIAL_PACKAGING_DESC";
    ItemMastersOrderBy["SPECIAL_PACKAGING_TEXT_ASC"] = "SPECIAL_PACKAGING_TEXT_ASC";
    ItemMastersOrderBy["SPECIAL_PACKAGING_TEXT_DESC"] = "SPECIAL_PACKAGING_TEXT_DESC";
    ItemMastersOrderBy["COMPONENT_ID_ASC"] = "COMPONENT_ID_ASC";
    ItemMastersOrderBy["COMPONENT_ID_DESC"] = "COMPONENT_ID_DESC";
    ItemMastersOrderBy["CONTRACT_ENDING_ITEM_LEVEL_ASC"] = "CONTRACT_ENDING_ITEM_LEVEL_ASC";
    ItemMastersOrderBy["CONTRACT_ENDING_ITEM_LEVEL_DESC"] = "CONTRACT_ENDING_ITEM_LEVEL_DESC";
    ItemMastersOrderBy["HAS_SOFTWARE_VERSION_ASC"] = "HAS_SOFTWARE_VERSION_ASC";
    ItemMastersOrderBy["HAS_SOFTWARE_VERSION_DESC"] = "HAS_SOFTWARE_VERSION_DESC";
    ItemMastersOrderBy["SOFTWARE_VERSION_ASC"] = "SOFTWARE_VERSION_ASC";
    ItemMastersOrderBy["SOFTWARE_VERSION_DESC"] = "SOFTWARE_VERSION_DESC";
    ItemMastersOrderBy["IS_APPROVED_AS_DESIGN_ASC"] = "IS_APPROVED_AS_DESIGN_ASC";
    ItemMastersOrderBy["IS_APPROVED_AS_DESIGN_DESC"] = "IS_APPROVED_AS_DESIGN_DESC";
    ItemMastersOrderBy["IS_ARCHIVED_ASC"] = "IS_ARCHIVED_ASC";
    ItemMastersOrderBy["IS_ARCHIVED_DESC"] = "IS_ARCHIVED_DESC";
    ItemMastersOrderBy["DISABLE_QUOTA_ALERTS_ASC"] = "DISABLE_QUOTA_ALERTS_ASC";
    ItemMastersOrderBy["DISABLE_QUOTA_ALERTS_DESC"] = "DISABLE_QUOTA_ALERTS_DESC";
    ItemMastersOrderBy["GSE_ETCA_NOMENCLATURE_ASC"] = "GSE_ETCA_NOMENCLATURE_ASC";
    ItemMastersOrderBy["GSE_ETCA_NOMENCLATURE_DESC"] = "GSE_ETCA_NOMENCLATURE_DESC";
    ItemMastersOrderBy["GSE_GAUGE_TRACKING_COMMENTS_ASC"] = "GSE_GAUGE_TRACKING_COMMENTS_ASC";
    ItemMastersOrderBy["GSE_GAUGE_TRACKING_COMMENTS_DESC"] = "GSE_GAUGE_TRACKING_COMMENTS_DESC";
    ItemMastersOrderBy["GSE_SUB_TYPE_ASC"] = "GSE_SUB_TYPE_ASC";
    ItemMastersOrderBy["GSE_SUB_TYPE_DESC"] = "GSE_SUB_TYPE_DESC";
    ItemMastersOrderBy["GSE_TEST_STAND_NAME_ASC"] = "GSE_TEST_STAND_NAME_ASC";
    ItemMastersOrderBy["GSE_TEST_STAND_NAME_DESC"] = "GSE_TEST_STAND_NAME_DESC";
    ItemMastersOrderBy["GSE_TEST_STAND_NUMBER_ASC"] = "GSE_TEST_STAND_NUMBER_ASC";
    ItemMastersOrderBy["GSE_TEST_STAND_NUMBER_DESC"] = "GSE_TEST_STAND_NUMBER_DESC";
    ItemMastersOrderBy["PLANNER_ASC"] = "PLANNER_ASC";
    ItemMastersOrderBy["PLANNER_DESC"] = "PLANNER_DESC";
    ItemMastersOrderBy["PLANNING_BUYER_ASC"] = "PLANNING_BUYER_ASC";
    ItemMastersOrderBy["PLANNING_BUYER_DESC"] = "PLANNING_BUYER_DESC";
    ItemMastersOrderBy["PLANNING_LEAD_TIME_ASC"] = "PLANNING_LEAD_TIME_ASC";
    ItemMastersOrderBy["PLANNING_LEAD_TIME_DESC"] = "PLANNING_LEAD_TIME_DESC";
    ItemMastersOrderBy["PLANNING_MAKE_BUY_SUPPLY_ASC"] = "PLANNING_MAKE_BUY_SUPPLY_ASC";
    ItemMastersOrderBy["PLANNING_MAKE_BUY_SUPPLY_DESC"] = "PLANNING_MAKE_BUY_SUPPLY_DESC";
    ItemMastersOrderBy["PLANNING_MIN_ORDER_ASC"] = "PLANNING_MIN_ORDER_ASC";
    ItemMastersOrderBy["PLANNING_MIN_ORDER_DESC"] = "PLANNING_MIN_ORDER_DESC";
    ItemMastersOrderBy["PLANNING_ORDER_POLICY_ASC"] = "PLANNING_ORDER_POLICY_ASC";
    ItemMastersOrderBy["PLANNING_ORDER_POLICY_DESC"] = "PLANNING_ORDER_POLICY_DESC";
    ItemMastersOrderBy["PLANNING_SOLE_SOURCE_ASC"] = "PLANNING_SOLE_SOURCE_ASC";
    ItemMastersOrderBy["PLANNING_SOLE_SOURCE_DESC"] = "PLANNING_SOLE_SOURCE_DESC";
    ItemMastersOrderBy["PURCHASE_LEAD_TIME_ASC"] = "PURCHASE_LEAD_TIME_ASC";
    ItemMastersOrderBy["PURCHASE_LEAD_TIME_DESC"] = "PURCHASE_LEAD_TIME_DESC";
    ItemMastersOrderBy["RECEIVING_LEAD_TIME_ASC"] = "RECEIVING_LEAD_TIME_ASC";
    ItemMastersOrderBy["RECEIVING_LEAD_TIME_DESC"] = "RECEIVING_LEAD_TIME_DESC";
    ItemMastersOrderBy["PLANNING_ASC"] = "PLANNING_ASC";
    ItemMastersOrderBy["PLANNING_DESC"] = "PLANNING_DESC";
    ItemMastersOrderBy["GROUND_SUPPORT_EQUIPMENT_ASC"] = "GROUND_SUPPORT_EQUIPMENT_ASC";
    ItemMastersOrderBy["GROUND_SUPPORT_EQUIPMENT_DESC"] = "GROUND_SUPPORT_EQUIPMENT_DESC";
    ItemMastersOrderBy["AVAILABLE_QUANTITY_ASC"] = "AVAILABLE_QUANTITY_ASC";
    ItemMastersOrderBy["AVAILABLE_QUANTITY_DESC"] = "AVAILABLE_QUANTITY_DESC";
    ItemMastersOrderBy["INSTALLED_QUANTITY_ASC"] = "INSTALLED_QUANTITY_ASC";
    ItemMastersOrderBy["INSTALLED_QUANTITY_DESC"] = "INSTALLED_QUANTITY_DESC";
    ItemMastersOrderBy["INVENTORY_QUANTITY_ASC"] = "INVENTORY_QUANTITY_ASC";
    ItemMastersOrderBy["INVENTORY_QUANTITY_DESC"] = "INVENTORY_QUANTITY_DESC";
    ItemMastersOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    ItemMastersOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(ItemMastersOrderBy || (ItemMastersOrderBy = {}));
export var ItemMasterType;
(function (ItemMasterType) {
    ItemMasterType["ANCILLARY_EQUIPMENT"] = "ANCILLARY_EQUIPMENT";
    ItemMasterType["BATTERY"] = "BATTERY";
    ItemMasterType["CAPITAL_PROPERTY"] = "CAPITAL_PROPERTY";
    ItemMasterType["COMMUNICATIONS_EQUIPMENT"] = "COMMUNICATIONS_EQUIPMENT";
    ItemMasterType["CREW_EQUIPMENT_STOWAGE_PROV"] = "CREW_EQUIPMENT_STOWAGE_PROV";
    ItemMasterType["CREW_ESCAPE_SYSTEM"] = "CREW_ESCAPE_SYSTEM";
    ItemMasterType["CTSD"] = "CTSD";
    ItemMasterType["DECALS_PLACARDS_TAGS_PATCHES"] = "DECALS_PLACARDS_TAGS_PATCHES";
    ItemMasterType["DISPLAY_AND_CONTROL_EQUIPMENT"] = "DISPLAY_AND_CONTROL_EQUIPMENT";
    ItemMasterType["EMU_LSS_FLIGHT_SSA_NBL"] = "EMU_LSS_FLIGHT_SSA_NBL";
    ItemMasterType["EVA_SUPPORT_EQUIPMENT"] = "EVA_SUPPORT_EQUIPMENT";
    ItemMasterType["EVA_TOOLS"] = "EVA_TOOLS";
    ItemMasterType["FACILITIES_CONTRACT"] = "FACILITIES_CONTRACT";
    ItemMasterType["FILM"] = "FILM";
    ItemMasterType["FOOD_AND_MEDICAL_SYSTEMS"] = "FOOD_AND_MEDICAL_SYSTEMS";
    ItemMasterType["GENERAL_COMPUTER_SUPPLIES"] = "GENERAL_COMPUTER_SUPPLIES";
    ItemMasterType["GROUND_SUPPORT_EQUIPMENT"] = "GROUND_SUPPORT_EQUIPMENT";
    ItemMasterType["IDWA"] = "IDWA";
    ItemMasterType["ISIL_IDWA"] = "ISIL_IDWA";
    ItemMasterType["ISS_IDWA"] = "ISS_IDWA";
    ItemMasterType["ISS_M_AND_O_HARDWARE_PBA"] = "ISS_M_AND_O_HARDWARE_PBA";
    ItemMasterType["ISS_WORK_THROUGH_FCE"] = "ISS_WORK_THROUGH_FCE";
    ItemMasterType["MANNED_MANEUVERING_UNIT"] = "MANNED_MANEUVERING_UNIT";
    ItemMasterType["MULTIPLE_EQ_GROUPS_FLIGHT"] = "MULTIPLE_EQ_GROUPS_FLIGHT";
    ItemMasterType["MULTIPLE_EQ_GROUPS_PSE"] = "MULTIPLE_EQ_GROUPS_PSE";
    ItemMasterType["NON_FCE"] = "NON_FCE";
    ItemMasterType["OBS"] = "OBS";
    ItemMasterType["OH_HOUSTON_OPS_JANITOR_SUPP"] = "OH_HOUSTON_OPS_JANITOR_SUPP";
    ItemMasterType["OH_HOUSTON_OPS_OFFICE_SUPP"] = "OH_HOUSTON_OPS_OFFICE_SUPP";
    ItemMasterType["OH_HOUSTON_OPS_REPROD_SUPP"] = "OH_HOUSTON_OPS_REPROD_SUPP";
    ItemMasterType["OH_SR_QA_ONLY"] = "OH_SR_QA_ONLY";
    ItemMasterType["OTHER_STS_SUPPORT_EQUIPMENT"] = "OTHER_STS_SUPPORT_EQUIPMENT";
    ItemMasterType["PGSC_PCS"] = "PGSC_PCS";
    ItemMasterType["PHOTOGRAPHIC_EQUIPMENT"] = "PHOTOGRAPHIC_EQUIPMENT";
    ItemMasterType["PREPACK_BENCH_REVIEW"] = "PREPACK_BENCH_REVIEW";
    ItemMasterType["SAFER"] = "SAFER";
    ItemMasterType["SHARED_CONSUMABLES"] = "SHARED_CONSUMABLES";
    ItemMasterType["SPECIAL_ORDER"] = "SPECIAL_ORDER";
    ItemMasterType["VEHICLE_ACCOMMODATIONS"] = "VEHICLE_ACCOMMODATIONS";
    ItemMasterType["XINFO"] = "XINFO";
    ItemMasterType["XPGS"] = "XPGS";
    ItemMasterType["XPLSS"] = "XPLSS";
    ItemMasterType["XFSE"] = "XFSE";
    ItemMasterType["XEMU"] = "XEMU";
    ItemMasterType["ELECTRICAL"] = "ELECTRICAL";
    ItemMasterType["MECHANICAL"] = "MECHANICAL";
})(ItemMasterType || (ItemMasterType = {}));
/** Methods to use when ordering `Jf288Contract`. */
export var Jf288ContractsOrderBy;
(function (Jf288ContractsOrderBy) {
    Jf288ContractsOrderBy["NATURAL"] = "NATURAL";
    Jf288ContractsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    Jf288ContractsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    Jf288ContractsOrderBy["PRIME_CONTRACTOR_ORGANIZATION_CODE_ASC"] = "PRIME_CONTRACTOR_ORGANIZATION_CODE_ASC";
    Jf288ContractsOrderBy["PRIME_CONTRACTOR_ORGANIZATION_CODE_DESC"] = "PRIME_CONTRACTOR_ORGANIZATION_CODE_DESC";
    Jf288ContractsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    Jf288ContractsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    Jf288ContractsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    Jf288ContractsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    Jf288ContractsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    Jf288ContractsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(Jf288ContractsOrderBy || (Jf288ContractsOrderBy = {}));
/** Methods to use when ordering `Jf288Detail`. */
export var Jf288DetailsOrderBy;
(function (Jf288DetailsOrderBy) {
    Jf288DetailsOrderBy["NATURAL"] = "NATURAL";
    Jf288DetailsOrderBy["ID_ASC"] = "ID_ASC";
    Jf288DetailsOrderBy["ID_DESC"] = "ID_DESC";
    Jf288DetailsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    Jf288DetailsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    Jf288DetailsOrderBy["CONTRACTOR_ORGANIZATION_CODE_ASC"] = "CONTRACTOR_ORGANIZATION_CODE_ASC";
    Jf288DetailsOrderBy["CONTRACTOR_ORGANIZATION_CODE_DESC"] = "CONTRACTOR_ORGANIZATION_CODE_DESC";
    Jf288DetailsOrderBy["DETAILS_YEAR_ASC"] = "DETAILS_YEAR_ASC";
    Jf288DetailsOrderBy["DETAILS_YEAR_DESC"] = "DETAILS_YEAR_DESC";
    Jf288DetailsOrderBy["DETAILS_MONTH_ASC"] = "DETAILS_MONTH_ASC";
    Jf288DetailsOrderBy["DETAILS_MONTH_DESC"] = "DETAILS_MONTH_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_EMPLOYEES_ON_NASA_PROPERTY_ASC"] = "NUMBER_OF_EMPLOYEES_ON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_EMPLOYEES_ON_NASA_PROPERTY_DESC"] = "NUMBER_OF_EMPLOYEES_ON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_EMPLOYEES_ON_NON_NASA_PROPERTY_ASC"] = "NUMBER_OF_EMPLOYEES_ON_NON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_EMPLOYEES_ON_NON_NASA_PROPERTY_DESC"] = "NUMBER_OF_EMPLOYEES_ON_NON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["MAN_HOURS_WORKED_ON_NASA_PROPERTY_ASC"] = "MAN_HOURS_WORKED_ON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["MAN_HOURS_WORKED_ON_NASA_PROPERTY_DESC"] = "MAN_HOURS_WORKED_ON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["MAN_HOURS_WORKED_ON_NON_NASA_PROPERTY_ASC"] = "MAN_HOURS_WORKED_ON_NON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["MAN_HOURS_WORKED_ON_NON_NASA_PROPERTY_DESC"] = "MAN_HOURS_WORKED_ON_NON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_WITH_DAYS_AWAY_ON_NASA_PROPERTY_ASC"] = "NUMBER_OF_NEW_CASES_WITH_DAYS_AWAY_ON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_WITH_DAYS_AWAY_ON_NASA_PROPERTY_DESC"] = "NUMBER_OF_NEW_CASES_WITH_DAYS_AWAY_ON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_WITH_DAYS_AWAY_ON_NON_NASA_PROPERTY_ASC"] = "NUMBER_OF_NEW_CASES_WITH_DAYS_AWAY_ON_NON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_WITH_DAYS_AWAY_ON_NON_NASA_PROPERTY_DESC"] = "NUMBER_OF_NEW_CASES_WITH_DAYS_AWAY_ON_NON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NEW_CASES_RESTRICTED_DUTY_OR_JOB_TRANSFER_NASA_PROPERTY_ASC"] = "NEW_CASES_RESTRICTED_DUTY_OR_JOB_TRANSFER_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NEW_CASES_RESTRICTED_DUTY_OR_JOB_TRANSFER_NASA_PROPERTY_DESC"] = "NEW_CASES_RESTRICTED_DUTY_OR_JOB_TRANSFER_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NEW_CASES_RESTRICTED_DUTY_OR_JOB_TRANSFER_NON_NASA_PROPERTY_ASC"] = "NEW_CASES_RESTRICTED_DUTY_OR_JOB_TRANSFER_NON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NEW_CASES_RESTRICTED_DUTY_OR_JOB_TRANSFER_NON_NASA_PROPERTY_DESC"] = "NEW_CASES_RESTRICTED_DUTY_OR_JOB_TRANSFER_NON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_CASES_MEDICAL_TREATMENT_ON_NASA_PROPERTY_ASC"] = "NUMBER_OF_CASES_MEDICAL_TREATMENT_ON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_CASES_MEDICAL_TREATMENT_ON_NASA_PROPERTY_DESC"] = "NUMBER_OF_CASES_MEDICAL_TREATMENT_ON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_CASES_MEDICAL_TREATMENT_ON_NON_NASA_PROPERTY_ASC"] = "NUMBER_OF_CASES_MEDICAL_TREATMENT_ON_NON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_CASES_MEDICAL_TREATMENT_ON_NON_NASA_PROPERTY_DESC"] = "NUMBER_OF_CASES_MEDICAL_TREATMENT_ON_NON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_FIRST_AID_ON_NASA_PROPERTY_ASC"] = "NUMBER_OF_NEW_CASES_FIRST_AID_ON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_FIRST_AID_ON_NASA_PROPERTY_DESC"] = "NUMBER_OF_NEW_CASES_FIRST_AID_ON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_FIRST_AID_ON_NON_NASA_PROPERTY_ASC"] = "NUMBER_OF_NEW_CASES_FIRST_AID_ON_NON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_FIRST_AID_ON_NON_NASA_PROPERTY_DESC"] = "NUMBER_OF_NEW_CASES_FIRST_AID_ON_NON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_ILLNESS_ON_NASA_PROPERTY_ASC"] = "NUMBER_OF_NEW_CASES_ILLNESS_ON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_ILLNESS_ON_NASA_PROPERTY_DESC"] = "NUMBER_OF_NEW_CASES_ILLNESS_ON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_ILLNESS_ON_NON_NASA_PROPERTY_ASC"] = "NUMBER_OF_NEW_CASES_ILLNESS_ON_NON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_NEW_CASES_ILLNESS_ON_NON_NASA_PROPERTY_DESC"] = "NUMBER_OF_NEW_CASES_ILLNESS_ON_NON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["TOTAL_DAYS_AWAY_FROM_WORK_ON_NASA_PROPERTY_ASC"] = "TOTAL_DAYS_AWAY_FROM_WORK_ON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["TOTAL_DAYS_AWAY_FROM_WORK_ON_NASA_PROPERTY_DESC"] = "TOTAL_DAYS_AWAY_FROM_WORK_ON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["TOTAL_DAYS_AWAY_FROM_WORK_ON_NON_NASA_PROPERTY_ASC"] = "TOTAL_DAYS_AWAY_FROM_WORK_ON_NON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["TOTAL_DAYS_AWAY_FROM_WORK_ON_NON_NASA_PROPERTY_DESC"] = "TOTAL_DAYS_AWAY_FROM_WORK_ON_NON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["TOTAL_DAYS_RESTRICTED_DUTY_ON_NASA_PROPERTY_ASC"] = "TOTAL_DAYS_RESTRICTED_DUTY_ON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["TOTAL_DAYS_RESTRICTED_DUTY_ON_NASA_PROPERTY_DESC"] = "TOTAL_DAYS_RESTRICTED_DUTY_ON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["TOTAL_DAYS_RESTRICTED_DUTY_ON_NON_NASA_PROPERTY_ASC"] = "TOTAL_DAYS_RESTRICTED_DUTY_ON_NON_NASA_PROPERTY_ASC";
    Jf288DetailsOrderBy["TOTAL_DAYS_RESTRICTED_DUTY_ON_NON_NASA_PROPERTY_DESC"] = "TOTAL_DAYS_RESTRICTED_DUTY_ON_NON_NASA_PROPERTY_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_VEHICLES_DAMAGED_ASC"] = "NUMBER_OF_VEHICLES_DAMAGED_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_VEHICLES_DAMAGED_DESC"] = "NUMBER_OF_VEHICLES_DAMAGED_DESC";
    Jf288DetailsOrderBy["COST_OF_VEHICLE_DAMAGE_ASC"] = "COST_OF_VEHICLE_DAMAGE_ASC";
    Jf288DetailsOrderBy["COST_OF_VEHICLE_DAMAGE_DESC"] = "COST_OF_VEHICLE_DAMAGE_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_FIRES_ASC"] = "NUMBER_OF_FIRES_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_FIRES_DESC"] = "NUMBER_OF_FIRES_DESC";
    Jf288DetailsOrderBy["COST_OF_FIRE_DAMAGE_ASC"] = "COST_OF_FIRE_DAMAGE_ASC";
    Jf288DetailsOrderBy["COST_OF_FIRE_DAMAGE_DESC"] = "COST_OF_FIRE_DAMAGE_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_PROPERTIES_DAMAGED_ASC"] = "NUMBER_OF_PROPERTIES_DAMAGED_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_PROPERTIES_DAMAGED_DESC"] = "NUMBER_OF_PROPERTIES_DAMAGED_DESC";
    Jf288DetailsOrderBy["COST_OF_PROPERTIES_DAMAGE_ASC"] = "COST_OF_PROPERTIES_DAMAGE_ASC";
    Jf288DetailsOrderBy["COST_OF_PROPERTIES_DAMAGE_DESC"] = "COST_OF_PROPERTIES_DAMAGE_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_NATURAL_PHENOMENA_ASC"] = "NUMBER_OF_NATURAL_PHENOMENA_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_NATURAL_PHENOMENA_DESC"] = "NUMBER_OF_NATURAL_PHENOMENA_DESC";
    Jf288DetailsOrderBy["COST_OF_NATURAL_PHENOMENA_DAMAGE_ASC"] = "COST_OF_NATURAL_PHENOMENA_DAMAGE_ASC";
    Jf288DetailsOrderBy["COST_OF_NATURAL_PHENOMENA_DAMAGE_DESC"] = "COST_OF_NATURAL_PHENOMENA_DAMAGE_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_CLOSE_CALLS_REPORTED_ASC"] = "NUMBER_OF_CLOSE_CALLS_REPORTED_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_CLOSE_CALLS_REPORTED_DESC"] = "NUMBER_OF_CLOSE_CALLS_REPORTED_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_OSHA_INSPECTIONS_ASC"] = "NUMBER_OF_OSHA_INSPECTIONS_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_OSHA_INSPECTIONS_DESC"] = "NUMBER_OF_OSHA_INSPECTIONS_DESC";
    Jf288DetailsOrderBy["NUMBER_OF_EPA_INSPECTIONS_ASC"] = "NUMBER_OF_EPA_INSPECTIONS_ASC";
    Jf288DetailsOrderBy["NUMBER_OF_EPA_INSPECTIONS_DESC"] = "NUMBER_OF_EPA_INSPECTIONS_DESC";
    Jf288DetailsOrderBy["PERCENTAGE_OF_WORKSITES_INSPECTED_THIS_MONTH_ASC"] = "PERCENTAGE_OF_WORKSITES_INSPECTED_THIS_MONTH_ASC";
    Jf288DetailsOrderBy["PERCENTAGE_OF_WORKSITES_INSPECTED_THIS_MONTH_DESC"] = "PERCENTAGE_OF_WORKSITES_INSPECTED_THIS_MONTH_DESC";
    Jf288DetailsOrderBy["PERCENTAGE_OF_WORKSITES_INSPECTED_THIS_QUARTER_ASC"] = "PERCENTAGE_OF_WORKSITES_INSPECTED_THIS_QUARTER_ASC";
    Jf288DetailsOrderBy["PERCENTAGE_OF_WORKSITES_INSPECTED_THIS_QUARTER_DESC"] = "PERCENTAGE_OF_WORKSITES_INSPECTED_THIS_QUARTER_DESC";
    Jf288DetailsOrderBy["INSPECTIONS_WERE_ENTERED_INTO_JSC_BITS_ASC"] = "INSPECTIONS_WERE_ENTERED_INTO_JSC_BITS_ASC";
    Jf288DetailsOrderBy["INSPECTIONS_WERE_ENTERED_INTO_JSC_BITS_DESC"] = "INSPECTIONS_WERE_ENTERED_INTO_JSC_BITS_DESC";
    Jf288DetailsOrderBy["WORKSITE_ANALYSIS_NOTES_ASC"] = "WORKSITE_ANALYSIS_NOTES_ASC";
    Jf288DetailsOrderBy["WORKSITE_ANALYSIS_NOTES_DESC"] = "WORKSITE_ANALYSIS_NOTES_DESC";
    Jf288DetailsOrderBy["HAZARD_ABATEMENT_NOTES_ASC"] = "HAZARD_ABATEMENT_NOTES_ASC";
    Jf288DetailsOrderBy["HAZARD_ABATEMENT_NOTES_DESC"] = "HAZARD_ABATEMENT_NOTES_DESC";
    Jf288DetailsOrderBy["PERCENTAGE_OF_EMPLOYEES_TRAINED_IN_CORE_SAFETY_AND_HEALTH_ASC"] = "PERCENTAGE_OF_EMPLOYEES_TRAINED_IN_CORE_SAFETY_AND_HEALTH_ASC";
    Jf288DetailsOrderBy["PERCENTAGE_OF_EMPLOYEES_TRAINED_IN_CORE_SAFETY_AND_HEALTH_DESC"] = "PERCENTAGE_OF_EMPLOYEES_TRAINED_IN_CORE_SAFETY_AND_HEALTH_DESC";
    Jf288DetailsOrderBy["PERCENTAGE_OF_EMPLOYEES_TRAINED_IN_OTHER_SIGNIFICANT_TRAINING_ASC"] = "PERCENTAGE_OF_EMPLOYEES_TRAINED_IN_OTHER_SIGNIFICANT_TRAINING_ASC";
    Jf288DetailsOrderBy["PERCENTAGE_OF_EMPLOYEES_TRAINED_IN_OTHER_SIGNIFICANT_TRAINING_DESC"] = "PERCENTAGE_OF_EMPLOYEES_TRAINED_IN_OTHER_SIGNIFICANT_TRAINING_DESC";
    Jf288DetailsOrderBy["SAFETY_AND_HEALTH_TRAINING_NOTES_ASC"] = "SAFETY_AND_HEALTH_TRAINING_NOTES_ASC";
    Jf288DetailsOrderBy["SAFETY_AND_HEALTH_TRAINING_NOTES_DESC"] = "SAFETY_AND_HEALTH_TRAINING_NOTES_DESC";
    Jf288DetailsOrderBy["MONTHLY_HAZARDS_DESCRIPTION_ASC"] = "MONTHLY_HAZARDS_DESCRIPTION_ASC";
    Jf288DetailsOrderBy["MONTHLY_HAZARDS_DESCRIPTION_DESC"] = "MONTHLY_HAZARDS_DESCRIPTION_DESC";
    Jf288DetailsOrderBy["MONTHLY_HAZARDS_OPEN_OR_CLOSED_ASC"] = "MONTHLY_HAZARDS_OPEN_OR_CLOSED_ASC";
    Jf288DetailsOrderBy["MONTHLY_HAZARDS_OPEN_OR_CLOSED_DESC"] = "MONTHLY_HAZARDS_OPEN_OR_CLOSED_DESC";
    Jf288DetailsOrderBy["JSC_HATS_SRTS_NUMBER_ASC"] = "JSC_HATS_SRTS_NUMBER_ASC";
    Jf288DetailsOrderBy["JSC_HATS_SRTS_NUMBER_DESC"] = "JSC_HATS_SRTS_NUMBER_DESC";
    Jf288DetailsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    Jf288DetailsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    Jf288DetailsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    Jf288DetailsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    Jf288DetailsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    Jf288DetailsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(Jf288DetailsOrderBy || (Jf288DetailsOrderBy = {}));
/** Methods to use when ordering `Jf288Event`. */
export var Jf288EventsOrderBy;
(function (Jf288EventsOrderBy) {
    Jf288EventsOrderBy["NATURAL"] = "NATURAL";
    Jf288EventsOrderBy["ID_ASC"] = "ID_ASC";
    Jf288EventsOrderBy["ID_DESC"] = "ID_DESC";
    Jf288EventsOrderBy["JF288_DETAILS_ID_ASC"] = "JF288_DETAILS_ID_ASC";
    Jf288EventsOrderBy["JF288_DETAILS_ID_DESC"] = "JF288_DETAILS_ID_DESC";
    Jf288EventsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    Jf288EventsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    Jf288EventsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    Jf288EventsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    Jf288EventsOrderBy["NAME_ASC"] = "NAME_ASC";
    Jf288EventsOrderBy["NAME_DESC"] = "NAME_DESC";
    Jf288EventsOrderBy["PURPOSE_ASC"] = "PURPOSE_ASC";
    Jf288EventsOrderBy["PURPOSE_DESC"] = "PURPOSE_DESC";
    Jf288EventsOrderBy["NUMBER_OF_MANAGERS_ASC"] = "NUMBER_OF_MANAGERS_ASC";
    Jf288EventsOrderBy["NUMBER_OF_MANAGERS_DESC"] = "NUMBER_OF_MANAGERS_DESC";
    Jf288EventsOrderBy["NUMBER_OF_EMPLOYEES_ASC"] = "NUMBER_OF_EMPLOYEES_ASC";
    Jf288EventsOrderBy["NUMBER_OF_EMPLOYEES_DESC"] = "NUMBER_OF_EMPLOYEES_DESC";
    Jf288EventsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    Jf288EventsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(Jf288EventsOrderBy || (Jf288EventsOrderBy = {}));
/** Methods to use when ordering `Jf288Mishap`. */
export var Jf288MishapsOrderBy;
(function (Jf288MishapsOrderBy) {
    Jf288MishapsOrderBy["NATURAL"] = "NATURAL";
    Jf288MishapsOrderBy["ID_ASC"] = "ID_ASC";
    Jf288MishapsOrderBy["ID_DESC"] = "ID_DESC";
    Jf288MishapsOrderBy["JF288_DETAILS_ID_ASC"] = "JF288_DETAILS_ID_ASC";
    Jf288MishapsOrderBy["JF288_DETAILS_ID_DESC"] = "JF288_DETAILS_ID_DESC";
    Jf288MishapsOrderBy["STATUS_ASC"] = "STATUS_ASC";
    Jf288MishapsOrderBy["STATUS_DESC"] = "STATUS_DESC";
    Jf288MishapsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    Jf288MishapsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    Jf288MishapsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    Jf288MishapsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    Jf288MishapsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    Jf288MishapsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    Jf288MishapsOrderBy["MISHAP_DATE_ASC"] = "MISHAP_DATE_ASC";
    Jf288MishapsOrderBy["MISHAP_DATE_DESC"] = "MISHAP_DATE_DESC";
    Jf288MishapsOrderBy["EMPLOYEE_NAME_ASC"] = "EMPLOYEE_NAME_ASC";
    Jf288MishapsOrderBy["EMPLOYEE_NAME_DESC"] = "EMPLOYEE_NAME_DESC";
    Jf288MishapsOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    Jf288MishapsOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    Jf288MishapsOrderBy["NEW_NUMBER_OF_RESTRICTED_WORKDAYS_ASC"] = "NEW_NUMBER_OF_RESTRICTED_WORKDAYS_ASC";
    Jf288MishapsOrderBy["NEW_NUMBER_OF_RESTRICTED_WORKDAYS_DESC"] = "NEW_NUMBER_OF_RESTRICTED_WORKDAYS_DESC";
    Jf288MishapsOrderBy["NEW_NUMBER_OF_DAYS_AWAY_ASC"] = "NEW_NUMBER_OF_DAYS_AWAY_ASC";
    Jf288MishapsOrderBy["NEW_NUMBER_OF_DAYS_AWAY_DESC"] = "NEW_NUMBER_OF_DAYS_AWAY_DESC";
    Jf288MishapsOrderBy["ESTIMATED_COST_OF_DAMAGE_ASC"] = "ESTIMATED_COST_OF_DAMAGE_ASC";
    Jf288MishapsOrderBy["ESTIMATED_COST_OF_DAMAGE_DESC"] = "ESTIMATED_COST_OF_DAMAGE_DESC";
    Jf288MishapsOrderBy["FINAL_COST_OF_DAMAGE_ASC"] = "FINAL_COST_OF_DAMAGE_ASC";
    Jf288MishapsOrderBy["FINAL_COST_OF_DAMAGE_DESC"] = "FINAL_COST_OF_DAMAGE_DESC";
    Jf288MishapsOrderBy["PREVIOUS_NUMBER_OF_RESTRICTED_WORKDAYS_ASC"] = "PREVIOUS_NUMBER_OF_RESTRICTED_WORKDAYS_ASC";
    Jf288MishapsOrderBy["PREVIOUS_NUMBER_OF_RESTRICTED_WORKDAYS_DESC"] = "PREVIOUS_NUMBER_OF_RESTRICTED_WORKDAYS_DESC";
    Jf288MishapsOrderBy["PREVIOUS_NUMBER_OF_DAYS_AWAY_ASC"] = "PREVIOUS_NUMBER_OF_DAYS_AWAY_ASC";
    Jf288MishapsOrderBy["PREVIOUS_NUMBER_OF_DAYS_AWAY_DESC"] = "PREVIOUS_NUMBER_OF_DAYS_AWAY_DESC";
    Jf288MishapsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    Jf288MishapsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(Jf288MishapsOrderBy || (Jf288MishapsOrderBy = {}));
export var Jf288MishapType;
(function (Jf288MishapType) {
    Jf288MishapType["FIRST_AID"] = "FIRST_AID";
    Jf288MishapType["ILLNESS"] = "ILLNESS";
    Jf288MishapType["MEDICAL_TREATMENT"] = "MEDICAL_TREATMENT";
    Jf288MishapType["RESTRICTED"] = "RESTRICTED";
    Jf288MishapType["DAYS_AWAY"] = "DAYS_AWAY";
    Jf288MishapType["DAMAGE"] = "DAMAGE";
})(Jf288MishapType || (Jf288MishapType = {}));
/** Methods to use when ordering `Jf288SafetyActivity`. */
export var Jf288SafetyActivitiesOrderBy;
(function (Jf288SafetyActivitiesOrderBy) {
    Jf288SafetyActivitiesOrderBy["NATURAL"] = "NATURAL";
    Jf288SafetyActivitiesOrderBy["ID_ASC"] = "ID_ASC";
    Jf288SafetyActivitiesOrderBy["ID_DESC"] = "ID_DESC";
    Jf288SafetyActivitiesOrderBy["JF288_DETAILS_ID_ASC"] = "JF288_DETAILS_ID_ASC";
    Jf288SafetyActivitiesOrderBy["JF288_DETAILS_ID_DESC"] = "JF288_DETAILS_ID_DESC";
    Jf288SafetyActivitiesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    Jf288SafetyActivitiesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    Jf288SafetyActivitiesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    Jf288SafetyActivitiesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    Jf288SafetyActivitiesOrderBy["SAFETY_ACTIVITY_DATE_ASC"] = "SAFETY_ACTIVITY_DATE_ASC";
    Jf288SafetyActivitiesOrderBy["SAFETY_ACTIVITY_DATE_DESC"] = "SAFETY_ACTIVITY_DATE_DESC";
    Jf288SafetyActivitiesOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    Jf288SafetyActivitiesOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    Jf288SafetyActivitiesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    Jf288SafetyActivitiesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(Jf288SafetyActivitiesOrderBy || (Jf288SafetyActivitiesOrderBy = {}));
export var LimitedLifeAlertType;
(function (LimitedLifeAlertType) {
    LimitedLifeAlertType["NONE"] = "NONE";
    LimitedLifeAlertType["SERVICE"] = "SERVICE";
    LimitedLifeAlertType["EXPIRED"] = "EXPIRED";
})(LimitedLifeAlertType || (LimitedLifeAlertType = {}));
/** Methods to use when ordering `LimitedLifeDefinition`. */
export var LimitedLifeDefinitionsOrderBy;
(function (LimitedLifeDefinitionsOrderBy) {
    LimitedLifeDefinitionsOrderBy["NATURAL"] = "NATURAL";
    LimitedLifeDefinitionsOrderBy["LIMITED_LIFE_ID_ASC"] = "LIMITED_LIFE_ID_ASC";
    LimitedLifeDefinitionsOrderBy["LIMITED_LIFE_ID_DESC"] = "LIMITED_LIFE_ID_DESC";
    LimitedLifeDefinitionsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    LimitedLifeDefinitionsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    LimitedLifeDefinitionsOrderBy["KIND_ASC"] = "KIND_ASC";
    LimitedLifeDefinitionsOrderBy["KIND_DESC"] = "KIND_DESC";
    LimitedLifeDefinitionsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    LimitedLifeDefinitionsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    LimitedLifeDefinitionsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    LimitedLifeDefinitionsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    LimitedLifeDefinitionsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    LimitedLifeDefinitionsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    LimitedLifeDefinitionsOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    LimitedLifeDefinitionsOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    LimitedLifeDefinitionsOrderBy["SERVICE_QUOTA_INTERVAL_ASC"] = "SERVICE_QUOTA_INTERVAL_ASC";
    LimitedLifeDefinitionsOrderBy["SERVICE_QUOTA_INTERVAL_DESC"] = "SERVICE_QUOTA_INTERVAL_DESC";
    LimitedLifeDefinitionsOrderBy["EXPIRATION_QUOTA_INTERVAL_ASC"] = "EXPIRATION_QUOTA_INTERVAL_ASC";
    LimitedLifeDefinitionsOrderBy["EXPIRATION_QUOTA_INTERVAL_DESC"] = "EXPIRATION_QUOTA_INTERVAL_DESC";
    LimitedLifeDefinitionsOrderBy["SERVICE_QUOTA_USES_ASC"] = "SERVICE_QUOTA_USES_ASC";
    LimitedLifeDefinitionsOrderBy["SERVICE_QUOTA_USES_DESC"] = "SERVICE_QUOTA_USES_DESC";
    LimitedLifeDefinitionsOrderBy["EXPIRATION_QUOTA_USES_ASC"] = "EXPIRATION_QUOTA_USES_ASC";
    LimitedLifeDefinitionsOrderBy["EXPIRATION_QUOTA_USES_DESC"] = "EXPIRATION_QUOTA_USES_DESC";
    LimitedLifeDefinitionsOrderBy["OVERRIDE_EXPIRATION_DATE_ASC"] = "OVERRIDE_EXPIRATION_DATE_ASC";
    LimitedLifeDefinitionsOrderBy["OVERRIDE_EXPIRATION_DATE_DESC"] = "OVERRIDE_EXPIRATION_DATE_DESC";
    LimitedLifeDefinitionsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    LimitedLifeDefinitionsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    LimitedLifeDefinitionsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    LimitedLifeDefinitionsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
})(LimitedLifeDefinitionsOrderBy || (LimitedLifeDefinitionsOrderBy = {}));
export var LimitedLifeKind;
(function (LimitedLifeKind) {
    LimitedLifeKind["DATE"] = "DATE";
    LimitedLifeKind["INTERVAL"] = "INTERVAL";
    LimitedLifeKind["USES"] = "USES";
})(LimitedLifeKind || (LimitedLifeKind = {}));
export var LimitedLifeType;
(function (LimitedLifeType) {
    LimitedLifeType["SHELF"] = "SHELF";
    LimitedLifeType["USAGE"] = "USAGE";
    LimitedLifeType["OPERATION"] = "OPERATION";
    LimitedLifeType["PRESSURIZED_TIME"] = "PRESSURIZED_TIME";
    LimitedLifeType["INTERVAL"] = "INTERVAL";
    LimitedLifeType["ON_ORBIT"] = "ON_ORBIT";
    LimitedLifeType["STATIC"] = "STATIC";
    LimitedLifeType["FLIGHT"] = "FLIGHT";
    LimitedLifeType["CYCLE"] = "CYCLE";
    LimitedLifeType["EVA"] = "EVA";
})(LimitedLifeType || (LimitedLifeType = {}));
/** Methods to use when ordering `LimitedLife`. */
export var LimitedLivesOrderBy;
(function (LimitedLivesOrderBy) {
    LimitedLivesOrderBy["NATURAL"] = "NATURAL";
    LimitedLivesOrderBy["ID_ASC"] = "ID_ASC";
    LimitedLivesOrderBy["ID_DESC"] = "ID_DESC";
    LimitedLivesOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    LimitedLivesOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    LimitedLivesOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    LimitedLivesOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    LimitedLivesOrderBy["SIDE_ASC"] = "SIDE_ASC";
    LimitedLivesOrderBy["SIDE_DESC"] = "SIDE_DESC";
    LimitedLivesOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    LimitedLivesOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    LimitedLivesOrderBy["DESCRIPTION_ASC"] = "DESCRIPTION_ASC";
    LimitedLivesOrderBy["DESCRIPTION_DESC"] = "DESCRIPTION_DESC";
    LimitedLivesOrderBy["DISABLE_ALERTS_ASC"] = "DISABLE_ALERTS_ASC";
    LimitedLivesOrderBy["DISABLE_ALERTS_DESC"] = "DISABLE_ALERTS_DESC";
    LimitedLivesOrderBy["CODE_ASC"] = "CODE_ASC";
    LimitedLivesOrderBy["CODE_DESC"] = "CODE_DESC";
    LimitedLivesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    LimitedLivesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    LimitedLivesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    LimitedLivesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    LimitedLivesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    LimitedLivesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(LimitedLivesOrderBy || (LimitedLivesOrderBy = {}));
/** Methods to use when ordering `Link`. */
export var LinksOrderBy;
(function (LinksOrderBy) {
    LinksOrderBy["NATURAL"] = "NATURAL";
    LinksOrderBy["ID_ASC"] = "ID_ASC";
    LinksOrderBy["ID_DESC"] = "ID_DESC";
    LinksOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    LinksOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    LinksOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    LinksOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    LinksOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    LinksOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    LinksOrderBy["SIDE_ASC"] = "SIDE_ASC";
    LinksOrderBy["SIDE_DESC"] = "SIDE_DESC";
    LinksOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    LinksOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    LinksOrderBy["ORGANIZATION_CODE_ASC"] = "ORGANIZATION_CODE_ASC";
    LinksOrderBy["ORGANIZATION_CODE_DESC"] = "ORGANIZATION_CODE_DESC";
    LinksOrderBy["EVENT_ID_ASC"] = "EVENT_ID_ASC";
    LinksOrderBy["EVENT_ID_DESC"] = "EVENT_ID_DESC";
    LinksOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    LinksOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    LinksOrderBy["CURRENT_REVISION_ASC"] = "CURRENT_REVISION_ASC";
    LinksOrderBy["CURRENT_REVISION_DESC"] = "CURRENT_REVISION_DESC";
    LinksOrderBy["CURRENT_VERSION_URL_ASC"] = "CURRENT_VERSION_URL_ASC";
    LinksOrderBy["CURRENT_VERSION_URL_DESC"] = "CURRENT_VERSION_URL_DESC";
    LinksOrderBy["REFERENCE_NUMBER_ASC"] = "REFERENCE_NUMBER_ASC";
    LinksOrderBy["REFERENCE_NUMBER_DESC"] = "REFERENCE_NUMBER_DESC";
    LinksOrderBy["WORKING_VERSION_URL_ASC"] = "WORKING_VERSION_URL_ASC";
    LinksOrderBy["WORKING_VERSION_URL_DESC"] = "WORKING_VERSION_URL_DESC";
    LinksOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    LinksOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    LinksOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    LinksOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    LinksOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    LinksOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    LinksOrderBy["UPDATED_BY_USER_ID_ASC"] = "UPDATED_BY_USER_ID_ASC";
    LinksOrderBy["UPDATED_BY_USER_ID_DESC"] = "UPDATED_BY_USER_ID_DESC";
    LinksOrderBy["DIGITAL_LIBRARY_RECORD_ID_ASC"] = "DIGITAL_LIBRARY_RECORD_ID_ASC";
    LinksOrderBy["DIGITAL_LIBRARY_RECORD_ID_DESC"] = "DIGITAL_LIBRARY_RECORD_ID_DESC";
    LinksOrderBy["RISK_ID_ASC"] = "RISK_ID_ASC";
    LinksOrderBy["RISK_ID_DESC"] = "RISK_ID_DESC";
    LinksOrderBy["MEETING_ID_ASC"] = "MEETING_ID_ASC";
    LinksOrderBy["MEETING_ID_DESC"] = "MEETING_ID_DESC";
    LinksOrderBy["MEETING_ITEM_ID_ASC"] = "MEETING_ITEM_ID_ASC";
    LinksOrderBy["MEETING_ITEM_ID_DESC"] = "MEETING_ITEM_ID_DESC";
    LinksOrderBy["MEETING_SERIES_ID_ASC"] = "MEETING_SERIES_ID_ASC";
    LinksOrderBy["MEETING_SERIES_ID_DESC"] = "MEETING_SERIES_ID_DESC";
    LinksOrderBy["ENTITY_TYPE_ASC"] = "ENTITY_TYPE_ASC";
    LinksOrderBy["ENTITY_TYPE_DESC"] = "ENTITY_TYPE_DESC";
    LinksOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    LinksOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(LinksOrderBy || (LinksOrderBy = {}));
export var LinkType;
(function (LinkType) {
    LinkType["COSMIC"] = "COSMIC";
    LinkType["EDCC"] = "EDCC";
    LinkType["EDS"] = "EDS";
    LinkType["OTHER"] = "OTHER";
    LinkType["QARC"] = "QARC";
    LinkType["RIDS"] = "RIDS";
    LinkType["DOCUMENT"] = "DOCUMENT";
})(LinkType || (LinkType = {}));
/** Methods to use when ordering `Location`. */
export var LocationsOrderBy;
(function (LocationsOrderBy) {
    LocationsOrderBy["NATURAL"] = "NATURAL";
    LocationsOrderBy["ID_ASC"] = "ID_ASC";
    LocationsOrderBy["ID_DESC"] = "ID_DESC";
    LocationsOrderBy["ORGANIZATION_CODE_ASC"] = "ORGANIZATION_CODE_ASC";
    LocationsOrderBy["ORGANIZATION_CODE_DESC"] = "ORGANIZATION_CODE_DESC";
    LocationsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    LocationsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    LocationsOrderBy["BUILDING_ASC"] = "BUILDING_ASC";
    LocationsOrderBy["BUILDING_DESC"] = "BUILDING_DESC";
    LocationsOrderBy["ROOM_ASC"] = "ROOM_ASC";
    LocationsOrderBy["ROOM_DESC"] = "ROOM_DESC";
    LocationsOrderBy["BIN_ASC"] = "BIN_ASC";
    LocationsOrderBy["BIN_DESC"] = "BIN_DESC";
    LocationsOrderBy["STOCK_ASC"] = "STOCK_ASC";
    LocationsOrderBy["STOCK_DESC"] = "STOCK_DESC";
    LocationsOrderBy["ADDRESS_ASC"] = "ADDRESS_ASC";
    LocationsOrderBy["ADDRESS_DESC"] = "ADDRESS_DESC";
    LocationsOrderBy["CITY_ASC"] = "CITY_ASC";
    LocationsOrderBy["CITY_DESC"] = "CITY_DESC";
    LocationsOrderBy["STATE_ASC"] = "STATE_ASC";
    LocationsOrderBy["STATE_DESC"] = "STATE_DESC";
    LocationsOrderBy["ZIP_ASC"] = "ZIP_ASC";
    LocationsOrderBy["ZIP_DESC"] = "ZIP_DESC";
    LocationsOrderBy["PHONE_ASC"] = "PHONE_ASC";
    LocationsOrderBy["PHONE_DESC"] = "PHONE_DESC";
    LocationsOrderBy["IS_OFF_EARTH_ASC"] = "IS_OFF_EARTH_ASC";
    LocationsOrderBy["IS_OFF_EARTH_DESC"] = "IS_OFF_EARTH_DESC";
    LocationsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    LocationsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    LocationsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    LocationsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    LocationsOrderBy["CONTACT_ID_ASC"] = "CONTACT_ID_ASC";
    LocationsOrderBy["CONTACT_ID_DESC"] = "CONTACT_ID_DESC";
    LocationsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    LocationsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    LocationsOrderBy["IS_ARCHIVED_ASC"] = "IS_ARCHIVED_ASC";
    LocationsOrderBy["IS_ARCHIVED_DESC"] = "IS_ARCHIVED_DESC";
    LocationsOrderBy["IS_INTERNAL_ASC"] = "IS_INTERNAL_ASC";
    LocationsOrderBy["IS_INTERNAL_DESC"] = "IS_INTERNAL_DESC";
    LocationsOrderBy["IS_MANAGED_ASC"] = "IS_MANAGED_ASC";
    LocationsOrderBy["IS_MANAGED_DESC"] = "IS_MANAGED_DESC";
    LocationsOrderBy["QUERY_SEARCH_ASC"] = "QUERY_SEARCH_ASC";
    LocationsOrderBy["QUERY_SEARCH_DESC"] = "QUERY_SEARCH_DESC";
    LocationsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    LocationsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(LocationsOrderBy || (LocationsOrderBy = {}));
export var MakeBuySupply;
(function (MakeBuySupply) {
    MakeBuySupply["MAKE"] = "MAKE";
    MakeBuySupply["BUY"] = "BUY";
    MakeBuySupply["SUPPLY"] = "SUPPLY";
})(MakeBuySupply || (MakeBuySupply = {}));
/** Reasons that an entity has to be kept or discarded during an item instance merge. */
export var MergeabilityReason;
(function (MergeabilityReason) {
    MergeabilityReason["INVENTORY_ASSEMBLY"] = "INVENTORY_ASSEMBLY";
    MergeabilityReason["INVENTORY_CHANGE_SETS"] = "INVENTORY_CHANGE_SETS";
    MergeabilityReason["INVENTORY_HARDWARE_LIST"] = "INVENTORY_HARDWARE_LIST";
    MergeabilityReason["CONFLICTS_WITH_DESTINATION"] = "CONFLICTS_WITH_DESTINATION";
    MergeabilityReason["BAD_SERIALIZED_INVENTORY_QUANTITY"] = "BAD_SERIALIZED_INVENTORY_QUANTITY";
})(MergeabilityReason || (MergeabilityReason = {}));
export var MergeConsolidationPlanAction;
(function (MergeConsolidationPlanAction) {
    MergeConsolidationPlanAction["KEEP_BOTH"] = "KEEP_BOTH";
    MergeConsolidationPlanAction["DISCARD_SOURCE"] = "DISCARD_SOURCE";
    MergeConsolidationPlanAction["KEEP_SPECIFIC_FROM_SOURCE"] = "KEEP_SPECIFIC_FROM_SOURCE";
})(MergeConsolidationPlanAction || (MergeConsolidationPlanAction = {}));
export var MergeConsolidationPlanConflictAction;
(function (MergeConsolidationPlanConflictAction) {
    MergeConsolidationPlanConflictAction["KEEP_BOTH"] = "KEEP_BOTH";
    MergeConsolidationPlanConflictAction["DISCARD_SOURCE"] = "DISCARD_SOURCE";
    MergeConsolidationPlanConflictAction["KEEP_SPECIFIC_FROM_SOURCE"] = "KEEP_SPECIFIC_FROM_SOURCE";
    MergeConsolidationPlanConflictAction["DISCARD_CONFLICTING_FROM_SOURCE"] = "DISCARD_CONFLICTING_FROM_SOURCE";
})(MergeConsolidationPlanConflictAction || (MergeConsolidationPlanConflictAction = {}));
/** Methods to use when ordering `MrbAuthority`. */
export var MrbAuthoritiesOrderBy;
(function (MrbAuthoritiesOrderBy) {
    MrbAuthoritiesOrderBy["NATURAL"] = "NATURAL";
    MrbAuthoritiesOrderBy["ID_ASC"] = "ID_ASC";
    MrbAuthoritiesOrderBy["ID_DESC"] = "ID_DESC";
    MrbAuthoritiesOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    MrbAuthoritiesOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    MrbAuthoritiesOrderBy["AUTHORITY_ASC"] = "AUTHORITY_ASC";
    MrbAuthoritiesOrderBy["AUTHORITY_DESC"] = "AUTHORITY_DESC";
    MrbAuthoritiesOrderBy["IS_FOR_ON_ORBIT_ASC"] = "IS_FOR_ON_ORBIT_ASC";
    MrbAuthoritiesOrderBy["IS_FOR_ON_ORBIT_DESC"] = "IS_FOR_ON_ORBIT_DESC";
    MrbAuthoritiesOrderBy["LOCATION_DESCRIPTIONS_ASC"] = "LOCATION_DESCRIPTIONS_ASC";
    MrbAuthoritiesOrderBy["LOCATION_DESCRIPTIONS_DESC"] = "LOCATION_DESCRIPTIONS_DESC";
    MrbAuthoritiesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    MrbAuthoritiesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    MrbAuthoritiesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    MrbAuthoritiesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    MrbAuthoritiesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    MrbAuthoritiesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(MrbAuthoritiesOrderBy || (MrbAuthoritiesOrderBy = {}));
/** Methods to use when ordering `Notification`. */
export var NotificationsOrderBy;
(function (NotificationsOrderBy) {
    NotificationsOrderBy["NATURAL"] = "NATURAL";
    NotificationsOrderBy["ID_ASC"] = "ID_ASC";
    NotificationsOrderBy["ID_DESC"] = "ID_DESC";
    NotificationsOrderBy["SUBJECT_ASC"] = "SUBJECT_ASC";
    NotificationsOrderBy["SUBJECT_DESC"] = "SUBJECT_DESC";
    NotificationsOrderBy["MESSAGE_TEXT_ASC"] = "MESSAGE_TEXT_ASC";
    NotificationsOrderBy["MESSAGE_TEXT_DESC"] = "MESSAGE_TEXT_DESC";
    NotificationsOrderBy["MESSAGE_HTML_ASC"] = "MESSAGE_HTML_ASC";
    NotificationsOrderBy["MESSAGE_HTML_DESC"] = "MESSAGE_HTML_DESC";
    NotificationsOrderBy["RECIPIENTS_ASC"] = "RECIPIENTS_ASC";
    NotificationsOrderBy["RECIPIENTS_DESC"] = "RECIPIENTS_DESC";
    NotificationsOrderBy["DATA_ASC"] = "DATA_ASC";
    NotificationsOrderBy["DATA_DESC"] = "DATA_DESC";
    NotificationsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    NotificationsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    NotificationsOrderBy["ATTEMPT_COUNT_ASC"] = "ATTEMPT_COUNT_ASC";
    NotificationsOrderBy["ATTEMPT_COUNT_DESC"] = "ATTEMPT_COUNT_DESC";
    NotificationsOrderBy["FAILURE_LOGS_ASC"] = "FAILURE_LOGS_ASC";
    NotificationsOrderBy["FAILURE_LOGS_DESC"] = "FAILURE_LOGS_DESC";
    NotificationsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    NotificationsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    NotificationsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    NotificationsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    NotificationsOrderBy["LAST_ATTEMPT_DATE_TIME_ASC"] = "LAST_ATTEMPT_DATE_TIME_ASC";
    NotificationsOrderBy["LAST_ATTEMPT_DATE_TIME_DESC"] = "LAST_ATTEMPT_DATE_TIME_DESC";
    NotificationsOrderBy["LAST_FAILURE_DATE_TIME_ASC"] = "LAST_FAILURE_DATE_TIME_ASC";
    NotificationsOrderBy["LAST_FAILURE_DATE_TIME_DESC"] = "LAST_FAILURE_DATE_TIME_DESC";
    NotificationsOrderBy["SENT_DATE_TIME_ASC"] = "SENT_DATE_TIME_ASC";
    NotificationsOrderBy["SENT_DATE_TIME_DESC"] = "SENT_DATE_TIME_DESC";
    NotificationsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    NotificationsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(NotificationsOrderBy || (NotificationsOrderBy = {}));
export var ObjectClass;
(function (ObjectClass) {
    ObjectClass["_5611"] = "_5611";
    ObjectClass["_5621"] = "_5621";
    ObjectClass["_5631"] = "_5631";
    ObjectClass["_5641"] = "_5641";
    ObjectClass["_5651"] = "_5651";
    ObjectClass["_5661"] = "_5661";
    ObjectClass["_5671"] = "_5671";
    ObjectClass["_5681"] = "_5681";
    ObjectClass["_5691"] = "_5691";
    ObjectClass["_5711"] = "_5711";
    ObjectClass["_5721"] = "_5721";
    ObjectClass["_5725"] = "_5725";
    ObjectClass["_5731"] = "_5731";
    ObjectClass["_5741"] = "_5741";
    ObjectClass["_5742"] = "_5742";
    ObjectClass["_5751"] = "_5751";
    ObjectClass["_5761"] = "_5761";
    ObjectClass["_5762"] = "_5762";
    ObjectClass["_5765"] = "_5765";
    ObjectClass["NO_OBJECT_CLASS_FOUND"] = "NO_OBJECT_CLASS_FOUND";
})(ObjectClass || (ObjectClass = {}));
/** Methods to use when ordering `Organization`. */
export var OrganizationsOrderBy;
(function (OrganizationsOrderBy) {
    OrganizationsOrderBy["NATURAL"] = "NATURAL";
    OrganizationsOrderBy["CODE_ASC"] = "CODE_ASC";
    OrganizationsOrderBy["CODE_DESC"] = "CODE_DESC";
    OrganizationsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    OrganizationsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    OrganizationsOrderBy["NAME_ASC"] = "NAME_ASC";
    OrganizationsOrderBy["NAME_DESC"] = "NAME_DESC";
    OrganizationsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    OrganizationsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    OrganizationsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    OrganizationsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    OrganizationsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    OrganizationsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    OrganizationsOrderBy["IS_CAGE_CODE_ASC"] = "IS_CAGE_CODE_ASC";
    OrganizationsOrderBy["IS_CAGE_CODE_DESC"] = "IS_CAGE_CODE_DESC";
    OrganizationsOrderBy["IS_ACTIVE_ASC"] = "IS_ACTIVE_ASC";
    OrganizationsOrderBy["IS_ACTIVE_DESC"] = "IS_ACTIVE_DESC";
    OrganizationsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    OrganizationsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    OrganizationsOrderBy["TIME_ZONE_ASC"] = "TIME_ZONE_ASC";
    OrganizationsOrderBy["TIME_ZONE_DESC"] = "TIME_ZONE_DESC";
    OrganizationsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    OrganizationsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(OrganizationsOrderBy || (OrganizationsOrderBy = {}));
export var OrganizationType;
(function (OrganizationType) {
    OrganizationType["GOVERNMENT"] = "GOVERNMENT";
    OrganizationType["MANUFACTURER"] = "MANUFACTURER";
    OrganizationType["PROCESSOR"] = "PROCESSOR";
    OrganizationType["SUPPLIER"] = "SUPPLIER";
    OrganizationType["VENDOR"] = "VENDOR";
    OrganizationType["CONTRACTOR"] = "CONTRACTOR";
})(OrganizationType || (OrganizationType = {}));
/** Methods to use when ordering `Outage`. */
export var OutagesOrderBy;
(function (OutagesOrderBy) {
    OutagesOrderBy["NATURAL"] = "NATURAL";
    OutagesOrderBy["ID_ASC"] = "ID_ASC";
    OutagesOrderBy["ID_DESC"] = "ID_DESC";
    OutagesOrderBy["REASON_ASC"] = "REASON_ASC";
    OutagesOrderBy["REASON_DESC"] = "REASON_DESC";
    OutagesOrderBy["IS_DISABLED_ASC"] = "IS_DISABLED_ASC";
    OutagesOrderBy["IS_DISABLED_DESC"] = "IS_DISABLED_DESC";
    OutagesOrderBy["USER_FACING_MESSAGE_ASC"] = "USER_FACING_MESSAGE_ASC";
    OutagesOrderBy["USER_FACING_MESSAGE_DESC"] = "USER_FACING_MESSAGE_DESC";
    OutagesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    OutagesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    OutagesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    OutagesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    OutagesOrderBy["ACTIVE_END_DATE_TIME_ASC"] = "ACTIVE_END_DATE_TIME_ASC";
    OutagesOrderBy["ACTIVE_END_DATE_TIME_DESC"] = "ACTIVE_END_DATE_TIME_DESC";
    OutagesOrderBy["ACTIVE_START_DATE_TIME_ASC"] = "ACTIVE_START_DATE_TIME_ASC";
    OutagesOrderBy["ACTIVE_START_DATE_TIME_DESC"] = "ACTIVE_START_DATE_TIME_DESC";
    OutagesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    OutagesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(OutagesOrderBy || (OutagesOrderBy = {}));
export var PhysicalInventoryAuditAdjustmentReason;
(function (PhysicalInventoryAuditAdjustmentReason) {
    PhysicalInventoryAuditAdjustmentReason["PHYSICAL_INVENTORY_DISCREPANCIES"] = "PHYSICAL_INVENTORY_DISCREPANCIES";
    PhysicalInventoryAuditAdjustmentReason["DAMAGE_OR_DESTRUCTION"] = "DAMAGE_OR_DESTRUCTION";
    PhysicalInventoryAuditAdjustmentReason["OBSOLESCENCE_OR_DETERIORATION"] = "OBSOLESCENCE_OR_DETERIORATION";
    PhysicalInventoryAuditAdjustmentReason["LOSS"] = "LOSS";
    PhysicalInventoryAuditAdjustmentReason["THEFT"] = "THEFT";
    PhysicalInventoryAuditAdjustmentReason["OPERATIONAL_ERRORS"] = "OPERATIONAL_ERRORS";
    PhysicalInventoryAuditAdjustmentReason["RETURNS_TO_VENDOR"] = "RETURNS_TO_VENDOR";
    PhysicalInventoryAuditAdjustmentReason["EXCESS_TRANSFERS_TO_PDO"] = "EXCESS_TRANSFERS_TO_PDO";
})(PhysicalInventoryAuditAdjustmentReason || (PhysicalInventoryAuditAdjustmentReason = {}));
/** Methods to use when ordering `PhysicalInventoryAuditContract`. */
export var PhysicalInventoryAuditContractsOrderBy;
(function (PhysicalInventoryAuditContractsOrderBy) {
    PhysicalInventoryAuditContractsOrderBy["NATURAL"] = "NATURAL";
    PhysicalInventoryAuditContractsOrderBy["ID_ASC"] = "ID_ASC";
    PhysicalInventoryAuditContractsOrderBy["ID_DESC"] = "ID_DESC";
    PhysicalInventoryAuditContractsOrderBy["PHYSICAL_INVENTORY_AUDIT_ID_ASC"] = "PHYSICAL_INVENTORY_AUDIT_ID_ASC";
    PhysicalInventoryAuditContractsOrderBy["PHYSICAL_INVENTORY_AUDIT_ID_DESC"] = "PHYSICAL_INVENTORY_AUDIT_ID_DESC";
    PhysicalInventoryAuditContractsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    PhysicalInventoryAuditContractsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    PhysicalInventoryAuditContractsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    PhysicalInventoryAuditContractsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    PhysicalInventoryAuditContractsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    PhysicalInventoryAuditContractsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    PhysicalInventoryAuditContractsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    PhysicalInventoryAuditContractsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(PhysicalInventoryAuditContractsOrderBy || (PhysicalInventoryAuditContractsOrderBy = {}));
/** Methods to use when ordering `PhysicalInventoryAuditItem`. */
export var PhysicalInventoryAuditItemsOrderBy;
(function (PhysicalInventoryAuditItemsOrderBy) {
    PhysicalInventoryAuditItemsOrderBy["NATURAL"] = "NATURAL";
    PhysicalInventoryAuditItemsOrderBy["ID_ASC"] = "ID_ASC";
    PhysicalInventoryAuditItemsOrderBy["ID_DESC"] = "ID_DESC";
    PhysicalInventoryAuditItemsOrderBy["PHYSICAL_INVENTORY_AUDIT_ID_ASC"] = "PHYSICAL_INVENTORY_AUDIT_ID_ASC";
    PhysicalInventoryAuditItemsOrderBy["PHYSICAL_INVENTORY_AUDIT_ID_DESC"] = "PHYSICAL_INVENTORY_AUDIT_ID_DESC";
    PhysicalInventoryAuditItemsOrderBy["INVENTORY_ID_ASC"] = "INVENTORY_ID_ASC";
    PhysicalInventoryAuditItemsOrderBy["INVENTORY_ID_DESC"] = "INVENTORY_ID_DESC";
    PhysicalInventoryAuditItemsOrderBy["LOCATION_ID_ASC"] = "LOCATION_ID_ASC";
    PhysicalInventoryAuditItemsOrderBy["LOCATION_ID_DESC"] = "LOCATION_ID_DESC";
    PhysicalInventoryAuditItemsOrderBy["RECORDED_QUANTITY_ASC"] = "RECORDED_QUANTITY_ASC";
    PhysicalInventoryAuditItemsOrderBy["RECORDED_QUANTITY_DESC"] = "RECORDED_QUANTITY_DESC";
    PhysicalInventoryAuditItemsOrderBy["ACTUAL_QUANTITY_ASC"] = "ACTUAL_QUANTITY_ASC";
    PhysicalInventoryAuditItemsOrderBy["ACTUAL_QUANTITY_DESC"] = "ACTUAL_QUANTITY_DESC";
    PhysicalInventoryAuditItemsOrderBy["ADJUSTMENT_REASON_ASC"] = "ADJUSTMENT_REASON_ASC";
    PhysicalInventoryAuditItemsOrderBy["ADJUSTMENT_REASON_DESC"] = "ADJUSTMENT_REASON_DESC";
    PhysicalInventoryAuditItemsOrderBy["TRANSACTION_ID_ASC"] = "TRANSACTION_ID_ASC";
    PhysicalInventoryAuditItemsOrderBy["TRANSACTION_ID_DESC"] = "TRANSACTION_ID_DESC";
    PhysicalInventoryAuditItemsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    PhysicalInventoryAuditItemsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    PhysicalInventoryAuditItemsOrderBy["COMMENT_TEXT_ASC"] = "COMMENT_TEXT_ASC";
    PhysicalInventoryAuditItemsOrderBy["COMMENT_TEXT_DESC"] = "COMMENT_TEXT_DESC";
    PhysicalInventoryAuditItemsOrderBy["UPDATED_BY_USER_ID_ASC"] = "UPDATED_BY_USER_ID_ASC";
    PhysicalInventoryAuditItemsOrderBy["UPDATED_BY_USER_ID_DESC"] = "UPDATED_BY_USER_ID_DESC";
    PhysicalInventoryAuditItemsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    PhysicalInventoryAuditItemsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    PhysicalInventoryAuditItemsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    PhysicalInventoryAuditItemsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    PhysicalInventoryAuditItemsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    PhysicalInventoryAuditItemsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(PhysicalInventoryAuditItemsOrderBy || (PhysicalInventoryAuditItemsOrderBy = {}));
/** Methods to use when ordering `PhysicalInventoryAudit`. */
export var PhysicalInventoryAuditsOrderBy;
(function (PhysicalInventoryAuditsOrderBy) {
    PhysicalInventoryAuditsOrderBy["NATURAL"] = "NATURAL";
    PhysicalInventoryAuditsOrderBy["ID_ASC"] = "ID_ASC";
    PhysicalInventoryAuditsOrderBy["ID_DESC"] = "ID_DESC";
    PhysicalInventoryAuditsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    PhysicalInventoryAuditsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    PhysicalInventoryAuditsOrderBy["STATUS_ASC"] = "STATUS_ASC";
    PhysicalInventoryAuditsOrderBy["STATUS_DESC"] = "STATUS_DESC";
    PhysicalInventoryAuditsOrderBy["BUILDING_ASC"] = "BUILDING_ASC";
    PhysicalInventoryAuditsOrderBy["BUILDING_DESC"] = "BUILDING_DESC";
    PhysicalInventoryAuditsOrderBy["ITEM_COUNT_ASC"] = "ITEM_COUNT_ASC";
    PhysicalInventoryAuditsOrderBy["ITEM_COUNT_DESC"] = "ITEM_COUNT_DESC";
    PhysicalInventoryAuditsOrderBy["SAMPLE_SIZE_ASC"] = "SAMPLE_SIZE_ASC";
    PhysicalInventoryAuditsOrderBy["SAMPLE_SIZE_DESC"] = "SAMPLE_SIZE_DESC";
    PhysicalInventoryAuditsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    PhysicalInventoryAuditsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    PhysicalInventoryAuditsOrderBy["CLOSED_DATE_ASC"] = "CLOSED_DATE_ASC";
    PhysicalInventoryAuditsOrderBy["CLOSED_DATE_DESC"] = "CLOSED_DATE_DESC";
    PhysicalInventoryAuditsOrderBy["CLOSED_BY_USER_ID_ASC"] = "CLOSED_BY_USER_ID_ASC";
    PhysicalInventoryAuditsOrderBy["CLOSED_BY_USER_ID_DESC"] = "CLOSED_BY_USER_ID_DESC";
    PhysicalInventoryAuditsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    PhysicalInventoryAuditsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    PhysicalInventoryAuditsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    PhysicalInventoryAuditsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(PhysicalInventoryAuditsOrderBy || (PhysicalInventoryAuditsOrderBy = {}));
export var PhysicalInventoryAuditStatus;
(function (PhysicalInventoryAuditStatus) {
    PhysicalInventoryAuditStatus["OPEN"] = "OPEN";
    PhysicalInventoryAuditStatus["COMPLETE"] = "COMPLETE";
    PhysicalInventoryAuditStatus["CANCELED"] = "CANCELED";
})(PhysicalInventoryAuditStatus || (PhysicalInventoryAuditStatus = {}));
export var PhysicalInventoryAuditType;
(function (PhysicalInventoryAuditType) {
    PhysicalInventoryAuditType["WALL_TO_WALL"] = "WALL_TO_WALL";
    PhysicalInventoryAuditType["SAMPLE"] = "SAMPLE";
})(PhysicalInventoryAuditType || (PhysicalInventoryAuditType = {}));
export var PreciousMetal;
(function (PreciousMetal) {
    PreciousMetal["GOLD"] = "GOLD";
    PreciousMetal["IRIDIUM"] = "IRIDIUM";
    PreciousMetal["OSMIUM"] = "OSMIUM";
    PreciousMetal["PALLADIUM"] = "PALLADIUM";
    PreciousMetal["PLATINUM"] = "PLATINUM";
    PreciousMetal["RHODIUM"] = "RHODIUM";
    PreciousMetal["RUTHENIUM"] = "RUTHENIUM";
    PreciousMetal["SILVER"] = "SILVER";
})(PreciousMetal || (PreciousMetal = {}));
export var PressurizationRange;
(function (PressurizationRange) {
    PressurizationRange["ZERO_POINT_TWO_TO_ONE_POINT_FOUR_ONE_PSI"] = "ZERO_POINT_TWO_TO_ONE_POINT_FOUR_ONE_PSI";
    PressurizationRange["ONE_POINT_FOUR_ONE_TO_FIVE_POINT_THREE_PSI"] = "ONE_POINT_FOUR_ONE_TO_FIVE_POINT_THREE_PSI";
    PressurizationRange["FIVE_POINT_THREE_ONE_PLUS_PSI"] = "FIVE_POINT_THREE_ONE_PLUS_PSI";
})(PressurizationRange || (PressurizationRange = {}));
export var Priority;
(function (Priority) {
    Priority["HIGH"] = "HIGH";
    Priority["MEDIUM"] = "MEDIUM";
    Priority["LOW"] = "LOW";
})(Priority || (Priority = {}));
export var PropertyClass;
(function (PropertyClass) {
    PropertyClass["BUILDINGS"] = "BUILDINGS";
    PropertyClass["COMPUTER_SOFTWARE"] = "COMPUTER_SOFTWARE";
    PropertyClass["LAND"] = "LAND";
    PropertyClass["MATERIAL"] = "MATERIAL";
    PropertyClass["NEEDS_ASSIGNMENT"] = "NEEDS_ASSIGNMENT";
    PropertyClass["OTHER_STRUCTURES"] = "OTHER_STRUCTURES";
    PropertyClass["PLANT_EQUIPMENT"] = "PLANT_EQUIPMENT";
    PropertyClass["SENSITIVE_EQUIPMENT"] = "SENSITIVE_EQUIPMENT";
    PropertyClass["SERVICE_MAINTENANCE"] = "SERVICE_MAINTENANCE";
    PropertyClass["SPACE_HARDWARE_APP"] = "SPACE_HARDWARE_APP";
    PropertyClass["SPECIAL_TEST_EQUIPMENT"] = "SPECIAL_TEST_EQUIPMENT";
    PropertyClass["SPECIAL_TOOLING"] = "SPECIAL_TOOLING";
})(PropertyClass || (PropertyClass = {}));
export var RcaRootCauseCategory;
(function (RcaRootCauseCategory) {
    RcaRootCauseCategory["PERSONAL_WARRANTY_VIOLATION"] = "PERSONAL_WARRANTY_VIOLATION";
    RcaRootCauseCategory["LACK_OF_TRAINING_OR_MENTORSHIP"] = "LACK_OF_TRAINING_OR_MENTORSHIP";
    RcaRootCauseCategory["POOR_COMMUNICATION_AMONG_STAKEHOLDERS_TEAM"] = "POOR_COMMUNICATION_AMONG_STAKEHOLDERS_TEAM";
    RcaRootCauseCategory["LACK_OF_DOCUMENTED_PROCEDURE"] = "LACK_OF_DOCUMENTED_PROCEDURE";
    RcaRootCauseCategory["ERROR_IN_PROCEDURE"] = "ERROR_IN_PROCEDURE";
    RcaRootCauseCategory["LACK_OF_A_DEFINED_SCOPE_OR_POOR_UNDERSTANDING_OF_SCOPE"] = "LACK_OF_A_DEFINED_SCOPE_OR_POOR_UNDERSTANDING_OF_SCOPE";
    RcaRootCauseCategory["CUSTOMER_INITIATED_SCHEDULE_PRESSURE_ADDITIONAL_SCOPE"] = "CUSTOMER_INITIATED_SCHEDULE_PRESSURE_ADDITIONAL_SCOPE";
    RcaRootCauseCategory["POOR_PROJECT_MANAGEMENT"] = "POOR_PROJECT_MANAGEMENT";
    RcaRootCauseCategory["LACK_OF_MAINTENANCE_UPKEEP"] = "LACK_OF_MAINTENANCE_UPKEEP";
    RcaRootCauseCategory["POOR_ENGINEERING_OR_DESIGN"] = "POOR_ENGINEERING_OR_DESIGN";
    RcaRootCauseCategory["POOR_INSPECTION_OF_FINAL_PRODUCT"] = "POOR_INSPECTION_OF_FINAL_PRODUCT";
    RcaRootCauseCategory["POOR_ASSEMBLY_TEST_OF_PRODUCT"] = "POOR_ASSEMBLY_TEST_OF_PRODUCT";
    RcaRootCauseCategory["HUMAN_ERROR"] = "HUMAN_ERROR";
})(RcaRootCauseCategory || (RcaRootCauseCategory = {}));
export var ReadinessReportInclusionReason;
(function (ReadinessReportInclusionReason) {
    ReadinessReportInclusionReason["OPEN_DOCUMENT_ITEM"] = "OPEN_DOCUMENT_ITEM";
    ReadinessReportInclusionReason["OPEN_MANIFEST_ITEM"] = "OPEN_MANIFEST_ITEM";
    ReadinessReportInclusionReason["LIMITED_LIFE_ITEM"] = "LIMITED_LIFE_ITEM";
})(ReadinessReportInclusionReason || (ReadinessReportInclusionReason = {}));
export var ReadinessStatus;
(function (ReadinessStatus) {
    ReadinessStatus["GO"] = "GO";
    ReadinessStatus["NO_GO"] = "NO_GO";
    ReadinessStatus["CHECK"] = "CHECK";
})(ReadinessStatus || (ReadinessStatus = {}));
/** Methods to use when ordering `ResponsibleOrganization`. */
export var ResponsibleOrganizationsOrderBy;
(function (ResponsibleOrganizationsOrderBy) {
    ResponsibleOrganizationsOrderBy["NATURAL"] = "NATURAL";
    ResponsibleOrganizationsOrderBy["ORGANIZATION_CODE_ASC"] = "ORGANIZATION_CODE_ASC";
    ResponsibleOrganizationsOrderBy["ORGANIZATION_CODE_DESC"] = "ORGANIZATION_CODE_DESC";
    ResponsibleOrganizationsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    ResponsibleOrganizationsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    ResponsibleOrganizationsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    ResponsibleOrganizationsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    ResponsibleOrganizationsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    ResponsibleOrganizationsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    ResponsibleOrganizationsOrderBy["PART_IDENTIFIER_ASC"] = "PART_IDENTIFIER_ASC";
    ResponsibleOrganizationsOrderBy["PART_IDENTIFIER_DESC"] = "PART_IDENTIFIER_DESC";
    ResponsibleOrganizationsOrderBy["IS_PREFERRED_ASC"] = "IS_PREFERRED_ASC";
    ResponsibleOrganizationsOrderBy["IS_PREFERRED_DESC"] = "IS_PREFERRED_DESC";
    ResponsibleOrganizationsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    ResponsibleOrganizationsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    ResponsibleOrganizationsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    ResponsibleOrganizationsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
})(ResponsibleOrganizationsOrderBy || (ResponsibleOrganizationsOrderBy = {}));
export var Side;
(function (Side) {
    Side["LEFT"] = "LEFT";
    Side["RIGHT"] = "RIGHT";
    Side["NONE"] = "NONE";
})(Side || (Side = {}));
export var TpsDocumentType;
(function (TpsDocumentType) {
    TpsDocumentType["CONFIGURATION_CHANGE"] = "CONFIGURATION_CHANGE";
    TpsDocumentType["FABRICATION"] = "FABRICATION";
    TpsDocumentType["ASSEMBLY"] = "ASSEMBLY";
    TpsDocumentType["TEMPORARY_CONFIGURATION_CHANGE"] = "TEMPORARY_CONFIGURATION_CHANGE";
    TpsDocumentType["DOWNGRADE_NON_DISCREPANT"] = "DOWNGRADE_NON_DISCREPANT";
    TpsDocumentType["CLASSIFICATION"] = "CLASSIFICATION";
    TpsDocumentType["ACTIVATION_INACTIVATION_OF_HARDWARE"] = "ACTIVATION_INACTIVATION_OF_HARDWARE";
    TpsDocumentType["OTHER_CONFIGURATION_CHANGE"] = "OTHER_CONFIGURATION_CHANGE";
    TpsDocumentType["MAINTENANCE"] = "MAINTENANCE";
    TpsDocumentType["ACCEPTANCE_TEST"] = "ACCEPTANCE_TEST";
    TpsDocumentType["QUALIFICATION_CERTIFICATION_TEST"] = "QUALIFICATION_CERTIFICATION_TEST";
    TpsDocumentType["TEST_CALIBRATION"] = "TEST_CALIBRATION";
    TpsDocumentType["ENGINEERING_EVALUATION"] = "ENGINEERING_EVALUATION";
    TpsDocumentType["TRANSFER_ROUND_ROBIN"] = "TRANSFER_ROUND_ROBIN";
    TpsDocumentType["OTHER_NON_CONFIGURATION_CHANGE"] = "OTHER_NON_CONFIGURATION_CHANGE";
    TpsDocumentType["POST_FLIGHT_ACTIVITY"] = "POST_FLIGHT_ACTIVITY";
    TpsDocumentType["FABRICATION_I_E"] = "FABRICATION_I_E";
    TpsDocumentType["I_E_PROJECT_WORK"] = "I_E_PROJECT_WORK";
})(TpsDocumentType || (TpsDocumentType = {}));
/** Methods to use when ordering `Transaction`. */
export var TransactionsOrderBy;
(function (TransactionsOrderBy) {
    TransactionsOrderBy["NATURAL"] = "NATURAL";
    TransactionsOrderBy["ID_ASC"] = "ID_ASC";
    TransactionsOrderBy["ID_DESC"] = "ID_DESC";
    TransactionsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    TransactionsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    TransactionsOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    TransactionsOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    TransactionsOrderBy["PARENT_ITEM_INSTANCE_ID_ASC"] = "PARENT_ITEM_INSTANCE_ID_ASC";
    TransactionsOrderBy["PARENT_ITEM_INSTANCE_ID_DESC"] = "PARENT_ITEM_INSTANCE_ID_DESC";
    TransactionsOrderBy["FROM_LOCATION_ID_ASC"] = "FROM_LOCATION_ID_ASC";
    TransactionsOrderBy["FROM_LOCATION_ID_DESC"] = "FROM_LOCATION_ID_DESC";
    TransactionsOrderBy["TO_LOCATION_ID_ASC"] = "TO_LOCATION_ID_ASC";
    TransactionsOrderBy["TO_LOCATION_ID_DESC"] = "TO_LOCATION_ID_DESC";
    TransactionsOrderBy["CHANGE_SET_ID_ASC"] = "CHANGE_SET_ID_ASC";
    TransactionsOrderBy["CHANGE_SET_ID_DESC"] = "CHANGE_SET_ID_DESC";
    TransactionsOrderBy["UNIT_COST_ASC"] = "UNIT_COST_ASC";
    TransactionsOrderBy["UNIT_COST_DESC"] = "UNIT_COST_DESC";
    TransactionsOrderBy["IS_COST_ESTIMATED_ASC"] = "IS_COST_ESTIMATED_ASC";
    TransactionsOrderBy["IS_COST_ESTIMATED_DESC"] = "IS_COST_ESTIMATED_DESC";
    TransactionsOrderBy["QUANTITY_ASC"] = "QUANTITY_ASC";
    TransactionsOrderBy["QUANTITY_DESC"] = "QUANTITY_DESC";
    TransactionsOrderBy["PROJECT_CODE_ASC"] = "PROJECT_CODE_ASC";
    TransactionsOrderBy["PROJECT_CODE_DESC"] = "PROJECT_CODE_DESC";
    TransactionsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    TransactionsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    TransactionsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    TransactionsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    TransactionsOrderBy["TOTAL_COST_ASC"] = "TOTAL_COST_ASC";
    TransactionsOrderBy["TOTAL_COST_DESC"] = "TOTAL_COST_DESC";
    TransactionsOrderBy["AUTHORITY_CODE_ASC"] = "AUTHORITY_CODE_ASC";
    TransactionsOrderBy["AUTHORITY_CODE_DESC"] = "AUTHORITY_CODE_DESC";
    TransactionsOrderBy["AUTHORITY_NUMBER_ASC"] = "AUTHORITY_NUMBER_ASC";
    TransactionsOrderBy["AUTHORITY_NUMBER_DESC"] = "AUTHORITY_NUMBER_DESC";
    TransactionsOrderBy["_TRANSACTION_DATE_ASC"] = "_TRANSACTION_DATE_ASC";
    TransactionsOrderBy["_TRANSACTION_DATE_DESC"] = "_TRANSACTION_DATE_DESC";
    TransactionsOrderBy["FEDERAL_SUPPLY_CLASS_ASC"] = "FEDERAL_SUPPLY_CLASS_ASC";
    TransactionsOrderBy["FEDERAL_SUPPLY_CLASS_DESC"] = "FEDERAL_SUPPLY_CLASS_DESC";
    TransactionsOrderBy["OBJECT_CLASS_ASC"] = "OBJECT_CLASS_ASC";
    TransactionsOrderBy["OBJECT_CLASS_DESC"] = "OBJECT_CLASS_DESC";
    TransactionsOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    TransactionsOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    TransactionsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    TransactionsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    TransactionsOrderBy["IS_REPORTABLE_ASC"] = "IS_REPORTABLE_ASC";
    TransactionsOrderBy["IS_REPORTABLE_DESC"] = "IS_REPORTABLE_DESC";
    TransactionsOrderBy["BUILDING_ASC"] = "BUILDING_ASC";
    TransactionsOrderBy["BUILDING_DESC"] = "BUILDING_DESC";
    TransactionsOrderBy["CONTRACT_NUMBER_ASC"] = "CONTRACT_NUMBER_ASC";
    TransactionsOrderBy["CONTRACT_NUMBER_DESC"] = "CONTRACT_NUMBER_DESC";
    TransactionsOrderBy["TRANSACTION_DATE_ASC"] = "TRANSACTION_DATE_ASC";
    TransactionsOrderBy["TRANSACTION_DATE_DESC"] = "TRANSACTION_DATE_DESC";
    TransactionsOrderBy["ITEM_CLASS_ASC"] = "ITEM_CLASS_ASC";
    TransactionsOrderBy["ITEM_CLASS_DESC"] = "ITEM_CLASS_DESC";
    TransactionsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    TransactionsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(TransactionsOrderBy || (TransactionsOrderBy = {}));
export var TransactionType;
(function (TransactionType) {
    TransactionType["COSMIC_SYSTEM_CORRECTION"] = "COSMIC_SYSTEM_CORRECTION";
    TransactionType["COST_CORRECTION"] = "COST_CORRECTION";
    TransactionType["INSTALL"] = "INSTALL";
    TransactionType["INVENTORY_AUDIT_CORRECTION"] = "INVENTORY_AUDIT_CORRECTION";
    TransactionType["INVENTORY_AUDIT_VERIFICATION"] = "INVENTORY_AUDIT_VERIFICATION";
    TransactionType["INVENTORY_CHECK"] = "INVENTORY_CHECK";
    TransactionType["ISSUE"] = "ISSUE";
    TransactionType["ISSUE_ASSEMBLED"] = "ISSUE_ASSEMBLED";
    TransactionType["MERGE_CORRECTION"] = "MERGE_CORRECTION";
    TransactionType["RECEIPT"] = "RECEIPT";
    TransactionType["RECEIPT_ASSEMBLED"] = "RECEIPT_ASSEMBLED";
    TransactionType["REMOVE"] = "REMOVE";
    TransactionType["SERIALIZE"] = "SERIALIZE";
    TransactionType["SERIALIZE_FROM"] = "SERIALIZE_FROM";
    TransactionType["SERIALIZE_FROM_ISSUED"] = "SERIALIZE_FROM_ISSUED";
    TransactionType["SERIALIZE_ISSUED"] = "SERIALIZE_ISSUED";
    TransactionType["TRANSFER_ISSUED"] = "TRANSFER_ISSUED";
    TransactionType["TRANSFER_ISSUED_ASSEMBLED"] = "TRANSFER_ISSUED_ASSEMBLED";
    TransactionType["TRANSFER_TO"] = "TRANSFER_TO";
    TransactionType["TRANSFER_ASSEMBLED_TO"] = "TRANSFER_ASSEMBLED_TO";
    TransactionType["TRANSFER_FROM"] = "TRANSFER_FROM";
    TransactionType["TRANSFER_ASSEMBLED_FROM"] = "TRANSFER_ASSEMBLED_FROM";
})(TransactionType || (TransactionType = {}));
export var UnitType;
(function (UnitType) {
    UnitType["DEGREE"] = "DEGREE";
    UnitType["RADIAN"] = "RADIAN";
    UnitType["INCH"] = "INCH";
    UnitType["FOOT"] = "FOOT";
    UnitType["YARD"] = "YARD";
    UnitType["METER"] = "METER";
    UnitType["SQUARE_INCH"] = "SQUARE_INCH";
    UnitType["SQUARE_FOOT"] = "SQUARE_FOOT";
    UnitType["SQUARE_YARD"] = "SQUARE_YARD";
    UnitType["SQUARE_METER"] = "SQUARE_METER";
    UnitType["GALLON"] = "GALLON";
    UnitType["OUNCE"] = "OUNCE";
    UnitType["PINT"] = "PINT";
    UnitType["QUART"] = "QUART";
    UnitType["MILLILITER"] = "MILLILITER";
    UnitType["LITER"] = "LITER";
    UnitType["CUBIC_FOOT"] = "CUBIC_FOOT";
    UnitType["CUBIC_INCH"] = "CUBIC_INCH";
    UnitType["POUND"] = "POUND";
    UnitType["GRAM"] = "GRAM";
    UnitType["KILOGRAM"] = "KILOGRAM";
    UnitType["SECOND"] = "SECOND";
    UnitType["MINUTE"] = "MINUTE";
    UnitType["HOUR"] = "HOUR";
    UnitType["DAY"] = "DAY";
    UnitType["MONTH"] = "MONTH";
    UnitType["YEAR"] = "YEAR";
    UnitType["SHOE_US"] = "SHOE_US";
    UnitType["PH"] = "PH";
    UnitType["CFU_PER_MILLILITER"] = "CFU_PER_MILLILITER";
    UnitType["MICRO_SEMENS_PER_CENTIMETER"] = "MICRO_SEMENS_PER_CENTIMETER";
    UnitType["PARTS_PER_BILLION"] = "PARTS_PER_BILLION";
    UnitType["MILLIMETER"] = "MILLIMETER";
    UnitType["DOZEN"] = "DOZEN";
    UnitType["CENTIMETER"] = "CENTIMETER";
})(UnitType || (UnitType = {}));
/** Reasons that two item instances are not mergeable. */
export var UnmergeabilityReason;
(function (UnmergeabilityReason) {
    UnmergeabilityReason["SOURCE_HAS_CROSS_CONTEXT_DATA"] = "SOURCE_HAS_CROSS_CONTEXT_DATA";
    UnmergeabilityReason["SOURCE_HAS_OPEN_CHANGE_SETS"] = "SOURCE_HAS_OPEN_CHANGE_SETS";
    UnmergeabilityReason["INVENTORY_CONFLICT"] = "INVENTORY_CONFLICT";
    UnmergeabilityReason["SOURCE_HAS_OPEN_PHYSICAL_INVENTORY_AUDITS"] = "SOURCE_HAS_OPEN_PHYSICAL_INVENTORY_AUDITS";
    UnmergeabilityReason["DEST_HAS_OPEN_PHYSICAL_INVENTORY_AUDITS"] = "DEST_HAS_OPEN_PHYSICAL_INVENTORY_AUDITS";
})(UnmergeabilityReason || (UnmergeabilityReason = {}));
/** Methods to use when ordering `UserGroup`. */
export var UserGroupsOrderBy;
(function (UserGroupsOrderBy) {
    UserGroupsOrderBy["NATURAL"] = "NATURAL";
    UserGroupsOrderBy["CODE_ASC"] = "CODE_ASC";
    UserGroupsOrderBy["CODE_DESC"] = "CODE_DESC";
    UserGroupsOrderBy["NAME_ASC"] = "NAME_ASC";
    UserGroupsOrderBy["NAME_DESC"] = "NAME_DESC";
    UserGroupsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    UserGroupsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    UserGroupsOrderBy["DELEGATE_GROUP_FOR_USER_ID_ASC"] = "DELEGATE_GROUP_FOR_USER_ID_ASC";
    UserGroupsOrderBy["DELEGATE_GROUP_FOR_USER_ID_DESC"] = "DELEGATE_GROUP_FOR_USER_ID_DESC";
    UserGroupsOrderBy["IS_IPT_ASC"] = "IS_IPT_ASC";
    UserGroupsOrderBy["IS_IPT_DESC"] = "IS_IPT_DESC";
    UserGroupsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    UserGroupsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    UserGroupsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    UserGroupsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    UserGroupsOrderBy["IS_ARCHIVED_ASC"] = "IS_ARCHIVED_ASC";
    UserGroupsOrderBy["IS_ARCHIVED_DESC"] = "IS_ARCHIVED_DESC";
    UserGroupsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    UserGroupsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(UserGroupsOrderBy || (UserGroupsOrderBy = {}));
export var UserGroupType;
(function (UserGroupType) {
    /** System-generated user group. */
    UserGroupType["SYSTEM"] = "SYSTEM";
    /** User created and managed user group. */
    UserGroupType["USER_MANAGED"] = "USER_MANAGED";
    /** Auto-generated delegate group for a specific user. */
    UserGroupType["USER_DELEGATE"] = "USER_DELEGATE";
})(UserGroupType || (UserGroupType = {}));
export var UserRole;
(function (UserRole) {
    /** Super admin with elevated cross-context privileges */
    UserRole["ADMIN"] = "ADMIN";
    /** Non-human user for automated tasks */
    UserRole["SYSTEM"] = "SYSTEM";
    /** Normal application user */
    UserRole["USER"] = "USER";
    /** User can no longer log in and does not show up in searches */
    UserRole["DISABLED"] = "DISABLED";
})(UserRole || (UserRole = {}));
/** Methods to use when ordering `User`. */
export var UsersOrderBy;
(function (UsersOrderBy) {
    UsersOrderBy["NATURAL"] = "NATURAL";
    UsersOrderBy["ID_ASC"] = "ID_ASC";
    UsersOrderBy["ID_DESC"] = "ID_DESC";
    UsersOrderBy["ACCOUNT_ID_ASC"] = "ACCOUNT_ID_ASC";
    UsersOrderBy["ACCOUNT_ID_DESC"] = "ACCOUNT_ID_DESC";
    UsersOrderBy["ROLE_ASC"] = "ROLE_ASC";
    UsersOrderBy["ROLE_DESC"] = "ROLE_DESC";
    UsersOrderBy["CONTACT_ID_ASC"] = "CONTACT_ID_ASC";
    UsersOrderBy["CONTACT_ID_DESC"] = "CONTACT_ID_DESC";
    UsersOrderBy["IS_PENDING_FIRST_LOGIN_ASC"] = "IS_PENDING_FIRST_LOGIN_ASC";
    UsersOrderBy["IS_PENDING_FIRST_LOGIN_DESC"] = "IS_PENDING_FIRST_LOGIN_DESC";
    UsersOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    UsersOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    UsersOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    UsersOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    UsersOrderBy["EMAIL_ASC"] = "EMAIL_ASC";
    UsersOrderBy["EMAIL_DESC"] = "EMAIL_DESC";
    UsersOrderBy["AUID_ASC"] = "AUID_ASC";
    UsersOrderBy["AUID_DESC"] = "AUID_DESC";
    UsersOrderBy["UUPIC_ASC"] = "UUPIC_ASC";
    UsersOrderBy["UUPIC_DESC"] = "UUPIC_DESC";
    UsersOrderBy["FIRST_NAME_ASC"] = "FIRST_NAME_ASC";
    UsersOrderBy["FIRST_NAME_DESC"] = "FIRST_NAME_DESC";
    UsersOrderBy["MIDDLE_NAME_ASC"] = "MIDDLE_NAME_ASC";
    UsersOrderBy["MIDDLE_NAME_DESC"] = "MIDDLE_NAME_DESC";
    UsersOrderBy["LAST_NAME_ASC"] = "LAST_NAME_ASC";
    UsersOrderBy["LAST_NAME_DESC"] = "LAST_NAME_DESC";
    UsersOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    UsersOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    UsersOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    UsersOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    UsersOrderBy["SORT_NAME_ASC"] = "SORT_NAME_ASC";
    UsersOrderBy["SORT_NAME_DESC"] = "SORT_NAME_DESC";
    UsersOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    UsersOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(UsersOrderBy || (UsersOrderBy = {}));
/** Methods to use when ordering `UsersToUserGroup`. */
export var UsersToUserGroupsOrderBy;
(function (UsersToUserGroupsOrderBy) {
    UsersToUserGroupsOrderBy["NATURAL"] = "NATURAL";
    UsersToUserGroupsOrderBy["GROUP_CODE_ASC"] = "GROUP_CODE_ASC";
    UsersToUserGroupsOrderBy["GROUP_CODE_DESC"] = "GROUP_CODE_DESC";
    UsersToUserGroupsOrderBy["USER_ID_ASC"] = "USER_ID_ASC";
    UsersToUserGroupsOrderBy["USER_ID_DESC"] = "USER_ID_DESC";
    UsersToUserGroupsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    UsersToUserGroupsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    UsersToUserGroupsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    UsersToUserGroupsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    UsersToUserGroupsOrderBy["AUID_ASC"] = "AUID_ASC";
    UsersToUserGroupsOrderBy["AUID_DESC"] = "AUID_DESC";
    UsersToUserGroupsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    UsersToUserGroupsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(UsersToUserGroupsOrderBy || (UsersToUserGroupsOrderBy = {}));
/** Methods to use when ordering `UserSupportContext`. */
export var UserSupportContextsOrderBy;
(function (UserSupportContextsOrderBy) {
    UserSupportContextsOrderBy["NATURAL"] = "NATURAL";
    UserSupportContextsOrderBy["ORGANIZATION_CODE_ASC"] = "ORGANIZATION_CODE_ASC";
    UserSupportContextsOrderBy["ORGANIZATION_CODE_DESC"] = "ORGANIZATION_CODE_DESC";
    UserSupportContextsOrderBy["PERMISSIONS_ASC"] = "PERMISSIONS_ASC";
    UserSupportContextsOrderBy["PERMISSIONS_DESC"] = "PERMISSIONS_DESC";
    UserSupportContextsOrderBy["USER_ID_ASC"] = "USER_ID_ASC";
    UserSupportContextsOrderBy["USER_ID_DESC"] = "USER_ID_DESC";
    UserSupportContextsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    UserSupportContextsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    UserSupportContextsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    UserSupportContextsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    UserSupportContextsOrderBy["ROLES_ASC"] = "ROLES_ASC";
    UserSupportContextsOrderBy["ROLES_DESC"] = "ROLES_DESC";
    UserSupportContextsOrderBy["AUID_ASC"] = "AUID_ASC";
    UserSupportContextsOrderBy["AUID_DESC"] = "AUID_DESC";
    UserSupportContextsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    UserSupportContextsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(UserSupportContextsOrderBy || (UserSupportContextsOrderBy = {}));
export var UsState;
(function (UsState) {
    UsState["AL"] = "AL";
    UsState["AK"] = "AK";
    UsState["AZ"] = "AZ";
    UsState["AR"] = "AR";
    UsState["CA"] = "CA";
    UsState["CO"] = "CO";
    UsState["CT"] = "CT";
    UsState["DE"] = "DE";
    UsState["DC"] = "DC";
    UsState["FL"] = "FL";
    UsState["GA"] = "GA";
    UsState["GU"] = "GU";
    UsState["HI"] = "HI";
    UsState["ID"] = "ID";
    UsState["IL"] = "IL";
    UsState["IN"] = "IN";
    UsState["IA"] = "IA";
    UsState["KS"] = "KS";
    UsState["KY"] = "KY";
    UsState["LA"] = "LA";
    UsState["ME"] = "ME";
    UsState["MD"] = "MD";
    UsState["MA"] = "MA";
    UsState["MI"] = "MI";
    UsState["MN"] = "MN";
    UsState["MS"] = "MS";
    UsState["MO"] = "MO";
    UsState["MT"] = "MT";
    UsState["NE"] = "NE";
    UsState["NV"] = "NV";
    UsState["NH"] = "NH";
    UsState["NJ"] = "NJ";
    UsState["NM"] = "NM";
    UsState["NY"] = "NY";
    UsState["NC"] = "NC";
    UsState["ND"] = "ND";
    UsState["OH"] = "OH";
    UsState["OK"] = "OK";
    UsState["OR"] = "OR";
    UsState["PW"] = "PW";
    UsState["PA"] = "PA";
    UsState["PR"] = "PR";
    UsState["RI"] = "RI";
    UsState["SC"] = "SC";
    UsState["SD"] = "SD";
    UsState["TN"] = "TN";
    UsState["TX"] = "TX";
    UsState["UT"] = "UT";
    UsState["VT"] = "VT";
    UsState["VI"] = "VI";
    UsState["VA"] = "VA";
    UsState["WA"] = "WA";
    UsState["WV"] = "WV";
    UsState["WI"] = "WI";
    UsState["WY"] = "WY";
})(UsState || (UsState = {}));
/** Methods to use when ordering `Vehicle`. */
export var VehiclesOrderBy;
(function (VehiclesOrderBy) {
    VehiclesOrderBy["NATURAL"] = "NATURAL";
    VehiclesOrderBy["ID_ASC"] = "ID_ASC";
    VehiclesOrderBy["ID_DESC"] = "ID_DESC";
    VehiclesOrderBy["NAME_ASC"] = "NAME_ASC";
    VehiclesOrderBy["NAME_DESC"] = "NAME_DESC";
    VehiclesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    VehiclesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    VehiclesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    VehiclesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    VehiclesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    VehiclesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(VehiclesOrderBy || (VehiclesOrderBy = {}));
/** Methods to use when ordering `WorkingAssembly`. */
export var WorkingAssembliesOrderBy;
(function (WorkingAssembliesOrderBy) {
    WorkingAssembliesOrderBy["NATURAL"] = "NATURAL";
    WorkingAssembliesOrderBy["ID_ASC"] = "ID_ASC";
    WorkingAssembliesOrderBy["ID_DESC"] = "ID_DESC";
    WorkingAssembliesOrderBy["PARENT_ID_ASC"] = "PARENT_ID_ASC";
    WorkingAssembliesOrderBy["PARENT_ID_DESC"] = "PARENT_ID_DESC";
    WorkingAssembliesOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    WorkingAssembliesOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    WorkingAssembliesOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    WorkingAssembliesOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    WorkingAssembliesOrderBy["SIDE_ASC"] = "SIDE_ASC";
    WorkingAssembliesOrderBy["SIDE_DESC"] = "SIDE_DESC";
    WorkingAssembliesOrderBy["SEQUENCE_ASC"] = "SEQUENCE_ASC";
    WorkingAssembliesOrderBy["SEQUENCE_DESC"] = "SEQUENCE_DESC";
    WorkingAssembliesOrderBy["INSTALLED_ON_ASC"] = "INSTALLED_ON_ASC";
    WorkingAssembliesOrderBy["INSTALLED_ON_DESC"] = "INSTALLED_ON_DESC";
    WorkingAssembliesOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    WorkingAssembliesOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    WorkingAssembliesOrderBy["ASSEMBLY_TEMPLATE_ID_ASC"] = "ASSEMBLY_TEMPLATE_ID_ASC";
    WorkingAssembliesOrderBy["ASSEMBLY_TEMPLATE_ID_DESC"] = "ASSEMBLY_TEMPLATE_ID_DESC";
    WorkingAssembliesOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    WorkingAssembliesOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    WorkingAssembliesOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    WorkingAssembliesOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    WorkingAssembliesOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    WorkingAssembliesOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(WorkingAssembliesOrderBy || (WorkingAssembliesOrderBy = {}));
/** Methods to use when ordering `WorkingAssemblyInventoryNode`. */
export var WorkingAssemblyInventoryNodesOrderBy;
(function (WorkingAssemblyInventoryNodesOrderBy) {
    WorkingAssemblyInventoryNodesOrderBy["NATURAL"] = "NATURAL";
    WorkingAssemblyInventoryNodesOrderBy["NODE_DEPTH_ASC"] = "NODE_DEPTH_ASC";
    WorkingAssemblyInventoryNodesOrderBy["NODE_DEPTH_DESC"] = "NODE_DEPTH_DESC";
    WorkingAssemblyInventoryNodesOrderBy["SEQUENCE_ASC"] = "SEQUENCE_ASC";
    WorkingAssemblyInventoryNodesOrderBy["SEQUENCE_DESC"] = "SEQUENCE_DESC";
    WorkingAssemblyInventoryNodesOrderBy["SORT_KEY_ASC"] = "SORT_KEY_ASC";
    WorkingAssemblyInventoryNodesOrderBy["SORT_KEY_DESC"] = "SORT_KEY_DESC";
    WorkingAssemblyInventoryNodesOrderBy["INVENTORY_ID_ASC"] = "INVENTORY_ID_ASC";
    WorkingAssemblyInventoryNodesOrderBy["INVENTORY_ID_DESC"] = "INVENTORY_ID_DESC";
    WorkingAssemblyInventoryNodesOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    WorkingAssemblyInventoryNodesOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    WorkingAssemblyInventoryNodesOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    WorkingAssemblyInventoryNodesOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    WorkingAssemblyInventoryNodesOrderBy["SIDE_ASC"] = "SIDE_ASC";
    WorkingAssemblyInventoryNodesOrderBy["SIDE_DESC"] = "SIDE_DESC";
    WorkingAssemblyInventoryNodesOrderBy["SERIAL_NUMBER_ASC"] = "SERIAL_NUMBER_ASC";
    WorkingAssemblyInventoryNodesOrderBy["SERIAL_NUMBER_DESC"] = "SERIAL_NUMBER_DESC";
    WorkingAssemblyInventoryNodesOrderBy["LOT_NUMBER_ASC"] = "LOT_NUMBER_ASC";
    WorkingAssemblyInventoryNodesOrderBy["LOT_NUMBER_DESC"] = "LOT_NUMBER_DESC";
    WorkingAssemblyInventoryNodesOrderBy["WORKING_ASSEMBLY_ID_ASC"] = "WORKING_ASSEMBLY_ID_ASC";
    WorkingAssemblyInventoryNodesOrderBy["WORKING_ASSEMBLY_ID_DESC"] = "WORKING_ASSEMBLY_ID_DESC";
    WorkingAssemblyInventoryNodesOrderBy["ROOT_INVENTORY_ID_ASC"] = "ROOT_INVENTORY_ID_ASC";
    WorkingAssemblyInventoryNodesOrderBy["ROOT_INVENTORY_ID_DESC"] = "ROOT_INVENTORY_ID_DESC";
})(WorkingAssemblyInventoryNodesOrderBy || (WorkingAssemblyInventoryNodesOrderBy = {}));
/** Methods to use when ordering `WorkOrderStepFormField`. */
export var WorkOrderStepFormFieldsOrderBy;
(function (WorkOrderStepFormFieldsOrderBy) {
    WorkOrderStepFormFieldsOrderBy["NATURAL"] = "NATURAL";
    WorkOrderStepFormFieldsOrderBy["ID_ASC"] = "ID_ASC";
    WorkOrderStepFormFieldsOrderBy["ID_DESC"] = "ID_DESC";
    WorkOrderStepFormFieldsOrderBy["WORK_ORDER_STEP_ID_ASC"] = "WORK_ORDER_STEP_ID_ASC";
    WorkOrderStepFormFieldsOrderBy["WORK_ORDER_STEP_ID_DESC"] = "WORK_ORDER_STEP_ID_DESC";
    WorkOrderStepFormFieldsOrderBy["SEQUENCE_ASC"] = "SEQUENCE_ASC";
    WorkOrderStepFormFieldsOrderBy["SEQUENCE_DESC"] = "SEQUENCE_DESC";
    WorkOrderStepFormFieldsOrderBy["NAME_ASC"] = "NAME_ASC";
    WorkOrderStepFormFieldsOrderBy["NAME_DESC"] = "NAME_DESC";
    WorkOrderStepFormFieldsOrderBy["FIELD_TYPE_ASC"] = "FIELD_TYPE_ASC";
    WorkOrderStepFormFieldsOrderBy["FIELD_TYPE_DESC"] = "FIELD_TYPE_DESC";
    WorkOrderStepFormFieldsOrderBy["PURPOSE_ASC"] = "PURPOSE_ASC";
    WorkOrderStepFormFieldsOrderBy["PURPOSE_DESC"] = "PURPOSE_DESC";
    WorkOrderStepFormFieldsOrderBy["IS_REQUIRED_ASC"] = "IS_REQUIRED_ASC";
    WorkOrderStepFormFieldsOrderBy["IS_REQUIRED_DESC"] = "IS_REQUIRED_DESC";
    WorkOrderStepFormFieldsOrderBy["CHOICES_ASC"] = "CHOICES_ASC";
    WorkOrderStepFormFieldsOrderBy["CHOICES_DESC"] = "CHOICES_DESC";
    WorkOrderStepFormFieldsOrderBy["COMPLETED_CHOICES_ASC"] = "COMPLETED_CHOICES_ASC";
    WorkOrderStepFormFieldsOrderBy["COMPLETED_CHOICES_DESC"] = "COMPLETED_CHOICES_DESC";
    WorkOrderStepFormFieldsOrderBy["COMPLETED_VALUE_ASC"] = "COMPLETED_VALUE_ASC";
    WorkOrderStepFormFieldsOrderBy["COMPLETED_VALUE_DESC"] = "COMPLETED_VALUE_DESC";
    WorkOrderStepFormFieldsOrderBy["COMPLETED_DATE_TIME_ASC"] = "COMPLETED_DATE_TIME_ASC";
    WorkOrderStepFormFieldsOrderBy["COMPLETED_DATE_TIME_DESC"] = "COMPLETED_DATE_TIME_DESC";
    WorkOrderStepFormFieldsOrderBy["COMPLETED_BY_USER_ID_ASC"] = "COMPLETED_BY_USER_ID_ASC";
    WorkOrderStepFormFieldsOrderBy["COMPLETED_BY_USER_ID_DESC"] = "COMPLETED_BY_USER_ID_DESC";
    WorkOrderStepFormFieldsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    WorkOrderStepFormFieldsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    WorkOrderStepFormFieldsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    WorkOrderStepFormFieldsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    WorkOrderStepFormFieldsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    WorkOrderStepFormFieldsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(WorkOrderStepFormFieldsOrderBy || (WorkOrderStepFormFieldsOrderBy = {}));
/** Methods to use when ordering `WorkOrderStep`. */
export var WorkOrderStepsOrderBy;
(function (WorkOrderStepsOrderBy) {
    WorkOrderStepsOrderBy["NATURAL"] = "NATURAL";
    WorkOrderStepsOrderBy["ID_ASC"] = "ID_ASC";
    WorkOrderStepsOrderBy["ID_DESC"] = "ID_DESC";
    WorkOrderStepsOrderBy["SUB_TYPE_ASC"] = "SUB_TYPE_ASC";
    WorkOrderStepsOrderBy["SUB_TYPE_DESC"] = "SUB_TYPE_DESC";
    WorkOrderStepsOrderBy["DOCUMENT_ID_ASC"] = "DOCUMENT_ID_ASC";
    WorkOrderStepsOrderBy["DOCUMENT_ID_DESC"] = "DOCUMENT_ID_DESC";
    WorkOrderStepsOrderBy["DRAWING_NUMBER_ASC"] = "DRAWING_NUMBER_ASC";
    WorkOrderStepsOrderBy["DRAWING_NUMBER_DESC"] = "DRAWING_NUMBER_DESC";
    WorkOrderStepsOrderBy["AS_BUILT_NUMBER_ASC"] = "AS_BUILT_NUMBER_ASC";
    WorkOrderStepsOrderBy["AS_BUILT_NUMBER_DESC"] = "AS_BUILT_NUMBER_DESC";
    WorkOrderStepsOrderBy["SIDE_ASC"] = "SIDE_ASC";
    WorkOrderStepsOrderBy["SIDE_DESC"] = "SIDE_DESC";
    WorkOrderStepsOrderBy["ITEM_INSTANCE_ID_ASC"] = "ITEM_INSTANCE_ID_ASC";
    WorkOrderStepsOrderBy["ITEM_INSTANCE_ID_DESC"] = "ITEM_INSTANCE_ID_DESC";
    WorkOrderStepsOrderBy["INVENTORY_ID_ASC"] = "INVENTORY_ID_ASC";
    WorkOrderStepsOrderBy["INVENTORY_ID_DESC"] = "INVENTORY_ID_DESC";
    WorkOrderStepsOrderBy["CHILD_INVENTORY_ID_ASC"] = "CHILD_INVENTORY_ID_ASC";
    WorkOrderStepsOrderBy["CHILD_INVENTORY_ID_DESC"] = "CHILD_INVENTORY_ID_DESC";
    WorkOrderStepsOrderBy["PAYLOAD_ASC"] = "PAYLOAD_ASC";
    WorkOrderStepsOrderBy["PAYLOAD_DESC"] = "PAYLOAD_DESC";
    WorkOrderStepsOrderBy["IS_APPLIED_ASC"] = "IS_APPLIED_ASC";
    WorkOrderStepsOrderBy["IS_APPLIED_DESC"] = "IS_APPLIED_DESC";
    WorkOrderStepsOrderBy["PROJECTED_DATE_ASC"] = "PROJECTED_DATE_ASC";
    WorkOrderStepsOrderBy["PROJECTED_DATE_DESC"] = "PROJECTED_DATE_DESC";
    WorkOrderStepsOrderBy["OPENED_DATE_ASC"] = "OPENED_DATE_ASC";
    WorkOrderStepsOrderBy["OPENED_DATE_DESC"] = "OPENED_DATE_DESC";
    WorkOrderStepsOrderBy["CREATED_BY_USER_ID_ASC"] = "CREATED_BY_USER_ID_ASC";
    WorkOrderStepsOrderBy["CREATED_BY_USER_ID_DESC"] = "CREATED_BY_USER_ID_DESC";
    WorkOrderStepsOrderBy["USER_GROUP_CODE_ASC"] = "USER_GROUP_CODE_ASC";
    WorkOrderStepsOrderBy["USER_GROUP_CODE_DESC"] = "USER_GROUP_CODE_DESC";
    WorkOrderStepsOrderBy["IS_LINE_ITEM_ASC"] = "IS_LINE_ITEM_ASC";
    WorkOrderStepsOrderBy["IS_LINE_ITEM_DESC"] = "IS_LINE_ITEM_DESC";
    WorkOrderStepsOrderBy["MEETING_ID_ASC"] = "MEETING_ID_ASC";
    WorkOrderStepsOrderBy["MEETING_ID_DESC"] = "MEETING_ID_DESC";
    WorkOrderStepsOrderBy["MEETING_ITEM_ID_ASC"] = "MEETING_ITEM_ID_ASC";
    WorkOrderStepsOrderBy["MEETING_ITEM_ID_DESC"] = "MEETING_ITEM_ID_DESC";
    WorkOrderStepsOrderBy["MEETING_SERIES_ID_ASC"] = "MEETING_SERIES_ID_ASC";
    WorkOrderStepsOrderBy["MEETING_SERIES_ID_DESC"] = "MEETING_SERIES_ID_DESC";
    WorkOrderStepsOrderBy["INITIATOR_CONTACT_ID_ASC"] = "INITIATOR_CONTACT_ID_ASC";
    WorkOrderStepsOrderBy["INITIATOR_CONTACT_ID_DESC"] = "INITIATOR_CONTACT_ID_DESC";
    WorkOrderStepsOrderBy["ENTITY_TYPE_ASC"] = "ENTITY_TYPE_ASC";
    WorkOrderStepsOrderBy["ENTITY_TYPE_DESC"] = "ENTITY_TYPE_DESC";
    WorkOrderStepsOrderBy["CREATED_DATE_TIME_ASC"] = "CREATED_DATE_TIME_ASC";
    WorkOrderStepsOrderBy["CREATED_DATE_TIME_DESC"] = "CREATED_DATE_TIME_DESC";
    WorkOrderStepsOrderBy["STEP_NUMBER_ASC"] = "STEP_NUMBER_ASC";
    WorkOrderStepsOrderBy["STEP_NUMBER_DESC"] = "STEP_NUMBER_DESC";
    WorkOrderStepsOrderBy["ACTION_ASC"] = "ACTION_ASC";
    WorkOrderStepsOrderBy["ACTION_DESC"] = "ACTION_DESC";
    WorkOrderStepsOrderBy["WARNING_ASC"] = "WARNING_ASC";
    WorkOrderStepsOrderBy["WARNING_DESC"] = "WARNING_DESC";
    WorkOrderStepsOrderBy["CAUTION_ASC"] = "CAUTION_ASC";
    WorkOrderStepsOrderBy["CAUTION_DESC"] = "CAUTION_DESC";
    WorkOrderStepsOrderBy["NOTES_ASC"] = "NOTES_ASC";
    WorkOrderStepsOrderBy["NOTES_DESC"] = "NOTES_DESC";
    WorkOrderStepsOrderBy["UPDATED_DATE_TIME_ASC"] = "UPDATED_DATE_TIME_ASC";
    WorkOrderStepsOrderBy["UPDATED_DATE_TIME_DESC"] = "UPDATED_DATE_TIME_DESC";
    WorkOrderStepsOrderBy["ATTRIBUTES_ASC"] = "ATTRIBUTES_ASC";
    WorkOrderStepsOrderBy["ATTRIBUTES_DESC"] = "ATTRIBUTES_DESC";
    WorkOrderStepsOrderBy["TITLE_ASC"] = "TITLE_ASC";
    WorkOrderStepsOrderBy["TITLE_DESC"] = "TITLE_DESC";
    WorkOrderStepsOrderBy["OVERRIDE_START_DATE_ASC"] = "OVERRIDE_START_DATE_ASC";
    WorkOrderStepsOrderBy["OVERRIDE_START_DATE_DESC"] = "OVERRIDE_START_DATE_DESC";
    WorkOrderStepsOrderBy["RISK_SCORE_AT_COMPLETION_ASC"] = "RISK_SCORE_AT_COMPLETION_ASC";
    WorkOrderStepsOrderBy["RISK_SCORE_AT_COMPLETION_DESC"] = "RISK_SCORE_AT_COMPLETION_DESC";
    WorkOrderStepsOrderBy["ASSIGNEE_CONTACT_ID_ASC"] = "ASSIGNEE_CONTACT_ID_ASC";
    WorkOrderStepsOrderBy["ASSIGNEE_CONTACT_ID_DESC"] = "ASSIGNEE_CONTACT_ID_DESC";
    WorkOrderStepsOrderBy["COMPUTED_START_DATE_ASC"] = "COMPUTED_START_DATE_ASC";
    WorkOrderStepsOrderBy["COMPUTED_START_DATE_DESC"] = "COMPUTED_START_DATE_DESC";
    WorkOrderStepsOrderBy["PRIMARY_KEY_ASC"] = "PRIMARY_KEY_ASC";
    WorkOrderStepsOrderBy["PRIMARY_KEY_DESC"] = "PRIMARY_KEY_DESC";
})(WorkOrderStepsOrderBy || (WorkOrderStepsOrderBy = {}));
